import { ListOption } from './../../../../../models/listOption';
import {
  Component,
  OnInit,
  Input,
  ComponentFactoryResolver
} from '@angular/core';
import { ListDataEntity, ControlType } from 'src/app/models/data-entities';
import { DataEntityInputComponent } from '../../data-entity-input/data-entity-input.component';

@Component({
  selector: 'wm-list-data-entity-input',
  templateUrl: './list-data-entity-input.component.html',
  styleUrls: ['./list-data-entity-input.component.css']
})
export class ListDataEntityInputComponent extends DataEntityInputComponent
  implements OnInit {
  @Input() entity: ListDataEntity;
  
  selectedEntityValues: ListOption[];
  listEntity: ListDataEntity;
  selectedDropdownItemId: string = '';

  constructor(componentFactoryResolver: ComponentFactoryResolver) {
    super(componentFactoryResolver);
  }

  ngOnInit() {
    this.selectedEntityValues = [];

    this.listEntity = this.entity;

    this.listEntity.selectedValues.forEach(e => this.addItem(true, e));

    if (
      !this.entity.externalDataSource ||
      !this.entity.externalDataSource.sourceConfig ||
      (this.entity.canEditExternalValue &&
        this.form.controls[this.entity.templateCode])
    ) {
      this.form.controls[this.entity.templateCode].enable();
    } else {
      this.form.controls[this.entity.templateCode].disable();
    }
  }

  addItem(checked: boolean, item: any) {
    if (!item || item === "") {
      // don't do anything if there is no item
      return;
    }

    this.listEntity.value = '';

    let listItem = this.listEntity.availableListItems.find(
      a => a.id === item.id
    );

    // if selected item is not in the list, add it
    if (!listItem) {
      listItem = item;

      this.listEntity.listTextValues.push(listItem);
    }

    if (this.entity.controlType === ControlType.Check) {
      if (checked === false) {
        let i = this.selectedEntityValues.indexOf(item);

        this.selectedEntityValues.splice(i, 1);
      } else {
        this.selectedEntityValues.push(listItem);
      }
    } else {
      this.selectedEntityValues = [];

      this.selectedEntityValues.push(listItem);
    }

    if (this.entity.controlType === ControlType.Dropdown) {
      this.selectedDropdownItemId = listItem.id;
    }

    // remove all of the $id attributes before serializing
    this.selectedEntityValues.forEach(f => delete f['$id']);

    this.listEntity.value = JSON.stringify(this.selectedEntityValues);

    if (this.listEntity.controlType !== ControlType.Dropdown) {
      if (this.listEntity.value === '[]') {
        this.form.controls[this.entity.templateCode].setValue('');
      } else {
        this.form.controls[this.entity.templateCode].setValue(
          this.listEntity.value
        );
      }
    } else {
      this.form.controls[this.entity.templateCode].setValue(listItem.value);
    }
  }

  addItemById(checked: boolean, itemId: string) {
    let listItem = this.listEntity.availableListItems.find(
      a => a.id === itemId
    );

    this.addItem(checked, listItem);
  }

  isSelected(item: any): boolean {
    const selected = !!this.selectedEntityValues.find(v => 
      v && v.id === item.id
    );

    return selected;
  }
}
