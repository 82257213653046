import * as moment from 'moment';
import { parsePhoneNumberFromString } from 'libphonenumber-js';

import { Injectable } from '@angular/core';
import {
  UntypedFormGroup,
  AbstractControl,
  UntypedFormControl,
  ValidationErrors,
  Validators,
  ValidatorFn
} from '@angular/forms';

import { Activity, ActivityModel } from '../models/activities';
import { DataEntity } from '../models/data-entities';
import { WorkflowService, DataEntityFactory } from './workflow.service';

@Injectable()
export class ValidationService {
  static CONFIG = {
    required: 'Required',
    requiredFile: 'Required',
    requiredDate: 'Date is required',
    requiredTime: 'Time is required',
    invalidCreditCard: 'Invalid credit card number',
    email: 'Invalid email address',
    invalidEmailAddress: 'Invalid email address',
    invalidPassword:
      'Invalid password. Password must be at least 6 characters long, and contain a number.',
    invalidURL:
      'Invalid URL. Please enter a valid URL (e.g. http://www.google.com).',
    min: 'Number must be greater than or equal to {min}',
    max: 'Number must be less than or equal to {max}',
    minlength: 'Must be at least {requiredLength} characters long',
    maxlength: 'Must be no more than {requiredLength} characters long',
    appointmentDateMinimum:
      'This appointment must be scheduled no earlier than {0}.',
    appointmentDateMaximum:
      'This appointment must be schedule no later than {0}.',
    appointmentDateAvailable:
      'This date is not available for this appointment.',
    uniqueUsername: 'You must supply a username that is not already taken.',
    invalidPhoneNumber: 'Valid phone number format: 555-555-5555',
    invalidFormula:
      'Your formula is incorrect, ({failedMsg}) please refer to the help if you are unable to resolve the formula',
    decimalPoint: 'Does not meet the decimal requirements ({decimalPoint}).',
    pattern: 'Invalid Input',
    uniqueTemplateCode: 'Template Code already used for {label} on {activity}',
    invalidUserEmail:
      'Please enter a valid email address or a data entity reference to an email address',
    uniqueRoleName: '"{label}" already in use.',
    invalidDate: 'Date is invalid',
    minimumDate: 'Date must be on or after {minimumDate}.',
    fileTypeValidator: 'Please don\'t include a "." in the File Type',
    requiredIfDeNotPresent:
      'If you don\'t have an inspectors DataEntity, or if the inspectors DataEntity isn\'t required you must select a default inspector',
    recipientSpecified: 'You must select at least one Role or \'To\' recipient',
    weekDaysSpecified: 'You must select at least one day of the week',
    monthDaysSpecified: 'You must select at least one day of the month',
    shapeFieldTooMany:
      'For a Shapefile Export, non GIS Data Entities are limited to 50.',
    notShapefileWithGeoJSONSelected:
      'If you check a GIS Data Entities you must export the data as a shapefile.',
    shapefileWithNoGeoJSONSelected:
      'You must check at least one GIS Data Entity in order to export a shapefile.',
    noRenewableFieldMapped:
      'You must specify at least one previous registration field in order to use Previous Registration Data as an external source',
    tabularDataFileSelected:
      'A file must be chosen if Upload Data is selected.',
    attachmentIndexFileSelected:
      'An index file must be chosen if Upload Data Attachments is selected.',
    cannotReplaceData:
      'Apart from attachments, you must choose data to upload in order to clear existing data.',
    noContractorWorkflowFieldMapped:
      'You must choose at least one entity to map from in order to use Previous Contractor Registration Data as an external source',
  };

  static roleNameDebouncer: any;

  static getValidatorErrorMessage(code: string, data?: string[]) {
    let msg = ValidationService.CONFIG[code];

    if (data && msg) {
      const keys = Object.keys(data);
      for (const k in keys) {
        if (k) {
          // for (var x: number = 0; x < data.length; x++) {
          msg = msg.replace('{' + keys[k] + '}', data[keys[k]]);
        }
      }
    }

    return msg;
  }

  static validateEmail(emailValue: string): boolean {
    const tempControl = new UntypedFormControl(emailValue, Validators.email);

    // if there are control errors, return false cause the email is invalid
    if (tempControl.errors && tempControl.errors.email) {
      return false;
    }

    return true;
  }

  static validatePhoneNumber(phoneValue: string): boolean {
    const phoneNumber = parsePhoneNumberFromString(phoneValue, 'US');
    return !!phoneNumber ? phoneNumber.isPossible() : false;
  }

  static fileTypeValidator(control: AbstractControl): ValidationErrors {
    if (!control.value) {
      return null;
    }

    const pattern = new RegExp('^[^.]+$');
    const result = pattern.test(control.value);

    if (!result) {
      return {
        fileTypeValidator: true
      };
    }

    return null;
  }

  static phoneNumberValidator(control: AbstractControl) {
    if (!control || !control.value) {
      return null;
    }

    const response = ValidationService.validatePhoneNumber(control.value)
      ? null
      : { invalidPhoneNumber: true };

    return response;
  }

  static DateValidator(format = 'MM/dd/YYYY', minimumValue = null): any {
    return (control: UntypedFormControl): { [key: string]: any } => {
      if ((control.value || '') !== '') {
        if (control.value && control.value.day) {
          const dateText =
            control.value.month +
            '/' +
            control.value.day +
            '/' +
            control.value.year;
          const val = moment(dateText, format, false);

          if (!val.isValid()) {
            return { invalidDate: true };
          } else if (minimumValue) {
            if (val.isBefore(minimumValue)) {
              return {
                minimumDate: {
                  data: {
                    minimumDate: moment(minimumValue, format).format(
                      'MM/DD/YYYY'
                    )
                  }
                }
              };
            }
          }
        }
      }

      return null;
    };
  }

  static emailOrEntityValidator(control) {
    if (!control || !control.value) {
      return null;
    } else if (control.value.includes('@(') && control.value.includes(')')) {
      return null;
    } else if (!control.value.includes('@(') && !control.value.includes(')')) {
      // validate email address if data entity isn't entered
      return ValidationService.emailValidator(control);
    } else {
      return { invalidUserEmail: true };
    }
  }
  // these should be incorporated into the validation of email using this custom validator.
  // it was proposed to be handled on a ticket seperate from the GetUserNames by email ticket.
  // it is a slightly different pattern, but I wanted to keep the logic close by the
  // email validator the data entities are using.

  static emailOrEntityValidatorCustom() {
    // validate email address if data entity isn't entered
    return ValidationService.customPatternValid({
      pattern: /^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$/,
      msg: 'Invalid email address'
    });
  }

  static customPatternValid(config: any): ValidatorFn {
    return (control: UntypedFormControl) => {
      const urlRegEx: RegExp = config.pattern;
      if (control.value && !control.value.match(urlRegEx)) {
        return {
          invalidMsg: config.msg,
          invalidEmailAddress: true
        };
      } else {
        return null;
      }
    };
  }
  static emailValidator(control) {
    // RFC 2822 compliant regex
    if (
      (control &&
        control.value &&
        ValidationService.validateEmail(control.value)) ||
      control.value === '' ||
      control.value === undefined
    ) {
      return null;
    } else {
      return { invalidEmailAddress: true };
    }
  }

  static templateCodeValidator(
    workflowSvc: WorkflowService,
    workflowId: string,
    origLabel: string,
    state: { reloadingForms: boolean }
  ) {
    return async (control: AbstractControl) => {
      // if the form is being reset, don't validate or this endpoint will get called 15+ times (see WM-1283)
      if (state.reloadingForms) {
        return null;
      }
      if (!control || !control.value) {
        return null;
      }

      const res = await workflowSvc
        .isTemplateCodeAvailable(workflowId, control.value, origLabel)
        .toPromise();

      if (res) {
        return {
          uniqueTemplateCode: {
            data: {
              activity: res.activityName,
              label: res.label
            }
          }
        };
      }

      return null;
    };
  }

  static passwordMatchValidator(
    passwordKey: string,
    confirmPasswordKey: string
  ) {
    return (group: UntypedFormGroup): { [key: string]: any } => {
      const pwd = group.controls[passwordKey];
      const cpwd = group.controls[confirmPasswordKey];

      if (cpwd.value && pwd.value !== cpwd.value) {
        return {
          mismatchedPasswords: true
        };
      }
    };
  }
  static shapefileExportValidator(shapefileValidation) {
    return (control: UntypedFormControl): { [key: string]: any } => {
      return shapefileValidation.validate(control.value);
    };
  }
  static requiredIfDeNotPresent(
    activity: Activity<ActivityModel>,
    deTemplateCode: string,
    group: UntypedFormGroup
  ) {
    return (control: UntypedFormControl): { [key: string]: any } => {
      let valid = false;
      // if it has a defaultOption, clearly it isn't null!
      if (
        control.value !== 'Select Item...' &&
        control.value !== null &&
        control.value !== ''
      ) {
        // 'select item is OKAY NOW!'
        valid = true;
      }

      // if the inspectordefault selector is required then it doesn't matter if it's null; (but it can be...)
      activity.model.getEntities().forEach((de: any, i) => {
        // the only way the default option is valid when null is if the inspectorDefaultDE is required.
        if (de.templateCode === deTemplateCode) {
          if (de.isRequired) {
            // if the Inspector DE is required, then we don't need to select a default.
            valid = true;
          }
        }
      });
      if (valid) {
        return null;
      } else {
        return { requiredIfDeNotPresent: true };
      }
    };
  }
  static requiredIfValidator(
    validateControl: string,
    ifControl: string,
    group: UntypedFormGroup
  ) {
    return (control: UntypedFormControl): { [key: string]: any } => {
      if (
        (group.controls[ifControl].value || '') !== '' &&
        (control.value || '') === ''
      ) {
        return {
          required: false
        };
      }
    };
  }

  static requiredFileValidator(control) {
    if (control && control.value) {
      return null;
    } else {
      return { requiredFile: true };
    }
  }

  static min(min: any) {
    return (control: AbstractControl) => {
      const actual = parseFloat(control.value);
      if (actual < min) {
        return {
          min: {
            data: {
              min,
              actual
            }
          }
        };
      }
    };
  }

  static max(max: any) {
    return (control: AbstractControl) => {
      const actual = parseFloat(control.value);
      if (actual > max) {
        return {
          max: {
            data: {
              max,
              actual
            }
          }
        };
      }
    };
  }

  static decimalValidator(decimalPlaces: number) {
    return control => {
      const decPattern: RegExp = new RegExp(
        '^\\d*.\\d{' + decimalPlaces.toString() + '}$'
      );
      if (!decPattern.test(control.value)) {
        return {
          decimalPoint: {
            data: {
              decimalPoint: decimalPlaces
            }
          }
        };
      }
    };
  }

  static customPatternValidator(customPattern) {
    return control => {
      const exp: RegExp = new RegExp(customPattern);

      return {
        customPatternValidator: {
          result: exp.test(control.value)
        }
      };
    };
  }

  static createEntityValidationGroup(de: DataEntity): UntypedFormControl {
    const deValidators = de.getValidators();

    if (deValidators && deValidators.length > 0) {
      return new UntypedFormControl('', Validators.compose(deValidators));
    } else {
      return new UntypedFormControl('', Validators.nullValidator);
    }
  }

  static createValidationGroup(activity: Activity<ActivityModel>) {
    const groups: { [key: string]: AbstractControl } = {};

    activity.model.getEntities().forEach((de: any, i) => {
      if (!(de instanceof DataEntity)) {
        de = DataEntityFactory.createDataEntity(de.dataEntityTypeCode, de);
      }

      const deValidationControl = this.createEntityValidationGroup(de);

      groups[de.templateCode] = deValidationControl;
    });

    return groups;
  }
}
