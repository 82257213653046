<app-loader *ngIf="!announcements"></app-loader>

<ng-container *ngIf="announcements">

    <div class="row mt-4">
        <div class="col">
          <h3>Announcements</h3>
        </div>

        <div class="col">
            <button
                class="btn btn-raised btn-success float-right"
                (click)="createAnnouncement()"
            >
                New Announcement
            </button>
        </div>
    </div>

    <div class="row ml-2 mt-3" *ngIf="announcements.length === 0">
        <div class="flex-col pt-2">
            <i>No Announcements to display</i>
        </div>
    </div>
    
    <table class="table mt-3 text-left" *ngIf="announcements.length > 0">
        <tbody>
            <tr>
                <th>Title</th>
                <th>Status</th>
                <th></th>
                <th>Start Date</th>
                <th>End Date</th>
                <th>Audience</th>
                <th></th>
                <th></th>
            </tr>
            <tr *ngFor="let a of announcements">
                <td>
                    {{ a.title }}
                </td>
                <td>
                    <div 
                        *ngIf="a.isLive"
                        class="badge badge-pill badge-success"
                    >
                        Live
                    </div>
                    <div *ngIf="!a.isLive && a.isEnabled">
                        Inactive
                    </div>
                    <div *ngIf="!a.isLive && !a.isEnabled">
                        Disabled
                    </div>
                </td>
                <td>
                    <ng-container *ngIf="a.isEnabled">
                        <button
                            class="btn btn-raised btn-danger"
                            (click)="changeEnabledStatus(a.id, false)"
                        >
                            Disable
                        </button>
                    </ng-container>
                    <ng-container *ngIf="!a.isEnabled">
                        <button
                            class="btn btn-raised btn-primary"
                            (click)="changeEnabledStatus(a.id, true)"
                        >
                            Enable
                        </button>
                    </ng-container>
                </td>
                <td>
                    {{ a.startDateString | date: 'M/d/yyyy h:mm a' }}
                </td>
                <td>
                    {{ a.endDateString | date: 'M/d/yyyy h:mm a' }}
                </td>
                <td>
                    <ng-container *ngIf="a.audienceIsGlobal">
                        All Jurisdictions
                    </ng-container>                                 
                    <ng-container *ngIf="a.announcementClients && a.announcementClients.length > 0">                            
                        <div class="row" *ngFor="let ac of a.announcementClients; let idx = index">
                            <ng-container *ngIf="idx < 3">
                                {{ ac.clientName }}
                            </ng-container>
                        </div>
                        <ng-container *ngIf="a.announcementClients.length > 3">
                            and {{ a.announcementClients.length - 3 }} more 
                        </ng-container>
                    </ng-container>

                    <ng-container *ngIf="a.announcementStates && a.announcementStates.length > 0">
                        <div *ngFor="let ast of a.announcementStates; let idx = index">
                            <ng-container *ngIf="idx < 3">
                                {{ ast.stateName }}
                            </ng-container>
                        </div>
                        <ng-container *ngIf="a.announcementStates.length > 3">
                            and {{ a.announcementStates.length - 3 }} more 
                        </ng-container>
                    </ng-container>
                </td>
                <td>
                    <button class="btn btn-primary btn-sm" (click)="editAnnouncement(a)">
                        Edit
                    </button>
                </td>
                <td>
                    <button class="btn btn-primary btn-sm" (click)="deleteAnnouncement(a.id)">
                    Delete
                    </button>
                </td>
            </tr>
        </tbody>
    </table>

</ng-container>

<wm-modal-confirm
    #deleteAnnouncementModal
    [showButtonText]="true"
    [showButton]="false"
    commandText="Yes"
    title="Delete Announcement"
    (accepted)="deleteAnnouncement(deletingAnnouncementId, true)"
    btnColor="danger"
    class="mx-2"
    [closeOnAccept]="false"
>
    Are you sure you want to delete this Announcement?
</wm-modal-confirm>

<wm-modal-confirm
    #announcementEditorModal
    [showButton]="false"
    commandText="Save"
    title="Announcement"
    (accepted)="saveClicked()"
    (canceled)="cancelEditing()"
    btnColor="success"
    class="mx-2"
    [closeOnAccept]="false"
    [form]="form"
>
    <wm-announcement-editor
        #announcementEditor
        *ngIf="editingAnnouncement"
        [announcement]="editingAnnouncement"
        [globalManagement]="globalManagement"
        [availableJurisdictions]="availableJurisdictions"
        [availableStates]="availableStates"
        [form]="form"
    ></wm-announcement-editor>
</wm-modal-confirm>
