import { PreviousRouteService } from './../../../services/previous-route';
import { Workflow } from './../../../models/workflow';
import { FilterBuilderParamOpts } from './../../../models/filter-builder';
import { Actions } from './../../../models/actions';
import { SecurityService } from './../../../services/security.service';
import { ToastrService } from 'ngx-toastr';
import { ModalConfirmComponent } from './../../system/modal-confirm/modal-confirm.component';
import { DatePipe } from '@angular/common';
import { UntypedFormGroup, UntypedFormControl, Validators } from '@angular/forms';
import { BulkAction, DatatableComponent } from './../../system/datatable/datatable.component';
import { FilterBuilderParam } from 'src/app/models/filter-builder';
import { DataTableHelpers } from './../../system/datatable/datatable-helper';
import { ApplicationStatusPipe } from 'src/app/pipes/application-status.pipe';
import {
  Component,
  OnInit,
  Input,
  Output,
  EventEmitter,
  ChangeDetectorRef,
  ContentChild,
  TemplateRef,
  OnDestroy,
  ViewChild
} from '@angular/core';
import { WorkflowContextService } from 'src/app/services';
import { DataService } from '../../../services';
import { WorkflowApplicationVM, ApplicationStatus } from 'src/app/models';
import {
  ItemSearchOptionField,
  ItemSearchUtils
} from 'src/app/components/filter-list/models/filterClasses';
import { MapDataRequest } from 'src/app/components/arcgis/basic-esri-map/basic-esri-map.component';
import { map } from 'rxjs/operators';
import { Router } from '@angular/router';
import { ColumnMode, TableColumn } from '@swimlane/ngx-datatable';
import * as moment from 'moment';
import { ColumnOption, GridSettings } from 'src/app/models/grid-settings';

@Component({
  selector: 'wm-workflow-application-list',
  templateUrl: './workflow-application-list.component.html',
  styleUrls: ['./workflow-application-list.component.css']
})
export class WorkflowApplicationListComponent implements OnInit, OnDestroy {
  @ViewChild('voidConfirm', { static: true }) voidConfirm: ModalConfirmComponent;
  @ViewChild('currentActivitiesConfirm', { static: true }) currentActivitiesConfirm: ModalConfirmComponent;

  @Output() dataLoaded = new EventEmitter<string>();
  @Output() recordCountSet = new EventEmitter<number>();
  @Output() filtersChanged = new EventEmitter<boolean>();

  @Input() public workflows: Workflow[];
  @Input() public id: string;
  @Input() public workflowId: string;
  @Input() public expandToChild = false;
  @Input() staticFilters: ItemSearchOptionField[] = [];
  @Input() public sortField = 'dateCreated';
  @Input() public UseMap = true;
  @Input() public sortDescending = true;
  @Input() public showAll = false;
  public filterOptions: ItemSearchOptionField[];

  workflowOpts: FilterBuilderParamOpts[] = [];

  @Input()
  public filterCriteria: FilterBuilderParam[];

  mapPopupActions = [
    {
      // This text is displayed as a tooltip
      title: 'View Application',
      // The ID by which to reference the action in the event handler
      id: 'view-application',
      // Sets the icon font used to style the action button
      className: 'esri-icon-zoom-out-magnifying-glass',
      // Handler of the action
      handler: item => {
        // navigate to the application
        this.router.navigate([
          `/application/workflow-application/${item.appId}`
        ]);
      }
    }
  ];

  loading = true;
  applications: WorkflowApplicationVM[];
  public page = 1;
  public submitPage = 1;

  public pageSize = 10;
  public pageCount = 1;
  public recordCount = 0;
  isDestroyed = false;

  selected: string[];
  selectedApplication: WorkflowApplicationVM;

  now = Date.now();
  pipe = new DatePipe('en-US');
  voidForm: UntypedFormGroup = new UntypedFormGroup({
    voidReason: new UntypedFormControl('', [Validators.required]),
    voidDateTime: new UntypedFormControl({
      value: this.pipe.transform(this.now, 'MM/dd/yyyy h:mm:ss a'),
      disabled: true
    }),
    voidedBy: new UntypedFormControl({
      value: this.context.user ? this.context.user.userName : '',
      disabled: true
    })
  });
  get voidReason() {
    return this.voidForm.get('voidReason');
  }

  public bulkActions: BulkAction[] = [];

  @ViewChild('currentActivityTmpl', { static: true }) currentActivityTmpl: TemplateRef<any>;
  @ContentChild(TemplateRef, { static: true}) templateVariable: TemplateRef<any>;
  @ViewChild('appIdTmpl', { static: true }) appIdTmpl: TemplateRef<any>;

  @Input() columns: TableColumn[];
  availableColumns: TableColumn[];
  columnOptions: ColumnOption[] = [];
  ColumnMode = ColumnMode;
  unpermissibleCount = 0;
  ApplicationStatus = ApplicationStatus;

  // column prop strings to be hidden on the grid by default
  defaultHiddenColumns: string[] = [];
  // column prop strings that should have no option to hide in the grid, and are not exportable (such as button columns like Refund or Actions)
  actionColumns: string[] = [];
  // column prop strings to be excluded from exports by default
  defaultExportExludedColumns: string[] = [];

  constructor(
    public context: WorkflowContextService,
    public ds: DataService,
    public router: Router,
    private dtHelpers: DataTableHelpers,
    private toast: ToastrService,
    private _securitySvc: SecurityService,
    public ref: ChangeDetectorRef,
    public prevRouteSvc: PreviousRouteService
  ) {}

  getId = row => {
    return row && row.id;
  };

  showCurrentActivities(row: any) {
    this.selectedApplication = row;
    this.currentActivitiesConfirm.open();
  }

  async bulkVoidApplications(applicationIds: string[]) {
    this.loading = true;
    const voidRequest = {
      applicationIds,
      voidReason: this.voidReason.value,
      voidDateTime: new Date(),
      voidedByUser: this.context.user.id
    };

    await this.ds.bulkVoidApplications(voidRequest).toPromise();

    this.toast.success(`${this.selected.length} application(s) voided!`);

    await this.requestData();
  }

  detectChanges() {
    if (!this.isDestroyed) {
      this.ref.detectChanges();
    }
  }

  ngOnDestroy() {
    this.isDestroyed = true;
    this.ref.detach();
  }

  initFilters() {
    this.filterCriteria = this.filterCriteria || [
      {
        name: 'Application Number',
        id: 'applicationNumber'
      },
      {
        name: 'Permit Type',
        id: 'permitType',
        types: ['is', 'in'],
        options: this.workflowOpts
      },
      {
        name: 'Description',
        id: 'description'
      },
      {
        name: 'Applicant Name',
        id: 'applicantsName'
      },
      {
        name: 'Date Created',
        id: 'dateCreated',
        inputType: 'date',
        types: ['is', 'range']
      },
      {
        name: 'Submitter',
        id: 'submitterId',
        types: ['is']
      },
      {
        name: 'Status',
        id: 'status',
        types: ['is', 'in'],
        options: [
          {
            name: 'Not Started',
            value: '0'
          },
          {
            name: 'In Progress',
            value: '1'
          },
          {
            name: 'Completed',
            value: '2'
          },
          {
            name: 'Voided',
            value: '3'
          },
          {
            name: 'Rejected',
            value: '4'
          },
          {
            name: 'Discarded',
            value: '5'
          }
        ]
      },
      {
        name: 'Parcel Address',
        id: 'parcelAddress'
      },
      {
        name: 'Parcel City',
        id: 'parcelCity',
        types: ['is', 'contains', 'in']
      },
      {
        name: 'Parcel Id',
        id: 'parcelId',
        types: ['is', 'contains', 'in']
      },
      {
        name: 'Parcel Owner',
        id: 'parcelOwner'
      },
      {
        name: 'Current Activity',
        id: 'currentActivity',
        types: ['is', 'contains', 'in']
      }
    ];
  }

  handleSettingsChanged(event: GridSettings) {
    this.filterOptions = event.legacyFilters;
    this.pageSize = event.pageSize;
    this.submitPage = event.pageNumber + 1;
    this.sortField = event.sortField;
    this.sortDescending = event.sortDescending;
    this.columnOptions = event.columnOptions;

    const visibleColumnProps = this.columnOptions
      .filter(co => co.checked)
      .map(co => co.name);
    this.columns = this.availableColumns.filter(
      c =>
        visibleColumnProps.includes(c.prop as string) ||
        this.actionColumns.includes(c.prop as string)
    );

    this.requestData();
    this.filtersChanged.emit(true);
  }

  handleColumnOptionsChanged(event: ColumnOption[]) {
    this.columnOptions = event;
  }

  ngOnInit() {
    if (this.workflows) {
      this.workflowOpts = [
        ...this.workflows.map(wf => ({
          name: wf.version.name,
          value: wf.id
        }))
      ];
    }
    this.initFilters();
    const currentActivityCol = this.columns ? this.columns.find(c=> c.prop == 'currentActivityData') : null;

    if(currentActivityCol) {
      currentActivityCol.cellTemplate = this.currentActivityTmpl;
    }
    this.columns = this.columns || [
      {
        prop: 'applicationNumber',
        name: 'Application Number',
        cellTemplate: this.appIdTmpl
      },
      { prop: 'permitType', name: 'Type' },
      { prop: 'description', name: 'Description' },
      { prop: 'applicantsName', name: 'Applicant' },
      {
        prop: 'dateCreated',
        name: 'Date Created',
        pipe: this.dtHelpers.getDatePipe()
      },
      {
        prop: 'status',
        name: 'Status',
        pipe: new ApplicationStatusPipe()
      },
      {
        prop: 'currentActivityName',
        name: 'Current Activity',
        cellTemplate: this.currentActivityTmpl
      }
    ];
    this.setAvailableColumns();

    this.filterOptions = [];

    this._securitySvc
      .isLoginEntitled(Actions.WORKFLOW_APPLICATIONS_VOID)
      .subscribe(isEntitledResult => {
        if (isEntitledResult) {
          this.bulkActions.push({
            name: 'Void',
            id: 'void',
            action: async rows => {
              const applicationIds = rows.map(a => a.id);
              this.selected = applicationIds;
              this.voidReason.reset();

              this.voidConfirm.open();

              return false;
            }
          });
        }
      });
  }

  setAvailableColumns() {
    this.availableColumns = this.columns;
  }

  emitDataLoaded() {
    this.dataLoaded.emit('WorkflowApplicationListComponent');
  }

  requestData() {
    this.loading = true;
    this.detectChanges();

    if (this.context.user) {
      const filter = ItemSearchUtils.mergeFilters(
        this.filterOptions,
        this.staticFilters
      );

      this.ds
        .getAllWorkflowApplications(
          this.workflowId,
          this.sortField,
          this.sortDescending,
          filter,
          this.submitPage,
          this.pageSize,
          null,
          this.showAll
        )
        .subscribe(applications => {
          this.loading = false;
          this.detectChanges();

          this.unpermissibleCount = applications.unpermissibleCount;
          this.page = this.submitPage;
          this.pageCount = applications.pageCount;
          this.recordCount = applications.recordCount;
          this.applications = applications.workflowApplications.map(wa => {
            return {
              ...wa,
              currentActivityDetails: wa.currentActivityData ? JSON.parse(wa.currentActivityData) : null
            };
          });

          this.recordCountSet.emit(this.recordCount);
          this.emitDataLoaded();
        });
    }
  }

  onMapDataRequested(q: MapDataRequest) {
    const filter = ItemSearchUtils.mergeFilters(q.query, this.staticFilters);

    this.ds
      .getAllWorkflowApplications(
        this.workflowId,
        null,
        false,
        filter,
        1,
        500,
        q.ext,
        this.showAll
      )
      .pipe(map(data => data.workflowApplications))
      .subscribe(data => {
        const appsMapped = data.map(app => {
          return {
            geometryWkts: app.geometryWkts,
            data: {
              appId: app.id,
              applicantsName: app.applicantsName,
              applicationNumber: app.applicationNumber,
              dateCreated: moment(app.dateCreated).format('MM/DD/YYYY hh:mm A'),
              description: app.description,
              permitType: app.permitType,
              status: ApplicationStatus[parseFloat(app.status)]
            }
          };
        });

        q.gimmeTheData$.emit(appsMapped);
      });
  }
}
