import { AfterViewInit, Component, EventEmitter, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { interval, Subscription } from 'rxjs';
import { Announcement } from 'src/app/models/announcement';
import { AnnouncementService, SystemService, WorkflowContextService } from 'src/app/services';
import { ModalConfirmComponent } from '../system/modal-confirm/modal-confirm.component';

@Component({
  selector: 'wm-announcements-banner',
  templateUrl: './announcements-banner.component.html',
  styleUrl: './announcements-banner.component.css'
})
export class AnnouncementsBannerComponent implements OnInit, OnDestroy, AfterViewInit {
  @ViewChild('fullAnnouncement') fullAnnouncementModal: ModalConfirmComponent;

  @Output() bannerOpened: EventEmitter<boolean> = new EventEmitter();
  @Output() bannerClosed: EventEmitter<boolean> = new EventEmitter();

  announcements: Announcement[] = [];
  userAnnouncements: Announcement[];
  clientSub: Subscription;
  dismissedAnnouncementIdsLocalStorageKey = 'dismissed-announcementIds';
  visibleAnnouncement: Announcement;
  visibleAnnouncementIndex: number = 0;
  displayStyle = 'none';
  newAnnouncementSub: Subscription;
  
  constructor(
    private _context: WorkflowContextService,
    private _announcementSvc: AnnouncementService
  ) {}

  ngOnInit() {
    if (!this._announcementSvc.isAnnouncementBannerClosed() || this._announcementSvc.newAnnouncementsAvailable()) {
      this._announcementSvc.getGlobalAnnouncements()
        .subscribe(a => {
          if (a && a.length > 0) {
            this.announcements.push(...a);

            this.setUserAnnouncements(true);
          }
        });

      if (this._context && this._context.client) {
        this.getAnnouncementsForClient(this._context.client.id);
      } else {
        this.clientSub = this._context.client$.subscribe(() => {
          if (this._context.client) {
            this.getAnnouncementsForClient(this._context.client.id);
          }
        });
      }
    }

    this.newAnnouncementSub = this._announcementSvc.newAnnouncementsFound
      .subscribe(newAnnouncements => {
        this.announcements = newAnnouncements;

        this.setUserAnnouncements();
      });
  }

  ngAfterViewInit() {
    this._announcementSvc.startAnnouncementEngine();
  }
  
  ngOnDestroy() {
    if (this.clientSub) {
      this.clientSub.unsubscribe();
      this.clientSub = null;
    }

    if (this.newAnnouncementSub) {
      this.newAnnouncementSub.unsubscribe();
      this.newAnnouncementSub = null;
    }

    this._announcementSvc.stopAnnouncementEngine();
  }

  getAnnouncementsForClient(clientId: string) {
    this._announcementSvc.getAnnouncementsForClient(clientId)
      .subscribe(announcementsForClient => {
        if (announcementsForClient && announcementsForClient.length > 0) {
          this.announcements.push(...announcementsForClient);
  
          this.setUserAnnouncements(true);
        }
    });
  }

  setUserAnnouncements(showFirst = false) {
    if (showFirst) {
      this.visibleAnnouncementIndex = 0;
    }

    let userAnnouncements: Announcement[] = [];

    if (this.announcements && this.announcements.length > 0) {
      this._announcementSvc.setMostRecentAnnouncementIds(this.announcements.map(ua => ua.id));

      const dismissedAnnouncementIds = JSON.parse(
        localStorage.getItem(this.dismissedAnnouncementIdsLocalStorageKey) || '[]'
      ) as string[];
  
      userAnnouncements = this.announcements.filter(a => 
        !dismissedAnnouncementIds 
        || dismissedAnnouncementIds.length === 0
        || !dismissedAnnouncementIds.includes(a.id)
      );
  
      // sort by newest first
      this.userAnnouncements = userAnnouncements.sort(function(a, b) {
        return new Date(b.modifiedOnString).getTime() - new Date(a.modifiedOnString).getTime();
      });

      if (this.userAnnouncements.length > 0) {
        if (!this.visibleAnnouncement || showFirst) {
          this.visibleAnnouncement = this.userAnnouncements[this.visibleAnnouncementIndex];
        }
      }

      if (this.visibleAnnouncement) {
        this.openBanner();
      }
    }
  }

  nextAnnouncemet() {
    this.visibleAnnouncementIndex = this.visibleAnnouncementIndex + 1;

    this.setVisibleAnnouncement();
  }

  previousAnnouncement() {
    if (this.visibleAnnouncementIndex !== 0) {
      this.visibleAnnouncementIndex = this.visibleAnnouncementIndex - 1;
    }

    this.setVisibleAnnouncement();
  }

  setVisibleAnnouncement() {
    this.visibleAnnouncement = (this.userAnnouncements.length > 0) 
      ? this.userAnnouncements[this.visibleAnnouncementIndex]
      : null;

    if (!this.visibleAnnouncement) {
      this.closeBanner();
    }
  }

  openAnnouncement() {
    this.fullAnnouncementModal.open();
  }

  dismissAnnouncement() {
    const dismissedAnnouncementId = this.visibleAnnouncement.id;

    this.userAnnouncements = this.userAnnouncements.filter(ua => ua.id !== dismissedAnnouncementId);

    // did we dismiss the last announcement in the list? if so, back up the previous announcement
    if (this.userAnnouncements.length === this.visibleAnnouncementIndex) {
      this.previousAnnouncement();
    } else {
      this.setVisibleAnnouncement();
    }

    let dismissedAnnouncementIds = JSON.parse(
      localStorage.getItem(this.dismissedAnnouncementIdsLocalStorageKey) || '[]'
    ) as string[];

    dismissedAnnouncementIds.push(dismissedAnnouncementId);

    localStorage.setItem(
      this.dismissedAnnouncementIdsLocalStorageKey,
      JSON.stringify(dismissedAnnouncementIds)
    );
  }

  openBanner() {
    this.displayStyle = 'inline-block';

    this.bannerOpened.emit(true);
  }

  closeBanner() {
    this.displayStyle = 'none';

    this._announcementSvc.userClosedAnnouncementBanner();

    this.bannerClosed.emit(true);
  }
}
