<div class="row">
    <div class="col">
      <h3>Global Custom Lists</h3>
    </div>
    <div class="col-2">
      <button
        class="btn btn-raised btn-primary float-right"
        (click)="addCustomList()"
      >
        Add Custom List
      </button>
    </div>
</div>

<app-loader *ngIf="!customLists"></app-loader>

<div *ngIf="customLists && customLists.length === 0">
  <p>No custom lists to display</p>
</div>

<table class="table mt-2" *ngIf="customLists && customLists.length > 0">
  <thead>
    <tr>
      <th scope="col">Name</th>
      <th scope="col">Actions</th>
    </tr>
  </thead>
  <tbody>
    <tr *ngFor="let list of customLists">
      <th scope="row">
        {{ list.name }}
      </th>
      <td>
        <button class="btn btn-primary btn-sm" (click)="editCustomList(list)">
          Edit
        </button>
      </td>
    </tr>
  </tbody>
</table>