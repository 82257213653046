<app-navbar 
  [client]="context.client"
></app-navbar>

<div class="hero-client">
  <div class="container">
    <!-- <div class="row">
      <div class="col">
        <h2 class="mb-3">{{ (client && client.name) || 'Jurisdiction' }}</h2>
      </div>
    </div> -->
    <div class="row">
      <!-- Applications -->
      <div class="col-md-8">
        <div class="row" *ngIf="context.isLoggedIn && context.user">
          <div class="col mb-4">
            <div class="mb-3 d-flex justify-content-between">
              <h4>My Applications</h4>

              <button
                class="btn btn-secondary btn-sm"
                [routerLink]="['/applications']"
              >
                Show More
              </button>
            </div>

            <wm-user-workflow-application-list
              *ngIf="context.client"
              [user]="context.user"
            ></wm-user-workflow-application-list>
          </div>
        </div>

        <div class="row" *ngIf="context.user">
          <div class="col">
            <!-- Contractor Registration Renewals-->
            <div *ngIf="contractorRegistrations">
              <div *ngIf="contractorRegistrations.length > 0">
                <wm-registration-list-section
                  title="My Contractor Registration Renewals"
                  [areRenewalRegistrations]="false"
                  [contractorRegistrations]="contractorRegistrations"
                ></wm-registration-list-section>
              </div>
            </div>

            <!-- Renewable Renewals -->
            <div *ngIf="renewalRegistrations">
              <div *ngIf="renewalRegistrations.length > 0">
                <wm-registration-list-section
                  title="My Renewals"
                  [areRenewalRegistrations]="true"
                  [renewalRegistrations]="renewalRegistrations"
                ></wm-registration-list-section>
              </div>
            </div>
          </div>
        </div>

        <!-- Regular Workflows -->
        <div class="row">
          <div class="col">
            <app-loader *ngIf="!workflows"></app-loader>

            <div *ngIf="workflows">
              <p *ngIf="workflows.length === 0">
                There are no applications
              </p>

              <wm-workflow-list-section
                *ngIf="workflowTags.length === 0"
                title="Applications"
                [workflows]="workflows"
              ></wm-workflow-list-section>

              <!-- Tagged workflows -->
              <ng-container *ngIf="workflowTags.length > 0">
                <div *ngFor="let tag of workflowTags" [id]="tag.id">
                  <wm-workflow-list-section
                    [title]="tag.name"
                    [workflows]="getWorkflowsForTag(workflows, tag.id)"
                  ></wm-workflow-list-section>
                </div>

                <ng-container
                  *ngIf="
                    workflows && getWorkflowsNotTagged(workflows).length > 0
                  "
                >
                  <wm-workflow-list-section
                    title="Other Applications"
                    [workflows]="getWorkflowsNotTagged(workflows)"
                  ></wm-workflow-list-section>
                </ng-container>
              </ng-container>
            </div>
          </div>
        </div>

        <!-- Contractor Workflows -->
        <div class="row">
          <div class="col">
            <app-loader *ngIf="!contractorWorkflows"></app-loader>

            <div *ngIf="contractorWorkflows">
              <p *ngIf="contractorWorkflows.length === 0">
                There are no applications
              </p>

              <wm-workflow-list-section
                *ngIf="contractorTags.length === 0"
                title="Contractor Applications"
                [workflows]="contractorWorkflows"
              ></wm-workflow-list-section>

              <!-- Tagged workflows -->
              <ng-container *ngIf="contractorTags.length > 0">
                <div *ngFor="let tag of contractorTags" [id]="tag.id">
                  <wm-workflow-list-section
                    [title]="tag.name"
                    [workflows]="
                      getWorkflowsForTag(contractorWorkflows, tag.id)
                    "
                  ></wm-workflow-list-section>
                </div>

                <ng-container
                  *ngIf="
                    contractorWorkflows &&
                    getWorkflowsNotTagged(contractorWorkflows).length > 0
                  "
                >
                  <wm-workflow-list-section
                    title="Other Contractor Applications"
                    [workflows]="getWorkflowsNotTagged(contractorWorkflows)"
                  ></wm-workflow-list-section>
                </ng-container>
              </ng-container>
            </div>
          </div>
        </div>
      </div>

      <!-- Sidebar -->
      <div class="col-md-4">
        <div class="row">
          <div class="col">
            <div class="card">
              <div class="card-body card-sidebar">
                <h5 class="card-title">
                  Contacts
                </h5>

                <ul class="list-group">
                  <li class="list-group-item" *ngFor="let contact of contacts">
                    <div class="bmd-list-group-col">
                      <p class="list-group-item-heading mb-1">
                        {{ contact.firstName }} {{ contact.lastName }}
                      </p>
                      <p class="list-group-item-text" *ngIf="contact.title">
                        {{ contact.title }}
                      </p>
                      <p
                        class="list-group-item-text"
                        *ngIf="contact.emailAddress"
                      >
                        <a
                          href="javascript:void(0)"
                          (click)="openContact(content, contact)"
                          ngbTooltip="View contact info"
                        >
                          Contact
                        </a>
                      </p>
                    </div>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<ng-template #content let-modal>
  <div class="modal-header">
    <h4 class="modal-title" id="modal-contact">
      {{ selectedContact?.firstName }}
      {{ selectedContact?.lastName }}
    </h4>
    <button
      type="button"
      class="close"
      aria-label="Close"
      (click)="modal.dismiss('Cross click')"
    >
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <table class="table table-borderless">
      <tbody>
        <tr *ngIf="selectedContact?.title">
          <th scope="row">Title</th>
          <td>{{ selectedContact.title }}</td>
        </tr>
        <tr *ngIf="selectedContact?.phoneNumber">
          <th scope="row">Phone</th>
          <td>
            <a href="tel:{{ selectedContact.phoneNumber }}">
              {{ selectedContact.phoneNumber }}
            </a>
          </td>
        </tr>
        <tr *ngIf="selectedContact?.emailAddress?.email">
          <th scope="row">Email</th>
          <td>
            <a href="mailto:{{ selectedContact.emailAddress.email }}">
              {{ selectedContact.emailAddress.email }}
            </a>
          </td>
        </tr>
        <tr *ngIf="selectedContact?.address?.address1">
          <th scope="row">Address</th>
          <td>
            {{ selectedContact?.address.address1 }}<br />
            {{ selectedContact?.address.address2 }}
            <br *ngIf="selectedContact?.address.address2" />

            {{ selectedContact?.address.city }},
            {{ selectedContact?.address.state }}
            {{ selectedContact?.address.zip }}
          </td>
        </tr>
        <tr *ngIf="selectedContact?.officeHours">
          <th scope="row">Office Hours</th>
          <td>
            <div [innerHTML]="getOfficeHours(selectedContact)"></div>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
  <div class="modal-footer">
    <button
      type="button"
      class="btn btn-outline-dark"
      (click)="modal.close('Close click')"
    >
      Close
    </button>
  </div>
</ng-template>
