import { Component, OnInit, ViewChild } from '@angular/core';
import { CustomListListViewComponent } from '../custom-list-editor/custom-list-list-view/custom-list-list-view.component';
import { SystemService } from 'src/app/services';
import { Router } from '@angular/router';
import { CustomList } from 'src/app/models/custom-list';

@Component({
  selector: 'wm-global-custom-lists-list',
  templateUrl: './global-custom-lists-list.component.html',
  styleUrl: './global-custom-lists-list.component.css'
})
export class GlobalCustomListsListComponent implements OnInit {
  customLists: CustomList[];

  constructor(
    private _router: Router,
    private _systemSvc: SystemService
  ) {}

  getCustomLists() {
    this._systemSvc
      .getGlobalCustomLists()
      .subscribe(customLists => {
        this.customLists = customLists;
      });
  }

  ngOnInit() {
    this.getCustomLists();
  }

  addCustomList() {
    this._router.navigate([
      `/admin/global/custom-lists/add`
    ]);
  }

  editCustomList(list: any) {
    this._router.navigate([
      '/admin/global/custom-lists',
      'edit',
      list.id
    ]);
  }
}
