import { Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { ModalConfirmComponent } from 'src/app/components/system/modal-confirm/modal-confirm.component';
import { Actions } from 'src/app/models';
import { Announcement, AvailableAnnouncementClient, AvailableAnnouncementState } from 'src/app/models/announcement';
import { AnnouncementService, SecurityService, Utilities, WorkflowContextService } from 'src/app/services';
import { AnnouncementEditorComponent } from '../announcement-editor/announcement-editor.component';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';

@Component({
  selector: 'wm-announcements-editor',
  templateUrl: './announcements-editor.component.html',
  styleUrl: './announcements-editor.component.css'
})
export class AnnouncementsEditorComponent implements OnInit {
  @ViewChild('announcementEditor', { static: false }) announcementEditor: AnnouncementEditorComponent;
  @ViewChild('announcementEditorModal') editModal: ModalConfirmComponent;
  @ViewChild('deleteAnnouncementModal') deleteModal: ModalConfirmComponent;

  globalManagement = false;
  clientId: string; // pass a value for this when the app.module route for the client Admin's component
  canManageAnnouncements = false;
  announcements: Announcement[];
  editingAnnouncement: Announcement = null;
  deletingAnnouncementId: string;
  availableJurisdictions: AvailableAnnouncementClient[] = [];
  availableStates: AvailableAnnouncementState[] = [];
  form: UntypedFormGroup;

  constructor (
    private _context: WorkflowContextService,
    private _route: ActivatedRoute,
    private _securitySvc: SecurityService,
    private _announcementSvc: AnnouncementService,
    private _toastr: ToastrService,
    private _fb: UntypedFormBuilder
  ) { }

  ngOnInit() {
    // create an empty form group to be populated by children
    this.form = this._fb.group({});

    this._route.data.subscribe(d => {
      if (d.globalManagement) {
        this.globalManagement = d.globalManagement;
      }
      if (d.clientId) {
        this.clientId = d.clientId;
      }

      this._securitySvc
      .isLoginEntitled(Actions.DO_ANYTHING)
      .subscribe(r => {
        this.canManageAnnouncements = r;
      });

      this.getAnnouncements();
    });
  }

  getAnnouncements() {
    this._announcementSvc.getAnnouncementsToManage(
      this.globalManagement,
      this.globalManagement ? null : this.clientId
    ).subscribe(resp => {
      this.announcements = resp || [];

      if (this.globalManagement) {
        this. getGlobalAnnouncementAudiences();
      }
    });
  }

  getGlobalAnnouncementAudiences() {
    this._announcementSvc.getGlobalAnnouncementAudiences()
      .subscribe(gaa => {
        this.availableJurisdictions = gaa.availableJurisdictions;
        this.availableStates = gaa.availableStates;
      });
  }

  createAnnouncement() {
    let newAnnouncement = new Announcement({
      id: Utilities.generateId(),
      clientId: this.globalManagement ? null : this._context.client.id,
      isEnabled: true,
      audienceIsGlobal: true,
      announcementClients: [],
      announcementStates: [],
      htmlContent: ''
    });

    this.editingAnnouncement = newAnnouncement;

    this.editModal.open();
  }

  editAnnouncement(announcement: Announcement) {
    this.editingAnnouncement = JSON.parse(JSON.stringify(announcement));

    this.editModal.open();
  }

  cancelEditing() {
    this.editingAnnouncement = null;
  }

  saveClicked() {
    this.editingAnnouncement = this.announcementEditor.getCurrentAnnouncement();

    this.saveAnnouncement(true);
  }

  saveAnnouncement(closeModal = false) {
    this._announcementSvc
      .saveAnnouncement(this.editingAnnouncement)
      .subscribe(savedAnnouncement => {
        let announcementToUpdate = this.announcements.find(
          a => a.id === this.editingAnnouncement.id
        );

        if (announcementToUpdate) {
          const objIndex = this.announcements.findIndex(es => es.id === announcementToUpdate.id);

          this.announcements[objIndex] = savedAnnouncement;  
        } else {
          this.announcements.unshift(savedAnnouncement);
        }

        this.editingAnnouncement = null;

        if (closeModal) {
          this.editModal.cancel();
        }
      });
  }

  deleteAnnouncement(announcementId: string, confirmed = false) {
    if (confirmed) {
      this._announcementSvc.deleteAnnouncement(announcementId).subscribe(success => {
        if (success) {
          this.announcements = this.announcements.filter(
            a => a.id !== announcementId
          );

          this.deleteModal.cancel();
        } else {
          this._toastr.error(
            'Something went wrong when deleting the Announcement. Please contact support if this error persists.',
            'Announcement was not deleted.',
            { disableTimeOut: true }
          );
        }
      });
    } else {
      this.deletingAnnouncementId = announcementId;

      this.deleteModal.open();
    }
  }

  changeEnabledStatus(id: string, enable: boolean) {
    let announcementToChange = this.announcements.find(
      a => a.id === id
    );

    if (announcementToChange) {
      this.editingAnnouncement = {...announcementToChange};

      this.editingAnnouncement.isEnabled = enable;

      this.saveAnnouncement();
    }
  }
}