<h3 class="pt-4 pb-1">Previous System Data</h3>

<wm-datatable
  [id]="id"
  [loading]="loading"
  [rows]="rows"
  [columns]="columns"
  [count]="page.totalElements"
  [offset]="page.pageNumber"
  [limit]="page.size"
  [params]="params"
  [translateToLegacy]="false"
  [showFilterBuilder]="false"
  [showSimpleSearch]="true"
  simpleSearchTitle="Search for permits"
  [translateToLegacy]="false"
  (settingsChanged)="handleSettingsChanged($event)"
  [sortField]="sortField"
  [sortDescending]="sortDescending"
  [defaultHiddenColumns]="defaultHiddenColumns"
  [actionColumns]="actionColumns"
  [defaultExportExludedColumns]="defaultExportExludedColumns"
></wm-datatable>

<ng-template #detailsTmpl let-row="row" let-value="value">
  <wm-modal-confirm
    #detailsModal
    [title]="Details"
    (opened)="loadViewDetails(row)"
    cancelText="Close"
    [moduleId]="row.id.toString()"
    [showButton]="true"
    [showButtonText]="true"
    buttonText="View"
    [buttonStyle]="ButtonStyle.Link"
  >
    <app-loader *ngIf="detailsLoading"></app-loader>
    <div *ngIf="!detailsLoading">
      <div class="row" *ngFor="let detailRow of detailRows">
        <div class="col-4">
          <b>{{ detailRow.detailKey }}:</b>
        </div>
        <div class="col-8">
          {{ detailRow.detailValue }}
        </div>
      </div>
    </div>
  </wm-modal-confirm>
</ng-template>

<ng-template #actionsTmpl let-row="row" let-value="value">
  <div ngbDropdown container="body">
    <button
      class="btn btn-secondary"
      id="dropdownMenuButton"
      ngbDropdownToggle
      style="padding-top:0px;"
    >
      Action
    </button>
    <div ngbDropdownMenu aria-labelledby="dropdownMenuButton">
      <a
        *ngFor="let action of commands"
        ngbDropdownItem
        [disabled]="!action.canPerform(row)"
        (click)="action.handler(row)"
        href="javascript:void(0)"
      >
        {{ action.title }}
      </a>
    </div>
  </div>
</ng-template>

<wm-modal-confirm
  #attachmentsEditModal
  title="Details"
  (opened)="loadPreviousSystemPermitAttachments()"
  cancelText="Close"
  acceptedText="Save"
  moduleId="attachmentModal"
  [showButton]="false"
  [showButtonText]="true"
  commandText="Save"
  [showCommandButton]="false"
  buttonText="Attachments"
  [buttonStyle]="ButtonStyle.Link"
  [closeOnAccept]="false"
>
  <app-loader *ngIf="attachmentsLoading"></app-loader>
  <span>Attachments</span>
  <div *ngIf="!attachmentsLoading">
    <ul class="list-group list-unstyled">
      <li
        class="list-item"
        *ngFor="let d of attachmentRows; let idx = index"
        [ngStyle]="{ cursor: allowSelect ? 'pointer' : 'default' }"
      >
        <div class="row border border-light">
          <div
            class="col-4"
            style="margin-right:0px; word-wrap: break-word; align-self:center"
          >
            <a href="{{ d.path }}" target="_blank">{{ d.name }} </a>
          </div>
          <div class="col-3" style="margin-right:0px;padding-top:5px;">
            <wm-modal-confirm
              [moduleId]="'delete_document' + d.id"
              [commandText]="'Delete Document'"
              [title]="'Delete  Document'"
              [showButtonText]="true"
              (accepted)="deletePreviousSystemPermitAttachment(d)"
              [enabled]="true"
              btnIcon="delete"
              btnColor="link"
            >
              <p>
                {{ d.name }} will be permanently deleted when Save, Next or
                Update is clicked.
              </p>
            </wm-modal-confirm>
          </div>
          <div class="col-5"></div>
        </div>
      </li>
    </ul>
    <div style="align-self:center;">
      <wm-upload
        [validDocumentTypes]="validDocumentTypes"
        [pathSegments]="[
          clientId,
          'previousSystemData',
          selectedAttachmentHeaderId,
          'attachments'
        ]"
        (fileUploaded)="fileUploaded($event)"
        [documentService]="this.uploadService"
        [key]="selectedAttachmentHeaderId"
        [isPreview]="isPreview"
        category=""
        [showUploadedFilesList]="false"
      ></wm-upload>
    </div>
  </div>
</wm-modal-confirm>

<wm-modal-confirm
  #detailsEditModal
  title="Details"
  (opened)="loadDetails()"
  (accepted)="onModalSave()"
  cancelText="Close"
  acceptedText="Save"
  moduleId="EditModal"
  [showButton]="false"
  [showButtonText]="true"
  commandText="Save"
  [showCommandButton]="true"
  buttonText="Edit"
  [buttonStyle]="ButtonStyle.Link"
  [closeOnAccept]="false"
  [hidden]="!(canEditPreviousSystemData() | async)"
>
  <app-loader *ngIf="detailsLoading"></app-loader>
  <div *ngIf="!detailsLoading">
    <div>
      <div class="card">
        <div class="card-header"></div>
        <div class="card-body">
          <div class="form-group">
            <div class="row">
              <div class="col-4">
                <b>Type:</b>
              </div>
              <div class="col-8">
                <input
                  type="text"
                  class="form-control"
                  [(ngModel)]="selectedHeaderRow.type"
                  name="headerRowId"
                />
              </div>
            </div>

            <div class="row">
              <div class="col-4">
                <b>Number:</b>
              </div>
              <div class="col-8">
                <input
                  type="text"
                  class="form-control"
                  [(ngModel)]="selectedHeaderRow.number"
                  name="headerRowNumber"
                />
              </div>
            </div>

            <div class="row">
              <div class="col-4">
                <b>Name:</b>
              </div>
              <div class="col-8">
                <input
                  type="text"
                  class="form-control"
                  [(ngModel)]="selectedHeaderRow.name"
                  name="headerRowName"
                />
              </div>
            </div>

            <div class="row">
              <div class="col-4">
                <b>Address:</b>
              </div>
              <div class="col-8">
                <input
                  type="text"
                  class="form-control"
                  [(ngModel)]="selectedHeaderRow.address"
                  name="headerRowAddress"
                />
              </div>
            </div>

            <div class="row">
              <div class="col-4">
                <b>Date:</b>
              </div>
              <div class="col-8">
                <input
                  type="text"
                  class="form-control"
                  [(ngModel)]="selectedHeaderRow.dateTimeString"
                  name="headerRowDateTimeString"
                  (click)="showDateErrorMessage = false"
                />
                <div
                  class="text-danger"
                  [hidden]="showDateErrorMessage == false"
                >
                  Invalid Date Entry.
                </div>
              </div>
            </div>

            <div class="row">
              <div class="col-4">
                <b>Parcel Number:</b>
              </div>
              <div class="col-8">
                <input
                  type="text"
                  class="form-control"
                  [(ngModel)]="selectedHeaderRow.parcelNumber"
                  name="headerRowParcelNumber"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="form-group">
        <div class="row" *ngFor="let detailRow of detailRows">
          <div class="col-4">
            <b>{{ detailRow.detailKey }}:</b>
          </div>
          <div class="col-8">
            <input
              type="text"
              class="form-control"
              [(ngModel)]="detailRow.detailValue"
              name="detailValue"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</wm-modal-confirm>
