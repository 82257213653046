<div>
  <div class="mb-2" *ngIf="canEdit">
    <button
      class="btn btn-raised btn-primary mr-2"
      (click)="showNew = !showNew"
      type="button"
    >
      New item
    </button>
    <button
      class="btn btn-raised btn-secondary mr-2"
      (click)="open(content)"
      type="button"
    >
      Bulk add
    </button>
  </div>

  <wm-list-item-editor
    *ngIf="showNew"
    #editor
    [item]="newItem"
    (save)="addItem($event, editor)"
  ></wm-list-item-editor>

  <div class="mt-3">
    <div *ngIf="listItems && listItems.length > 0">
      <p class="text-muted" *ngIf="canEdit">Click and drag to reorder</p>
      <p class="text-muted" *ngIf="isRoleMembersList">Associate images with the Role Members listed below by clicking the edit icon.</p>
      <div
        dragula="listEditor"
        [(dragulaModel)]="listItems"
        (dragulaModelChange)="emitChange()"
        style="cursor: move;"
      >
        <div *ngFor="let item of listItems" class="mb-2">
          <div class="card card-sm card-grey" *ngIf="currentItem !== item.id">
            <div class="card-body">
              <div class="card-title">
                {{ item.text || item.value }} 

                <div class="float-right">
                  <i
                    *ngIf="canEdit || isRoleMembersList"
                    class="material-icons"
                    style="cursor: pointer;"
                    (click)="currentItem = item.id"
                    ngbTooltip="Edit List Item"
                  >
                    create
                  </i>
                  <i
                    *ngIf="canEdit && !isRoleMembersList"
                    class="material-icons"
                    style="cursor: pointer;"
                    (click)="removeItem(item)"
                    ngbTooltip="Delete List Item"
                  >
                    delete
                  </i>
                </div>
              </div>
            </div>
          </div>

          <wm-list-item-editor
            [item]="item"
            title="Edit list item"
            saveText="Apply"
            *ngIf="currentItem === item.id"
            (save)="saveItem()"
            [imageEditOnlyMode]="isRoleMembersList"
          ></wm-list-item-editor>
        </div>
      </div>
    </div>
    <p *ngIf="!listItems || listItems.length < 1" class="text-muted">
      There are no list items.
    </p>
  </div>
</div>

<ng-template #content let-modal>
  <div class="modal-header">
    <h4 class="modal-title" id="modal-bulk-title">Bulk add list items</h4>
    <button
      type="button"
      class="close"
      aria-label="Close"
      (click)="modal.dismiss('Cross click')"
    >
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <form>
      <div class="form-group">
        <label class="bmd-label-static" for="bulkAdd">
          List items
        </label>
        <textarea
          class="form-control"
          name="bulkAdd"
          id="bulkAdd"
          rows="3"
          aria-describedby="bulkHelpBlock"
          [(ngModel)]="bulkAddText"
        ></textarea>
        <small id="bulkHelpBlock" class="form-text text-muted">
          Comma separated list of values
        </small>
      </div>
    </form>
  </div>
  <div class="modal-footer">
    <button
      type="button"
      class="btn btn-outline-dark"
      (click)="modal.close('Save click')"
    >
      Add
    </button>
  </div>
</ng-template>
