import { Component, OnInit } from '@angular/core';
import { User, EmailAddress, Address, PhoneNumber } from '../../models';
import { Router } from '@angular/router';
import { WorkflowContextService, SecurityService } from '../../services';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'wm-user-profile-view',
  templateUrl: './user-profile-view.component.html',
  styleUrls: ['./user-profile-view.component.css']
  /* viewProviders: [RouterLink, AddressListComponent, EmailAddressListComponent, PhoneListComponent,
     ProcessingStatusComponent, AddressDetailComponent, EmailAddressDetailComponent, PhoneNumberDetailComponent]*/
})
export class UserProfileViewComponent implements OnInit {
  showNewEmail = false;
  showNewAddress = false;
  showNewPhoneNumber = false;
  showChangePassword = false;
  loading = false;
  oldPassword: string;
  newPassword: string;
  newPasswordConfirmed: string;
  user: Partial<User>;
  userId: string;
  topMargin: number;

  constructor(
    public context: WorkflowContextService,
    private _securitySvc: SecurityService,
    private _router: Router,
    private _toastr: ToastrService
  ) {
    this.userId = this.context.user.id;
  }

  ngOnInit() {
    if (this.userId) {
      this._securitySvc.getUser(this.userId).subscribe(u => {
        this.user = u;
      });
    } else if (this.context.user) {
      this.user = this.context.user;
    }
  }

  save() {
    this.loading = true;
    this._securitySvc.saveUser(this.user).subscribe(res => {
      if (!res.responseMessage) {
        setTimeout(() => {
          this._router.navigate(['/']);
          this.loading = false;
        }, 2000);
      } else {
        this._toastr.error(res.responseMessage, 'Could not save user', {
          disableTimeOut: true
        });
        this.loading = false;
      }
    });
  }

  enterNewEmailAddress() {
    this.showNewEmail = true;
  }

  // newEmailAddressSaved(emailAddress: EmailAddress) {
  //   this.showNewEmail = false;
  //   this.context.user.emailAddress.push(emailAddress);
  // }

  newEmailAddressCanceled(emailAddress: EmailAddress) {
    this.showNewEmail = false;
  }

  enterNewAddress() {
    this.showNewAddress = true;
  }

  // newAddressSaved(address: Address) {
  //   this.showNewAddress = false;
  //   this.context.user.addresses.push(address);
  // }

  newAddressCanceled(address: Address) {
    this.showNewAddress = false;
  }

  enterNewPhoneNumber() {
    this.showNewPhoneNumber = true;
  }

  // newPhoneNumberSaved(phoneNumber: PhoneNumber) {
  //   if (!this.context.user.phoneNumbers) {
  //     this.context.user.phoneNumbers = [];
  //   }
  //   this.showNewPhoneNumber = false;
  //   this.context.user.phoneNumbers.push(phoneNumber);
  // }

  newPhoneNumberCanceled(phoneNumber: PhoneNumber) {
    this.showNewPhoneNumber = false;
  }

  deleteUser() {
    this._securitySvc.deleteUser(this.context.user).subscribe(res => {
      this._router.navigate(['/']);
    });
  }

  changePassword() {
    this.loading = true;
    this._securitySvc
      .changePwd(
        this.context.user.userName,
        this.oldPassword,
        this.newPassword,
        this.newPasswordConfirmed
      )
      .subscribe(() => {
        this.showChangePassword = false;
        this.loading = false;
      });
  }

  setTopMarginForNavbar(pixelHeight: number) {
    this.topMargin = pixelHeight;
  }
}
