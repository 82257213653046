<form [formGroup]="form" (submit)="save()">
    <div>
      <h3>Custom List</h3>
      <app-loader *ngIf="!customList"></app-loader>
  
      <div *ngIf="customList">
        <div class="form-group">
          <label class="bmd-label-floating" for="name">
            Name
          </label>
          <input
            type="text"
            class="form-control"
            [(ngModel)]="customList.name"
            formControlName="name"
            name="name"
          />
          <wm-control-message [control]="form.controls.name">
          </wm-control-message>
        </div>
  
        <h6>List Items</h6>
        <wm-list-editor
          [listItems]="customList.items"
          [canEdit]="true"
          (delete)="removeItem($event)"
        ></wm-list-editor>
      </div>
    </div>
  
    <div class="row mt-4" *ngIf="customList">
      <div class="col">
        <button
          type="submit"
          class="btn btn-raised btn-success"
          [disabled]="!form.valid"
        >
          Save
        </button>
      </div>
  
      <div class="col">
        <div class=" float-right">
          <wm-modal-confirm
            *ngIf="canRemove()"
            [commandText]="'Remove Custom List'"
            [title]="'Remove Custom List'"
            [showButtonText]="true"
            (accepted)="remove($event)"
            class="mx-2"
            btnColor="danger"
          >
            <p>
              Are you sure you want to remove this custom list ({{
                customList.name
              }}) from the system?
            </p>
          </wm-modal-confirm>
          <button
            type="button"
            class="btn btn-raised btn-default mx-2"
            (click)="goBack()"
          >
            Cancel
          </button>
        </div>
      </div>
    </div>
  </form>
  