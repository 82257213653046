<form [formGroup]="form" novalidate>
  <br />
  <div
    *ngIf="
      listEntity.controlType.toString() === 'radio' ||
      listEntity.controlType.toString() === 'checkbox'
    "
  >
    <wm-de-label [entity]="entity" [for]="entity.templateCode"></wm-de-label>
    <div
      class="custom-control"
      [ngClass]="'custom-' + listEntity.controlType"
      *ngFor="let ali of listEntity.availableListItems"
    >
      <input
        class="custom-control-input"
        [type]="listEntity.controlType"
        (change)="addItem($event.target.checked, ali)"
        [formControlName]="entity.templateCode"
        [checked]="isSelected(ali)"
        [name]="listEntity.templateCode"
        [id]="listEntity.templateCode + ali.id"
        [value]="ali.value"
      />
      <label
        class="custom-control-label"
        [for]="listEntity.templateCode + ali.id"
      >
        {{ ali.text }}
      </label>
    </div>
  </div>

  <div
    *ngIf="
      listEntity.controlType.toString() === 'multi-select' ||
      listEntity.controlType.toString() === 'dropdown'
    "
  >
    <wm-de-label [entity]="entity" [for]="entity.templateCode"></wm-de-label>
    <select
      class="form-control"
      [id]="entity.templateCode"
      [name]="entity.templateCode"
      [formControlName]="entity.templateCode"
      (change)="addItemById(true, $event.target.value)"
      [(ngModel)]="selectedDropdownItemId"
    >
      <option
        [selected]="!selectedEntityValues || selectedEntityValues.length < 1"
        value=""
        [disabled]="true"
      >
        Select Item...
      </option>
      <option
        *ngFor="let ali of listEntity.availableListItems"
        [value]="ali.id"
        [selected]="isSelected(ali)"
      >
        {{ ali.text }}
      </option>
    </select>
  </div>
</form>
