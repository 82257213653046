<form [formGroup]="form" (submit)="save()">
  <div>
    <h3>Custom List</h3>
    <app-loader *ngIf="!customList"></app-loader>

    <div *ngIf="customList">
      <div class="form-group">


        <div class="switch mb-3">
          <label>
            <input
              id="isRoleMemembersList"
              type="checkbox"
              [(ngModel)]="isRoleMembersList"
              formControlName="isRoleMemembersList"
              (change)="tryToggleCustomListType()"
            />
            Role Members List
          </label>
        </div>
        
        Name
        <input
          type="text"
          class="form-control"
          [(ngModel)]="customList.name"
          formControlName="name"
          name="name"
        />
        <wm-control-message [control]="form.controls.name">
        </wm-control-message>
      </div>

      <!-- ordinary Custom List editor -->
      <ng-container *ngIf="!isRoleMembersList">
        <!-- LOAD FROM TEMPLATE button -->      
        <div class="mt-4 mb-4">
          <button
            type="button"
            class="btn btn-raised btn-primary"
            (click)="openCustomListTemplates()"
          >
            Load items from template
          </button>
        </div>
      </ng-container>

      <!-- Role Members List editor -->
      <ng-container *ngIf="isRoleMembersList">
        Select Role:
        <div class="row mb-4">
          <div class="col-6">
            <select 
              class="form-control" 
              [(ngModel)]="customList.roleId"
              formControlName="roleSelected"
              (change)="loadRoleMembers()"
            >
              <option [ngValue]="null"></option>
              <option
                *ngFor="let role of availableRoles"
                [ngValue]="role.id"
                >{{ role.name }}</option
              >
            </select>
          </div>
          <div class="col-6"></div>
        </div>
      </ng-container>

      <h6>List Items</h6>
      <wm-list-editor
        [listItems]="customList.items"
        [canEdit]="!isRoleMembersList"
        (delete)="removeItem($event)"
        [isRoleMembersList]="isRoleMembersList"
      ></wm-list-editor>

    </div>
  </div>

  <div class="row mt-4" *ngIf="customList">
    <div class="col">
      <button
        type="submit"
        class="btn btn-raised btn-success"
        [disabled]="!form.valid"
      >
        Save
      </button>
    </div>

    <div class="col">
      <div class=" float-right">
        <wm-modal-confirm
          *ngIf="canRemove()"
          [commandText]="'Remove Custom List'"
          [title]="'Remove Custom List'"
          [showButtonText]="true"
          (accepted)="remove($event)"
          class="mx-2"
          btnColor="danger"
        >
          <p>
            Are you sure you want to remove this custom list ({{
              customList.name
            }}) from the system?
          </p>
        </wm-modal-confirm>
        <button
          type="button"
          class="btn btn-raised btn-default mx-2"
          (click)="goBack()"
        >
          Cancel
        </button>
      </div>
    </div>
  </div>
</form>

<wm-modal-confirm
  #customListTemplates
  title="Load Custom List Template"
  [showButton]="false"
  commandText="Load Template"
  (accepted)="loadSelectedTemplate()"
  [form]="selectedTemplateForm"
>
  <form [formGroup]="selectedTemplateForm">
    <app-loader *ngIf="!globalCustomLists"></app-loader>
  
    <ng-container *ngIf="globalCustomLists && globalCustomLists.length === 0">
      There are not any Custom List Templates available at this time.
    </ng-container>
  
    <ng-container *ngIf="globalCustomLists && globalCustomLists.length > 0">
      <select 
        class="form-control" 
        [(ngModel)]="selectedTemplate"
        formControlName="selectedTemplate"
      >
        <option [ngValue]="null"></option>
        <option
          *ngFor="let template of globalCustomLists"
          [ngValue]="template"
          >{{ template.name }}</option
        >
      </select>
    </ng-container>
  </form>
</wm-modal-confirm>

<wm-modal-confirm
  #changeCustomListType
  [showButtonText]="true"
  [showButton]="false"
  [buttonText]="''"
  commandText="Yes"
  title="Change Custom List Type"
  (accepted)="toggleCustomListType()"
  btnColor="success"
  class="mx-2"
  (canceled)="cancelChangeCustomListType()"
>
  All list items will be cleared if you change the type of this custom list.  Are you sure you want to change the type of this custom list?
</wm-modal-confirm>