import { WorkflowService } from './../../../../../services/workflow.service';
import {
  Component,
  OnInit,
  ComponentFactoryResolver,
  Input,
  Inject,
  forwardRef,
  AfterViewInit,
  ChangeDetectorRef
} from '@angular/core';
import { DataEntityInputComponent } from '../../data-entity-input/data-entity-input.component';
import { UntypedFormBuilder, Validators } from '@angular/forms';
import { FeeDataEntity } from '../../../../../models/data-entities';
import {
  SecurityService,
  WorkflowContextService
} from '../../../../../services';
import { CalcOnChangeDataEntityComponent } from '../../data-entity-input/calc-on-change-data-entity';

@Component({
  selector: 'wm-fee-data-entity-input',
  templateUrl: './fee-data-entity-input.component.html',
  styleUrls: ['./fee-data-entity-input.component.css']
})
export class FeeDataEntityInputComponent extends CalcOnChangeDataEntityComponent
  implements OnInit {
  @Input() entity: FeeDataEntity;
  @Input() isPreview: boolean;

  constructor(
    componentFactoryResolver: ComponentFactoryResolver,
    ref: ChangeDetectorRef,
    private _fb: UntypedFormBuilder,
    @Inject(forwardRef(() => SecurityService))
    securitySvc: SecurityService,
    @Inject(forwardRef(() => WorkflowService))
    workflowSvc: WorkflowService,
    @Inject(forwardRef(() => WorkflowContextService))
    context: WorkflowContextService
  ) {
    super(componentFactoryResolver, workflowSvc, context, securitySvc, ref);
  }
}
