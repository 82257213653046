<span>
    Select previous registration field(s)
    <wm-help-text
        placement="right"
        helpText="Each of the Renewable workflows listed here uses this workflow as its Renewal workflow.  Select a field from any or each of the workflows to carry over to this Data Entity in a renewal application."
    ></wm-help-text>
</span>

<br />

<div
    class="mt-4"
    *ngFor="let option of sourceConfig.options; let i = index"
>
    <ng-container *ngIf="!option.isThisWorkflow">
        <b>{{ option.sourceWorkflowDraftVersionName }}</b>
        <div class="mt-1">
            <wm-data-entity-autocomplete
                id="'registrationExternalDataSource-' + option.sourceWorkflowId"
                [showFormulaEditor]="false"
                [clearOnSelect]="true"
                [form]="form"
                [model]="mappedValues[i]"
                [required]="false"
                [workflowId]="option.sourceWorkflowId"
                [workflowVersionId]="option.sourceWorkflowDraftVersionId"
                [types]=""
                [activityTypes]="renewableWorkflowForms"
                (valueUpdate)="changeParentRenewableMapping(option, $event)"
            ></wm-data-entity-autocomplete>
        </div>
    </ng-container>

    <div [formGroup]="form" *ngIf="option.isThisWorkflow">
        <span>
            <b>This workflow</b>&nbsp;
            <wm-help-text
                placement="right"
                helpText="Since this workflow uses itself as its Renewal workflow, values from this Data Entity can be carried over to renewal applications."
            ></wm-help-text>
        </span>

        <select
            class="form-control"
            formControlName="mapFromThisWorkflow"
            (change)="handleChanges()"
            [(ngModel)]="sourceConfig.selectedMappings.mapFromThisWorkflow"
        >
            <option [ngValue]="false">(Do not map to this workflow)</option>
            <option [ngValue]="true">{{ entity.templateCode }}</option>
        </select>
    </div>
</div>

<wm-control-message
    [control]="form.controls['registrationExternalDataField']"
></wm-control-message>
