<div class="card card-grey">
  <div class="card-body">
    <form [formGroup]="listItemForm" (ngSubmit)="addItem()">
      <p class="card-title">
        {{ title }}
      </p>
      <div class="form-group">
        <label class="bmd-label-static" for="listValue">
          Value <span class="text-danger">*</span>
        </label>
        <input
          type="text"
          id="listValue"
          name="listValue"
          class="form-control"
          (keypress)="keyPress($event)"
          formControlName="value"
          (change)="valueChange()"
          [readonly]="imageEditOnlyMode"
        />
      </div>
      <div class="form-group">
        <label class="bmd-label-static" for="listText">
          Label
        </label>
        <input
          type="text"
          id="listText"
          name="listText"
          class="form-control"
          (keypress)="keyPress($event)"
          formControlName="text"
          (change)="textChange()"
          [readonly]="imageEditOnlyMode"
        />
      </div>
      <div>
        <div class="row mt-2">
          <div class="col">
            <label class="bmd-label-static" for="listText">
              Image
            </label>
          </div>
        </div>
        <div class="row mt-3">
          <div class="col-3">
            <div class="form-group">
              <wm-upload
                id="itemImageUpload"
                name="itemImageUpload"
                validDocumentTypes="png,jpg,jpeg"
                [allowMultipleFiles]="false"
                [pathSegments]="[this.item.id]"
                (fileUploaded)="imageUploaded($event)"
                category="Client"
              >
              </wm-upload>
            </div>
          </div>
          <div class="col-9">
            <wm-modal-confirm
              *ngIf="(item.imagePath || '') != ''"
              [moduleId]="'delete_item_image'"
              [commandText]="'Delete Image'"
              [title]="'Delete  Image'"
              [showButtonText]="true"
              (accepted)="deleteItemImage(item.imagePath)"
              [enabled]="true"
              btnIcon="delete"
            >
              <p>Are you sure you want to delete the image for this item?</p>
              <p>
                Deleting this image will cause it to not render in PDF's where
                referenced.
              </p>
            </wm-modal-confirm>
          </div>
        </div>
        <img
          *ngIf="item.imagePath"
          [src]="item.imagePath"
          alt="list item image"
          class="img-fluid"
        />
      </div>

      <button
        class="btn btn-outline-secondary float-right mt-3"
        type="submit"
        (click)="updateItem()"
        [disabled]="listItemForm.invalid"
      >
        {{ saveText }}
      </button>
    </form>
  </div>
</div>
