import { Contact } from 'src/app/models';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import {
  Component,
  OnInit,
  Input,
  ComponentFactoryResolver,
  forwardRef,
  Inject
} from '@angular/core';
import { DataEntityInputComponent } from '../../data-entity-input/data-entity-input.component';
import { ContactListDataEntity } from '../../../../../models/data-entities/contact-list-data-entity';
import { UntypedFormBuilder, NgForm } from '@angular/forms';
import { ContactEmailAddress, ContactAddress } from '../../../../../models';
import { ClientService, WorkflowContextService } from '../../../../../services';
import { ActivatedRoute } from '@angular/router';
import { map } from 'rxjs/operators';

@Component({
  selector: 'wm-contact-list-data-entity-input',
  templateUrl: './contact-list-data-entity-input.component.html',
  styleUrls: ['./contact-list-data-entity-input.component.css']
})
export class ContactListDataEntityInputComponent
  extends DataEntityInputComponent
  implements OnInit {
  @Input() entity: ContactListDataEntity;
  // constructor(
  //   componentFactoryResolver: ComponentFactoryResolver,
  //   private _fb: FormBuilder
  //   )
  constructor(
    //  _dataSvc: DataService,
    componentFactoryResolver: ComponentFactoryResolver,
    @Inject(forwardRef(() => ClientService)) public _clientSvc: ClientService,
    @Inject(forwardRef(() => WorkflowContextService))
    private _context: WorkflowContextService,
    @Inject(forwardRef(() => ActivatedRoute)) private route: ActivatedRoute,
    private _fb: UntypedFormBuilder,
    private modalService: NgbModal
  ) {
    super(componentFactoryResolver);
  }
  // constructor(
  // componentFactoryResolver: ComponentFactoryResolver,
  //   private _clientSvc: ClientService,
  //   private route: ActivatedRoute
  //  )

  // {
  // super(componentFactoryResolver);

  // }
  onFormSubmit(form: NgForm) {}

  open(content) {
    this.modalService.open(content, { ariaLabelledBy: 'modal-contact' });
  }

  ngOnInit() {
    //get the contact //this is messy, but it's because it's the way shawn implemnted it in contactdetalview.
    //this should all be moved down to the getContact service layer.
    this.route.params.subscribe(params => {
      if ((this.entity.selectedContactId || '') != '') {
        const z = this._clientSvc.getContact(this.entity.selectedContactId);

        z.pipe(
          map(contact => {
            this.entity.selectedContact = contact;
            if (this.entity.selectedContact.emailAddress == null) {
              this.entity.selectedContact.emailAddress = new ContactEmailAddress();
            }
            if (this.entity.selectedContact.address == null) {
              this.entity.selectedContact.address = new ContactAddress();
            }
            return this.entity.selectedContact;
          })
        ).subscribe(res => {
          this.entity.selectedContact = res;
        });
      } else {
        this.entity.selectedContact = this._clientSvc.createContact();
      }
    });
  }

  getOfficeHours(contact: Contact) {
    return contact.officeHours.replace(new RegExp('\n', 'g'), '<br />');
  }
}
