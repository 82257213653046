import { Component, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, ValidationErrors, Validators } from '@angular/forms';
import { CKEditorComponent } from '@ckeditor/ckeditor5-angular';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import { faTimes } from '@fortawesome/free-solid-svg-icons';
import { NgbInputDatepicker } from '@ng-bootstrap/ng-bootstrap';
import { Subscription } from 'rxjs';
import { Announcement, AnnouncementAudienceType, AnnouncementClient, AnnouncementState, AvailableAnnouncementClient, AvailableAnnouncementState } from 'src/app/models/announcement';
import { Utilities, WorkflowContextService } from 'src/app/services';
import { CkeditorUploadService } from 'src/app/services/ckeditor-upload.service';

@Component({
  selector: 'wm-announcement-editor',
  templateUrl: './announcement-editor.component.html',
  styleUrl: './announcement-editor.component.css'
})
export class AnnouncementEditorComponent implements OnInit, OnDestroy {
  @Input() announcement: Announcement;
  @Input() globalManagement = false;
  @Input() availableJurisdictions: AvailableAnnouncementClient[] = [];
  @Input() availableStates: AvailableAnnouncementState[] = [];
  @Input() form: UntypedFormGroup;

  @ViewChild('dStart', { static: true }) startDatePicker: NgbInputDatepicker;
  @ViewChild('dEnd', { static: true }) endDatePicker: NgbInputDatepicker;
  @ViewChild('htmlContentEditor', { static: false }) htmlContentEditor: CKEditorComponent;
  
  userMachineTimeZoneCode: string;
  clientTimeZoneCode: string;
  startDate: any;
  startTime: any;
  endDate: any;
  endTime: any;
  AnnouncementAudienceType = AnnouncementAudienceType;
  audienceType: AnnouncementAudienceType;
  faTimes = faTimes;
  haveEndDate = false;

  public Editor = ClassicEditor;
  public editorConfig = {
    toolbar: [
      'heading',
      '|',
      'bold',
      'italic',
      'link',
      '|',
      'bulletedList',
      'numberedList',
      'imageUpload',
      '|',
      'undo',
      'redo'
    ]
  };

  private clientSubscription: Subscription;

  constructor (
    private _context: WorkflowContextService,
    private _fb: UntypedFormBuilder,
    private _uploadAdapter: CkeditorUploadService
  ) { }

  ngOnInit() {
    if (!this._context.client) {
      this.clientSubscription = this._context.client$.subscribe(client => {
        if (client) {
          this.clientTimeZoneCode = this._context.client.timeZoneCode;
        }
      });
    }

    this.userMachineTimeZoneCode = new Date()
      .toLocaleTimeString(
        'en-us', 
        { timeZoneName: 'short' }
      ).split(' ')[2]
    
    // use a separate variable to bind to the time picker,
    // then merge the configured time back into the startDate on save
    if (this.announcement.startDateString) {
      const startDate = { year: 0, month: 0, day: 0 };
      const startTime = { hour: 0, minute: 0, second: 0 };

      const sd = new Date(this.announcement.startDateString);

      startDate.year = sd.getFullYear();
      startDate.month = sd.getMonth() + 1;
      startDate.day = sd.getDate();

      startTime.hour = sd.getHours();
      startTime.minute = sd.getMinutes();
      startTime.second = 0;

      this.startDate = startDate;
      this.startTime = startTime;
    } else {
      this.announcement.startDateString = Utilities.getDateTimeString(new Date());

      // set default times
      this.startTime = { hour: 12, minute: 0, second: 0 };
    }

    if (this.announcement.endDateString) {
      this.haveEndDate = true;

      const endDate = { year: 0, month: 0, day: 0 };
      const endTime = { hour: 0, minute: 0, second: 0 };

      const ed = new Date(this.announcement.endDateString);

      endDate.year = ed.getFullYear();
      endDate.month = ed.getMonth() + 1;
      endDate.day = ed.getDate();

      endTime.hour = ed.getHours();
      endTime.minute = ed.getMinutes();
      endTime.second = 0;

      this.endDate = endDate;
      this.endTime = endTime;
    }

    this.setSelectedAudience();

    this.form.addControl('title', this._fb.control('', [Validators.required]));
    this.form.addControl('enabled', this._fb.control('', [Validators.nullValidator]));
    this.form.addControl(
      'startDate',
      this._fb.control(this.startDate, Validators.required)
    );
    this.form.addControl(
      'startTime',
      this._fb.control(this.startTime, Validators.required)
    );
    this.form.addControl(
      'endDate',
      this._fb.control(this.endDate, Validators.nullValidator)
    );
    this.form.addControl(
      'endTime',
      this._fb.control(this.startTime, Validators.nullValidator)
    );
    this.form.addControl(
      'audienceSettingsAreValid',
      this._fb.control('', this.audienceSettingsAreValid.bind(this))
    );
    this.form.addControl('htmlContent', this._fb.control('', [Validators.required]));
    this.form.addControl('haveEndDate', this._fb.control('', Validators.nullValidator));
  }

  ngOnDestroy() {
    if (this.clientSubscription) {
      this.clientSubscription.unsubscribe();
      this.clientSubscription = null;
    }

    this.form.removeControl('title');
    this.form.removeControl('enabled');
    this.form.removeControl('startDate');
    this.form.removeControl('startTime');
    this.form.removeControl('endDate');
    this.form.removeControl('endTime');
    this.form.removeControl('audienceSettingsAreValid');
    this.form.removeControl('htmlContent');
    this.form.removeControl('haveEndDate');
  }

  getCurrentAnnouncement(): Announcement {
    this.setDateStrings();

    return this.announcement;
  }

  setDateStrings() {
    if (this.dateObjIsValid(this.startDate) && this.timeObjIsValid(this.startTime)) {
      const startDate = new Date();

      startDate.setFullYear(
        this.startDate.year,
        this.startDate.month - 1,
        this.startDate.day
      );

      startDate.setHours(this.startTime.hour, this.startTime.minute, 0);

      this.announcement.startDateString = Utilities.getDateTimeString(startDate);
    }

    if (this.haveEndDate && this.dateObjIsValid(this.endDate) && this.timeObjIsValid(this.endTime)) {
      const endDate = new Date();

      endDate.setFullYear(
        this.endDate.year,
        this.endDate.month - 1,
        this.endDate.day
      );

      endDate.setHours(this.endTime.hour, this.endTime.minute, 0);

      this.announcement.endDateString = Utilities.getDateTimeString(endDate);
    } else {
      this.announcement.endDateString = null;
    }
  }

  dateObjIsValid(dateObj: any): boolean {
    return (
      dateObj
      && dateObj.hasOwnProperty('year')
      && dateObj.hasOwnProperty('month')
      && dateObj.hasOwnProperty('day')
    );
  }

  timeObjIsValid(timeObj: any): boolean {
    return (
      timeObj
      && timeObj.hasOwnProperty('hour')
      && timeObj.hasOwnProperty('minute')
    );
  }

  setStartDate() {
    this.startDate = this.form.controls['startDate'].value;
  }

  setStartTime() {
    this.startTime = this.form.controls['startTime'].value;
  }

  setEndDate() {
    this.endDate = this.form.controls['endDate'].value;
  }

  setEndTime() {
    this.endTime = this.form.controls['endTime'].value;
  }

  onFocusStartDate() {
    this.startDatePicker.open();
    return true;
  }

  onFocusEndDate() {
    this.endDatePicker.open();
    return true;
  }

  handleAudienceTypeChange() {
    if (this.audienceType !== AnnouncementAudienceType.Jurisdictions) {
      this.announcement.announcementClients = [];

      if (this.availableJurisdictions && this.availableJurisdictions.length > 0) {
        for (let aj of this.availableJurisdictions) {
          aj.selected = false;
        }
      }
    }

    if (this.audienceType !== AnnouncementAudienceType.States) {
      this.announcement.announcementStates = [];

      if (this.availableStates && this.availableStates.length > 0) {
        for (let ast of this.availableStates) {
          ast.selected = false;
        }
      }
    }

    this.announcement.audienceIsGlobal = (this.audienceType === AnnouncementAudienceType.AllClients);

    this.refreshAudienceValidator();
  }

  setSelectedAudience() {
    this.availableJurisdictions = JSON.parse(JSON.stringify(this.availableJurisdictions));
    this.availableStates = JSON.parse(JSON.stringify(this.availableStates));

    if (this.announcement.announcementClients && this.announcement.announcementClients.length > 0) {
      this.audienceType = AnnouncementAudienceType.Jurisdictions;

      for (let aj of this.availableJurisdictions) {
        aj.selected = this.announcement.announcementClients.some(ac => ac.clientId === aj.clientId);
      }
    } else if (this.announcement.announcementStates && this.announcement.announcementStates.length > 0) {
      this.audienceType = AnnouncementAudienceType.States;

      for (let avst of this.availableStates) {
        avst.selected = this.announcement.announcementStates.some(ast => ast.stateName === avst.stateName);
      }
    } else {
      this.audienceType = AnnouncementAudienceType.AllClients;
    }
  }

  addRemoveJurisdiction(availableAnnouncmentClient: AvailableAnnouncementClient) {
    if (availableAnnouncmentClient.selected) {
      const announcmentClient = new AnnouncementClient({
        id: Utilities.generateId(),
        announcementId: this.announcement.id,
        clientId: availableAnnouncmentClient.clientId,
        clientName: availableAnnouncmentClient.clientName
      });

      this.announcement.announcementClients.push(announcmentClient);
    } else {
      this.announcement.announcementClients = [...this.announcement.announcementClients.filter(ac => ac.clientId !== availableAnnouncmentClient.clientId)];
    }

    this.refreshAudienceValidator();
  }

  addRemoveState(availableAnnouncementState: AvailableAnnouncementState) {
    if (availableAnnouncementState.selected) {
      const announcmentState = new AnnouncementState({
        id: Utilities.generateId(),
        announcementId: this.announcement.id,
        stateName: availableAnnouncementState.stateName
      });

      this.announcement.announcementStates.push(announcmentState);
    } else {
      this.announcement.announcementStates = [...this.announcement.announcementStates.filter(ast => ast.stateName !== availableAnnouncementState.stateName)];
    }

    this.refreshAudienceValidator();
  }

  removeJurisdictionFromSelected(announcementClientId: string) {
    this.announcement.announcementClients = [...this.announcement.announcementClients.filter(ac => ac.clientId !== announcementClientId)];

    let availableAnnouncementClient = this.availableJurisdictions.find(aj => aj.clientId === announcementClientId);

    if (availableAnnouncementClient) {
      availableAnnouncementClient.selected = false;
    }

    this.refreshAudienceValidator();
  }

  removeStateFromSelected(announcementStateName: string) {
    this.announcement.announcementStates = [...this.announcement.announcementStates.filter(ast => ast.stateName !== announcementStateName)];

    let availableAnnouncementState = this.availableStates.find(ast => ast.stateName === announcementStateName);

    if (availableAnnouncementState) {
      availableAnnouncementState.selected = false;
    }

    this.refreshAudienceValidator();
  }

  audienceSettingsAreValid(): ValidationErrors | null {
    if (this.audienceType === AnnouncementAudienceType.Jurisdictions) {  
      return this.announcement.announcementClients && this.announcement.announcementClients.length > 0
        ? null
        : { jurisdictionRequired: true };
    }

    if (this.audienceType === AnnouncementAudienceType.States) {
      return this.announcement.announcementStates && this.announcement.announcementStates.length > 0
        ? null
        : { stateRequired: true };
    }
  }

  refreshAudienceValidator() {
    this.form.get('audienceSettingsAreValid').updateValueAndValidity();
    this.form.get('audienceSettingsAreValid').markAsTouched();
  }

  public editorReady(editor) {
    const parentId: string = this.announcement.id;
    editor.plugins.get('FileRepository').createUploadAdapter = loader => {
      this._uploadAdapter.setLoader(loader, parentId);

      this._uploadAdapter.loader = loader;

      this._uploadAdapter.isUploading.subscribe((isUploading: boolean) => {
        if (this.form.controls['htmlContent-IS_UPLOADING']) {
          this.form.controls['$htmlContent-IS_UPLOADING'].setValue(
            isUploading ? 1 : 0
          );
        }
      });

      return this._uploadAdapter;
    };
  }
}
