import { AfterViewChecked, ChangeDetectorRef, Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { AbstractControl, UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { DataEntity } from 'src/app/models/data-entities';
import { ExternalDataSource, ParcelSearchExternalSourceConfig } from 'src/app/models/external-data-source';

@Component({
  selector: 'wm-parcel-search-external-source-config',
  templateUrl: './parcel-search-external-source-config.component.html',
  styleUrl: './parcel-search-external-source-config.component.css'
})
export class ParcelSearchExternalSourceConfigComponent implements OnInit, AfterViewChecked, OnDestroy {
  @Input() form: UntypedFormGroup;
  @Input() externalDataSource: ExternalDataSource;
  @Input() entity: DataEntity;

  @Output() externalSourceUpdated: EventEmitter<any> = new EventEmitter<any>(); 

  sourceConfig: ParcelSearchExternalSourceConfig;
  parcelSearchExternalDataFieldCtrl: AbstractControl;

  constructor(
    private _ref: ChangeDetectorRef,
    private _fb: UntypedFormBuilder
  ) {}

  ngOnInit() {
    if (this.entity.externalDataSource) {
      // it's possible the saved ExternalDataSource is no longer a valid option in the design.
      // Workflow validation will catch that, just keep it from erroring here.
      if (this.externalDataSource) {
        this.sourceConfig = this.externalDataSource.sourceConfig as ParcelSearchExternalSourceConfig;

        // apply any existing selections from the entity.externalDataSource 
        if ((this.entity.externalDataSource.sourceConfig as ParcelSearchExternalSourceConfig).selectedOption != null) {
          (this.externalDataSource.sourceConfig as ParcelSearchExternalSourceConfig).selectedOption = 
            (this.externalDataSource.sourceConfig as ParcelSearchExternalSourceConfig).options
              .find(o =>
                o.fieldName.toLocaleLowerCase() ===
                (this.entity.externalDataSource.sourceConfig as ParcelSearchExternalSourceConfig)
                  .selectedOption.fieldName.toLocaleLowerCase()
              );
        }
      }
    }

    this.form.addControl(
      'parcelSearchExternalDataField',
      this._fb.control('', Validators.required)
    );

    this.parcelSearchExternalDataFieldCtrl = this.form.controls[
      'parcelSearchExternalDataField'
    ];
  }

  ngAfterViewChecked() {
    this._ref.detectChanges();
  }

  ngOnDestroy() {
    // this seems to be necessary for controls with custom validator functions
    this.form.removeControl('parcelSearchExternalDataField');
  }

  handleChanges() {
    this.externalSourceUpdated.emit();

    this.setValidators();
  }

  setValidators() {
    this.parcelSearchExternalDataFieldCtrl.clearValidators();

    this.form.get('parcelSearchExternalDataField').updateValueAndValidity();

    this.parcelSearchExternalDataFieldCtrl.setValidators(
      Validators.required
    );
  }
}
