<div id="activities" *ngIf="activities">
  <ul
    ngbNav
    [(activeId)]="activeTabId"
    [destroyOnHide]="false"
    #tabs="ngbNav"
    class="nav-tabs"
  >
    <li
      *ngFor="let activity of activities"
      [ngbNavItem]="activity.activityDataId"
    >
      <a ngbNavLink>{{ activity.summaryLabel }}</a>
      <ng-template ngbNavContent>
        <div *ngIf="activity && activity.status === ActivityStatus.Completed">
          <span
            >Completed On {{ activity.completedOnString }}
            by
            {{ activity.completedBy }}</span
          >
          <a
            href="javascript:void(0)"
            class="float-right"
            *ngIf="canEditActivity"
            (click)="editActivity(activity.activityDataId)"
            >Edit Activity</a
          >
          <a
            href="javascript:void(0)"
            class="float-right mr-3"
            *ngIf="activity.hasHistory && canEditActivity"
            (click)="viewActivityHistory(activity)"
            >History</a
          >
        </div>
        <wm-activity-view
          [activity]="activity"
          [isReadOnly]="true"
          [form]="form"
          [isPreview]="false"
          #activityViewItem
        ></wm-activity-view>
      </ng-template>
    </li>
    <li *ngIf="application" ngbNavItem="externalNotes">
      <a ngbNavLink>External Notes</a>
      <ng-template ngbNavContent>
        <wm-application-external-note-editor
          [application]="application"
        ></wm-application-external-note-editor>
        <button
          class="btn btn-raised btn-success"
          (click)="saveNotes()"
          *ngIf="canEditNotes"
        >
          Save Notes
        </button>
      </ng-template>
    </li>
    <li *ngIf="application && canEditNotes" ngbNavItem="internalNotes">
      <a ngbNavLink>Internal Notes</a>
      <ng-template ngbNavContent>
        <ng-container *ngIf="canEditNotes">
          <wm-application-internal-note-editor
            [application]="application"
          ></wm-application-internal-note-editor>
          <button
            class="btn btn-raised btn-success"
            (click)="saveNotes()"
            *ngIf="canEditNotes"
          >
            Save Notes
          </button>
        </ng-container>
      </ng-template>
    </li>
  </ul>
  <div [ngbNavOutlet]="tabs"></div>
</div>

<div class="container">
  <div class="row">
    <div class="col-xs-2">
      <wm-modal-confirm
        #EditCompletedActivity
        [showButtonText]="true"
        [showButton]="false"
        [buttonText]="'blah'"
        [commandText]="'Update'"
        [title]="'Update Activity'"
        (accepted)="updateActivity($event)"
        (canceled)="cancelEdit()"
        btnColor="danger"
        class="mx-2"
        [form]="activityForm"
        [closeOnAccept]="false"
      >
        <wm-activity-view
          [activity]="editingActivity"
          [form]="activityForm"
          *ngIf="editingActivity"
          (goNext)="goNext()"
          #editActivityInput
        >
        </wm-activity-view>
      </wm-modal-confirm>
      <wm-modal-confirm
        #ActivityHistory
        [showButtonText]="false"
        [showButton]="false"
        [title]="'Activity History'"
        btnColor="danger"
        class="mx-2"
        (canceled)="closeHistory()"
        (accepted)="closeHistory()"
      >
        <app-loader *ngIf="historyLoading"></app-loader>
        <div class="row" style="width: 100%;margin-left: 0">
          <div class="col-3">Action</div>
          <div class="col-4">Modified By</div>
          <div class="col-5">Modified On</div>
        </div>
        <div
          ngbAccordion
          #accordianHistory="ngbAccordion"
          (shown)="historyExpand($event)"
          *ngIf="activityHistoryItems"
        >
          <div
            ngbAccordionItem="{{ h.id }}"
            *ngFor="let h of activityHistoryItems"
            [id]="h.id"
            (shown)="expandAudit(h.id)"
            class="d-flex flex-column h-100"
          >
            <h2 ngbAccordionHeader>
              <button
                ngbAccordionToggle
                class="p-0 btn btn-link text-start ps-0"
              >
                <div class="row">
                  <div class="col-3 text-left">{{ h.action }}</div>
                  <div class="col-4 text-left">{{ h.modifiedBy }}</div>
                  <div class="col-5 text-left">{{ h.modifiedOnString }}</div>
                </div>
              </button>
            </h2>
            <div ngbAccordionCollapse>
              <div ngbAccordionBody>
                <ng-template>
                  <app-loader *ngIf="auditLoading[h.id]"></app-loader>
                  <div
                    *ngIf="
                      !auditLoading[h.id] && auditEntityKeys(h.id).length == 0
                    "
                  >
                    Updated but nothing changed
                  </div>
                  <div *ngFor="let k of auditEntityKeys(h.id)">
                    <div class="row">
                      <div class="col text-center de-header">{{ k }}</div>
                    </div>
                    <div class="row">
                      <div class="col de-value-header">Old Value</div>
                    </div>
                    <div class="row">
                      <div class="col align-items-top de-value">
                        <wm-data-entity-view
                          [entity]="auditEntities[h.id][k].oldEntity"
                          [form]="form"
                          [applicationId]="applicationId"
                        ></wm-data-entity-view>
                      </div>
                    </div>
                    <div class="row">
                      <div class="col de-value-header">New Value</div>
                    </div>
                    <div class="row">
                      <div class="col align-items-top de-value">
                        <wm-data-entity-view
                          [entity]="auditEntities[h.id][k].newEntity"
                          [form]="form"
                          [applicationId]="applicationId"
                        ></wm-data-entity-view>
                      </div>
                    </div>
                  </div>
                </ng-template>
              </div>
            </div>
          </div>
        </div>
      </wm-modal-confirm>
    </div>
  </div>
</div>
