<div
    [formGroup]="form"
>
    Field from <b>{{ externalDataSource.label }}</b>
    <select
        class="form-control"
        formControlName="parcelSearchExternalDataField"
        name="parcelSearchExternalDataField"
        (change)="handleChanges()"
        [(ngModel)]="sourceConfig.selectedOption"
    >
        <option
            *ngFor="let psOption of sourceConfig.options"
            [ngValue]="psOption"
        >
            {{ psOption.fieldAlias }}
        </option>
    </select>   
</div>
