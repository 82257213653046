<div>
  <input
    #fileUpload
    style="display: none;"
    type="file"
    id="customFile"
    [accept]="validDocumentTypes"
    [multiple]="allowMultipleFiles"
  />

  <button
    #uploadButton
    type="button"
    class="btn btn-raised btn-primary mr-1"
    (click)="onClick()"
  >
    {{ buttonText }}
  </button>

  <span style="padding-left: 10px" *ngIf="files && files.length > 0">
    {{ files[0].progress }}%
  </span>
</div>

<!-- Files being uploaded -->
<div *ngIf="allowMultipleFiles">
  <ul *ngIf="files.length > 0">
    <li *ngFor="let file of files">
      {{ file.data.name }} ({{ file.size }}) - {{ file.progress }}%
    </li>
  </ul>
</div>

<!-- successfully uploaded files -->
<ul class="list-group" *ngIf="showUploadedFilesList">
  <li
    *ngFor="let f of uploadedFiles"
    class="list-group-item"
    [ngStyle]="{ cursor: allowSelect ? 'pointer' : 'default' }"
  >
    <div class="row">
      <div
        [class]="showRemove ? 'col-xs-10' : 'col-xs-12'"
        style="padding-top: 10px"
      >
        <a href="{{ f.path }}" target="_blank">{{ f.name }}</a>
      </div>
      <div class="col-xs-3">
        <wm-modal-confirm
          [moduleId]="'delete_document' + f.id"
          commandText="Delete"
          title="Delete Document"
          [showButtonText]="true"
          (accepted)="deleteFile(f)"
          [enabled]="true"
        >
          <p>Are you sure you want to delete {{ f.name }}?</p>
          <ng-container *ngIf="deleteDocumentMessage">
            <p>{{ deleteDocumentMessage }}</p>
          </ng-container>
        </wm-modal-confirm>
      
      </div>
    </div>
  </li>
</ul>

<div class="row" *ngIf="validDocumentTypes">
  <div class="col-12">
    <span>Valid Document Types: {{ validDocumentTypes }}</span>
  </div>
</div>
