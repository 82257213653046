import {
  DataService,
  WorkflowContextService,
  SecurityService,
  ClientService
} from './../../services';
import {
  ChangeDetectorRef,
  Component,
  ElementRef,
  OnInit,
  TemplateRef,
  ViewChild
} from '@angular/core';
import {
  WorkflowApplication,
  ApplicationStatus,
  WorkflowApplicationVM
} from './../../models/workflow-application';
import { Router } from '@angular/router';
import { ItemSearchOptionFieldOption } from 'src/app/components/filter-list/models/filterClasses';
import { MyInspectionQueueCompleteComponent } from './my-inspection-queue/InspectionComplete/my-inspection-queue-complete.component';
import { UpcomingInspectionToCompleteComponent } from './my-inspection-queue/UpcomingInspectionToComplete/upcoming-inspection-to-complete.component';
import { InspectionRequestedQueueComponent } from './my-inspection-queue/InspectionRequested/inspection-requested-queue.component';
import { Actions } from 'src/app/models/actions';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { Workflow } from 'src/app/models';
import { DragulaService } from 'ng2-dragula';
import {
  PanelConfig,
  PanelVisibilityOption
} from 'src/app/models/user-dashboard-panels-config';
import { ModalConfirmComponent } from 'src/app/components/system/modal-confirm/modal-confirm.component';
import {
  NgbAccordionDirective,
  NgbAccordionItem
} from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-user-applications',
  templateUrl: './user-applications.component.html',
  styleUrls: ['./user-applications.component.css']
})
export class UserApplicationsComponent implements OnInit {
  @ViewChild('myInspectionQueueComplete', { static: false })
  myInspectionQueueComplete: MyInspectionQueueCompleteComponent;
  @ViewChild('upcomingInspectionToCompleteComponent', { static: false })
  upcomingInspectionToCompleteComponent: UpcomingInspectionToCompleteComponent;
  @ViewChild('inspectionRequestedQueueComponent', { static: false })
  inspectionRequestedQueueComponent: InspectionRequestedQueueComponent;

  @ViewChild('panelConfigModal', { static: true })
  panelConfigModal: ModalConfirmComponent;

  @ViewChild('myWorkflowApplications', { static: false })
  myWorkflowApplications: TemplateRef<any>;
  @ViewChild('myWorkflowApplicationsAcc', { static: false })
  myWorkflowApplicationsAcc: NgbAccordionDirective;
  @ViewChild('myWorkflowApplicationsItem', { static: false })
  myWorkflowApplicationsItem: NgbAccordionItem;
  @ViewChild('applicationsSharedByMe', { static: true })
  applicationsSharedByMe: TemplateRef<any>;
  @ViewChild('applicationsSharedWithMe', { static: true })
  applicationsSharedWithMe: TemplateRef<any>;
  @ViewChild('applicationsNeedingMyAttention', { static: true })
  applicationsNeedingMyAttention: TemplateRef<any>;
  @ViewChild('applicationsPendingPayment', { static: true })
  applicationsPendingPayment: TemplateRef<any>;
  @ViewChild('inspectionsToSchedule', { static: true })
  inspectionsToSchedule: TemplateRef<any>;
  @ViewChild('inspectionsToComplete', { static: true })
  inspectionsToComplete: TemplateRef<any>;
  @ViewChild('upcomingInspections', { static: true })
  upcomingInspections: TemplateRef<any>;
  @ViewChild('requestedInspections', { static: true })
  requestedInspections: TemplateRef<any>;
  @ViewChild('expiringRegistrations', { static: true })
  expiringRegistrations: TemplateRef<any>;
  @ViewChild('expiringRenewals', { static: true })
  expiringRenewals: TemplateRef<any>;
  @ViewChild('acc', { static: false })
  acc: NgbAccordionDirective;

  public loading = false;
  public opened = true;
  public loaded = 0;
  public Actions: Actions = Actions;
  public workflows: Workflow[];
  userConfigsKey: string;
  activeIds: string[] = [];
  public panelConfigs: PanelConfig[];
  public trialPanelConfigs: PanelConfig[];
  defaultConfigs = [
    {
      name: 'My Workflow Applications',
      panelId: 'wm-workflow-application-list-pane',
      templateId: 'myWorkflowApplications',
      displayOrder: 1,
      visibility: PanelVisibilityOption.Expanded
    },
    {
      name: 'Workflow Applications Shared By Me',
      panelId: 'wm-workflow-applications-shared-by-me',
      templateId: 'applicationsSharedByMe',
      displayOrder: 2,
      visibility: PanelVisibilityOption.Expanded
    },
    {
      name: 'Workflow Applications Shared With Me',
      panelId: 'wm-workflow-applications-shared-with-me',
      templateId: 'applicationsSharedWithMe',
      displayOrder: 3,
      visibility: PanelVisibilityOption.Expanded
    },
    {
      name: 'Applications Needing My Attention',
      panelId: 'wm-workflow-applications-needing-my-attention',
      templateId: 'applicationsNeedingMyAttention',
      displayOrder: 4,
      visibility: PanelVisibilityOption.Expanded
    },
    {
      name: 'Inspections I Have Requested',
      panelId: 'app-inspection-requested-queue-pane',
      templateId: 'requestedInspections',
      displayOrder: 10, // the value of 10 here has to do with panels to be added for admin roles in getPanelConfigs()
      visibility: PanelVisibilityOption.Expanded
    }
  ];

  waitingOnComponents = [
    {
      name: 'WorkflowApplicationListComponent',
      type: 'wm-workflow-application-list',
      loaded: false
    },
    {
      name: 'WorkflowApplicationsNeedingMyAttentionComponent',
      type: 'wm-workflow-applications-needing-my-attention',
      loaded: false
    },
    {
      name: 'InspectionRequestedQueueComponent',
      type: 'app-inspection-requested-queue',
      loaded: false
    }
  ];

  myWorkflowsFilter = [
    {
      title: 'submitterId',
      options: [
        {
          title: 'submitterId',
          selected: true,
          filterText: '',
          searchText: this.context.user ? this.context.user.id : null,
          strOperator: null
        }
      ]
    }
  ];

  sharedByMeWorkflowsFilter = [
    {
      title: 'sharedByMe',
      options: [
        {
          title: 'sharedByMe',
          selected: true,
          filterText: '',
          searchText: this.context.user ? this.context.user.userName : null,
          strOperator: null
        }
      ]
    }
  ];

  sharedWithMeWorkflowsFilter = [
    {
      title: 'sharedWithMe',
      options: [
        {
          title: 'sharedWithMe',
          selected: true,
          filterText: '',
          searchText: this.context.user ? this.context.user.userName : null,
          strOperator: null
        }
      ]
    }
  ];

  // sets default datatable sorting of upcoming inspections
  // to sort on scheduled date, earliest to latest
  public upcomingInspectionsSortField = 'scheduledOn';
  public upcomingInspectionsSortDescending = false;

  // record counts emitted from components called in each panel
  recordCounts: { [key: string]: number } = {};

  changedFilterPanel: string;

  topMargin: number;

  constructor(
    private _service: DataService,
    public context: WorkflowContextService,
    private _securitySvc: SecurityService,
    private _router: Router,
    private _dragulaService: DragulaService,
    private _ref: ChangeDetectorRef,
    private _clientSvc: ClientService
  ) {}

  isAdmin(): Observable<boolean> {
    return this.isEntitled(Actions.ADMIN);
  }

  isEntitled(actionId: string): Observable<boolean> {
    return this._securitySvc.isLoginEntitled(actionId);
  }

  async getPanelConfigs(): Promise<PanelConfig[]> {
    const isAdmin = await this.isAdmin().toPromise();
    let configs = [];

    // The irregular displayOrder values account for a variety of situations in which an admin role is given to
    // or removed from a user, and ensures existing admins experience no change in the order of their panels until
    // they opt to change the order with the new config modal.  In no situation will any of these displayOrder values
    // be the same as any displayOrder value in either defaultConfigs, or existing configs coming from localstorage
    const adminConfigs = [
      {
        name: 'Applications Pending Payment',
        panelId: 'wm-workflow-applications-pending-payments',
        templateId: 'applicationsPendingPayment',
        displayOrder: 6,
        visibility: PanelVisibilityOption.Expanded
      },
      {
        name: 'Inspections To Schedule',
        panelId: 'app-my-inspection-queue-schedule-pane',
        templateId: 'inspectionsToSchedule',
        displayOrder: 7,
        visibility: PanelVisibilityOption.Expanded
      },
      {
        name: 'Inspections To Complete',
        panelId: 'app-my-inspection-queue-complete-pane',
        templateId: 'inspectionsToComplete',
        displayOrder: 8,
        visibility: PanelVisibilityOption.Expanded
      },
      {
        name: 'Upcoming Inspections To Complete (<7 days from today)',
        panelId: 'app-upcoming-inspection-to-complete-pane',
        templateId: 'upcomingInspections',
        displayOrder: 9,
        visibility: PanelVisibilityOption.Expanded
      },
      {
        name: 'Registrations About To Expire',
        panelId: 'app-contractor-expire-registrations-pane',
        templateId: 'expiringRegistrations',
        displayOrder: 11,
        visibility: PanelVisibilityOption.Expanded
      },
      {
        name: 'Renewals About To Expire',
        panelId: 'wm-expiring-renewals-pane',
        templateId: 'expiringRenewals',
        displayOrder: 12,
        visibility: PanelVisibilityOption.Expanded
      }
    ];

    const existingConfigs = JSON.parse(
      localStorage.getItem(this.userConfigsKey) ?? '[]'
    );

    if (existingConfigs !== null && existingConfigs.length > 0) {
      // if user has been granted admin role since last session, add the appropriate panels to their configs
      if (existingConfigs.length === 5) {
        if (isAdmin) {
          configs = existingConfigs.concat(adminConfigs);
        } else {
          return existingConfigs;
        }
      } else if (existingConfigs.length === 11) {
        if (!isAdmin) {
          configs = existingConfigs.filter(
            config =>
              config.panelId !== 'wm-workflow-applications-pending-payments' &&
              config.panelId !== 'app-my-inspection-queue-schedule-pane' &&
              config.panelId !== 'app-my-inspection-queue-complete-pane' &&
              config.panelId !== 'app-upcoming-inspection-to-complete-pane' &&
              config.panelId !== 'app-contractor-expire-registrations-pane' &&
              config.panelId !== 'wm-expiring-renewals-pane'
          );
        } else {
          configs = existingConfigs;
        }
      } else if (
        existingConfigs.length ===
        10 /*this means an admin is using the setting modal for the first time since the new expiring renewals panel was added*/
      ) {
        if (isAdmin) {
          existingConfigs.push({
            name: 'Renewals About To Expire',
            panelId: 'wm-expiring-renewals-pane',
            templateId: 'expiringRenewals',
            displayOrder: 12,
            visibility: PanelVisibilityOption.Expanded
          });
          configs = existingConfigs;
        } else {
          configs = existingConfigs.filter(
            config =>
              config.panelId !== 'wm-workflow-applications-pending-payments' &&
              config.panelId !== 'app-my-inspection-queue-schedule-pane' &&
              config.panelId !== 'app-my-inspection-queue-complete-pane' &&
              config.panelId !== 'app-upcoming-inspection-to-complete-pane' &&
              config.panelId !== 'app-contractor-expire-registrations-pane'
          );
        }
      }
    } else {
      if (isAdmin) {
        configs = this.sortConfigs(this.defaultConfigs.concat(adminConfigs));
      } else {
        configs = this.defaultConfigs;
      }
    }

    return JSON.parse(JSON.stringify(configs) ?? '[]');
  }

  getActiveIds(): string[] {
    const ids: string[] = [];
    for (let i = 0; i < this.panelConfigs.length; i++) {
      if (this.panelConfigs[i].visibility === PanelVisibilityOption.Expanded) {
        ids.push(this.panelConfigs[i].panelId);
      }
    }
    return ids;
  }

  setRecordCounts(count: number, id: string) {
    this.recordCounts[id] = count;
    if (count == 0 && id !== this.changedFilterPanel) {
      this.acc.collapse(id);
    } else if (this.activeIds.find(aid => aid == id)) {
      this.acc.expand(id);
    }
    this.changedFilterPanel = '';
  }

  ngAfterViewInit(): void {
    this._dragulaService.dropModel().subscribe(value => {
      this.onDropPanelRow(value);
    });

    this._ref.detectChanges();
  }

  onDropPanelRow(args: any) {
    for (let i = 0; i < args.sourceModel.length; i++) {
      if (args.sourceModel[i]) {
        args.sourceModel[i].displayOrder = i + 1;
      }
    }
  }

  sortConfigs(configs: PanelConfig[]) {
    return configs.sort((a, b) => a.displayOrder - b.displayOrder);
  }

  onModalOpen() {
    this.trialPanelConfigs = JSON.parse(JSON.stringify(this.panelConfigs) ?? '[]');
  }

  onModalCancel() {
    this.trialPanelConfigs = [];
  }

  async onModalSave() {
    this.panelConfigs = JSON.parse(JSON.stringify(this.trialPanelConfigs) ?? '[]');
    this.trialPanelConfigs = [];
    localStorage.setItem(
      this.userConfigsKey,
      JSON.stringify(this.panelConfigs)
    );
    this.activeIds = this.getActiveIds();
    this._ref.detectChanges();
  }

  async ngOnInit() {
    const clientIds: string[] = [];

    const members = this.context.user.members;
    if (members) {
      for (const m of members) {
        if (m.role.clientId && !clientIds.includes(m.role.clientId)) {
          clientIds.push(m.role.clientId);
        }
      }
    }
    const result = await this.isEntitled(Actions.ADMIN).toPromise();
    if (result === true) {
      this.waitingOnComponents.push({
        name: 'WorkflowApplicationsPendingPaymentsComponent',
        type: 'wm-workflow-applications-pending-payments',
        loaded: false
      });
      this.waitingOnComponents.push({
        name: 'MyInspectionQueueScheduleComponent',
        type: 'app-my-inspection-queue-schedule',
        loaded: false
      });
      this.waitingOnComponents.push({
        name: 'MyInspectionQueueCompleteComponent',
        type: 'app-my-inspection-queue-complete',
        loaded: false
      });
      this.waitingOnComponents.push({
        name: 'UpcomingInspectionToCompleteComponent',
        type: 'app-upcoming-inspection-to-complete',
        loaded: false
      });
    }
    if (clientIds && clientIds.length === 1) {
      const client = await this._clientSvc
        .getPublicClient(clientIds[0])
        .toPromise();
      if (client) {
        this.context.client$.next(client);
      }
    }

    const userId = this.context.user.id;
    this.userConfigsKey = `${userId}-dashboard`;
    this.panelConfigs = await this.getPanelConfigs();
    this.activeIds = this.getActiveIds();

    this.workflows = await this._service
      .getPublicWorkflows(this.context.client, false, null)
      .toPromise();
  }

  isAllLoaded() {
    return this.waitingOnComponents.filter(item => {
      if (item.loaded === false) {
        return true;
      } else {
        return false;
      }
    });
  }
  isLoaded(name) {
    return this.waitingOnComponents.filter(item => {
      if (item.loaded === true && item.type === name) {
        return item;
      }
    });
  }
  getContractors() {}
  incrementLoaded(value) {
    this.loaded += 1;
    this.waitingOnComponents = this.waitingOnComponents.map(v => {
      if (v.name === value) {
        v.loaded = true;
      }
      return v;
    });
  }

  goToApplication(applicationId) {
    // TODO: Set the client context before navigating to the app
    this._router.navigate(['/application/workflow-application', applicationId]);
  }
  updateStatusEvent(evt) {
    this.myInspectionQueueComplete.reload();
    this.upcomingInspectionToCompleteComponent.reload();
    this.inspectionRequestedQueueComponent.reload();
  }

  filtersChange(panelId: string) {
    this.changedFilterPanel = panelId;
  }

  setTopMarginForNavbar(pixelHeight: number) {
    this.topMargin = pixelHeight;
  }
}
