import { MultipleParcelSearchActivity } from './../models/activities/multiple-parcel-search-activity';
import { QueryMapLayerActivityModel } from './../models/activities/query-map-layer-activity';
import { CancelScheduledEmailActivityEditorComponent } from './../components/workflow/activities/cancel-scheduled-email-activity/cancel-scheduled-email-activity-editor/cancel-scheduled-email-activity-editor.component';
import { CancelScheduledEmailActivityModel } from './../models/activities/cancel-scheduled-email-activity';
import { CancelScheduledEmailActivity } from 'src/app/models/activities/cancel-scheduled-email-activity';
import { DecisionActivityViewComponent } from './../components/workflow/activities/decision-activity-view/decision-activity-view.component';
import { QRDataEntity } from './../models/data-entities/qr-data-entity';
import * as _ from 'underscore';
import { ExportDataActivityViewComponent } from '../components/workflow/activities/export-data-activity/export-data-activity-view/export-data-activity-view.component';
import { ExportDataActivityInputComponent } from '../components/workflow/activities/export-data-activity/export-data-activity-input/export-data-activity-input.component';
import { ExportDataActivityEditorComponent } from './../components/workflow/activities/export-data-activity/export-data-activity-editor/export-data-activity-editor.component';
import {
  DestinationConfiguration,
  ExportDataActivity,
  ExportDataActivityModel
} from './../models/activities/export-data-activity';
import { RenewableParentsResponse, WorkflowType } from './../models/workflow';
import {
  GenerateApplicationNumberActivityModel,
  GenerateApplicationNumberActivity
} from './../models/activities/generate-application-number-activity';
import { GenerateApplicationNumberActivityInputComponent } from './../components/workflow/activities/generate-application-number-activity/generate-application-number-activity-input/generate-application-number-activity-input.component';
import { GenerateApplicationNumberActivityEditorComponent } from './../components/workflow/activities/generate-application-number-activity/generate-application-number-activity-editor/generate-application-number-activity-editor.component';
import {
  ItemSearchOptionFieldOption,
  ExportEntityOption,
  ItemSearchOptionField
} from 'src/app/components/filter-list/models/filterClasses';

import { ApplicationStatus } from 'src/app/models';
import { AppointmentActivityViewComponent } from './../components/workflow/activities/appointment-activity/appointment-activity-view/appointment-activity-view.component';
import {
  CallWorkflowActivity,
  CallWorkflowActivityModel
} from './../models/activities/call-workflow-activity';
import { EmailActivityViewComponent } from './../components/workflow/activities/email-activity/email-activity-view/email-activity-view.component';
import { RichTextDataEntityEditorComponent } from './../components/workflow/data-entities/rich-text-data-entity/rich-text-data-entity-editor/rich-text-data-entity-editor.component';
import { RichTextDataEntityInputComponent } from './../components/workflow/data-entities/rich-text-data-entity/rich-text-data-entity-input/rich-text-data-entity-input.component';
import {
  Injectable,
  Inject,
  forwardRef,
  Type,
  EventEmitter,
  Output
} from '@angular/core';
import {
  Observable,
  of,
  combineLatest,
  Subscriber,
  empty,
  forkJoin,
  lastValueFrom
} from 'rxjs';
import {
  map,
  concatMap,
  flatMap,
  debounceTime,
  switchMap,
  tap
} from 'rxjs/operators';

import {
  Workflow,
  Client,
  CommunityWorkflow,
  CollectedValue,
  WorkflowCheckpoint,
  Role
} from '../models';
import {
  ActivityUtilities,
  Utilities,
  DataService,
  WorkflowContextService,
  TemplateService,
  ApplicationDiagramInfo
} from './';
import * as Activities from '../models/activities';

import {
  Activity,
  PrintTemplateActivity,
  ActivityModel,
  FormActivityModel,
  FormActivity,
  EmailActivityModel,
  EmailActivity,
  DecisionActivity,
  DecisionActivityModel,
  PaymentActivityModel,
  PaymentActivity,
  PrintTemplateActivityModel,
  SearchParcelDataActivity,
  SearchParcelDataActivityModel,
  EmailActivityAttachment,
  WaitActivityModel,
  WaitActivity,
  StartWorkflowActivity,
  StartWorkflowActivityModel
} from '../models/activities';
import {
  DataEntity,
  FeeDataEntity,
  DocumentDataEntity,
  FreeFormTextDataEntity,
  RestrictedFormat,
  NumericDataEntity,
  ListDataEntity,
  YesNoDataEntity,
  DisplayEntityValueDataEntity,
  DateDataEntity,
  CalculatedValueDataEntity
} from '../models/data-entities';
import { FormActivityInputComponent } from '../components/workflow/activities/form-activity-editor/form-activity-input/form-activity-input.component';
import { DataEntityInputComponent } from '../components/workflow/data-entities/data-entity-input/data-entity-input.component';
import { FreeFormTextDataEntityInputComponent } from '../components/workflow/data-entities/free-form-text-data-entity/free-form-text-data-entity-input/free-form-text-data-entity-input.component';
import {
  CompleteWorkflowActivity,
  CompleteWorkflowActivityModel
} from '../models/activities/complete-workflow-activity';
import { CompleteWorkflowActivityInputComponent } from '../components/workflow/activities/complete-workflow-activity-editor/complete-workflow-activity-input/complete-workflow-activity-input.component';
import { StartApplicationResponse } from '../models/start-application-response';
import { DataEntityEditorBaseComponent } from '../components/workflow/data-entities/data-entity-editor-base/data-entity-editor-base.component';
import { NumericDataEntityComponent } from '../components/workflow/data-entities/numeric-data-entity/numeric-data-entity.component';
import { NumericDataEntityInputComponent } from '../components/workflow/data-entities/numeric-data-entity/numeric-data-entity-input/numeric-data-entity-input.component';
import { FreeFormTextDataEntityComponent } from '../components/workflow/data-entities/free-form-text-data-entity/free-form-text-data-entity.component';
import { ActivityEditorBaseComponent } from '../components/workflow/activities/activity-editor-base/activity-editor-base.component';
import { ActivityViewComponent } from '../components/workflow/activities/activity-editor/activity-view/activity-view.component';
import { ActivityView } from '../views/master-views/app.view/app.view.component';
import { FormActivityEditorComponent } from '../components/workflow/activities/form-activity-editor/form-activity-editor.component';
import { EmailActivityEditorComponent } from '../components/workflow/activities/email-activity/email-activity-editor/email-activity-editor.component';
import { MapsketchDataEntityEditorComponent } from '../components/workflow/data-entities/mapsketch-data-entity/mapsketch-data-entity-editor/mapsketch-data-entity-editor.component';
import { MapsketchDataEntityInputComponent } from '../components/workflow/data-entities/mapsketch-data-entity/mapsketch-data-entity-input/mapsketch-data-entity-input.component';
import { MapsketchDataEntity } from '../models/data-entities/mapsketch-data-entity';
import { DocumentDataEntityInputComponent } from '../components/workflow/data-entities/document-data-entity/document-data-entity-input/document-data-entity-input.component';
import { DocumentDataEntityComponent } from '../components/workflow/data-entities/document-data-entity/document-data-entity.component';
import { FormActivityViewComponent } from '../components/workflow/activities/form-activity-editor/form-activity-view/form-activity-view.component';
import { FreeFormTextDataEntityViewComponent } from '../components/workflow/data-entities/free-form-text-data-entity/free-form-text-data-entity-view/free-form-text-data-entity-view.component';
import { DataEntityViewComponent } from '../components/workflow/data-entities/data-entity-view/data-entity-view.component';
import { NumericDataEntityViewComponent } from '../components/workflow/data-entities/numeric-data-entity/numeric-data-entity-view/numeric-data-entity-view.component';
import { FeeDataEntityEditorComponent } from '../components/workflow/data-entities/fee-data-entity/fee-data-entity-editor/fee-data-entity-editor.component';
import { FeeDataEntityInputComponent } from '../components/workflow/data-entities/fee-data-entity/fee-data-entity-input/fee-data-entity-input.component';
import { FeeDataEntityViewComponent } from '../components/workflow/data-entities/fee-data-entity/fee-data-entity-view/fee-data-entity-view.component';
import { SearchParcelDataActivityEditorComponent } from '../components/workflow/activities/search-parcel-data-activity-editor/search-parcel-data-activity-editor.component';
import { SearchParcelDataActivityInputComponent } from '../components/workflow/activities/search-parcel-data-activity-editor/search-parcel-data-activity-input/search-parcel-data-activity-input.component';
import { SearchParcelDataActivityViewComponent } from '../components/workflow/activities/search-parcel-data-activity-editor/search-parcel-data-activity-view/search-parcel-data-activity-view.component';
import { PaymentActivityEditorComponent } from '../components/workflow/activities/payment-activity/payment-activity-editor/payment-activity-editor.component';
import { PaymentActivityViewComponent } from '../components/workflow/activities/payment-activity/payment-activity-view/payment-activity-view.component';
import { PaymentActivityInputComponent } from '../components/workflow/activities/payment-activity/payment-activity-input/payment-activity-input.component';
import { PrintTemplateActivityEditorComponent } from '../components/workflow/activities/print-template-activity/print-template-activity-editor/print-template-activity-editor.component';
import { PrintTemplateActivityInputComponent } from '../components/workflow/activities/print-template-activity/print-template-activity-input/print-template-activity-input.component';
import { PrintTemplateActivityViewComponent } from '../components/workflow/activities/print-template-activity/print-template-activity-view/print-template-activity-view.component';
import { MapsketchDataEntityViewComponent } from '../components/workflow/data-entities/mapsketch-data-entity/mapsketch-data-entity-view/mapsketch-data-entity-view.component';
import { DecisionActivityEditorComponent } from '../components/workflow/activities/decision-activity-editor/decision-activity-editor.component';
import { ListDataEntityEditorComponent } from '../components/workflow/data-entities/list-data-entity/list-data-entity-editor/list-data-entity-editor.component';
import { ListDataEntityInputComponent } from '../components/workflow/data-entities/list-data-entity/list-data-entity-input/list-data-entity-input.component';
import { ListDataEntityViewComponent } from '../components/workflow/data-entities/list-data-entity/list-data-entity-view/list-data-entity-view.component';
import { WaitActivityEditorComponent } from '../components/workflow/activities/wait-activity/wait-activity-editor/wait-activity-editor.component';
import { WaitActivityInputComponent } from '../components/workflow/activities/wait-activity/wait-activity-input/wait-activity-input.component';
import { WaitActivityViewComponent } from '../components/workflow/activities/wait-activity/wait-activity-view/wait-activity-view.component';
import { SignatureDataEntityEditorComponent } from '../components/workflow/data-entities/signature-data-entity/signature-data-entity-editor/signature-data-entity-editor.component';
import { SignatureDataEntityInputComponent } from '../components/workflow/data-entities/signature-data-entity/signature-data-entity-input/signature-data-entity-input.component';
import { SignatureDataEntity } from '../models/data-entities/signature-data-entity';
import { SignatureDataEntityViewComponent } from '../components/workflow/data-entities/signature-data-entity/signature-data-entity-view/signature-data-entity-view.component';
import { YesNoDataEntityEditorComponent } from '../components/workflow/data-entities/yes-no-data-entity/yes-no-data-entity-editor/yes-no-data-entity-editor.component';
import { YesNoDataEntityInputComponent } from '../components/workflow/data-entities/yes-no-data-entity/yes-no-data-entity-input/yes-no-data-entity-input.component';
import { YesNoDataEntityViewComponent } from '../components/workflow/data-entities/yes-no-data-entity/yes-no-data-entity-view/yes-no-data-entity-view.component';
import { WorkflowGraph } from '../models/workflow-graph';
import { CompleteWorkflowActivityEditorComponent } from '../components/workflow/activities/complete-workflow-activity-editor/complete-workflow-activity-editor.component';
import { StartWorkflowActivityInputComponent } from '../components/workflow/activities/start-workflow-activity/start-workflow-activity-input/start-workflow-activity-input.component';
import { StartWorkflowActivityEditorComponent } from '../components/workflow/activities/start-workflow-activity/start-workflow-activity-editor/start-workflow-activity-editor.component';
import { StartWorkflowActivityViewComponent } from '../components/workflow/activities/start-workflow-activity/start-workflow-activity-view/start-workflow-activity-view.component';
import { ActivityNavigationInfo } from '../models/activity-navigation-Info';
import { DocumentDataEntityViewComponent } from '../components/workflow/data-entities/document-data-entity/document-data-entity-view/document-data-entity-view.component';
import { TodayDataEntityEditorComponent } from '../components/workflow/data-entities/today-data-entity/today-data-entity-editor/today-data-entity-editor.component';
import { TodayDataEntityInputComponent } from '../components/workflow/data-entities/today-data-entity/today-data-entity-input/today-data-entity-input.component';
import { TodayDataEntity } from '../models/data-entities/today-data-entity';
import { TodayDataEntityViewComponent } from '../components/workflow/data-entities/today-data-entity/today-data-entity-view/today-data-entity-view.component';
import { DisplayEntityValueDataEntityEditorComponent } from '../components/workflow/data-entities/display-entity-value/display-entity-value-data-entity-editor/display-entity-value-data-entity-editor.component';
import { DisplayEntityValueDataEntityInputComponent } from '../components/workflow/data-entities/display-entity-value/display-entity-value-data-entity-input/display-entity-value-data-entity-input.component';
import { DisplayEntityValueDataEntityViewComponent } from '../components/workflow/data-entities/display-entity-value/display-entity-value-data-entity-view/display-entity-value-data-entity-view.component';
import { ContractorRegistrationDecisionActivity } from '../models/activities/contractor-registration-decision-activity';
import { ContractorRegistrationDecisionActivityInputComponent } from '../components/workflow/activities/contractor-registration-decision-activity/contractor-registration-decision-activity-input/contractor-registration-decision-activity-input.component';
import { ContractorRegistrationDecisionActivityEditorComponent } from '../components/workflow/activities/contractor-registration-decision-activity/contractor-registration-decision-activity-editor/contractor-registration-decision-activity-editor.component';
import { ContractorRegistrationDecisionActivityViewComponent } from '../components/workflow/activities/contractor-registration-decision-activity/contractor-registration-decision-activity-view/contractor-registration-decision-activity-view.component';
import { ContractorRegistrationInfoFormActivity } from '../models/activities/contractor-registration-info-form-activity';
import { ContractorInfoFormActivity } from '../models/activities/contractor-info-form-activity';
import { ContractorRegistrationInfoFormActivityInputComponent } from '../components/workflow/activities/contractor-registration-info-form-activity/contractor-registration-info-form-activity-input/contractor-registration-info-form-activity-input.component';
import { ContractorInfoFormActivityInputComponent } from '../components/workflow/activities/contractor-info-form-activity/contractor-info-form-activity-input/contractor-info-form-activity-input.component';
import { ContractorInfoFormActivityViewComponent } from '../components/workflow/activities/contractor-info-form-activity/contractor-info-form-activity-view/contractor-info-form-activity-view.component';
import { ContractorInfoFormActivityEditorComponent } from '../components/workflow/activities/contractor-info-form-activity/contractor-info-form-activity-editor/contractor-info-form-activity-editor.component';
import { ContractorRegistrationInfoFormActivityEditorComponent } from '../components/workflow/activities/contractor-registration-info-form-activity/contractor-registration-info-form-activity-editor/contractor-registration-info-form-activity-editor.component';
import { ContractorRegistrationInfoFormActivityViewComponent } from '../components/workflow/activities/contractor-registration-info-form-activity/contractor-registration-info-form-activity-view/contractor-registration-info-form-activity-view.component';
import { ContractorDocumentDataEntityViewComponent } from '../components/workflow/data-entities/contractor-document-data-entity/contractor-document-data-entity-view/contractor-document-data-entity-view.component';
import { ContractorDocumentDataEntity } from '../models/data-entities/contractor-document-data-entity';
import { ContractorDocumentDataEntityInputComponent } from '../components/workflow/data-entities/contractor-document-data-entity/contractor-document-data-entity-input/contractor-document-data-entity-input.component';
import { ContractorDocumentDataEntityEditorComponent } from '../components/workflow/data-entities/contractor-document-data-entity/contractor-document-data-entity-editor/contractor-document-data-entity-editor.component';
import {
  InspectionRequestActivity,
  InspectionActivityModel
} from '../models/activities/inspection-request-activity';
import { InspectionSimpleActivity } from '../models/activities/inspection-simple-activity';

import { InspectionSimpleActivityInputComponent } from '../components/workflow/activities/inspection-simple-activity/inspection-simple-activity-input/inspection-simple-activity-input.component';
import { InspectionSimpleActivityEditorComponent } from '../components/workflow/activities/inspection-simple-activity/inspection-simple-activity-editor/inspection-simple-activity-editor.component';
import { InspectionSimpleActivityViewComponent } from '../components/workflow/activities/inspection-simple-activity/inspection-simple-activity-view/inspection-simple-activity-view.component';

import { InspectionRequestActivityInputComponent } from '../components/workflow/activities/inspection-request/inspection-request-activity-input/inspection-request-activity-input.component';
import { InspectionRequestActivityEditorComponent } from '../components/workflow/activities/inspection-request/inspection-request-activity-editor/inspection-request-activity-editor.component';
import { InspectionRequestActivityViewComponent } from '../components/workflow/activities/inspection-request/inspection-request-activity-view/inspection-request-activity-view.component';

import { GenerateClientApplicationNumberDataEntityEditorComponent } from '../components/workflow/data-entities/client-app-number-data-entity/client-app-number-data-entity-editor/client-app-number-data-entity-editor.component';
import { GenerateClientApplicationNumberDataEntityInputComponent } from '../components/workflow/data-entities/client-app-number-data-entity/client-app-number-data-entity-input/client-app-number-data-entity-input.component';
import { GenerateClientApplicationNumberDataEntityViewComponent } from '../components/workflow/data-entities/client-app-number-data-entity/client-app-number-data-entity-view/client-app-number-data-entity-view.component';
import { GenerateClientApplicationNumberDataEntity } from '../models/data-entities/client-app-number-data-entity';

import { DateDataEntityEditorComponent } from '../components/workflow/data-entities/date-data-entity/date-data-entity-editor/date-data-entity-editor.component';
import { DateDataEntityInputComponent } from '../components/workflow/data-entities/date-data-entity/date-data-entity-input/date-data-entity-input.component';
import { DateDataEntityViewComponent } from '../components/workflow/data-entities/date-data-entity/date-data-entity-view/date-data-entity-view.component';
import { ContactListDataEntityEditorComponent } from '../components/workflow/data-entities/contact-list-data-entity/contact-list-data-entity-editor/contact-list-data-entity-editor.component';
import { ContactListDataEntityInputComponent } from '../components/workflow/data-entities/contact-list-data-entity/contact-list-data-entity-input/contact-list-data-entity-input.component';
import { ContactListDataEntity } from '../models/data-entities/contact-list-data-entity';
import { ContactListDataEntityViewComponent } from '../components/workflow/data-entities/contact-list-data-entity/contact-list-data-entity-view/contact-list-data-entity-view.component';
import { CalculateValueDataEntityViewComponent } from '../components/workflow/data-entities/calculate-value-data-entity/calculate-value-data-entity-view/calculate-value-data-entity-view.component';
import { CalculateValueDataEntityInputComponent } from '../components/workflow/data-entities/calculate-value-data-entity/calculate-value-data-entity-input/calculate-value-data-entity-input.component';
import { CalculateValueDataEntityEditorComponent } from '../components/workflow/data-entities/calculate-value-data-entity/calculate-value-data-entity-editor/calculate-value-data-entity-editor.component';
import { PagedInspectionsVM, InspectionVM } from '../models/inspections';
import { InspectionScheduleActivityEditorComponent } from '../components/workflow/activities/inspection-schedule/inspection-schedule-activity-editor/inspection-schedule-activity-editor.component';
import { InspectionScheduleActivityViewComponent } from '../components/workflow/activities/inspection-schedule/inspection-schedule-activity-view/inspection-schedule-activity-view.component';
import { InspectionScheduleActivityInputComponent } from '../components/workflow/activities/inspection-schedule/inspection-schedule-activity-input/inspection-schedule-activity-input.component';
import { InspectionScheduleActivity } from '../models/activities/inspection-schedule-activity';
import { ReviewContractorRegistrationActivity } from '../models/activities/review-contractor-registration-activity';
import { ReviewContractorRegistrationActivityInputComponent } from '../components/workflow/activities/review-contractor-registration-activity/review-contractor-registration-activity-input/review-contractor-registration-activity-input.component';
import { ReviewContractorRegistrationActivityEditorComponent } from '../components/workflow/activities/review-contractor-registration-activity/review-contractor-registration-activity-editor/review-contractor-registration-activity-editor.component';
import { ReviewContractorRegistrationActivityViewComponent } from '../components/workflow/activities/review-contractor-registration-activity/review-contractor-registration-activity-view/review-contractor-registration-activity-view.component';
import { CompleteInspectionActivity } from '../models/activities/complete-inspection-activity';
import { CompleteInspectionActivityInputComponent } from '../components/workflow/activities/complete-inspection-activity/complete-inspection-activity-input/complete-inspection-activity-input.component';
import { CompleteInspectionActivityEditorComponent } from '../components/workflow/activities/complete-inspection-activity/complete-inspection-activity-editor/complete-inspection-activity-editor.component';
import { CompleteInspectionActivityViewComponent } from '../components/workflow/activities/complete-inspection-activity/complete-inspection-activity-view/complete-inspection-activity-view.component';

import { MapActivityEditorComponent } from '../components/workflow/activities/map-activity/map-activity-editor/map-activity-editor.component';
import { MapActivityInputComponent } from '../components/workflow/activities/map-activity/map-activity-input/map-activity-input.component';
import { MapActivityViewComponent } from '../components/workflow/activities/map-activity/map-activity-view/map-activity-view.component';
import {
  MapActivityModel,
  MapActivity
} from '../models/activities/map-activity';
import { ContractorSelectionActivityViewComponent } from '../components/workflow/activities/contractor-selection-activity/contractor-selection-activity-view/contractor-selection-activity-view.component';
import { ContractorSelectionActivityEditorComponent } from '../components/workflow/activities/contractor-selection-activity/contractor-selection-activity-editor/contractor-selection-activity-editor.component';
import { ContractorSelectionActivity } from '../models/activities/contractor-selection-activity';
import { ContractorSelectionActivityInputComponent } from '../components/workflow/activities/contractor-selection-activity/contractor-selection-activity-input/contractor-selection-activity-input.component';
import { ContractorRenewalDecisionActivityInputComponent } from '../components/workflow/activities/contractor-renewal-decision-activity-input/contractor-renewal-decision-activity-input.component';
import { ContractorRenewalDecisionActivity } from '../models/activities/contractor-renewal-decision-activity';
import { ContractorRenewalDecisionActivityEditorComponent } from '../components/workflow/activities/contractor-renewal-decision-activity-editor/contractor-renewal-decision-activity-editor.component';
import { ContractorRenewalDecisionActivityViewComponent } from '../components/workflow/activities/contractor-renewal-decision-activity-view/contractor-renewal-decision-activity-view.component';
import {
  RequiredUserActivityModel,
  RequiredUserActivity
} from '../models/activities/required-user-activity';
import { RequiredUserActivityInputComponent } from '../components/workflow/activities/required-user-activity/required-user-activity-input/required-user-activity-input.component';
import { RequiredUserActivityEditorComponent } from '../components/workflow/activities/required-user-activity/required-user-activity-editor/required-user-activity-editor.component';
import { RequiredUserActivityViewComponent } from '../components/workflow/activities/required-user-activity/required-user-activity-view/required-user-activity-view.component';
import { ContractorRegistrationCertificateActivity } from '../models/activities/contractor-registration-certificate-activity';
import { MapExtent } from '../models/mapextent';
import { RichTextDataEntity } from '../models/data-entities/rich-text-data-entity';
import { RichTextDataEntityViewComponent } from '../components/workflow/data-entities/rich-text-data-entity/rich-text-data-entity-view/rich-text-data-entity-view.component';
import { XDataEntityViewComponent } from '../components/workflow/data-entities/x-data-entity/x-data-entity-view/x-data-entity-view.component';
import { YDataEntityViewComponent } from '../components/workflow/data-entities/y-data-entity/y-data-entity-view/y-data-entity-view.component';
import { GeoJSONDataEntityViewComponent } from '../components/workflow/data-entities/geojson-data-entity/geojson-data-entity-view/geojson-data-entity-view.component';

import { XDataEntity } from '../models/data-entities/x-data-entity';
import { YDataEntity } from '../models/data-entities/y-data-entity';
import { GeoJSONDataEntity } from '../models/data-entities/geojson-data-entity';

import { InspectionDocument } from '../models/inspection-document';
import { Document } from '../models/document';
import { InspectionDocumentDataEntityEditorComponent } from '../components/workflow/data-entities/inspection-document-data-entity/inspection-document-data-entity-editor/inspection-document-data-entity-editor.component';
import { InspectionDocumentDataEntityInputComponent } from '../components/workflow/data-entities/inspection-document-data-entity/inspection-document-data-entity-input/inspection-document-data-entity-input.component';
import { InspectionDocumentDataEntity } from '../models/data-entities/inspector-document-data-entity';
import { InspectionDocumentDataEntityViewComponent } from '../components/workflow/data-entities/inspection-document-data-entity/inspection-document-data-entity-view/inspection-document-data-entity-view.component';
import { VoidRequest } from '../models/voidRequest';
import { CallWorkflowViewComponent } from '../components/workflow/activities/call-workflow/call-workflow-view/call-workflow-view.component';
import { CallWorkflowEditorComponent } from '../components/workflow/activities/call-workflow/call-workflow-editor/call-workflow-editor.component';
import { CallWorkflowInputComponent } from '../components/workflow/activities/call-workflow/call-workflow-input/call-workflow-input.component';
import { SystemDataEntity } from '../models/data-entities/system-data-entity';
import { SystemDataEntityInputComponent } from '../components/workflow/data-entities/system-data-entity/system-data-entity-input/system-data-entity-input.component';
import { SystemDataEntityViewComponent } from '../components/workflow/data-entities/system-data-entity/system-data-entity-view/system-data-entity-view.component';
import { CopyWorkflowRequest } from '../models/copy-workflow-request';
import { ConditionTarget } from '../components/system/condition-builder/condition-builder.model';
import { JsonNetDecycle } from './utilities/json-net-decycle';
import { AppointmentActivityEditorComponent } from '../components/workflow/activities/appointment-activity/appointment-activity-editor/appointment-activity-editor.component';
import {
  AppointmentActivity,
  AppointmentActivityModel
} from '../models/activities/appointment-activity';
import { InitiateWorkflowActivity } from '../models/activities/initiate-workflow-activity';
import { InitiateWorkflowEditorComponent } from '../components/workflow/activities/initiate-workflow/initiate-workflow-editor/initiate-workflow-editor.component';
import { InitiateWorkflowInputComponent } from '../components/workflow/activities/initiate-workflow/initiate-workflow-input/initiate-workflow-input.component';
import { InitiateWorkflowViewComponent } from '../components/workflow/activities/initiate-workflow/initiate-workflow-view/initiate-workflow-view.component';
import { GenerateApplicationNumberActivityViewComponent } from '../components/workflow/activities/generate-application-number-activity/generate-application-number-activity-view/generate-application-number-activity-view.component';
import { PaymentConfirmation } from '../models/payment-request';
import { DataEntityDetail } from '../models/data-entity-detail';
import {
  ScheduleEmailActivityModel,
  ScheduleEmailActivity
} from '../models/activities/schedule-email-activity';
import { ScheduleEmailActivityEditorComponent } from '../components/workflow/activities/schedule-email-activity/schedule-email-activity-editor/schedule-email-activity-editor.component';
import { ScheduleEmailActivityViewComponent } from '../components/workflow/activities/schedule-email-activity/schedule-email-activity-view/schedule-email-activity-view.component';
import { SharedAccessDetails } from '../models/shared-access-details';
// import * as System from 'system';
import {
  PagedRegistrationVM,
  Registration,
  RegistrationNote,
  RegistrationVM,
  UnpagedRegistrationVM
} from '../models/registration';
import { RegistrationDocument } from '../models/registration-document';
import { RenewalDecisionActivity } from '../models/activities/renewal-decision-activity';
import { RenewalDecisionActivityInputComponent } from '../components/workflow/activities/renewal-decision-activity/renewal-decision-activity-input/renewal-decision-activity-input.component';
import { RenewalDecisionActivityEditorComponent } from '../components/workflow/activities/renewal-decision-activity/renewal-decision-activity-editor/renewal-decision-activity-editor.component';
import { RenewalDecisionActivityViewComponent } from '../components/workflow/activities/renewal-decision-activity/renewal-decision-activity-view/renewal-decision-activity-view.component';
import { RenewalReviewActivity } from '../models/activities/renewal-review-activity';
import { RenewalReviewActivityViewComponent } from '../components/workflow/activities/renewal-review-activity/renewal-review-activity-view/renewal-review-activity-view.component';
import { RenewalReviewActivityEditorComponent } from '../components/workflow/activities/renewal-review-activity/renewal-review-activity-editor/renewal-review-activity-editor.component';
import { RenewalReviewActivityInputComponent } from '../components/workflow/activities/renewal-review-activity/renewal-review-activity-input/renewal-review-activity-input.component';
import { RenewalCertificateActivity } from '../models/activities/renewal-certificate-activity';
import {
  AgendaItemActivity,
  AgendaItemActivityModel
} from '../models/activities/agenda-item-activity';
import { AgendaItemActivityInputComponent } from '../components/workflow/activities/agenda-item-activity/agenda-item-activity-input/agenda-item-activity-input.component';
import { AgendaItemActivityEditorComponent } from '../components/workflow/activities/agenda-item-activity/agenda-item-activity-editor/agenda-item-activity-editor.component';
import { AgendaItemActivityViewComponent } from '../components/workflow/activities/agenda-item-activity/agenda-item-activity-view/agenda-item-activity-view.component';
import {
  DataSet,
  ReportDataSet,
  WorkflowApplicationReportDataSet
} from '../models/report-data-set';
import { WorkflowApplicationActivityDataAudit } from '../models/workflow-application-activity-data-audit';
import { WorkflowApplicationActivityAuditDetails } from '../models/workflow-application-activity-audit-details';
import { MultipleParcelSearchActivityViewComponent } from '../components/workflow/activities/multiple-parcel-search-activity/multiple-parcel-search-activity-view/multiple-parcel-search-activity-view.component';
import { MultipleParcelSearchActivityEditorComponent } from '../components/workflow/activities/multiple-parcel-search-activity/multiple-parcel-search-activity-editor/multiple-parcel-search-activity-editor.component';
import { MultipleParcelSearchActivityInputComponent } from '../components/workflow/activities/multiple-parcel-search-activity/multiple-parcel-search-activity-input/multiple-parcel-search-activity-input.component';
import { DataEntitiesExportParameters } from '../models/exportOption';
import { DuplicateActivityResponse } from '../models/duplicate-activity-response';
import { NavigateToActivityResponse } from '../models/navigate-to-activity-response';
import { ReviewActivity } from '../models/activities/review-activity';
import { ReviewActivityViewComponent } from '../components/workflow/activities/review-activity/review-activity-view/review-activity-view.component';
import { ReviewActivityEditorComponent } from '../components/workflow/activities/review-activity/review-activity-editor/review-activity-editor.component';
import { ReviewActivityInputComponent } from '../components/workflow/activities/review-activity/review-activity-input/review-activity-input.component';
import { QueryMapLayerActivityViewComponent } from '../components/workflow/activities/query-map-layer-activity/query-map-layer-activity-view/query-map-layer-activity-view.component';
import { QueryMapLayerActivityEditorComponent } from '../components/workflow/activities/query-map-layer-activity/query-map-layer-activity-editor/query-map-layer-activity-editor.component';
import { QueryMapLayerActivityInputComponent } from '../components/workflow/activities/query-map-layer-activity/query-map-layer-activity-input/query-map-layer-activity-input.component';
import { SaveActivityResponse } from '../models/save-activity-response';
import { DataSetField } from '../models/custom-report';
import { PreviousSystemDataImportRequest, PreviousSystemDataImportResponse } from '../models/previous-system-data';

declare var System: any;
declare var $: any;

export class RenderOptions {
  x: number;
  y: number;
  parent: any;
  priorShape: any;
  activity: Activities.Activity<ActivityModel>;
  priorActivity: Activities.Activity<ActivityModel>;
}

export class RenderResults {
  x: number;
  y: number;
  shape: any;
  activity: Activities.Activity<ActivityModel>;
}

export enum EditorShape {
  Rectangle,
  Diamond,
  Ellipse,
  Circle,
  Square,
  MultipleRectangle,
  Start,
  End,
  External,
  Switch,
  Parallelogram,
  Container
}

export class TemplateItem {
  label: string;
  templateCode: string;

  constructor(options?: Partial<TemplateItem>) {
    if (options) {
      Object.assign(this, options);
    }
  }
}

export interface IActivityRenderer {
  shape: any;
  graph: any;
  render(options: RenderOptions): RenderResults;
}

export class BaseCellModel {
  constructor(public activity: Activity<ActivityModel>) {}
  getToolTip(context: WorkflowContextService): string {
    return this.activity.designerTooltip;
    // return this.activity.model && this.activity.model.screenName
    //   ? this.activity.model.screenName
    //   : 'tooltip';
  }
  getLabel(): string {
    return this.activity.model && this.activity.model.screenName
      ? this.activity.model.screenName
      : this.activity.name;
  }
}
export class EmailActivityCellModel extends BaseCellModel {
  myActivity: EmailActivity;

  constructor(activity: EmailActivity) {
    super(activity);
    this.myActivity = activity;
  }
}
export class ScheduleEmailActivityCellModel extends EmailActivityCellModel {
  myActivity: ScheduleEmailActivity;

  constructor(activity: ScheduleEmailActivity) {
    super(activity);
    this.myActivity = activity;
  }
}
export class CancelScheduledEmailActivityCellModel extends BaseCellModel {
  constructor(activity: CancelScheduledEmailActivity) {
    super(activity);
  }
}

export class AppointmentActivityCellModel extends EmailActivityCellModel {
  myActivity: AppointmentActivity;

  constructor(activity: AppointmentActivity) {
    super(activity);
    this.myActivity = activity;
  }
}

export class FormActivityCellModel extends BaseCellModel {
  myActivity: FormActivity;

  constructor(activity: FormActivity) {
    super(activity);
    this.myActivity = activity;
  }
}

export class RenewalDecisionActivityCellModel extends BaseCellModel {
  myActivity: RenewalDecisionActivity;

  constructor(activity: RenewalDecisionActivity) {
    super(activity);
    this.myActivity = activity;
  }
}

export class ContractorRegistrationDecisionActivityCellModel extends BaseCellModel {
  myActivity: ContractorRegistrationDecisionActivity;

  constructor(activity: ContractorRegistrationDecisionActivity) {
    super(activity);
    this.myActivity = activity;
  }
}

export class ContractorRegistrationInfoActivityCellModel extends BaseCellModel {
  myActivity: ContractorRegistrationInfoFormActivity;

  constructor(activity: ContractorRegistrationInfoFormActivity) {
    super(activity);
    this.myActivity = activity;
  }
}

export class ContractorInfoActivityCellModel extends BaseCellModel {
  myActivity: ContractorInfoFormActivity;

  constructor(activity: ContractorInfoFormActivity) {
    super(activity);
    this.myActivity = activity;
  }
}

export class ContractorSelectionActivityCellModel extends BaseCellModel {
  myActivity: ContractorSelectionActivity;

  constructor(activity: ContractorSelectionActivity) {
    super(activity);
    this.myActivity = activity;
  }
}

export class InspectionRequestActivityCellModel extends BaseCellModel {
  myActivity: InspectionRequestActivity;

  constructor(activity: InspectionRequestActivity) {
    super(activity);
    this.myActivity = activity;
  }
}
export class InspectionScheduleActivityCellModel extends BaseCellModel {
  myActivity: InspectionScheduleActivity;

  constructor(activity: InspectionScheduleActivity) {
    super(activity);
    this.myActivity = activity;
  }
}
export class InspectionSimpleActivityCellModel extends BaseCellModel {
  myActivity: InspectionSimpleActivity;

  constructor(activity: InspectionSimpleActivity) {
    super(activity);
    this.myActivity = activity;
  }
}
export class CompleteInspectionActivityCellModel extends BaseCellModel {
  myActivity: CompleteInspectionActivity;

  constructor(activity: CompleteInspectionActivity) {
    super(activity);
    this.myActivity = activity;
  }
}

export class AgendaItemActivityCellModel extends BaseCellModel {
  myActivity: AgendaItemActivity;

  constructor(activity: AgendaItemActivity) {
    super(activity);
    this.myActivity = activity;
  }
}

export class QueryMapLayerActivityCellModel extends BaseCellModel {
  myActivity: Activities.QueryMapLayerActivity;

  constructor(activity: Activities.QueryMapLayerActivity) {
    super(activity);
    this.myActivity = activity;
  }
}

export enum PaymentRequestResult {
  Success = 0,
  Failed = 1
}

export class WorkflowPaymentRequest {
  method: string;
  url: string;
  data: { [key: string]: string };
  result: PaymentRequestResult;
  errorMessage: string;
  reloadActivity?: boolean;
  execute(form: HTMLFormElement): boolean {
    return false;
  }
}

export class WorkflowPaymentRequestGet extends WorkflowPaymentRequest {
  params: { [key: string]: string };

  constructor(
    url: string,
    params: { [key: string]: string },
    result: PaymentRequestResult,
    errorMessage: string,
    reloadActivity?: boolean
  ) {
    super();

    Object.assign(this, {
      url: url,
      params: params,
      errorMessage: errorMessage,
      result: result,
      reloadActivity: reloadActivity
    });
  }
  execute(form: HTMLFormElement): boolean {
    let fullUrl: string = this.url;

    if (this.params) {
      Object.keys(this.params).forEach((key: string) => {
        if (fullUrl.indexOf('?') > -1) {
          fullUrl += '&';
        } else {
          fullUrl += '?';
        }

        fullUrl += key + '=' + escape(this.params[key]);
      });
    }

    window.location.href = fullUrl;
    return true;
  }
}

export class WorkflowPaymentRequestPOST extends WorkflowPaymentRequest {
  postData: { [key: string]: string };

  constructor(
    url: string,
    postData: { [key: string]: string },
    result: PaymentRequestResult,
    errorMessage: string,
    reloadActivity?: boolean
  ) {
    super();

    Object.assign(this, {
      url: url,
      postData: postData,
      result: result,
      errorMessage: errorMessage,
      reloadActivity: reloadActivity
    });
  }

  execute(form: HTMLFormElement): boolean {
    form.method = 'POST';
    form.name = 'PaymentRequest';
    form.action = this.url;

    Object.keys(this.postData).forEach((f: string) => {
      const formInput = document.createElement('input');
      formInput.type = 'hidden';
      formInput.name = f;
      formInput.value = this.postData[f];
      form.appendChild(formInput);
    });

    form.submit();

    return true;
  }
}

export enum ExportFormat {
  Excel = 1,
  CSV = 2,
  ShapeFile = 3
}

@Injectable()
export class WorkflowService {
  copyEntityToOtherWorkflow(arg0: { targetClientId: string; sourceWorkflowVersionId: string; targetWorkflowVersionId: string; sourceActivityId: string; targetActivityId: string; templateCodes: string[]; }): Observable<void> {
    return this._dataSvc.copyEntityToOtherWorkflow(arg0);
  }
  getPaymentButtonParameters(accountId: any, amount: string): Observable<any> {
    return this._dataSvc.getPaymentButtonParameters(accountId, amount);
  }
  confirmForteCheckoutPayment(
    applicationId: string,
    activity: Activities.PaymentActivity,
    order_number: any,
    confirmationDataJSON: string
  ): Observable<{ nextActivity: Activity<ActivityModel> }> {
    return this._dataSvc
      .confirmForteCheckoutPayment(
        applicationId,
        activity,
        order_number,
        confirmationDataJSON
      )
      .pipe(
        map(results => {
          if (results.nextActivity) {
            results.nextActivity = ActivityFactory.createActivity(
              results.nextActivity
            );
          }
          return results;
        })
      );
  }
  calculateFormulas(
    activity: Activities.Activity<Activities.ActivityModel>,
    applicationId: string,
    templateCodes: string[]
  ): Observable<{ calculatedValues: { [key: string]: string } }> {
    return this._dataSvc.calculateFormulas(
      activity,
      applicationId,
      templateCodes
    );
  }
  getInspectionActivity(
    workflowApplicationId: string,
    inspectionId: string,
    actionType: string
  ): Observable<Activity<ActivityModel>> {
    return this._dataSvc
      .getInspectionActivity(workflowApplicationId, inspectionId, actionType)
      .pipe(
        map(a => {
          if (a) {
            return ActivityFactory.createActivity(a);
          } else {
            return null;
          }
        })
      );
  }
  copyDataSet(request: {
    dataSetId: string;
    targetClientId: string;
    targetName: string;
  }) {
    return this._dataSvc.copyDataSet(request);
  }
  exportDataSet(options: {
    dataSetId: string;
    exportFormat: ExportFormat;
    columnsToInclude: string[];
    filterInput: {
      inputValues: ItemSearchOptionFieldOption[];
      maxRows?: number;
    };
  }): Observable<any> {
    return this._dataSvc.exportDataSet(options);
  }
  undiscardApplication(applicationId: string): Observable<boolean> {
    return this._dataSvc.undiscardApplication(applicationId);
  }

  reopenApplication(applicationId: string): Observable<boolean> {
    return this._dataSvc.reopenApplication(applicationId);
  }

  recalculateApplicationDescription(applicationId: string): Observable<string> {
    return this._dataSvc.recalculateApplicationDescription(applicationId);
  }
  regeneratePrintTemplate(
    applicationId: string,
    id: string,
    applicationIteration: number
  ): Observable<boolean> {
    return this._dataSvc.regeneratePrintTemplate(
      applicationId,
      id,
      applicationIteration
    );
  }

  private _previousSystemFields: DataSetField[];
  previousSystemObs$: Observable<DataSetField[]>;

  async getPreviousSystemDataFields(clientId: string): Promise<DataSetField[]> {
    if (!this._previousSystemFields) {
      if (clientId) {
        if (!this.previousSystemObs$) {
          this.previousSystemObs$ = this._dataSvc.getPreviousSystemDataFields(
            clientId
          );
          this._previousSystemFields = await lastValueFrom(
            this.previousSystemObs$
          );
          this.previousSystemObs$ = null;
        } else {
          return await lastValueFrom(this.previousSystemObs$);
        }
      }
    }

    return this._previousSystemFields;
  }
  getActivityDataAuditDetails(
    applicationId: string,
    auditId: string
  ): Observable<WorkflowApplicationActivityAuditDetails> {
    return this._dataSvc.getActivityDataAuditDetails(applicationId, auditId);
  }
  getActivityDataAudit(
    applicationId: string,
    activityDataId: string
  ): Observable<WorkflowApplicationActivityDataAudit[]> {
    return this._dataSvc.getActivityDataAudit(applicationId, activityDataId);
  }
  /*
      !!!!!!!!!!  THE ACTIVITIES ARE COMMENTED OUT FOR NOW FOR THE 8/1/2017 MILESTONE  !!!!!!!!
  */

  public static ACTIVITIES = {
    Form: 'form-activity',
    Email: 'email-activity',
    ScheduleEmail: 'schedule-email-activity',
    Appointment: 'appointment-activity',
    Sequential: 'sequential-activity',
    OnStarted: 'on-started-activity',
    OnCompleted: 'on-completed-activity',
    CompleteWorkflow: 'complete-workflow-activity',
    Decision: 'decision-activity',
    Payment: 'payment-activity',
    PrintTemplate: 'print-template-activity',
    // Wait: 'wait-activity',
    Start: 'start-workflow-activity',
    RenewalDecision: 'renewal-decision-activity',
    ContractorRegistrationDecision: 'contractor-registration-decision-activity',
    ContractorInfo: 'contractor-info-form-activity',
    ContractorRegistrationInfo: 'contractor-registration-info-form-activity',
    ContractorSelection: 'contractor-selection-activity',
    InspectionRequest: 'inspection-request-activity',
    InspectionSchedule: 'inspection-schedule-activity',
    InspectionSimple: 'inspection-simple-activity',
    RenewalReview: 'renewal-review-activity',
    ReviewContractorRegistration: 'review-contractor-registration-activity',
    CompleteInspection: 'complete-inspection-activity',
    ContractorRenewalDecision: 'contractor-renewal-decision-activity',
    CallWorkflow: 'call-workflow-activity',
    InitiateWorkflow: 'initiate-workflow-activity',
    RequiredUser: 'required-user-activity',
    RenewalCertificateActivity: 'renewal-certificate-activity',
    ContractorRegistrationCertificateActivity:
      'contractor-registration-certificate-activity',
    // Looping: 'looping-activity',
    // CreateUser: 'create-user-activity',
    // SelectContractor: 'select-contractor-activity',
    // Parallel: 'parallel-activity',
    // Schedule: 'schedule-activity',
    // StartTimeout: 'start-timeout-activity',
    // CompleteTimeout: 'complete-timeout-activity',
    // ScreenTemplate: 'screen-template-activity',
    SearchParcelData: 'search-parcel-data-activity',
    GenerateApplicationNumber: 'generate-application-number-activity',
    Map: 'map-activity',
    ExportData: 'export-data-activity',
    // SearchExternalData: 'search-external-data-activity',
    // TrueOrFalseQuestion: 'true-or-false-question-activity',
    // MilestoneActivity: 'milestone-activity',
    // QueryMapLayerActivity: 'query-map-layer-activity',
    AgendaItemActivity: 'agenda-item-activity',
    CancelScheduledEmailActivity: 'cancel-scheduled-email-activity',
    MultipleParcelSearchActivity: 'multiple-parcel-search-activity',
    ReviewActivity: 'review-activity',
    QueryMapLayerActivity: 'query-map-layer-activity'
  };

  public static activities: {
    [key: string]: Activities.Activity<ActivityModel>;
  } = {
    'form-activity': new Activities.FormActivity(),
    'email-activity': new Activities.EmailActivity(),
    'on-started-activity': new Activities.OnStartedActivity(),
    'on-completed-activity': new Activities.OnCompletedActivity(),
    'sequential-activity': new Activities.SequentialActivity(),
    'looping-activity': new Activities.LoopingActivity(),
    'create-user-activity': new Activities.CreateUserActivity(),
    'complete-workflow-activity': new Activities.CompleteWorkflowActivity(),
    'payment-activity': new Activities.PaymentActivity(),
    'wait-activity': new Activities.WaitActivity(),
    'start-workflow-activity': new Activities.StartWorkflowActivity(),
    'renewal-review-activity': new RenewalReviewActivity(),
    'review-contractor-registration-activity': new ReviewContractorRegistrationActivity(),
    'review-activity': new ReviewActivity(),
    // "complete-inspection-activity": new CompleteInspectionActivity(),
    // 'parallel-activity': new Activities.ParallelActivity({ type: 'parallel-activity', name: 'Parallel Steps', description: 'Use this activity to definte a set of sequential activities that run at the same time.', shape: EditorShape.Container, isContainer: true }),
    // 'schedule-activity': new Activities.ScreenActivity({ type: 'schedule-activity', name: 'Make Appointment', description: 'Use this activity to schedule an appointment in the workflow.', shape: EditorShape.Rectangle, isContainer: false, inputs: [{ id: 'only-input', name: 'Previous' }], outputs: [{ id: 'only-output', name: 'Next' }] }),
    // 'start-timeout-activity': new Activities.StartTimeoutActivity({ type: 'start-timeout-activity', name: 'Start Timeout', description: 'Use this activity to start a timeout in the workflow.', shape: EditorShape.Rectangle, isContainer: false, inputs: [{ id: 'only-input', name: 'Previous' }], outputs: [{ id: 'only-output', name: 'Next' }] }),
    // 'complete-timeout-activity': new Activities.CompleteTimeoutActivity({ type: 'complete-timeout-activity', name: 'Complete Timeout', description: 'Use this activity to complete a timeout activity that was started.', shape: EditorShape.Rectangle, isContainer: false, inputs: [{ id: 'only-input', name: 'Previous' }], outputs: [{ id: 'only-output', name: 'Next' }] }),
    // 'screen-template-activity': new Activities.ScreenTemplateActivity({ type: 'screen-template-activity', name: 'Screen Template', description: 'Use this activity if you want to display static text on the screen based on a template defined in the activity.', shape: EditorShape.Rectangle, isContainer: false, inputs: [{ id: 'only-input', name: 'Previous' }], outputs: [{ id: 'only-output', name: 'Next' }] }),
    // 'print-template-activity': new Activities.PrintTemplateActivity({ type: 'print-template-activity', name: 'Print Template', description: 'Use this activity if you want the system to generate a PDF based on a template defined in the activity.', shape: EditorShape.Rectangle, isContainer: false, inputs: [{ id: 'only-input', name: 'Previous' }], outputs: [{ id: 'only-output', name: 'Next' }] }),
    'search-parcel-data-activity': new Activities.SearchParcelDataActivity({
      type: 'search-parcel-data-activity',
      name: 'Search Parcel Data',
      description:
        'Use this activity if you want to search Parcel Data for additional data based on the parcel number.',
      shape: EditorShape.Rectangle,
      isContainer: false,
      inputs: [{ id: 'only-input', name: 'Previous' }],
      outputs: [{ id: 'only-output', name: 'Next' }]
    }),
    'export-data-activity': new Activities.ExportDataActivity(),
    // 'search-external-data-activity': new Activities.SearchExternalDataActivity({ type: 'search-external-data-activity', name: 'Search External Data', description: 'Use this activity if you want to search an external data connection for additional data based on data you have retrieved from this system.', shape: EditorShape.Rectangle, isContainer: false, inputs: [{ id: 'only-input', name: 'Previous' }], outputs: [{ id: 'only-output', name: 'Next' }] }),
    // 'true-or-false-question-activity': new Activities.TrueOrFalseQuestionActivity({ type: 'true-or-false-question-activity', name: 'If/Then', description: 'Use this activity if you are wanting to make a decision to choose between two paths.', shape: EditorShape.Diamond, isContainer: true, inputs: [{ id: 'only-input', name: 'Previous' }], outputs: [{ id: 'yes-output', name: 'Yes' }, { id: 'no-output', name: 'No' }] }),
    // 'milestone-activity': new Activities.MilestoneActivity({ type: 'milestone-activity', name: 'Milestone', description: 'Use this activity to mark a milestone for the workflow completed.', shape: EditorShape.Rectangle, isContainer: false, inputs: [{ id: 'only-input', name: 'Previous' }], outputs: [{ id: 'only-output', name: 'Next' }] }),
    // 'query-map-layer-activity': new Activities.QueryMapLayerActivity({ type: 'query-map-layer-activity', name: 'Query Map Layer', description: 'Use this activity to query a layer in the map for information.', shape: EditorShape.Rectangle, isContainer: false, inputs: [{ id: 'only-input', name: 'Previous' }], outputs: [{ id: 'only-output', name: 'Next' }] })
    'agenda-item-activity': new Activities.AgendaItemActivity(),
    'multiple-parcel-search-activity': new Activities.MultipleParcelSearchActivity(),
    'query-map-layer-activity': new Activities.QueryMapLayerActivity()
  };

  public static screenActivities: string[] = [
    'form-activity',
    'complete-workflow-activity'
  ];

  static DATA_ENTITIES: {
    [key: string]: { code: string; name: string; hideInEditor?: boolean };
  } = {
    ParcelNotes: {
      code: 'parcel-notes',
      name: 'Parcel Notes',
      hideInEditor: true
    },
    FreeFormText: { code: 'free-form-text', name: 'Free Form Text' },
    Document: { code: 'document', name: 'Document' },
    DisplayEntityValue: {
      code: 'display-entity-value',
      name: 'Display Entity Value'
    },
    Fee: { code: 'fee', name: 'Fee' },
    NumericData: { code: 'numeric-data', name: 'Numeric Data' },
    Date: { code: 'date', name: 'Date' },
    // Time: 'time',
    // AppointmentDate: 'appointment-date',
    // AppointmentTime: 'appointment-time',
    // StaticHtml: 'static-html',
    // ListData: 'list-data',
    MapSketch: { code: 'mapsketch-data', name: 'Map Sketch Data' },
    ListData: { code: 'list-data', name: 'List Data' },
    // MapData: 'map-data',
    Signature: { code: 'signature', name: 'Signature' },
    // Today: 'today',
    YesNo: { code: 'yes-no', name: 'Yes No', hideInEditor: true },
    RichText: { code: 'rich-text', name: 'Formatted Text' },
    Today: { code: 'today', name: 'Today' },
    // YesNo: 'yes-no',
    CalculatedValue: { code: 'calculated-value', name: 'Calculated Value' },
    // UserAddress: 'user-address',
    // UserPhoneNumber: 'user-phone-number',
    GenerateClientApplicationNumber: {
      code: 'generate-client-application-number',
      name: 'Generate Client Application Number'
    },
    ContactList: { code: 'contact-list', name: 'Contact List' },
    ContractorDocument: {
      code: 'contractor-document',
      name: 'Contractor Document'
    },
    InspectionDocument: {
      code: 'inspection-document',
      name: 'Inspection Document'
    },
    XData: {
      code: 'xdata',
      name: 'X Data',
      hideInEditor: true
    },
    YData: {
      code: 'ydata',
      name: 'Y Data',
      hideInEditor: true
    },
    GeoJSONData: {
      code: 'geojsondata',
      name: 'GeoJSON Data',
      hideInEditor: true
    },
    SystemData: {
      code: 'system-data-entity',
      name: 'System Data Entity',
      hideInEditor: true
    },
    QR: {
      code: 'qr',
      name: 'Application QR',
      hideInEditor: true
    }
  };

  @Output() workflowChangesSaving: EventEmitter<any> = new EventEmitter();
  @Output() workflowChangesSaved: EventEmitter<any> = new EventEmitter();
  @Output() centerOnActivity$: EventEmitter<string> = new EventEmitter();

  dataEntitiesBeforeMeCache: {
    [key: string]: DataEntity[];
  } = {};
  dataEntityDetailsBeforeMeCache: {
    [key: string]: DataEntityDetail[];
  } = {};
  public routingChanges: EventEmitter<string> = new EventEmitter();
  dataEntitiesBeforeMe: { [key: string]: { [key: string]: DataEntity } };
  templateCodeAvailCache: { [key: string]: Observable<any> } = {};
  deBeforeMeObs: { [aid: string]: Observable<DataEntity[]> } = {};
  public designerVersionMode = false;
  cachedWFEntities: any[];

  static getEmailDataEntityTypes(): string[] {
    return [
      WorkflowService.DATA_ENTITIES.ContactList.code,
      WorkflowService.DATA_ENTITIES.GenerateClientApplicationNumber.code,
      WorkflowService.DATA_ENTITIES.CalculatedValue.code,
      WorkflowService.DATA_ENTITIES.Today.code,
      WorkflowService.DATA_ENTITIES.ListData.code,
      WorkflowService.DATA_ENTITIES.Date.code,
      WorkflowService.DATA_ENTITIES.NumericData.code,
      WorkflowService.DATA_ENTITIES.Fee.code,
      WorkflowService.DATA_ENTITIES.FreeFormText.code,
      WorkflowService.DATA_ENTITIES.SystemData.code,
      WorkflowService.DATA_ENTITIES.ParcelNotes.code
    ];
  }

  static isScreenActivity(activity): boolean {
    return (
      activity instanceof Activities.ScreenActivity ||
      this.screenActivities.indexOf(activity.type) > -1
    );
  }

  static isContainerActivity(activity): boolean {
    if (activity) {
      return (
        activity instanceof Activities.SequentialActivity ||
        activity instanceof Activities.ParallelActivity ||
        activity instanceof Activities.LoopingActivity
        // || activity.type === WorkflowService.ACTIVITIES.Sequential
        // || activity.type === WorkflowService.ACTIVITIES.Looping
      );
    }
    return false;
  }

  getExportWorkflows(): Observable<Workflow[]> {
    return this._dataSvc.getExportWorkflows();
  }
  loadDestinationEditor(
    transportMethod: string
  ): Observable<DestinationConfiguration> {
    return this._dataSvc.loadDestinationEditor(transportMethod);
  }

  searchWorkflow(
    workflowId: string,
    text: string
  ): Observable<
    {
      type: string;
      id: string;
      activityId: string;
      activityName: string;
      templateCode: string;
      dataEntityLabel: string;
    }[]
  > {
    return this._dataSvc.searchWorkflow(workflowId, text);
  }

  getApplicationDiagramInfo(
    applicationId: string,
    iteration?: number
  ): Observable<ApplicationDiagramInfo> {
    return this._dataSvc.getApplicationDiagramInfo(applicationId, iteration);
  }

  getApplicationActivityInfo(
    appId: string,
    activityId: string
  ): Observable<Activity<ActivityModel>[]> {
    return this._dataSvc.getApplicationActivityInfo(appId, activityId).pipe(
      map(a => {
        ActivityUtilities.convertActivitiesJsonToClasses(a);
        return a;
      })
    );
  }

  confirmWorkflowPaymentRequest(
    uniqueId: string,
    data: any
  ): Observable<PaymentConfirmation> {
    return this._dataSvc.confirmWorkflowPaymentRequest(uniqueId, data);
  }

  clearDataEntityBeforeMeCache(activityId: string) {
    delete this.dataEntitiesBeforeMeCache[activityId];
    delete this.dataEntityDetailsBeforeMeCache[activityId];
  }
  clearDataEntityBeforeMeCacheOtherActivities(activityId: string) {
    const keys = Object.keys(this.dataEntitiesBeforeMeCache);

    keys.forEach((key, index) => {
      if (key !== activityId) {
        delete this.dataEntitiesBeforeMeCache[key];
      }
    });
  }

  createDataEntity(
    dataEntityCode: string,
    activity: Activity<ActivityModel>
  ): Observable<DataEntity> {
    return this._dataSvc
      .createDataEntity(dataEntityCode, activity)
      .pipe(
        map((m: DataEntity) =>
          DataEntityFactory.createDataEntity(m.dataEntityTypeCode, m)
        )
      );
  }

  getDataEntityDetailsBeforeMe(
    arg0: Workflow,
    activity: Activities.Activity<Activities.ActivityModel>,
    types: string[],
    designStatus: ApplicationStatus
  ): Observable<DataEntityDetail[]> {
    // if I don't have details cached but have full de cached, cache details from full de
    if (
      !this.dataEntityDetailsBeforeMeCache[activity.id] &&
      this.dataEntitiesBeforeMeCache[activity.id]
    ) {
      this.cacheEntityDetails(
        activity,
        this.dataEntitiesBeforeMeCache[activity.id]
      );
    }

    // if I don't have either cached, then fetch details and cache details.
    if (!this.dataEntityDetailsBeforeMeCache[activity.id]) {
      return this._dataSvc
        .getDataEntityDetailsBeforeMe(arg0, activity, types, designStatus, [
          WorkflowService.DATA_ENTITIES.RichText.code
        ])
        .pipe(
          map(entities => {
            if (entities) {
              if (!types) {
                this.dataEntityDetailsBeforeMeCache[activity.id] = entities;
              }
              return entities;
            }
          })
        );
    } else {
      const cachedItems = this.dataEntityDetailsBeforeMeCache[activity.id];
      if (cachedItems) {
        if (!types) {
          return of(cachedItems);
        } else {
          return of(
            cachedItems.filter(f => types.indexOf(f.dataEntityTypeCode) > -1)
          );
        }
      } else {
        return null;
      }
    }
  }

  private _saving: boolean = false;

  get saving() {
    return this._saving;
  }

  saveWorkflowChanges(
    workflowId: string,
    changes: any[],
    designStatus: ApplicationStatus
  ): Observable<boolean> {
    this.workflowChangesSaving.emit();
    this._saving = true;
    return this._dataSvc
      .saveWorkflowChanges(workflowId, changes, designStatus)
      .pipe(
        map(result => {
          this.workflowChangesSaved.emit();
          this._saving = false;
          return result;
        })
      );
  }

  saveActivityDefinition(
    workflow: Workflow,
    activity: Activities.Activity<Activities.ActivityModel>
  ): Observable<void> {
    return this._dataSvc.saveActivityDefinition(workflow.id, activity);
  }
  getCompletedActivities(
    applicationId: string
  ): Observable<Activity<ActivityModel>[]> {
    return this._dataSvc.getCompletdActivities(applicationId).pipe(
      map(activities => {
        ActivityUtilities.convertActivitiesJsonToClasses(activities);
        return activities;
      })
    );
  }

  doesApplicationHaveFees(applicationId: string): Observable<boolean> {
    return this._dataSvc.doesApplicationHaveFees(applicationId);
  }
  navigatePrevious(
    applicationId: string,
    activity: Activity<ActivityModel>
  ): any {
    return this._dataSvc.navigatePrevious(applicationId, activity).pipe(
      map(resp => {
        if (resp.activity) {
          const activities = ActivityUtilities.convertActivitiesJsonToClasses([
            resp.activity
          ]);
          resp.activity = activities[0];
          return resp;
        }

        return resp;
      })
    );
  }

  navigateToActivity(
    applicationId: string,
    activityDataId: string,
    isSummaryView: boolean = false
  ): Observable<NavigateToActivityResponse> {
    return this._dataSvc.navigateToActivity(applicationId, activityDataId, isSummaryView).pipe(
      map(resp => {
        if (resp.activity) {
          const activities = ActivityUtilities.convertActivitiesJsonToClasses([
            resp.activity
          ]);
          resp.activity = activities[0];
        }

        return resp;
      })
    );
  }
  prepareWorkflowPayment(
    applicationId: string,
    activity: PaymentActivity,
    paymentRequestId?: string
  ): Observable<WorkflowPaymentRequest> {
    return this._dataSvc.createWorkflowPaymentRequest(
      applicationId,
      activity,
      paymentRequestId
    );
  }
  validateEmailFields(
    criteria: string
  ): Observable<{ valid: boolean; data: { failedMsg: string } }> {
    return of({
      valid: true,
      data: {
        failedMsg: 'failed validation message'
      }
    });
  }
  validateRoutingCriteria(
    critera: string
  ): Observable<{ valid: boolean; data: { failedMsg: string } }> {
    return of({
      valid: true,
      data: {
        failedMsg: 'failed validation message'
      }
    });
  }
  validateWaitCriteria(
    forumla: string
  ): Observable<{ valid: boolean; data: { failedMsg: string } }> {
    return of({
      valid: true,
      data: null
    });
  }
  validateApplicationTemplateCriteria(
    formula: string
  ): Observable<{ valid: boolean; data: { failedMsg: string } }> {
    return of({
      valid: true,
      data: null
    });
  }
  validateFeeFormula(
    formula: string
  ): Observable<{ valid: boolean; data: { failedMsg: string } }> {
    return of({
      valid: true,
      data: {
        failedMsg: 'failed formula'
      }
    });
  }

  constructor(
    @Inject(forwardRef(() => DataService)) private _dataSvc: DataService,
    @Inject(forwardRef(() => TemplateService))
    private _templateSvc: TemplateService
  ) {}

  isSequentialActivity(activity): boolean {
    return false;
  }
  isLoopingActivity(activity): boolean {
    return false;
  }

  getCommunityWorkflows(): Observable<CommunityWorkflow[]> {
    return this._dataSvc.getCommunityWorkflows();
  }
  getCommunityWorkflow(id: string): Observable<CommunityWorkflow> {
    return this._dataSvc.getCommunityWorkflow(id);
  }
  publishWorkflowToCommunity(communityWorkflow: CommunityWorkflow) {}
  cloneCommunityWorkflow(communityWorkflow: CommunityWorkflow) {}
  searchCommunityWorkflows(
    searchText: string
  ): Observable<CommunityWorkflow[]> {
    return this._dataSvc.searchCommunityWorkflows(searchText);
  }
  getActivty(type: string): Activities.Activity<ActivityModel> {
    if (WorkflowService.activities[type]) {
      return WorkflowService.activities[type];
    }
  }
  cancelApplications(applicationId: string) {
    return this._dataSvc.cancelApplications(applicationId);
  }
  voidApplications(applicationId: string, voidRequest: VoidRequest) {
    return this._dataSvc.voidApplications(applicationId, voidRequest);
  }
  unVoidApplication(applicationId: string): Observable<boolean> {
    return this._dataSvc.unVoidApplication(applicationId);
  }
  cleanupTestApplication(applicationId: string): Observable<boolean> {
    return this._dataSvc.deleteTestWorkflowApplication(applicationId);
  }
  getPublishedWorkflows(
    client: Client,
    types?: WorkflowType[]
  ): Observable<Workflow[]> {
    return this._dataSvc.getPublishedWorkflows(client, types);
  }

  getWorkflows(
    client: Client,
    showArchived: boolean,
    loadGlobal?: boolean,
    workflowTypes?: WorkflowType[]
  ) {
    return this._dataSvc.getWorkflows(
      client,
      showArchived,
      workflowTypes,
      loadGlobal
    );
  }

  getRenewableWorkflows(
    client: Client,
    showArchived: boolean,
    loadGlobal?: boolean
  ) {
    return this._dataSvc.getWorkflows(client, showArchived, [4], false);
  }

  getCurrentWorkflows(client: Client) {
    return this._dataSvc.getCurrentWorkflows(client);
  }
  getParentChildWorkflows(workflowId: string) {
    return this._dataSvc.getParentChildWorkflows(workflowId);
  }
  getWorkflowVersionDataEntities(
    versionId: string,
    templateCodes?: string[],
    filterUnprefixed = false
  ) {
    return this._dataSvc.getWorkflowVersionDataEntities(
      versionId,
      null,
      templateCodes,
      null,
      filterUnprefixed
    );
  }
  getUniqueInspectorIds(
    clientId?: string
  ): Observable<{ [key: string]: string }> {
    return this._dataSvc.getUniqueInspectorIds(clientId);
  }
  getWorkflowVersion(workflowVersionId: string, includeGraph?: boolean) {
    return this._dataSvc
      .getWorkflowVersion(workflowVersionId, includeGraph)
      .pipe(
        map(workflow => {
          const wfClasses = ActivityUtilities.convertWorkflowJsonToClasses(
            workflow
          );
          return wfClasses;
        })
      );
  }

  searchInspections(
    clientId: string,
    sortField: string,
    sortDescending: boolean,
    filterObjects: any,
    pageNumber: number,
    pageSize: number,
    ext: MapExtent
  ): Observable<PagedInspectionsVM> {
    return this._dataSvc
      .searchInspections(
        clientId,
        sortField,
        sortDescending,
        filterObjects,
        pageNumber,
        pageSize,
        ext
      )
      .pipe(
        map(regs => {
          return regs;
        })
      );
  }

  getApplicationActivities(
    applicationId: string
  ): Observable<Activities.Activity<ActivityModel>[]> {
    return this._dataSvc.getFullApplication(applicationId).pipe(
      map(app => {
        ActivityUtilities.convertActivitiesJsonToClasses(
          app.workflowVersion.graph.onStarted.activities
        );
        ActivityUtilities.convertActivitiesJsonToClasses(
          app.workflowVersion.graph.onCompleted.activities
        );
        return app.workflowVersion.graph.getActivities(app.status);
      })
    );
  }

  getApplicationActivity(
    applicationActivityId: string
  ): Observable<Activities.Activity<ActivityModel>> {
    return null;
  }

  getCollectableFields(workflow: Workflow): Observable<CollectedValue[]> {
    // gather collected data entities
    const dataEntitySource: Observable<CollectedValue[]> = this.getDataEntities(
      workflow
    ).pipe(
      map(values => {
        return values.map(value => {
          return {
            label: value.label,
            name: value.templateCode,
            value: '${' + value.templateCode + '}',
            source: value.templateCode
          };
        });
      })
    );

    const gpSource = new Observable<CollectedValue[]>(o => {
      const values: CollectedValue[] = [];

      this.getWorkflowActivities(workflow, [
        WorkflowService.ACTIVITIES.SearchParcelData
      ]).subscribe(activites => {
        activites.forEach(
          (value: Activities.SearchParcelDataActivity, index: number) => {
            if (value.resultFields) {
              value.resultFields.forEach(field => {
                values.push({
                  label: field.alias,
                  value: '${' + value.id + '|' + field.field + '}',
                  name: field.field,
                  source: value.displayName()
                });
              });
            }
          }
        );

        o.next(values);
      });
    });

    return combineLatest([dataEntitySource, gpSource]).pipe(
      switchMap(([de, gp]) => {
        let result = [];
        if (de) {
          if (gp) {
            result = de.concat(gp);
          }
          result = [];
        }
        return of(result);
      })
    );
  }

  getCollectedValues(workflow: Workflow): Observable<CollectedValue[]> {
    // gather collected data entities
    const dataEntitySource: Observable<CollectedValue[]> = this.getDataEntities(
      workflow
    ).pipe(
      map(values => {
        return values.map(value => {
          return {
            label: value.label,
            name: value.templateCode,
            value: '$(' + value.templateCode + ')',
            source: null
          };
        });
      })
    );

    // gather data collected from parcel data
    const gpSource: Observable<CollectedValue[]> = new Observable(o => {
      const values: CollectedValue[] = [];

      this.getWorkflowActivities(workflow, [
        WorkflowService.ACTIVITIES.SearchParcelData
      ]).subscribe(gpActivities => {
        // TODO: [WorkflowService.ACTIVITIES.SearchParcelData]);
        gpActivities.forEach((value: Activities.SearchParcelDataActivity) => {
          const searchPD = value;

          if (searchPD.selectedData) {
            for (const field in searchPD.selectedData) {
              if (field) {
                values.push({
                  label: field,
                  value: searchPD.selectedData[field],
                  name: field,
                  source: value.name
                });
              }
            }
          }
        });
      });

      o.next(values);
    });

    // gather data collected from external connection

    return combineLatest([dataEntitySource, gpSource]).pipe(
      switchMap(([de, gp]) => {
        let result = [];
        if (de) {
          if (gp) {
            result = de.concat(gp);
          }
          result = [];
        }
        return of(result);
      })
    );
  }

  startApplication(
    workflowId: string,
    isTestApplication: boolean
  ): Observable<StartApplicationResponse> {
    return this._dataSvc.startApplication(workflowId, isTestApplication);
  }
  getWorkflowRoles(workflowId: string): Observable<Role[]> {
    return this._dataSvc.getWorkflowRoles(workflowId);
  }
  isWorkflowApplicationInProgress(applicationId: string): Observable<boolean> {
    return this._dataSvc.isWorkflowApplicationInProgress(applicationId);
  }
  getCurrentActivity(
    applicationId: string,
    expectedActivity?: string
  ): Observable<{
    activity: Activities.Activity<ActivityModel>;
    workflowId: string;
    workflowVersionId: string;
    applicationNumber: string;
    clientApplicationNumber: string;
    client: Client;
    isTestApplication: boolean;
    navigationItems: ActivityNavigationInfo[];
    fees: FeeDataEntity[];
    hasFees: boolean;
    submitterId: string;
    workflowName: string;
    canUserVoidApplication: boolean;
    isProcessing: boolean;
  }> {
    // Observable<Activities.Activity<ActivityModel>> {
    return this._dataSvc
      .getCurrentApplicationActivity(applicationId, expectedActivity)
      .pipe(
        map(a => {
          if (a) {
            return {
              activity: ActivityFactory.createActivity(a.activity),
              workflowId: a.workflowId,
              workflowVersionId: a.workflowVersionId,
              applicationNumber: a.applicationNumber,
              clientApplicationNumber: a.clientApplicationNumber,
              client: a.client,
              isTestApplication: a.isTestApplication,
              navigationItems: a.navigationActivities,
              fees: a.fees,
              hasFees: a.hasFees,
              submitterId: a.submitterId,
              workflowName: a.workflowName,
              canUserVoidApplication: a.canUserVoidApplication,
              isProcessing: a.isProcessing
            };
          } else {
            return null;
          }
        })
      )
      .pipe(
        map(a => {
          return a;
        })
      );
  }
  getWorkflowCheckpoints(workflowId: string): Observable<WorkflowCheckpoint[]> {
    return this._dataSvc.getWorkflowCheckpoints(workflowId);
  }
  saveWorkflowActivity(
    applicationId: string,
    activity: Activities.Activity<ActivityModel>,
    editingCompletedApplication = false
  ): Observable<SaveActivityResponse> {
    return this._dataSvc.saveActivity(
      applicationId,
      activity,
      editingCompletedApplication
    );
  }

  importPreviousSystemData(
    request: PreviousSystemDataImportRequest
  ): Observable<PreviousSystemDataImportResponse> {
    return this._dataSvc.importPreviousSystemData(request);
  }

  saveWorkflowApplicationNotes(
    applicationId: string,
    internalNotes: string,
    externalNotes: string
  ): Observable<boolean> {
    return this._dataSvc.saveWorkflowApplicationNotes(
      applicationId,
      internalNotes,
      externalNotes
    );
  }
  saveActivityDataEntity(
    workflow: Workflow,
    activity: Activities.Activity<ActivityModel>,
    dataEntity: DataEntity
  ) {
    // if (!dataEntity.id) {
    //   dataEntity.id = Utilities.generateId();
    // }
    return this._dataSvc.saveWorkflow(workflow);
  }

  addActivityToWorkflow(
    workflowId: string,
    previousActivityId: string,
    activity: Activity<ActivityModel>
  ): Observable<WorkflowGraph> {
    return this._dataSvc
      .addActivityToWorkflow(workflowId, previousActivityId, activity)
      .pipe(
        map((a: WorkflowGraph) => {
          return ActivityUtilities.convertWorkflowGraphJsonToClasses(a);
        })
      );
  }

  removeActivityFromWorkflow(
    workflowId: string,
    activityId: string
  ): Observable<WorkflowGraph> {
    return this._dataSvc
      .removeActivityFromWorkflow(workflowId, activityId)
      .pipe(
        map((graph: WorkflowGraph) => {
          return ActivityUtilities.convertWorkflowGraphJsonToClasses(graph);
        })
      );
  }

  saveWorkflow(wf: Workflow) {
    // clone the object so that we are not modifying it directly
    const workflow = { ...wf };
    // null this out to get rid of circular dependency
    workflow.version.workflow = null;
    return this._dataSvc.saveWorkflow(workflow).pipe(
      map(wfres => {
        return ActivityUtilities.convertWorkflowJsonToClasses(wfres);
      })
    );
  }

  restoreWorkflow(workflowId: string): Observable<boolean> {
    return this._dataSvc.restoreWorkflow(workflowId);
  }

  restoreWorkflowCheckpoint(checkpointId: string): Observable<boolean> {
    return this._dataSvc.restoreWorkflowCheckpoint(checkpointId);
  }
  cleanupWorkflowHistory(workflowId: string): Observable<boolean> {
    return this._dataSvc.cleanupWorkflowHistory(workflowId);
  }

  disableWorkflow(workflowId: string): Observable<boolean> {
    return this._dataSvc.disableWorkflow(workflowId);
  }

  enableWorkflow(workflowId: string): Observable<boolean> {
    return this._dataSvc.enableWorkflow(workflowId);
  }

  isTemplateCodeAvailable(
    workflowId: string,
    templateCode: string,
    label: string
  ): Observable<any> {
    if (!this.templateCodeAvailCache[templateCode]) {
      this.templateCodeAvailCache[
        templateCode
      ] = this._dataSvc
        .isTemplateCodeAvailable(workflowId, templateCode, label)
        .pipe(
          map(r => {
            delete this.templateCodeAvailCache[templateCode];
            return r;
          })
        );
    }

    return this.templateCodeAvailCache[templateCode];
  }

  publishWorkflow(workflowId: string): Observable<string> {
    return this._dataSvc.publishWorkflow(workflowId);
  }

  archiveWorkflow(
    workflowId: string,
    archiveNotes: string
  ): Observable<boolean> {
    return this._dataSvc.archiveWorkflow(workflowId, archiveNotes);
  }

  submitWorkflowActivity(
    applicationId: string,
    activity: Activities.Activity<ActivityModel>,
    isNavigating: boolean
  ): Observable<{
    applicationId: string;
    applicationNumber: string;
    clientApplicationNumber: string;
    nextActivity: Activity<ActivityModel>;
    navigationActivities: ActivityNavigationInfo[];
    fees: FeeDataEntity[];
    hasFees: boolean;
    refreshUser: boolean;
  }> {
    const activityObj = ActivityFactory.createActivity(activity);

    if (activityObj) {
      activityObj.dereference();
      return this._dataSvc
        .processActivity(applicationId, activityObj, isNavigating)
        .pipe(
          map(result => {
            if (result) {
              let nextActivity;
              if (result.nextActivity) {
                const activities = ActivityUtilities.convertActivitiesJsonToClasses(
                  [result.nextActivity]
                );

                nextActivity = activities[0];
              }

              return {
                nextActivity: nextActivity,
                navigationActivities: result.navigationActivities,
                fees: result.fees,
                hasFees: result.hasFees,
                applicationNumber: result.applicationNumber,
                applicationId: result.applicationId,
                refreshUser: result.refreshUser,
                clientApplicationNumber: result.clientApplicationNumber
              };
            } else {
              return null;
            }
          })
        );
    } else {
      return empty();
    }
  }

  getMappableWorkflowActivities(workflowId: string) {
    return this.getWorkflowActivitiesById(workflowId, [
      WorkflowService.ACTIVITIES.Form
    ]);
  }

  getAllWorkflowActivities(
    workflow: Workflow,
    activityTypes?: string[]
  ): Observable<Activity<ActivityModel>[]> {
    return Observable.create((o: Subscriber<Activity<ActivityModel>[]>) => {
      let flatActivities = ActivityUtilities.flattenActivities(
        workflow.version.graph.getActivities()
      );

      if (activityTypes) {
        flatActivities = flatActivities.filter(
          (value: Activities.Activity<ActivityModel>, index: number) => {
            if (activityTypes.indexOf(value.type) > -1) {
              return true;
            }
          }
        );
      }

      o.next(flatActivities);
      o.complete();
    });
  }

  getWorkflowActivities(
    workflow: Workflow,
    activityTypes?: string[]
  ): Observable<Activity<ActivityModel>[]> {
    return Observable.create((o: Subscriber<Activity<ActivityModel>[]>) => {
      let flatActivities = ActivityUtilities.flattenActivities(
        workflow.version.graph.getActivities(workflow.designStatus)
      );

      if (activityTypes) {
        flatActivities = flatActivities.filter(
          (value: Activities.Activity<ActivityModel>, index: number) => {
            if (activityTypes.indexOf(value.type) > -1) {
              return true;
            }
          }
        );
      }

      o.next(flatActivities);
      o.complete();
    });
  }
  getWorkflowActivitiesById(
    workflowId: string,
    activityTypes?: string[]
  ): Observable<Activity<ActivityModel>[]> {
    return Observable.create(o => {
      this._dataSvc
        .getWorkflowActivities(workflowId)
        .pipe(
          concatMap(a => {
            return of(a);
          })
        )
        .subscribe(activities => {
          let flatActivities = ActivityUtilities.flattenActivities(activities);

          if (activityTypes) {
            flatActivities = flatActivities.filter(
              (value: Activities.Activity<ActivityModel>, index: number) => {
                if (activityTypes.indexOf(value.type) > -1) {
                  return true;
                }
              }
            );
          }

          const ao = ActivityUtilities.convertActivitiesJsonToClasses(
            flatActivities
          );
          flatActivities = Object.keys(ao).map(
            (value: string, index: number) => ao[value]
          );

          o.next(flatActivities);
        });
    });
  }
  getWorkflowActivityPreviewById(
    workflowId: string,
    activityId: string
  ): Observable<Activities.Activity<ActivityModel>> {
    return this._dataSvc
      .getWorkflowActivityForPreview(workflowId, activityId)
      .pipe(
        map(activity => {
          const activities = ActivityUtilities.convertActivitiesJsonToClasses([
            activity
          ]);
          return activities[0];
        })
      );
  }

  getWorkflowActivityPreview(
    workflow: Workflow,
    activityId: string
  ): Observable<Activities.Activity<ActivityModel>> {
    return this._dataSvc
      .getWorkflowActivityForPreview(workflow.id, activityId)
      .pipe(
        map(activity => {
          const activities = ActivityUtilities.convertActivitiesJsonToClasses([
            activity
          ]);
          return activities[0];
        })
      );
  }

  /**
   * Update a Workflow activity with a new version.
   *
   * @param workflow The workflow object to update the activity on
   * @param newActivity The new activity object to update the old one with
   */
  updateWorkflowActivity(
    workflow: Workflow,
    newActivity: Activities.Activity<Activities.ActivityModel>
  ) {
    let workflowActivity;

    if (workflow.version.graph.onStarted.id === newActivity.id) {
      workflowActivity = workflow.version.graph.onStarted;
    } else {
      workflowActivity = workflow.version.graph.onStarted.activities.find(
        a => a.id === newActivity.id
      );
    }

    if (workflowActivity) {
      Object.assign(workflowActivity, newActivity);
    }
  }

  /**
   * Get a Workflow activity and convert the JSON to classes.
   *
   * @param workflow The workflow object to get the activity from
   * @param activityId The ID of the activity to get
   */
  getWorkflowActivity(workflow: Workflow, activityId: string) {
    // const crawlActivities = function(
    //   activities: { [key: number]: Activities.Activity<ActivityModel> },
    //   activityId: string
    // ): Activities.Activity<ActivityModel> {
    //   let foundActivity: Activities.Activity<ActivityModel>;

    //   for (const key in activities) {
    //     if (activities[key]) {
    //       const activity = activities[key];
    //       if (activity.id === activityId) {
    //         foundActivity = activity;
    //         break;
    //       }

    //       const containerActivity: Activities.ContainerActivity<
    //         ActivityModel
    //       > = activity as Activities.ContainerActivity<ActivityModel>;

    //       if (containerActivity && containerActivity.activities) {
    //         foundActivity = crawlActivities(
    //           containerActivity.activities,
    //           activityId
    //         );
    //         if (foundActivity) {
    //           break;
    //         }
    //       }
    //     }
    //   }

    //   return JsonNetDecycle.retrocycle(foundActivity);
    // };
    const graph = new WorkflowGraph(workflow.version.graph);

    const activities = graph.getActivities(workflow.designStatus);

    if (activities) {
      return activities.find(a => a.id === activityId);
    }

    return null;
  }

  _crawActivitiesForEntity(
    activities: { [key: number]: Activities.Activity<ActivityModel> },
    dataEntityId?: string,
    templateCode?: string
  ): DataEntity {
    let foundDE: DataEntity;

    for (const key in activities) {
      if (activities[key]) {
        const activity: Activities.Activity<ActivityModel> = activities[key];

        const entities =
          (activity && activity.model && activity.model.getEntities()) || [];
        if (
          entities.some((entity: DataEntity, i: number) => {
            if (
              (dataEntityId &&
                entity.templateCode &&
                entity.templateCode.toLowerCase() ===
                  dataEntityId.toLowerCase()) ||
              (templateCode &&
                entity.templateCode.toLowerCase() ===
                  templateCode.toLowerCase())
            ) {
              foundDE = entity;
              return true;
            }
          })
        ) {
          break;
        }
      }
    }

    return foundDE;
  }

  getWorkflowDataEntityByCode(
    workflow: Workflow,
    templateCode: string
  ): Observable<DataEntity> {
    let de: DataEntity;

    if (this.dataEntitiesBeforeMe) {
      const activityKeys = Object.keys(this.dataEntitiesBeforeMe);

      activityKeys.forEach((key, i) => {
        de = this.dataEntitiesBeforeMe[key][templateCode];
        if (de) {
          return false;
        }
      });
    }

    if (!de) {
      de = this._crawActivitiesForEntity(
        workflow.version.graph.onStarted.activities,
        null,
        templateCode
      );
    }

    if (!de) {
      return this._dataSvc.getDataEntityByCode(workflow, templateCode).pipe(
        map(entity => {
          if (entity) {
            return DataEntityFactory.createDataEntity(
              entity.dataEntityTypeCode,
              entity
            );
          }
          return null;
        })
      );
    } else {
      return of(de);
    }
  }
  getWorkflowDataEntity(
    workflow: Workflow,
    dataEntityId: string
  ): Observable<DataEntity> {
    return Utilities.makeObservable(() => {
      return this._crawActivitiesForEntity(
        workflow.version.graph.getActivities(),
        dataEntityId
      );
    });
  }
  getActivityTypes(
    client: Client
  ): Observable<Activities.Activity<ActivityModel>[]> {
    return Utilities.makeObservable(() => {
      return Object.keys(WorkflowService.activities).map(key => {
        return WorkflowService.activities[key];
      });
    });
  }
  getDataEntityTypes(
    client: Client
  ): Observable<{ code: string; name: string; hideInEditor?: boolean }[]> {
    return Utilities.makeObservable(() => {
      return Object.keys(WorkflowService.DATA_ENTITIES).map(key => {
        return WorkflowService.DATA_ENTITIES[key];
      });
    });
  }
  getDataEntityType(type: string) {
    if (WorkflowService.DATA_ENTITIES[type]) {
      const de = DataEntityFactory.createDataEntity(type);
      return de;
    }
  }

  getWorkflow(
    workflowId: string,
    loadGraph = true,
    loadFullGraph = true
  ): Observable<Workflow> {
    return this._dataSvc.getWorkflow(workflowId, loadGraph, loadFullGraph).pipe(
      map(workflow => {
        const wfClasses = ActivityUtilities.convertWorkflowJsonToClasses(
          workflow
        );
        return wfClasses;
      })
    );
  }
  makeWorkflowVisibleToPublic(workflowId: string): Observable<boolean> {
    return this._dataSvc.makeWorkflowVisibleToPublic(workflowId);
  }
  hideWorkflowFromPublic(workflowId: string): Observable<boolean> {
    return this._dataSvc.hideWorkflowFromPublic(workflowId);
  }
  getWorkflowApplicationFees(
    applicationId: string,
    paymentActivityDataId: string,
    ignoreStatus: boolean = false
  ): Observable<FeeDataEntity[]> {
    return this._dataSvc.getWorkflowApplicationFees(
      applicationId,
      paymentActivityDataId,
      ignoreStatus
    );
  }
  getWorkflowFees(
    workflow: Workflow,
    paymentActivity: Activities.Activity<ActivityModel>
  ): Observable<FeeDataEntity[]> {
    return this._dataSvc
      .getWorkflowFees(workflow.id, paymentActivity.id)
      .pipe(map((de: DataEntity[]) => <FeeDataEntity[]>de));
  }
  createActivity(type: string): Activities.Activity<ActivityModel> {
    return null;
  }
  getDataEntityValue(
    workflow: Workflow,
    templateCode: string
  ): Observable<string> {
    return null;
  }
  cacheEntityDetails(
    activity: Activity<ActivityModel>,
    entities: DataEntity[]
  ) {
    if (activity && entities) {
      this.dataEntityDetailsBeforeMeCache[activity.id] = entities.map(e => {
        return new DataEntityDetail(e);
      });
    }
  }
  includeDataEntityTypes(activityId: string, types?: string[]) {
    const cachedList = this.dataEntitiesBeforeMeCache[activityId];
    if (types) {
      const filteredList = cachedList.filter(f =>
        types.includes(f.dataEntityTypeCode)
      );
      return filteredList;
    }
    return cachedList;
  }
  excludeDataEntityTypes(activityId: string, types?: string[]) {
    const cachedList = this.dataEntitiesBeforeMeCache[activityId];
    if (types) {
      const filteredList = cachedList.filter(
        f => !types.includes(f.dataEntityTypeCode)
      );
      return filteredList;
    } else {
      return cachedList;
    }
  }

  getDataEntitiesBeforeMe(
    workflow: Workflow,
    activity: Activity<ActivityModel>,
    includedTypes?: string[],
    excludedTypes?: string[],
    filterUnprefixed = false
  ): Observable<DataEntity[]> {
    const aid = activity ? activity.id.toLowerCase() : null;

    if (!aid) {
      return of([]);
    }

    const filterEntities = () => {
      if (this.dataEntitiesBeforeMeCache[aid]) {
        // if included/excluded types are null it returns the full cachelist from the function.
        const includedETCache = this.includeDataEntityTypes(aid, includedTypes);
        const excludedETCache = this.excludeDataEntityTypes(aid, excludedTypes);
        const availableDataEntitiesBeforeMe = _.intersection(
          includedETCache,
          excludedETCache
        );
        return availableDataEntitiesBeforeMe;
      } else {
        return [];
      }
    };

    // we have the full list from the server and we need to filter out the list for our filter types
    if (this.dataEntitiesBeforeMeCache[aid]) {
      return of(filterEntities());
    }

    // nothing has asked for a list of entities before me so ask the server for a full list
    if (!this.deBeforeMeObs[aid]) {
      this.deBeforeMeObs[aid] = this._dataSvc
        .getDataEntitiesBeforeMe(
          workflow,
          activity,
          null, // we want to grab all entity types from server and cache the many requests locally
          null, // we want to grab all entity types from server and cache the many requests locally
          filterUnprefixed
        )
        .pipe(
          map(result => {
            this.dataEntitiesBeforeMeCache[aid] = result;
            this.cacheEntityDetails(activity, result);
            this.deBeforeMeObs[aid] = null;
            return result;
          })
        );
    }

    // return the observable that was created the first time regardless of how many times it was asked for because we are in the process of asking and then filter out the results before we return
    return this.deBeforeMeObs[aid].pipe(map(filterEntities));
  }
  getTemplateItems(
    workflow: Workflow,
    activity: Activity<ActivityModel>,
    dataEntityTypes?: string[],
    activityTypes?: string[]
  ): Observable<TemplateItem[]> {
    const deObs = this.getDataEntitiesBeforeMe(
      workflow,
      activity,
      dataEntityTypes
    );
    const actObs = this.getWorkflowActivities(workflow, activityTypes);

    return deObs.pipe(
      flatMap(entities => {
        const items: TemplateItem[] = entities.map(
          (de: DataEntity, index: number) =>
            new TemplateItem({
              label: de.templateCode,
              templateCode: de.templateCode
            })
        );

        return actObs.pipe(
          map(activities => {
            const aItems: TemplateItem[] = Object.keys(activities).map(
              (key: string, index: number) => {
                const a: Activity<ActivityModel> = activities[key];
                return new TemplateItem({
                  label: a.model.screenName + '.Status',
                  templateCode: a.model.screenName + '.Status'
                });
              }
            );

            return items.concat(aItems);
          })
        );
      })
    );
  }
  copyWorkflow(request: CopyWorkflowRequest): Observable<Workflow> {
    return this._dataSvc.copyWorkflow(request);
  }
  exportEntities(workflowId: string, options: DataEntitiesExportParameters) {
    return this._dataSvc.exportEntities(workflowId, options);
  }
  getExportEntityOptions(workflowId: string): Observable<ExportEntityOption[]> {
    return this._dataSvc.getExportEntityOptions(workflowId);
  }
  getExportEntities(workflowId: string): Observable<{ [key: string]: string }> {
    return this._dataSvc.getExportEntities(workflowId);
  }

  getWorkflowEntities(workflowid: string): Observable<any[]> {
    if (!this.cachedWFEntities) {
      return this._dataSvc.getWorkflowEntities(workflowid).pipe(
        tap(entities => {
          this.cachedWFEntities = entities;
        })
      );
    } else {
      return of(this.cachedWFEntities);
    }
  }
  getNavigationPanelItems(
    applicationId: string,
    currentActivityId: string
  ): Observable<ActivityNavigationInfo[]> {
    return this._dataSvc.getNavigationPanelItems(
      applicationId,
      currentActivityId
    );
  }
  getDraftWorkflowEntities(workflowId: string): Observable<DataEntity[]> {
    return this._dataSvc.getDraftVersionEntities(workflowId);
  }

  getDataEntities(
    workflow: Workflow,
    types?: string[],
    onlyGlobalDataEntities?: boolean,
    activityTypes?: string[]
  ): Observable<DataEntity[]> {
    if (types) {
      types = types.map(t => t.toLowerCase());
    }

    return this._dataSvc
      .getWorkflowVersionDataEntities(
        workflow && workflow.version ? workflow.version.id : null,
        types,
        null,
        onlyGlobalDataEntities,
        false,
        activityTypes
      )
      .pipe(
        map(entities => {
          return entities
            ? entities.map(e =>
                DataEntityFactory.createDataEntity(e.dataEntityTypeCode, e)
              )
            : [];
        })
      );
  }
  getFormulaDataEntities(workflow: Workflow): Observable<DataEntity[]> {
    return this.getDataEntities(workflow, [
      WorkflowService.DATA_ENTITIES.NumericData.code,
      WorkflowService.DATA_ENTITIES.FreeFormText.code,
      WorkflowService.DATA_ENTITIES.Fee.code
    ]);
  }
  getEmailAttachments(
    workflow: Workflow,
    currentActivity: Activity<ActivityModel>
  ): Observable<EmailActivityAttachment[]> {
    const dataEntitysource: Observable<EmailActivityAttachment[]> = this.getDataEntitiesBeforeMe(
      workflow,
      currentActivity,
      [
        WorkflowService.DATA_ENTITIES.Document.code,
        WorkflowService.DATA_ENTITIES.ContractorDocument.code
      ]
    ).pipe(
      map((de: DocumentDataEntity[]) => {
        return de
          ? de.map(d => {
              return {
                name: d.label,
                path: this._templateSvc.generateToken(d),
                activityId: d.parent
                  ? d.parent.id
                  : d.parentActivity
                  ? d.parentActivity.id
                  : null,
                deTemplateCode: d.templateCode
              };
            })
          : [];
      })
    );

    const printTemplateSource: Observable<EmailActivityAttachment[]> = this._dataSvc
      .getActivitiesDefinedBeforeMe(
        workflow.version.id,
        currentActivity.id,
        null,
        [
          WorkflowService.ACTIVITIES.PrintTemplate,
          WorkflowService.ACTIVITIES.ContractorRegistrationCertificateActivity,
          WorkflowService.ACTIVITIES.RenewalCertificateActivity
        ]
      )
      .pipe(
        map(activities => {
          return activities.map(a => {
            return {
              name: a.model.screenName,
              path: (<PrintTemplateActivityModel>a.model).documentPath,
              activityId: a.id,
              deTemplateCode: null
            };
          });
        })
      );

    return forkJoin(dataEntitysource, printTemplateSource).pipe(
      map((data: any[]) => {
        // join de's with print templates
        const des = data.length > 0 ? data[0] : null;
        const act = data.length > 1 ? data[1] : null;

        return des && act ? data[0].concat(data[1]) : des;
      })
    );
  }
  getEmailDataEntities(
    workflow: Workflow,
    activity?: Activity<ActivityModel>
  ): Observable<DataEntity[]> {
    const des = this.getDataEntitiesBeforeMe(workflow, activity, [
      WorkflowService.DATA_ENTITIES.FreeFormText.code
    ]);
    return des.pipe(
      map(entities => {
        if (entities) {
          const filteredDEs = entities.filter(
            (value: DataEntity, index: number) =>
              (value.dataEntityTypeCode ===
                WorkflowService.DATA_ENTITIES.FreeFormText.code &&
                (value as FreeFormTextDataEntity).restrictedFormat ===
                  RestrictedFormat.Email) ||
              value.dataEntityTypeCode !==
                WorkflowService.DATA_ENTITIES.FreeFormText.code
          );
          return filteredDEs;
        } else {
          return [];
        }
      })
    );
  }
  calculateFormula(
    formulaCriteria: ConditionTarget[],
    activity: Activity<ActivityModel>,
    applicationId: string,
    templateCode: string
  ): Observable<string> {
    return this._dataSvc.calculateFormula(
      formulaCriteria,
      activity,
      applicationId,
      templateCode
    );
  }
  deleteDataEntity(
    workflowId: string,
    templateCode: string
  ): Observable<boolean> {
    return this._dataSvc.deleteDataEntity(workflowId, templateCode);
  }
  getActivityEditor(
    workflowId: string,
    activityId: string,
    versionId?: string
  ): Observable<Activity<ActivityModel>> {
    return this._dataSvc
      .getActivityEditor(workflowId, activityId, versionId)
      .pipe(
        map(a => {
          const activities = ActivityUtilities.convertActivitiesJsonToClasses([
            a
          ]);
          return activities[0];
        })
      );
  }
  getWorkflowApplicationShareDetails(
    applicationId: string,
    activity: Activity<ActivityModel>
  ): Observable<SharedAccessDetails> {
    return this._dataSvc.getWorkflowApplicationShareDetails(
      applicationId,
      activity
    );
  }

  getRegistration(registrationId: string): Observable<Registration> {
    return this._dataSvc.getRegistration(registrationId);
  }
  getInspection(inspectionId: string): Observable<InspectionVM> {
    return this._dataSvc.getInspection(inspectionId);
  }
  searchRegistrationsUnpaged(
    clientId: string,
    sortField?: string,
    sortDescending?: boolean,
    filterObjects?: ItemSearchOptionField[],
    publicData?: boolean
  ): Observable<UnpagedRegistrationVM> {
    return this._dataSvc
      .searchRegistrationsUnpaged(
        clientId,
        sortField,
        sortDescending,
        filterObjects,
        publicData
      )
      .pipe(
        map(regs => {
          return regs;
        })
      );
  }

  searchRegistrationsPaged(
    clientId: string,
    sortField?: string,
    sortDescending?: boolean,
    filterObjects?: ItemSearchOptionField[],
    pageNumber?: number,
    pageSize?: number,
    publicData?: boolean
  ): Observable<PagedRegistrationVM> {
    return this._dataSvc
      .searchRegistrationsPaged(
        clientId,
        sortField,
        sortDescending,
        filterObjects,
        pageNumber,
        pageSize,
        publicData
      )
      .pipe(
        map(regs => {
          return regs;
        })
      );
  }

  exportRegistrations(
    clientId: string,
    useExcelFormat: boolean,
    exportColumns: string[],
    sortField?: string,
    sortDescending?: boolean,
    filterObjects?: ItemSearchOptionField[],
    pageNumber?: number,
    pageSize?: number,
    publicData?: boolean
  ): Observable<any> {
    return this._dataSvc
      .exportRegistrations(
        clientId,
        useExcelFormat,
        exportColumns,
        sortField,
        sortDescending,
        filterObjects,
        pageNumber,
        pageSize,
        publicData
      )
      .pipe(
        map(regs => {
          return regs;
        })
      );
  }

  saveRegistration(registration: Registration): Observable<Registration> {
    return this._dataSvc.saveRegistration(registration);
  }

  deleteRegistration(deleteRegistrationId: string): Observable<boolean> {
    return this._dataSvc.deleteRegistration(deleteRegistrationId);
  }

  getRegistrationDocuments(registrationId: string) {
    return this._dataSvc.getRegistrationDocuments(registrationId);
  }

  saveRegistrationDocuments(
    registrationId: string,
    documents: RegistrationDocument[]
  ): Observable<RegistrationDocument[]> {
    return this._dataSvc.saveRegistrationDocuments(registrationId, documents);
  }

  saveRegistrationDocument(document: RegistrationDocument) {
    return this._dataSvc.saveRegistrationDocument(document);
  }

  saveRegsitrationCustomFields(registration: Registration) {
    return this._dataSvc.saveRegistrationCustomFields(registration);
  }

  deleteRegistrationDocument(documentId: string): Observable<void> {
    return this._dataSvc.deleteRegistrationDocument(documentId);
  }

  saveRegistrationNote(note: RegistrationNote): Observable<RegistrationNote> {
    return this._dataSvc.saveRegistrationNote(note);
  }

  // don't send start test renewal app requrests through here, go to the one below
  startRegistrationRenewal(
    registrationId: any,
    isTestApplication: boolean = false
  ): Observable<{ applicationId: string }> {
    return this._dataSvc.startRegistrationRenewal(
      registrationId,
      null,
      isTestApplication
    );
  }

  getUserParcels(userId: string): Observable<{ [key: string]: string }[]> {
    return this._dataSvc.getUserParcels(userId);
  }

  // startTestRegistrationRenewal(
  //   testRenewalWorkflowId: string,
  //   isTestApplication: boolean = true
  // ): Observable<{ applicationId: string }> {
  //   return this._dataSvc.startRegistrationRenewal(
  //     null,
  //     testRenewalWorkflowId,
  //     isTestApplication
  //   );
  // }

  getRenewableParentIds(
    workflowId: string
  ): Observable<RenewableParentsResponse> {
    return this._dataSvc.getRenewableParentIds(workflowId);
  }

  disableRegistration(registrationId: string): Observable<boolean> {
    return this._dataSvc.disableRegistration(registrationId);
  }

  reEnableRegistration(registrationId: string): Observable<boolean> {
    return this._dataSvc.reEnableRegistration(registrationId);
  }

  duplicateActivities(
    activities: Activity<ActivityModel>[]
  ): Observable<DuplicateActivityResponse> {
    return this._dataSvc.duplicateActivities(activities);
  }

  isProcessing(applicationId: string): Observable<boolean> {
    return this._dataSvc.isProcessing(applicationId);
  }

  getDataSets(clientId: string): Observable<DataSet[]> {
    return this._dataSvc.getDataSets(clientId);
  }

  getPublishedDataSets(clientId: string): Observable<DataSet[]> {
    return this._dataSvc.getPublishedDataSets(clientId);
  }

  getDataSet(
    dataSetId: string,
    buildFieldFilterItems: boolean = false
  ): Observable<DataSet> {
    return this._dataSvc.getDataSet(dataSetId, buildFieldFilterItems);
  }

  saveDataSet(dataSet: DataSet): Observable<DataSet> {
    return this._dataSvc.saveDataSet(dataSet);
  }

  deleteDataSet(dataSetId: string): Observable<boolean> {
    return this._dataSvc.deleteDataSet(dataSetId);
  }
  publishDataSet(dataSetId: string): Observable<boolean> {
    return this._dataSvc.publishDataSet(dataSetId);
  }

  reSendEmail(
    applicationId: string,
    activityDataId: string
  ): Observable<boolean> {
    return this._dataSvc.reSendEmail(applicationId, activityDataId);
  }

  updateClientApplicationNumber(
    applicationId: string,
    newApplicationNumber: string,
    ignoreWarning: boolean
  ): Observable<{ applicationNumberExist: boolean }> {
    return this._dataSvc.updateClientApplicationNumber(
      applicationId,
      newApplicationNumber,
      ignoreWarning
    );
  }

  queueCustomFieldTemplateCodeRename(
    draftTemplateCode: string,
    templateCode: string,
    workflowId: string
  ): Observable<boolean> {
    return this._dataSvc.queueCustomFieldTemplateCodeRename(
      draftTemplateCode,
      templateCode,
      workflowId
    );
  }

  calculateClientAppNumberResetDate(
    resetClientAppNumberMonth: number,
    resetClientAppNumberDay: number
  ): Observable<string> {
    return this._dataSvc.calculateClientAppNumberResetDate(
      resetClientAppNumberMonth,
      resetClientAppNumberDay
    );
  }

  getCompletedApplicationActivityForEditing(
    workflowApplicationId: string, 
    workflowApplicationActivityDataId: string
  ): Observable<Activity<ActivityModel>> {
    return this._dataSvc.getCompletedApplicationActivityForEditing(workflowApplicationId, workflowApplicationActivityDataId);
  }
}

export class DataEntityFactory {
  static defaultDataEntityData: { [key: string]: DataEntity }; // This comes from the server and is used by CreateActivity

  static dataEntityMetaData: {
    [key: string]: {
      editorComponent: Type<DataEntityEditorBaseComponent>;
      viewComponent?: Type<DataEntityViewComponent>;
      inputComponent: Type<DataEntityInputComponent>;
      modelType: Type<DataEntity>;
    };
  } = {};

  static createDataEntity(type: string, options?: any) {
    /*
    if (ActivityFactory.activityMetaData[activityType]) {
      return new ActivityFactory.activityMetaData[activityType].activityType(
        ActivityFactory.defaultActivityData[activityType]
      );
    }

    return null;
    */

    if (this.dataEntityMetaData[type]) {
      if (this.dataEntityMetaData[type].modelType) {
        return new DataEntityFactory.dataEntityMetaData[type].modelType({
          ...this.defaultDataEntityData[type],
          ...options
        });
      }
    } else {
      return new SystemDataEntity(options);
    }

    return null;
  }

  static createViewComponent(dataEntity: DataEntity, options?: any) {
    let viewComp: Type<DataEntityViewComponent>;

    if (DataEntityFactory.dataEntityMetaData[dataEntity.dataEntityTypeCode]) {
      viewComp =
        DataEntityFactory.dataEntityMetaData[dataEntity.dataEntityTypeCode]
          .viewComponent;

      return viewComp;
    }

    return null;
  }

  static createInputComponent(
    dataEntity: DataEntity,
    options?: any
  ): Type<DataEntityInputComponent> {
    let inputComp: Type<DataEntityInputComponent>;

    inputComp =
      DataEntityFactory.dataEntityMetaData[dataEntity.dataEntityTypeCode]
        .inputComponent;

    return inputComp;
  }

  static createEditComponent(
    dataEntity: DataEntity,
    options?: any
  ): Type<DataEntityEditorBaseComponent> {
    let editComp: Type<DataEntityEditorBaseComponent>;

    editComp =
      DataEntityFactory.dataEntityMetaData[dataEntity.dataEntityTypeCode]
        .editorComponent;

    return editComp;
  }

  static initialize(dataEntities?: DataEntity[]): void {
    DataEntityFactory.defaultDataEntityData = {};

    if (dataEntities) {
      dataEntities.forEach(de => {
        DataEntityFactory.defaultDataEntityData[de.dataEntityTypeCode] = de;
      });
    }
    this.dataEntityMetaData[WorkflowService.DATA_ENTITIES.ParcelNotes.code] = {
      editorComponent: FreeFormTextDataEntityComponent,
      inputComponent: FreeFormTextDataEntityInputComponent,
      viewComponent: FreeFormTextDataEntityViewComponent,
      modelType: FreeFormTextDataEntity
    };

    this.dataEntityMetaData[WorkflowService.DATA_ENTITIES.FreeFormText.code] = {
      editorComponent: FreeFormTextDataEntityComponent,
      inputComponent: FreeFormTextDataEntityInputComponent,
      viewComponent: FreeFormTextDataEntityViewComponent,
      modelType: FreeFormTextDataEntity
    };
    this.dataEntityMetaData[WorkflowService.DATA_ENTITIES.NumericData.code] = {
      editorComponent: NumericDataEntityComponent,
      inputComponent: NumericDataEntityInputComponent,
      viewComponent: NumericDataEntityViewComponent,
      modelType: NumericDataEntity
    };
    this.dataEntityMetaData[WorkflowService.DATA_ENTITIES.MapSketch.code] = {
      editorComponent: MapsketchDataEntityEditorComponent,
      inputComponent: MapsketchDataEntityInputComponent,
      viewComponent: MapsketchDataEntityViewComponent,
      modelType: MapsketchDataEntity
    };
    this.dataEntityMetaData[WorkflowService.DATA_ENTITIES.Document.code] = {
      editorComponent: DocumentDataEntityComponent,
      inputComponent: DocumentDataEntityInputComponent,
      modelType: DocumentDataEntity,
      viewComponent: DocumentDataEntityViewComponent
    };
    this.dataEntityMetaData[WorkflowService.DATA_ENTITIES.Fee.code] = {
      editorComponent: FeeDataEntityEditorComponent,
      inputComponent: FeeDataEntityInputComponent,
      modelType: FeeDataEntity,
      viewComponent: FeeDataEntityViewComponent
    };
    this.dataEntityMetaData[
      WorkflowService.DATA_ENTITIES.DisplayEntityValue.code
    ] = {
      editorComponent: DisplayEntityValueDataEntityEditorComponent,
      inputComponent: DisplayEntityValueDataEntityInputComponent,
      viewComponent: DisplayEntityValueDataEntityViewComponent,
      modelType: DisplayEntityValueDataEntity
    };
    this.dataEntityMetaData[WorkflowService.DATA_ENTITIES.ListData.code] = {
      editorComponent: ListDataEntityEditorComponent,
      inputComponent: ListDataEntityInputComponent,
      modelType: ListDataEntity,
      viewComponent: ListDataEntityViewComponent
    };
    this.dataEntityMetaData[WorkflowService.DATA_ENTITIES.YesNo.code] = {
      editorComponent: YesNoDataEntityEditorComponent,
      inputComponent: YesNoDataEntityInputComponent,
      modelType: YesNoDataEntity,
      viewComponent: YesNoDataEntityViewComponent
    };
    this.dataEntityMetaData[WorkflowService.DATA_ENTITIES.Today.code] = {
      editorComponent: TodayDataEntityEditorComponent,
      inputComponent: TodayDataEntityInputComponent,
      modelType: TodayDataEntity,
      viewComponent: TodayDataEntityViewComponent
    };
    this.dataEntityMetaData[WorkflowService.DATA_ENTITIES.Signature.code] = {
      editorComponent: SignatureDataEntityEditorComponent,
      inputComponent: SignatureDataEntityInputComponent,
      modelType: SignatureDataEntity,
      viewComponent: SignatureDataEntityViewComponent
    };
    this.dataEntityMetaData[
      WorkflowService.DATA_ENTITIES.GenerateClientApplicationNumber.code
    ] = {
      editorComponent: GenerateClientApplicationNumberDataEntityEditorComponent,
      inputComponent: GenerateClientApplicationNumberDataEntityInputComponent,
      modelType: GenerateClientApplicationNumberDataEntity,
      viewComponent: GenerateClientApplicationNumberDataEntityViewComponent
    };
    this.dataEntityMetaData[WorkflowService.DATA_ENTITIES.Date.code] = {
      editorComponent: DateDataEntityEditorComponent,
      inputComponent: DateDataEntityInputComponent,
      modelType: DateDataEntity,
      viewComponent: DateDataEntityViewComponent
    };

    this.dataEntityMetaData[WorkflowService.DATA_ENTITIES.ContactList.code] = {
      editorComponent: ContactListDataEntityEditorComponent,
      inputComponent: ContactListDataEntityInputComponent,
      modelType: ContactListDataEntity,
      viewComponent: ContactListDataEntityViewComponent
    };

    this.dataEntityMetaData[
      WorkflowService.DATA_ENTITIES.ContractorDocument.code
    ] = {
      editorComponent: ContractorDocumentDataEntityEditorComponent,
      inputComponent: ContractorDocumentDataEntityInputComponent,
      modelType: ContractorDocumentDataEntity,
      viewComponent: ContractorDocumentDataEntityViewComponent
    };
    this.dataEntityMetaData[
      WorkflowService.DATA_ENTITIES.InspectionDocument.code
    ] = {
      editorComponent: InspectionDocumentDataEntityEditorComponent,
      inputComponent: InspectionDocumentDataEntityInputComponent,
      modelType: InspectionDocumentDataEntity,
      viewComponent: InspectionDocumentDataEntityViewComponent
    };

    this.dataEntityMetaData[
      WorkflowService.DATA_ENTITIES.CalculatedValue.code
    ] = {
      editorComponent: CalculateValueDataEntityEditorComponent,
      inputComponent: CalculateValueDataEntityInputComponent,
      modelType: CalculatedValueDataEntity,
      viewComponent: CalculateValueDataEntityViewComponent
    };
    this.dataEntityMetaData[WorkflowService.DATA_ENTITIES.RichText.code] = {
      editorComponent: RichTextDataEntityEditorComponent,
      inputComponent: RichTextDataEntityInputComponent,
      viewComponent: RichTextDataEntityViewComponent,
      modelType: RichTextDataEntity
    };
    this.dataEntityMetaData[WorkflowService.DATA_ENTITIES.XData.code] = {
      editorComponent: null,
      inputComponent: null,
      viewComponent: XDataEntityViewComponent,
      modelType: XDataEntity
    };
    this.dataEntityMetaData[WorkflowService.DATA_ENTITIES.YData.code] = {
      editorComponent: null,
      inputComponent: null,
      viewComponent: YDataEntityViewComponent,
      modelType: YDataEntity
    };
    this.dataEntityMetaData[WorkflowService.DATA_ENTITIES.GeoJSONData.code] = {
      editorComponent: null,
      inputComponent: null,
      viewComponent: GeoJSONDataEntityViewComponent,
      modelType: GeoJSONDataEntity
    };
    this.dataEntityMetaData[WorkflowService.DATA_ENTITIES.QR.code] = {
      editorComponent: null,
      inputComponent: null,
      viewComponent: null,
      modelType: QRDataEntity
    };
    // this does not live in the DATA_ENTITIES list because that list is used to build the side bar
    this.dataEntityMetaData['system-data-entity'] = {
      inputComponent: SystemDataEntityInputComponent,
      viewComponent: SystemDataEntityViewComponent,
      modelType: SystemDataEntity,
      editorComponent: null
    };
  }
}

DataEntityFactory.initialize();

export class ActivityFactory {
  static activityMetaData: {
    [key: string]: {
      readonlyViewType?: Type<ActivityView>;
      editViewType?: Type<ActivityEditorBaseComponent>;
      miniEditViewType: Type<ActivityEditorBaseComponent>;
      inputViewType: Type<ActivityView>;
      modelType: Type<ActivityModel>;
      cellModelType?: Type<BaseCellModel>;
      activityType: Type<Activity<ActivityModel>>;
    };
  } = {};
  static defaultActivityData: { [key: string]: Activity<ActivityModel> }; // This comes from the server and is used by CreateActivity

  static initialize(activities: Activity<ActivityModel>[]) {
    ActivityFactory.defaultActivityData = {};

    activities.forEach(a => {
      ActivityFactory.defaultActivityData[a.type] = a;
    });

    ActivityFactory.activityMetaData[WorkflowService.ACTIVITIES.Form] = {
      modelType: FormActivityModel,
      cellModelType: FormActivityCellModel,
      inputViewType: FormActivityInputComponent,
      activityType: FormActivity,
      miniEditViewType: FormActivityEditorComponent,
      readonlyViewType: FormActivityViewComponent
    };
    ActivityFactory.activityMetaData[WorkflowService.ACTIVITIES.Email] = {
      modelType: EmailActivityModel,
      cellModelType: EmailActivityCellModel,
      activityType: EmailActivity,
      miniEditViewType: EmailActivityEditorComponent,
      inputViewType: null,
      readonlyViewType: EmailActivityViewComponent
    };
    ActivityFactory.activityMetaData[
      WorkflowService.ACTIVITIES.ScheduleEmail
    ] = {
      modelType: ScheduleEmailActivityModel,
      cellModelType: ScheduleEmailActivityCellModel,
      activityType: ScheduleEmailActivity,
      miniEditViewType: ScheduleEmailActivityEditorComponent,
      inputViewType: null,
      readonlyViewType: ScheduleEmailActivityViewComponent
    };
    ActivityFactory.activityMetaData[
      WorkflowService.ACTIVITIES.CancelScheduledEmailActivity
    ] = {
      modelType: CancelScheduledEmailActivityModel,
      cellModelType: CancelScheduledEmailActivityCellModel,
      activityType: CancelScheduledEmailActivity,
      miniEditViewType: CancelScheduledEmailActivityEditorComponent,
      inputViewType: null,
      readonlyViewType: null
    };
    ActivityFactory.activityMetaData[WorkflowService.ACTIVITIES.Appointment] = {
      modelType: AppointmentActivityModel,
      cellModelType: AppointmentActivityCellModel,
      activityType: AppointmentActivity,
      miniEditViewType: AppointmentActivityEditorComponent,
      inputViewType: null,
      readonlyViewType: AppointmentActivityViewComponent
    };
    ActivityFactory.activityMetaData[
      WorkflowService.ACTIVITIES.SearchParcelData
    ] = {
      modelType: SearchParcelDataActivityModel,
      activityType: SearchParcelDataActivity,
      miniEditViewType: SearchParcelDataActivityEditorComponent,
      inputViewType: SearchParcelDataActivityInputComponent,
      readonlyViewType: SearchParcelDataActivityViewComponent
    };
    ActivityFactory.activityMetaData[WorkflowService.ACTIVITIES.Map] = {
      modelType: MapActivityModel,
      activityType: MapActivity,
      miniEditViewType: MapActivityEditorComponent,
      inputViewType: MapActivityInputComponent,
      readonlyViewType: MapActivityViewComponent
    };
    ActivityFactory.activityMetaData[
      WorkflowService.ACTIVITIES.CompleteWorkflow
    ] = {
      modelType: CompleteWorkflowActivityModel,
      inputViewType: CompleteWorkflowActivityInputComponent,
      activityType: CompleteWorkflowActivity,
      miniEditViewType: CompleteWorkflowActivityEditorComponent
    };
    ActivityFactory.activityMetaData[WorkflowService.ACTIVITIES.Decision] = {
      modelType: DecisionActivityModel,
      activityType: DecisionActivity,
      miniEditViewType: DecisionActivityEditorComponent,
      inputViewType: DecisionActivityViewComponent
    };
    ActivityFactory.activityMetaData[WorkflowService.ACTIVITIES.Payment] = {
      modelType: PaymentActivityModel,
      activityType: PaymentActivity,
      miniEditViewType: PaymentActivityEditorComponent,
      inputViewType: PaymentActivityInputComponent,
      readonlyViewType: PaymentActivityViewComponent
    };
    ActivityFactory.activityMetaData[
      WorkflowService.ACTIVITIES.PrintTemplate
    ] = {
      modelType: PrintTemplateActivityModel,
      activityType: PrintTemplateActivity,
      miniEditViewType: PrintTemplateActivityEditorComponent,
      inputViewType: PrintTemplateActivityInputComponent,
      readonlyViewType: PrintTemplateActivityViewComponent
    };
    ActivityFactory.activityMetaData['wait-activity'] = {
      modelType: WaitActivityModel,
      activityType: WaitActivity,
      miniEditViewType: WaitActivityEditorComponent,
      inputViewType: WaitActivityInputComponent,
      readonlyViewType: WaitActivityViewComponent
    };
    ActivityFactory.activityMetaData[WorkflowService.ACTIVITIES.Start] = {
      modelType: StartWorkflowActivityModel,
      inputViewType: StartWorkflowActivityInputComponent,
      activityType: StartWorkflowActivity,
      miniEditViewType: StartWorkflowActivityEditorComponent,
      readonlyViewType: StartWorkflowActivityViewComponent
    };
    ActivityFactory.activityMetaData[
      WorkflowService.ACTIVITIES.RenewalDecision
    ] = {
      modelType: FormActivityModel,
      cellModelType: RenewalDecisionActivityCellModel,
      inputViewType: RenewalDecisionActivityInputComponent,
      activityType: RenewalDecisionActivity,
      miniEditViewType: RenewalDecisionActivityEditorComponent,
      readonlyViewType: RenewalDecisionActivityViewComponent
    };
    ActivityFactory.activityMetaData[
      WorkflowService.ACTIVITIES.ContractorRegistrationDecision
    ] = {
      modelType: FormActivityModel,
      cellModelType: ContractorRegistrationDecisionActivityCellModel,
      inputViewType: ContractorRegistrationDecisionActivityInputComponent,
      activityType: ContractorRegistrationDecisionActivity,
      miniEditViewType: ContractorRegistrationDecisionActivityEditorComponent,
      readonlyViewType: ContractorRegistrationDecisionActivityViewComponent
    };
    ActivityFactory.activityMetaData[
      WorkflowService.ACTIVITIES.ContractorInfo
    ] = {
      modelType: FormActivityModel,
      cellModelType: ContractorInfoActivityCellModel,
      inputViewType: ContractorInfoFormActivityInputComponent,
      activityType: ContractorInfoFormActivity,
      miniEditViewType: ContractorInfoFormActivityEditorComponent,
      readonlyViewType: ContractorInfoFormActivityViewComponent
    };
    ActivityFactory.activityMetaData[
      WorkflowService.ACTIVITIES.ContractorRegistrationInfo
    ] = {
      modelType: FormActivityModel,
      cellModelType: ContractorRegistrationInfoActivityCellModel,
      inputViewType: ContractorRegistrationInfoFormActivityInputComponent,
      activityType: ContractorRegistrationInfoFormActivity,
      miniEditViewType: ContractorRegistrationInfoFormActivityEditorComponent,
      readonlyViewType: ContractorRegistrationInfoFormActivityViewComponent
    };

    ActivityFactory.activityMetaData[
      WorkflowService.ACTIVITIES.ContractorSelection
    ] = {
      modelType: FormActivityModel,
      cellModelType: ContractorSelectionActivityCellModel,
      inputViewType: ContractorSelectionActivityInputComponent,
      activityType: ContractorSelectionActivity,
      miniEditViewType: ContractorSelectionActivityEditorComponent,
      readonlyViewType: ContractorSelectionActivityViewComponent
    };

    ActivityFactory.activityMetaData[
      WorkflowService.ACTIVITIES.InspectionRequest
    ] = {
      modelType: InspectionActivityModel,
      cellModelType: InspectionRequestActivityCellModel,
      inputViewType: InspectionRequestActivityInputComponent,
      activityType: InspectionRequestActivity,
      miniEditViewType: InspectionRequestActivityEditorComponent,
      readonlyViewType: InspectionRequestActivityViewComponent
    };
    ActivityFactory.activityMetaData[
      WorkflowService.ACTIVITIES.InspectionSimple
    ] = {
      modelType: InspectionActivityModel,
      cellModelType: InspectionSimpleActivityCellModel,
      inputViewType: InspectionSimpleActivityInputComponent,
      activityType: InspectionSimpleActivity,
      miniEditViewType: InspectionSimpleActivityEditorComponent,
      readonlyViewType: InspectionSimpleActivityViewComponent
    };

    ActivityFactory.activityMetaData[
      WorkflowService.ACTIVITIES.InspectionSchedule
    ] = {
      modelType: InspectionActivityModel,
      cellModelType: InspectionScheduleActivityCellModel,
      inputViewType: InspectionScheduleActivityInputComponent,
      activityType: InspectionScheduleActivity,
      miniEditViewType: InspectionScheduleActivityEditorComponent,
      readonlyViewType: InspectionScheduleActivityViewComponent
    };

    ActivityFactory.activityMetaData[
      WorkflowService.ACTIVITIES.RenewalReview
    ] = {
      modelType: FormActivityModel,
      cellModelType: BaseCellModel,
      inputViewType: RenewalReviewActivityInputComponent,
      activityType: RenewalReviewActivity,
      miniEditViewType: RenewalReviewActivityEditorComponent,
      readonlyViewType: RenewalReviewActivityViewComponent
    };

    ActivityFactory.activityMetaData[
      WorkflowService.ACTIVITIES.ReviewContractorRegistration
    ] = {
      modelType: FormActivityModel,
      cellModelType: BaseCellModel,
      inputViewType: ReviewContractorRegistrationActivityInputComponent,
      activityType: ReviewContractorRegistrationActivity,
      miniEditViewType: ReviewContractorRegistrationActivityEditorComponent,
      readonlyViewType: ReviewContractorRegistrationActivityViewComponent
    };

    ActivityFactory.activityMetaData[
      WorkflowService.ACTIVITIES.ReviewActivity
    ] = {
      modelType: FormActivityModel,
      cellModelType: BaseCellModel,
      inputViewType: ReviewActivityInputComponent,
      activityType: ReviewActivity,
      miniEditViewType: ReviewActivityEditorComponent,
      readonlyViewType: ReviewActivityViewComponent
    };

    ActivityFactory.activityMetaData[
      WorkflowService.ACTIVITIES.RequiredUser
    ] = {
      modelType: RequiredUserActivityModel,
      inputViewType: RequiredUserActivityInputComponent,
      activityType: RequiredUserActivity,
      miniEditViewType: RequiredUserActivityEditorComponent,
      readonlyViewType: RequiredUserActivityViewComponent
    };

    ActivityFactory.activityMetaData[
      WorkflowService.ACTIVITIES.CompleteInspection
    ] = {
      modelType: InspectionActivityModel,
      cellModelType: CompleteInspectionActivityCellModel,
      inputViewType: CompleteInspectionActivityInputComponent,
      activityType: CompleteInspectionActivity,
      miniEditViewType: CompleteInspectionActivityEditorComponent,
      readonlyViewType: CompleteInspectionActivityViewComponent
    };
    ActivityFactory.activityMetaData[
      WorkflowService.ACTIVITIES.ContractorRenewalDecision
    ] = {
      modelType: FormActivityModel,
      inputViewType: ContractorRenewalDecisionActivityInputComponent,
      activityType: ContractorRenewalDecisionActivity,
      miniEditViewType: ContractorRenewalDecisionActivityEditorComponent,
      readonlyViewType: ContractorRenewalDecisionActivityViewComponent
    };
    ActivityFactory.activityMetaData[
      WorkflowService.ACTIVITIES.RenewalCertificateActivity
    ] = {
      modelType: PrintTemplateActivityModel,
      activityType: RenewalCertificateActivity,
      miniEditViewType: PrintTemplateActivityEditorComponent,
      inputViewType: PrintTemplateActivityInputComponent,
      readonlyViewType: PrintTemplateActivityViewComponent
    };
    ActivityFactory.activityMetaData[
      WorkflowService.ACTIVITIES.ContractorRegistrationCertificateActivity
    ] = {
      modelType: PrintTemplateActivityModel,
      activityType: ContractorRegistrationCertificateActivity,
      miniEditViewType: PrintTemplateActivityEditorComponent,
      inputViewType: PrintTemplateActivityInputComponent,
      readonlyViewType: PrintTemplateActivityViewComponent
    };
    ActivityFactory.activityMetaData[
      WorkflowService.ACTIVITIES.CallWorkflow
    ] = {
      modelType: CallWorkflowActivityModel,
      activityType: CallWorkflowActivity,
      miniEditViewType: CallWorkflowEditorComponent,
      inputViewType: CallWorkflowInputComponent,
      readonlyViewType: CallWorkflowViewComponent
    };
    ActivityFactory.activityMetaData[
      WorkflowService.ACTIVITIES.InitiateWorkflow
    ] = {
      modelType: CallWorkflowActivityModel,
      activityType: InitiateWorkflowActivity,
      miniEditViewType: InitiateWorkflowEditorComponent,
      inputViewType: InitiateWorkflowInputComponent,
      readonlyViewType: InitiateWorkflowViewComponent
    };
    ActivityFactory.activityMetaData[
      WorkflowService.ACTIVITIES.GenerateApplicationNumber
    ] = {
      modelType: GenerateApplicationNumberActivityModel,
      activityType: GenerateApplicationNumberActivity,
      miniEditViewType: GenerateApplicationNumberActivityEditorComponent,
      inputViewType: GenerateApplicationNumberActivityInputComponent,
      readonlyViewType: GenerateApplicationNumberActivityViewComponent
    };
    ActivityFactory.activityMetaData[WorkflowService.ACTIVITIES.ExportData] = {
      modelType: ExportDataActivityModel,
      activityType: ExportDataActivity,
      miniEditViewType: ExportDataActivityEditorComponent,
      inputViewType: ExportDataActivityInputComponent,
      readonlyViewType: ExportDataActivityViewComponent
    };
    ActivityFactory.activityMetaData[
      WorkflowService.ACTIVITIES.AgendaItemActivity
    ] = {
      modelType: AgendaItemActivityModel,
      cellModelType: AgendaItemActivityCellModel,
      inputViewType: AgendaItemActivityInputComponent,
      activityType: AgendaItemActivity,
      miniEditViewType: AgendaItemActivityEditorComponent,
      readonlyViewType: AgendaItemActivityViewComponent
    };
    ActivityFactory.activityMetaData[
      WorkflowService.ACTIVITIES.MultipleParcelSearchActivity
    ] = {
      modelType: Activities.MultipleParcelSearchActivityModel,
      inputViewType: MultipleParcelSearchActivityInputComponent,
      activityType: MultipleParcelSearchActivity,
      miniEditViewType: MultipleParcelSearchActivityEditorComponent,
      readonlyViewType: MultipleParcelSearchActivityViewComponent
    };
    ActivityFactory.activityMetaData[
      WorkflowService.ACTIVITIES.QueryMapLayerActivity
    ] = {
      modelType: QueryMapLayerActivityModel,
      cellModelType: QueryMapLayerActivityCellModel,
      inputViewType: QueryMapLayerActivityInputComponent,
      activityType: Activities.QueryMapLayerActivity,
      miniEditViewType: QueryMapLayerActivityEditorComponent,
      readonlyViewType: QueryMapLayerActivityViewComponent
    };
  }

  static createCellModel(
    activityType: string,
    activity: Activity<ActivityModel>
  ): BaseCellModel {
    if (ActivityFactory.activityMetaData[activityType]) {
      if (ActivityFactory.activityMetaData[activityType].cellModelType) {
        return new ActivityFactory.activityMetaData[activityType].cellModelType(
          activity
        );
      } else {
        return new BaseCellModel(activity);
      }
    }
  }

  static getShape(
    activityType: string
  ): {
    shape: string;
    width: number;
    height: number;
  } {
    return {
      shape: 'rectangle',
      width: 100,
      height: 50
    };
  }

  static getInputComponent(activityType: string): Type<ActivityView> {
    if (ActivityFactory.activityMetaData[activityType]) {
      return ActivityFactory.activityMetaData[activityType].inputViewType;
    }
  }

  static getMiniEditComponent(
    activityType: string
  ): Type<ActivityEditorBaseComponent> {
    if (ActivityFactory.activityMetaData[activityType]) {
      return ActivityFactory.activityMetaData[activityType].miniEditViewType;
    }
  }

  static getEditComponent(
    activityType: string
  ): Type<ActivityEditorBaseComponent> {
    if (ActivityFactory.activityMetaData[activityType]) {
      return ActivityFactory.activityMetaData[activityType].editViewType;
    }
  }

  static getViewComponent(activityType: string): Type<ActivityView> {
    if (ActivityFactory.activityMetaData[activityType]) {
      if (ActivityFactory.activityMetaData[activityType].readonlyViewType) {
        return ActivityFactory.activityMetaData[activityType].readonlyViewType;
      } else {
        return ActivityFactory.activityMetaData[activityType].inputViewType;
      }
    }
  }

  static createActivityByType(
    activityType: string,
    options?: any
  ): Activity<ActivityModel> {
    if (ActivityFactory.activityMetaData[activityType]) {
      return new ActivityFactory.activityMetaData[activityType].activityType(
        ActivityFactory.defaultActivityData[activityType]
      );
    }

    return null;
  }

  static createActivity(
    activity: Activities.Activity<ActivityModel>,
    options?: any,
    force?: boolean
  ): Activity<ActivityModel> {
    if (activity) {
      const activityType = activity.type;
      const isInstance = activity instanceof Activity;
      const activityInstance = activity as Activity<ActivityModel>;
      if (!isInstance || (force || false) === true) {
        if (ActivityFactory.activityMetaData[activityType]) {
          const returnActivity = new ActivityFactory.activityMetaData[
            activityType
          ].activityType(activity);
          return returnActivity;
        }
      } else {
        return activityInstance;
      }
    }
  }
}
