import { versions } from './versions';
import {
  DataService,
  MapServiceProvider,
  DocumentService,
  SecurityService,
  ClientService,
  ContractorService,
  SystemService,
  NotificationService,
  CalendarService,
  TemplateService,
  WorkflowService,
  SearchParcelDataProvider,
  AnnouncementService
} from '../app/services';
import { AuthenticationService } from '../app/services/authentication.service';
import { GlobalErrorHandler } from '../app/services/error';

export const environment = {
  production: true,
  dataServiceClass: DataService,
  securityServiceClass: SecurityService,
  clientServiceClass: ClientService,
  mapServiceClass: MapServiceProvider,
  searchParcelDataClass: SearchParcelDataProvider,
  contractorServiceClass: ContractorService,
  systemServiceClass: SystemService,
  notificationServiceClass: NotificationService,
  documentServiceClass: DocumentService,
  calendarServiceClass: CalendarService,
  templateServiceClass: TemplateService,
  workflowServiceClass: WorkflowService,
  authenticationServiceClass: AuthenticationService,
  globalErrorHandlerClass: GlobalErrorHandler,
  announcementServiceClass: AnnouncementService,
  versions
};
