import { Utilities } from 'src/app/services';

export class ListOption {
  constructor(options: Partial<ListOption>) {
    Object.assign(this, options);
  }
  id: string = Utilities.generateId();
  customListId: string;
  position: number;
  value: string;
  text: string;
  imagePath?: string;
}
