<app-navbar (pixelHeightChanged)="setTopMarginForNavbar($event)"></app-navbar>

<div class="hero-home" [style]="'padding-top: ' + topMargin + 'px;'">
  <div class="container">
    <div class="row">
      <div
        class="text-center col text-light landing-title"
        *ngIf="appConfiguration"
      >
        <h2>{{ appConfiguration.applicationTitle }}</h2>
      </div>
    </div>
    <div class="row">
      <div class="col-sm-8 offset-sm-2">
        <div class="card">
          <div class="card-body">
            <h5 class="card-title">Jurisdiction</h5>
            <wm-client-select [clients]="clients"></wm-client-select>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
