import {
  Injectable,
  Inject,
  forwardRef,
  Output,
  EventEmitter,
  OnDestroy
} from '@angular/core';
import { Observable, Subscription, interval } from 'rxjs';
import { DataService } from './data.service';
import { WorkflowContextService } from '.';
import {
  Announcement,
  AvailableGlobalAnnouncementAudiences,
  RefreshAnnouncementsResponse
} from '../models/announcement';

@Injectable()
export class AnnouncementService implements OnDestroy {
  @Output() newAnnouncementsFound: EventEmitter<
    Announcement[]
  > = new EventEmitter<Announcement[]>();

  mostRecentAnnouncementIds: string[] = [];
  newAnnouncements: Announcement[] = [];
  announcementBannerClosedByUser: boolean = false;
  announcementEngineRunning = false;
  newAnnouncementsSub: Subscription;

  constructor(
    @Inject(forwardRef(() => DataService)) private _dataSvc: DataService,
    @Inject(forwardRef(() => WorkflowContextService))
    private _context: WorkflowContextService
  ) {}

  ngOnDestroy() {
    this.stopAnnouncementEngine();
  }

  getAnnouncementsToManage(
    getGlobalList: boolean,
    clientId?: string
  ): Observable<Announcement[]> {
    return this._dataSvc.getAnnouncementsToManage(getGlobalList, clientId);
  }

  getGlobalAnnouncementAudiences(): Observable<
    AvailableGlobalAnnouncementAudiences
  > {
    return this._dataSvc.getGlobalAnnouncementAudiences();
  }

  saveAnnouncement(announcement: Announcement): Observable<Announcement> {
    return this._dataSvc.saveAnnouncement(announcement);
  }

  deleteAnnouncement(announcementId: string): Observable<boolean> {
    return this._dataSvc.deleteAnnouncement(announcementId);
  }

  getGlobalAnnouncements(): Observable<Announcement[]> {
    return this._dataSvc.getGlobalAnnouncements();
  }

  getAnnouncementsForClient(clientId: string): Observable<Announcement[]> {
    return this._dataSvc.getAnnouncementsForClient(clientId);
  }

  userClosedAnnouncementBanner() {
    this.announcementBannerClosedByUser = true;
  }

  isAnnouncementBannerClosed(): boolean {
    return this.announcementBannerClosedByUser;
  }

  setMostRecentAnnouncementIds(mostRecentAnnouncementIds: string[]) {
    this.mostRecentAnnouncementIds = mostRecentAnnouncementIds;
  }

  newAnnouncementsAvailable() {
    return this.newAnnouncements.some(
      na => !this.mostRecentAnnouncementIds.includes(na.id)
    );
  }

  startAnnouncementEngine() {
    if (!this.announcementEngineRunning) {
      this.announcementEngineRunning = true;

      this.newAnnouncementsSub = interval(120000) // 2 minutes
        .subscribe(() => {
          this._dataSvc
            .checkForNewAnnouncements(
              this.mostRecentAnnouncementIds,
              this._context.client ? this._context.client.id : null
            )
            .subscribe(response => {
              if (response && response.newAnnouncements) {
                this.newAnnouncements = response.newAnnouncements;

                this.newAnnouncementsFound.emit(response.newAnnouncements);
              }
            });
        });
    }
  }

  stopAnnouncementEngine() {
    this.newAnnouncementsSub.unsubscribe();
    this.newAnnouncementsSub = null;

    this.announcementEngineRunning = false;
  }
}
