import { FieldOrientation } from './../../../../../models/activities/print-template-activity';
import { DATA_ENTITIES } from './../../../../../services/mock-data/mock-data-entities';
import { Workflow } from './../../../../../models/workflow';
import { SystemDataEntity } from './../../../../../models/data-entities/system-data-entity';
import { GenerateClientApplicationNumberDataEntity } from './../../../../../models/data-entities/client-app-number-data-entity';
import {
  Component,
  OnInit,
  ElementRef,
  ViewContainerRef,
  ViewChild,
  HostListener,
  Input,
  Inject,
  forwardRef,
  OnDestroy,
  ChangeDetectorRef
} from '@angular/core';
import { ActivityEditorBaseComponent } from '../../activity-editor-base/activity-editor-base.component';
import {
  PrintTemplateActivity,
  TemplatePage,
  TemplateField,
  TemplateFieldType,
  AgendaItemActivity
} from '../../../../../models/activities';
import {
  WorkflowService,
  WorkflowContextService,
  DocumentService,
  Utilities,
  DataEntityFactory,
  WorkflowValidationService
} from '../../../../../services';
import { UploadService } from '../../../../../services/upload/upload.service';
import { Document } from '../../../../../models';
import { fabric } from 'fabric';
import { Observable, of, throwError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { NgbModalRef, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { DataEntity } from 'src/app/models/data-entities';
import { ConditionTarget } from 'src/app/components/system/condition-builder/condition-builder.model';
import { faAlignLeft, faAlignRight } from '@fortawesome/free-solid-svg-icons';
import * as Sentry from '@sentry/browser';
import { ValidationResponse } from 'src/app/models/validation';
import { FormBuilder, UntypedFormControl, Validators } from '@angular/forms';
import { ModalConfirmComponent } from 'src/app/components/system/modal-confirm/modal-confirm.component';
import { ToastrService } from 'ngx-toastr';

export class DataEntityYesNoItem {
  code: string;
  text: string;
  extraData?: any;
  children?: DataEntityYesNoItem[];

  constructor(options?: Partial<DataEntityYesNoItem>) {
    if (options) {
      Object.assign(this, options);
    }
  }
}

export class DataEntityListItem {
  code: string;
  text: string;
  extraData?: any;
  children?: DataEntityListItem[];

  constructor(options?: Partial<DataEntityListItem>) {
    if (options) {
      Object.assign(this, options);
    }
  }
}

export class PdfImageData {
  pageImages: {
    pageWidth: number;
    pageHeight: number;
    imagePath: string;
    scaleFactorX?: number;
    scaleFactorY?: number;
    isLandscape: boolean;
  }[];
}

const { addListener, removeListener } = fabric.util;
const { Canvas } = fabric;

@Component({
  selector: 'wm-print-template-activity-editor',
  templateUrl: './print-template-activity-editor.component.html',
  styleUrls: ['./print-template-activity-editor.component.css']
})
export class PrintTemplateActivityEditorComponent
  extends ActivityEditorBaseComponent
  implements OnInit, OnDestroy {
  @ViewChild('template', { read: ViewContainerRef, static: false })
  template: ViewContainerRef;
  @ViewChild('imgDoc', { static: false }) imgDoc: ElementRef;
  @ViewChild('rotationtModal', { static: true })
  rotationModal: ModalConfirmComponent;
  @ViewChild('invalidTemplate', { static: true })
  invalidTemplateModal: ModalConfirmComponent;
  @Input() showTitle = true;

  // this allows Agenda Item Activity Editor to use the print template editor for it's ballot
  @Input() activity: PrintTemplateActivity | AgendaItemActivity;
  searchText: string;
  isOpen = false;

  faAlignLeft = faAlignLeft;
  faAlignRight = faAlignRight;

  templateHeight: number;
  templateWidth: number;
  entities: DataEntityListItem[];
  dataEntities: DataEntity[];
  filteredEntities: DataEntityListItem[];
  pageIndex = 0;
  templateDocument: Document;
  onFileComplete: any;
  templatePagePath: string;
  templatePages: {
    pageHeight: number;
    pageWidth: number;
    imagePath: string;
    scaleFactorX?: number;
    scaleFactorY?: number;
    isLandscape: boolean;
  }[];
  pageLoading = false;
  canvas: fabric.Canvas;
  canvasContainer: any;
  statusMessage: string;
  fieldItems: any = {};
  selectedFields: TemplateField[];
  formatFields: string[] = ['fontWeight', 'fontSize', 'fontColor', 'emptyText'];
  deFormatFields: { [key: string]: string[] } = {};
  anythingToFormat: boolean;
  isDestroyed = false;
  dragDataEntities: any[] = [];
  handleDragStartListener: any;
  handleDragEndListener: any;
  dropHandler: any;
  keydownHandler: any;
  deleteHandler: any;
  private activeGroupFractionCoords: {
    top: number;
    left: number;
    height: number;
    width: number;
  };

  validationErrors: ValidationResponse[] = [];

  isAgendaItemActivity = false;

  formatData: {
    fontSize: { canEdit: boolean; value: string };
    fontWeight: { canEdit: boolean; value: string };
    fontColor: { canEdit: boolean; value: string };
    emptyText: { canEdit: boolean; value: string };
    criteria: { canEdit: boolean; value: ConditionTarget[] };
    outputFormat: { canEdit: boolean; value: string };
    orientation: { canEdit: boolean; value: string };
    verticalOrientation: { canEdit: boolean; value: string };
  };

  FieldOrientation = FieldOrientation;

  isVertical(orientation): boolean {
    return orientation === FieldOrientation.Vertical;
  }

  get currentPage() {
    return this.activity.model.templateModel.pages[this.pageIndex];
  }
  set currentPage(value: TemplatePage) {
    this.activity.model.templateModel.pages[this.pageIndex] = value;
  }

  get currentTemplate() {
    return this.templatePages && this.templatePages[this.pageIndex];
  }

  get fieldTitle() {
    const selection = this.canvas && this.getActiveObjects();
    const selectionLength = (selection && selection.length) || 0;

    if (selectionLength > 0) {
      return selection
        .map(obj => obj && obj.customData && obj.customData.label)
        .join(', ');
    }

    return 'None Selected';
  }

  constructor(
    @Inject(forwardRef(() => WorkflowService))
    private _workflowSvc: WorkflowService,
    @Inject(forwardRef(() => WorkflowContextService))
    public _context: WorkflowContextService,
    @Inject(forwardRef(() => DocumentService))
    private docService: DocumentService,
    @Inject(forwardRef(() => UploadService))
    public uploadService: UploadService,
    @Inject(forwardRef(() => WorkflowValidationService))
    private _validationSvc: WorkflowValidationService,
    private element: ElementRef,
    private modalService: NgbModal,
    private _ref: ChangeDetectorRef,
    private _toastr: ToastrService
  ) {
    super();

    // custom Textbox for vertical height adjustment
    fabric.ResizableTextbox = fabric.util.createClass(fabric.Textbox, {
      minHeight: 10,
      minWidth: 10,
      type: 'resizeableTextbox',
      fontFamily: 'Times-Roman',
      initialize: function(text, options) {
        this.callSuper('initialize', text, options);
      },

      _dimensionAffectingProps: fabric.Textbox.prototype._dimensionAffectingProps.concat(
        'height'
      ),

      initDimensions: function() {
        const origHeight = this.height;
        this.callSuper('initDimensions');
        // override height to original before it was calculated
        this.height = origHeight;
      },
      getMinHeight: function() {
        return this.minHeight;
      }
    });

    const setObjectScaleOverridden = Canvas.prototype._setObjectScale;

    Canvas.prototype._setObjectScale = function(
      localMouse,
      transform,
      lockScalingX,
      lockScalingY,
      by,
      lockScalingFlip,
      _dim
    ) {
      const t = transform.target;
      if (by === 'x' && t instanceof fabric.ResizableTextbox) {
        const tw = t._getTransformedDimensions().x;
        const w = t.width * (localMouse.x / tw);
        if (w >= t.getMinWidth()) {
          t.set('width', w);
          return true;
        }
      } else if (by === 'y' && t instanceof fabric.ResizableTextbox) {
        const th = t._getTransformedDimensions().y;
        const h = t.height * (localMouse.y / th);
        if (h >= t.getMinHeight()) {
          t.set('height', h);
          return true;
        }
      } else {
        return setObjectScaleOverridden.call(
          Canvas.prototype,
          localMouse,
          transform,
          lockScalingX,
          lockScalingY,
          by,
          lockScalingFlip,
          _dim
        );
      }
    };

    fabric.util.object.extend(
      fabric.ResizableTextbox.prototype,
      /** @lends fabric.Textbox.prototype */ {
        /**
         * @private
         */
        _removeExtraneousStyles: function() {
          for (const prop in this._styleMap) {
            if (!this._textLines[prop]) {
              delete this.styles[this._styleMap[prop].line];
            }
          }
        }
      }
    );
  }

  private resizeTemplateItem(
    fieldInfo: TemplateField,
    obj: fabric.Object
  ): void {
    if (fieldInfo) {
      obj.left = this.documentToScreenX(fieldInfo.x);
      obj.top = this.documentToScreenY(fieldInfo.y);
      obj.width = this.documentToScreenX(fieldInfo.width);
      obj.height = this.documentToScreenY(fieldInfo.height);
      obj.fontSize = this.scaleFontSize(fieldInfo.fontSize);

      if (obj.group) {
        // when in a group, coordinates are based on the bottom right of that group, not based on the canvas........
        obj.left = obj.left - obj.group.left - obj.group.width / 2;
        obj.top = obj.top - obj.group.top - obj.group.height / 2;
      }

      obj.setCoords();
    }
  }

  @HostListener('window:resize', ['$event']) async onResize(event?) {
    if (this.canvasContainer && this.templatePages && this.canvas) {
      this.setCanvasScale();

      const activeObj = this.canvas.getActiveObject();

      if (activeObj && this.activeGroupFractionCoords) {
        // re-size/position the active group based on the cached fractional scale
        activeObj.top = this.activeGroupFractionCoords.top * this.canvas.height;
        activeObj.left =
          this.activeGroupFractionCoords.left * this.canvas.width;
        activeObj.height =
          this.activeGroupFractionCoords.height * this.canvas.height;
        activeObj.width =
          this.activeGroupFractionCoords.width * this.canvas.width;

        activeObj.setCoords();
      }

      // adjust fields on screen to match new scale x, y, width & height (this should not change the document coordinates, only the screen position)
      const objs = this.canvas.getObjects();

      for (const obj of objs) {
        if (obj.customData) {
          const de = await this.getDataEntityByCode(
            obj.customData.code
          ).toPromise();

          const fieldInfo = this.findField(obj.customData.id);

          if (de) {
            de.resizeTemplateItem(fieldInfo, this.buildHelperFunctions(), obj);
          } else {
            this.resizeTemplateItem(fieldInfo, obj);
          }
        }
      }

      this.canvas.renderAll();
    }
  }

  templateUploaded(e: Document) {
    this.activity.model.templateModel.templateDocName = e.name;
    this.activity.model.templateModel.templateDocPath = e.path;
  }

  private findField(id: string): TemplateField {
    return this.currentPage.fields.find(f => f.id === id);
  }

  convertPDF(file: Document): Observable<any> {
    this.statusMessage = 'Converting PDF into images...';
    this.pageLoading = true;
    return this.docService.convertPDFToPageImages(file).pipe(
      map((pdfImageData: any) => {
        if (pdfImageData) {
          if (
            pdfImageData.Message &&
            pdfImageData.Message === 'Applied rotation not supported'
          ) {
            this.clearTemplate(false);
            this.statusMessage = '';
            this.rotationModal.open();
          } else {
            const keys: string[] = Object.keys(pdfImageData).filter(
              k => k !== '$id'
            );
            let pageCount = 0;

            for (let keyIdx = 0; keyIdx < keys.length; keyIdx++) {
              const key = keys[keyIdx];
              const doc = pdfImageData[key];
              let pg: TemplatePage = this.activity.model.templateModel.pages[
                keyIdx
              ];

              if (!pg) {
                pg = new TemplatePage();
                this.activity.model.templateModel.pages.push(pg);
              }

              pg.imagePath = doc.path;
              pg.pageHeight = parseFloat(doc.metaData['pageHeight']);
              pg.pageWidth = parseFloat(doc.metaData['pageWidth']);
              pg.isLandscape =
                (doc.metaData['isLandscape'] || 'false') === 'true';
              pageCount++;
            }

            // remove any additional pages than there are pages in the new doc
            this.activity.model.templateModel.pages.splice(pageCount);

            this.statusMessage = '';
            this.pageIndex = 0;
          }
        } else {
          this.clearTemplate(false);
          this.statusMessage = '';
          this.invalidTemplateModal.open();
        }
      })
    );
  }

  hasChildren(de: DataEntityListItem) {
    return de && de.children && de.children.length > 0;
  }

  async onOpened() {
    // fetching cached validation errors for the whole workflow allows
    // access to data entity validation onOpened() without having to hit Save on
    // the entire activity (just hit Save in the PDF editor modal).
    this.validationErrors = await this._validationSvc.validate(
      this._context.workflow.id
    );

    this.loadCanvasImage();
    this.loadCanvas();
  }

  onClosed() {
    this.isOpen = false;
    this.removeDragListeners();
    this.unBindCanvasContainerHandlers();
    this.dataEntities = null;
    this.filteredEntities = null;
    this.clearTemplatePage();
    if (this.canvas) {
      this.canvas.dispose();
    }
    this.canvas = null;
    this.templatePagePath = null;
    this.formatData = null;
  }

  onSaved() {
    this.saved.emit(this.activity);
  }

  goNextPage() {
    this.pageIndex++;
    this.showPage();
  }

  goPreviousPage() {
    this.pageIndex--;
    this.showPage();
  }

  showPage() {
    if (this.templatePages && this.templatePages.length > this.pageIndex) {
      this.pageLoading = true;
      this.templatePagePath = this.currentTemplate.imagePath;
    }
  }

  canNextPage(): boolean {
    return this.templatePages && this.pageIndex < this.templatePages.length - 1;
  }

  canPreviousPage(): boolean {
    return this.pageIndex > 0;
  }

  searchDataEntities() {
    if (this.searchText) {
      this.filteredEntities = this.entities.filter(de =>
        de.text.toLowerCase().includes(this.searchText.toLowerCase())
      );
    } else {
      this.filteredEntities = Object.assign([], this.entities);
    }

    setTimeout(() => {
      this.initDragListeners();
    }, 200);
  }

  getOffset(el) {
    const rect = el.getBoundingClientRect();

    return {
      top: rect.top + document.body.scrollTop,
      left: rect.left + document.body.scrollLeft
    };
  }

  async clearTemplate(save = true) {
    this.activity.model.reset();

    this.templateDocument = null;
    this.templatePagePath = null;

    const ps: string[] = [
      (this.workflow || this._context.workflow).id,
      this.activity.id,
      'templateImages'
    ];

    this.clearTemplatePage();
    if (save) {
      this.onSaved();
    }
  }

  getDocuments(): Observable<any> {
    if (this.activity) {
      if (this.activity.model.templateModel.templateDocName) {
        this.templateDocument = new Document({
          name: this.activity.model.templateModel.templateDocName,
          path: this.activity.model.templateModel.templateDocPath
        });
      }
      return of(this.templateDocument);
    }
  }

  fileUploaded(e) {
    const imgField = this.buildField({
      x: 50,
      y: 50,
      name: e.name,
      label: e.name,
      fieldType: TemplateFieldType.Image,
      width: 200,
      height: 50,
      criteria: [
        new ConditionTarget({
          isOtherwise: true,
          value: `\"${e.path}\"`
        })
      ]
    });

    this.addFieldToPage(imgField);
    this.addFieldToCanvas(imgField);
  }

  getDocumentImages(): Observable<any> {
    // retrieve the images for the template

    if (this.activity) {
      const pages = this.activity.model.templateModel.pages;
      return of(pages).pipe(
        map(imgs => {
          const keys = Object.keys(imgs).filter(k => k !== '$id');
          this.templatePages = keys.map(k => {
            const pg: TemplatePage = imgs[k];
            return {
              pageHeight: pg.pageHeight,
              pageWidth: pg.pageWidth,
              isLandscape: pg.isLandscape,
              imagePath: pg.imagePath
            };
          });

          this.pageIndex = 0;
          this.showPage();
        })
      );
    }
  }

  serialize(de: DataEntityListItem) {
    return JSON.stringify({
      id: '',
      code: de.code,
      extraData: de.extraData
    });
  }

  watchForNewTemplateUpload() {
    this.onFileComplete = this.docService.fileUploadComplete.subscribe(
      files => {
        if (files.key === this.activity.id) {
          this.convertPDF(files.files[0]).subscribe(() => {
            this.getDocuments().subscribe(() => {
              if (this.isOpen) {
                this.destroyCanvas();
                this.loadCanvasImage();
              }
            });
          });
        }
      }
    );
  }

  ngOnInit() {
    if (this.activity instanceof AgendaItemActivity) {
      this.isAgendaItemActivity = true;
    }

    this.handleDragStartListener = this.handleDragStart.bind(this);
    this.handleDragEndListener = this.handleDragEnd.bind(this);
    this.dropHandler = this.handleDrop.bind(this);
    this.keydownHandler = this.handleKeydown.bind(this);
    this.deleteHandler = this.handleDelete.bind(this);

    this.getDocuments().subscribe(() => {
      this.watchForNewTemplateUpload();
    });

    this.form.addControl(
      'customNameFormula',
      new UntypedFormControl('', [Validators.nullValidator])
    );
  }

  migrateActivity() {
    this.activity.model.templateModel.migrationHTMLTemplate = null;
  }

  unBindCanvasContainerHandlers() {
    // remove the event listeners for the canvas
    if (!this.template || !this.canvasContainer) {
      return;
    }

    this.canvasContainer.removeEventListener('dragenter', this.handleDragEnter);
    this.canvasContainer.removeEventListener('dragover', this.handleDragOver);
    this.canvasContainer.removeEventListener('dragleave', this.handleDragLeave);
    this.canvasContainer.removeEventListener('drop', this.dropHandler);
    this.canvasContainer.removeEventListener('keyup', this.deleteHandler);
    window.removeEventListener('keydown', this.keydownHandler);
  }

  ngOnDestroy(): void {
    this.destroyCanvas();

    if (this.onFileComplete) {
      this.onFileComplete.unsubscribe();
    }

    this.isDestroyed = true;
    if (this._ref) {
      this._ref.detach();
    }
  }

  destroyCanvas() {
    this.removeDragListeners();
    this.unBindCanvasContainerHandlers();
  }

  initDragListeners() {
    this.removeDragListeners();
    const dataEntities = Array.from(document.querySelectorAll('.draggable'));

    for (const de of dataEntities) {
      de.addEventListener('dragstart', this.handleDragStartListener, false);
      de.addEventListener('dragend', this.handleDragEndListener, false);
      this.dragDataEntities.push(de);
    }
  }

  removeDragListeners() {
    for (const de of this.dragDataEntities) {
      de.removeEventListener('dragstart', this.handleDragStartListener);
      de.removeEventListener('dragend', this.handleDragEndListener);
    }

    this.dragDataEntities = [];
  }

  /**
   * Cache the active group's position/size as a fractional value so that it can be resized
   */
  private calculateActiveGroupCoords(): void {
    if (!this.canvas) {
      return;
    }

    const group = this.canvas.getActiveObject();

    if (!group) {
      return;
    }

    this.activeGroupFractionCoords = {
      top: group.top / this.canvas.height,
      left: group.left / this.canvas.width,
      height: group.height / this.canvas.height,
      width: group.width / this.canvas.width
    };
  }
  loadCanvasImage() {
    this.getDocumentImages().subscribe();
  }

  loadCanvas() {
    this.canvas = new Canvas('c', { fireRightClick: true });

    this.canvas.on('object:modified', this.dragMoveListener.bind(this));

    this.canvas.on('selection:created', e => {
      this.calculateActiveGroupCoords();
      this.editFieldStyle();
    });
    this.canvas.on('selection:updated', e => {
      this.calculateActiveGroupCoords();
      this.editFieldStyle();
    });
    this.canvas.on('selection:cleared', e => {
      this.activeGroupFractionCoords = null;
      this.formatData = null;
    });

    this._workflowSvc
      .getDataEntitiesBeforeMe(
        this.workflow || this._context.workflow,
        this.activity,
        [
          WorkflowService.DATA_ENTITIES.Fee.code,
          WorkflowService.DATA_ENTITIES.NumericData.code,
          WorkflowService.DATA_ENTITIES.FreeFormText.code,
          WorkflowService.DATA_ENTITIES.DisplayEntityValue.code,
          WorkflowService.DATA_ENTITIES.Date.code,
          WorkflowService.DATA_ENTITIES.MapSketch.code,
          WorkflowService.DATA_ENTITIES.ListData.code,
          WorkflowService.DATA_ENTITIES.Signature.code,
          WorkflowService.DATA_ENTITIES.CalculatedValue.code,
          WorkflowService.DATA_ENTITIES.Today.code,
          WorkflowService.DATA_ENTITIES.GenerateClientApplicationNumber.code,
          WorkflowService.DATA_ENTITIES.SystemData.code,
          WorkflowService.DATA_ENTITIES.QR.code,
          WorkflowService.DATA_ENTITIES.ParcelNotes.code
        ]
      )
      .subscribe(entities => {
        this.dataEntities = entities;
        this.entities = [];

        // have each data entity build the list of values appropriate for dragging onto the template
        for (const entity of entities) {
          const itemList = entity.getPrintTemplateItems();

          this.deFormatFields[entity.dataEntityTypeCode] =
            entity.availablePrintFormatOptions;

          const specialAvailableOptions = entity.getSpecialAvailablePrintFormatOptions();

          if (specialAvailableOptions) {
            for (const sao in specialAvailableOptions) {
              this.deFormatFields[sao] = specialAvailableOptions[sao];
            }
          }

          if (itemList && itemList.length > 0) {
            this.entities = this.entities.concat(itemList);
          }
        }

        this.filteredEntities = Object.assign([], this.entities);

        // Bind the event listeners for the image elements
        window.setTimeout(() => {
          this.initDragListeners();

          // Bind the event listeners for the canvas
          this.canvasContainer =
            this.template && this.template.element.nativeElement;
          if (!this.canvasContainer) {
            return;
          }

          this.canvasContainer.tabIndex = 1000;
          this.canvasContainer.addEventListener(
            'dragenter',
            this.handleDragEnter,
            false
          );
          this.canvasContainer.addEventListener(
            'dragover',
            this.handleDragOver,
            false
          );
          this.canvasContainer.addEventListener(
            'dragleave',
            this.handleDragLeave,
            false
          );
          this.canvasContainer.addEventListener(
            'drop',
            this.dropHandler,
            false
          );
          this.canvasContainer.addEventListener(
            'keyup',
            this.deleteHandler,
            false
          );
          window.addEventListener('keydown', this.keydownHandler, false);
        }, 200);

        this.isOpen = true;
      });
  }

  getFormatValue(
    formatValue: { canEdit: boolean; value: string },
    defaultValue: string
  ): string {
    return formatValue && formatValue.canEdit
      ? formatValue.value
      : defaultValue;
  }

  getAvailableFormatFields(deTypeCode: string, fieldName: string): string[] {
    // Get available format fields based on the data entity types selected
    return (
      this.deFormatFields[fieldName.toUpperCase()] ||
      this.deFormatFields[deTypeCode]
    );
  }

  getDataEntityByCode(code: string): Observable<DataEntity> {
    return of(
      this.dataEntities.find(
        de =>
          de &&
          de.templateCode &&
          de.templateCode.toLowerCase() === code.toLowerCase()
      )
    );
  }

  async applyFormat() {
    if (!this.formatData || !this.selectedFields) {
      return;
    }

    const formatFields = Object.keys(this.formatData);

    // apply format data to all selected items where they can be edited
    for (const field of this.selectedFields) {
      const obj = this.getObject(field.id);
      if (!obj) {
        continue;
      }

      let de: DataEntity = null;

      if (field.fieldType === TemplateFieldType.DataEntity) {
        de = await this.getDataEntityByCode(field.name).toPromise();
      } else {
        de = DataEntityFactory.createDataEntity('system-data-entity');
      }

      if (de) {
        de.updateTemplateItem(field, obj);
      }

      for (const formatField of formatFields) {
        const formatValue = this.getFormatValue(
          this.formatData[formatField],
          field[formatField]
        );

        if (!formatValue) {
          continue;
        }

        if (typeof formatValue === 'string') {
          field[formatField] = formatValue;
        }

        // update the field value if it is criteria
        if (formatField === 'criteria') {
          field.criteria = <any>formatValue;
        }

        // update the object with the new format fields
        if (formatField === 'fontColor') {
          obj.stroke = `rgb(${field[formatField]})`;
        } else if (formatField === 'fontSize' && de) {
          de.resizeTemplateItem(field, this.buildHelperFunctions(), obj);
        } else if (formatField !== 'emptyText' && formatField !== 'criteria') {
          obj[formatField] = field[formatField];
        }
      }
    }

    this.canvas.renderAll();
  }

  getObject(id: string): fabric.Object {
    return this.canvas.getObjects().find(f => f.customData.id === id);
  }

  setEntityValidation(templateCode: string): boolean {
    // Due to the modal save event not caching validation errors in a way that they can be found through
    // validateActivity(), iterating through the workflow validation errors casts a net wide enough to
    // find an activity's data entity errors from their template codes
    for (let i = 0; i < this.validationErrors.length; i++) {
      if (
        this.validationErrors[i].id === this.activity.id ||
        this.validationErrors[i].activityId === this.activity.id
      ) {
        const deErrors = this.validationErrors[i].messages.filter(m =>
          m.message.startsWith(`(${templateCode.toLowerCase()}`)
        );
        if (deErrors.length > 0) {
          return false;
        }
      }
    }
    return true;
  }

  async getEntity(customData: {
    id: string;
    code: string;
  }): Promise<DataEntity> {
    let de = this.dataEntities.find(
      e => e.templateCode.toLowerCase() === customData.code.toLowerCase()
    );

    if (!de) {
      de = DataEntityFactory.createDataEntity('system-data-entity', {
        templateCode: customData.code.toLowerCase(),
        isValid: false
      });
    } else {
      de.isValid = this.setEntityValidation(de.templateCode);
    }

    return of(de).toPromise();
  }

  async addSelectedField(cell) {
    const fields: TemplateField[] = this.selectedFields || [];
    let formatFields: string[] = [];

    const field: TemplateField = this.findField(cell.customData.id);

    let selectedEntity: DataEntity;

    if (field && field.fieldType === TemplateFieldType.DataEntity) {
      selectedEntity = await this.getEntity(cell.customData);

      formatFields = this.getAvailableFormatFields(
        selectedEntity.dataEntityTypeCode,
        field.label
      );
    } else {
      formatFields.push('criteria');
    }

    fields.push(field);

    const formatData = this.formatData || {
      fontColor: null,
      fontSize: null,
      fontWeight: null,
      emptyText: null,
      criteria: null,
      outputFormat: null,
      orientation: null,
      verticalOrientation: null
    };

    for (let formatIdx = 0; formatIdx < formatFields.length; formatIdx++) {
      const formatName = formatFields[formatIdx];
      const fieldFormatValue = field[formatName];

      const canEdit: boolean = formatName !== 'criteria' || fields.length === 1;

      formatData[formatName] = {
        canEdit: canEdit,
        value: fieldFormatValue
      };
    }

    this.formatData = formatData;
    this.setAnythingToFormat();
    this.selectedFields = fields;
  }

  getActiveObjects() {
    return this.canvas.getActiveObjects();
  }

  alignControlsToRight() {
    const activeObjects = this.getActiveObjects();

    if (activeObjects.length > 0) {
      let highestX: number = Number.MIN_SAFE_INTEGER;
      let highestXObj: fabric.Object;

      for (const obj of activeObjects) {
        if (obj.left > highestX) {
          highestX = obj.left;
          highestXObj = obj;
        }
      }

      const rightX: number = highestXObj.left + highestXObj.width;

      for (const obj of activeObjects) {
        obj.left = rightX - obj.width;

        this.dragMoveListener({
          target: obj
        });
      }

      this.canvas.renderAll();
    }
  }

  alignControlsToLeft() {
    const activeObjects = this.getActiveObjects();

    if (activeObjects.length > 0) {
      let x: number = Number.MAX_VALUE;

      for (const obj of activeObjects) {
        if (obj.left < x) {
          x = obj.left;
        }
      }

      for (const obj of activeObjects) {
        obj.left = x;

        this.dragMoveListener({
          target: obj
        });
      }

      this.canvas.renderAll();
    }
  }

  editFieldStyle() {
    this.selectedFields = [];
    this.formatData = {
      fontColor: null,
      fontSize: null,
      fontWeight: null,
      emptyText: null,
      criteria: null,
      outputFormat: null,
      orientation: null,
      verticalOrientation: null
    };
    this.setAnythingToFormat();

    setTimeout(() => {
      if (!this.canvas) {
        return;
      }

      const selectedFields: any[] = this.canvas.getActiveObjects();

      if (selectedFields) {
        for (const selectedField of selectedFields) {
          this.addSelectedField(selectedField);
        }

        setTimeout(() => {
          this._ref.detectChanges();
        }, 1000);
      }
    }, 0);
  }

  setAnythingToFormat() {
    const formatFields = Object.keys(this.formatData);
    const populatedFields = formatFields.filter(f => this.formatData[f]);
    this.anythingToFormat = (populatedFields || []).length > 0;
  }

  private getImageSize() {
    if (!this.imgDoc || !this.imgDoc.nativeElement) {
      return null;
    }

    const size = this.imgDoc.nativeElement.getBoundingClientRect();

    return {
      height: size.height,
      width: size.width
    };
  }

  private setCanvasScale() {
    const imgSize = this.getImageSize();
    if (!imgSize || !this.canvas) {
      return;
    }

    this.templateHeight = imgSize.height;

    if (this.currentTemplate) {
      // x scale
      this.currentTemplate.scaleFactorX =
        this.currentTemplate.pageWidth / imgSize.width;
      // y scale
      this.currentTemplate.scaleFactorY =
        this.currentTemplate.pageHeight / imgSize.height;
    }

    this.canvas.setDimensions({
      width: imgSize.width,
      height: imgSize.height
    });
  }

  async pageImageLoaded() {
    this.pageLoading = false;

    this.setCanvasScale();

    if (!this.canvas) {
      return;
    }

    this.clearTemplatePage();

    await this.initTemplatePage();

    // for some reason, onResize needs to be called twice, with the second time in a setTimeout of 0
    // otherwise, the canvas size will be slightly off
    await this.onResize();

    // I really do not like this...
    setTimeout(() => {
      this.onResize();
    }, 0);
  }

  screenToDocumentX(screenValue: number): number {
    if (this.currentTemplate) {
      return screenValue * this.currentTemplate.scaleFactorX;
    } else {
      return 0;
    }
  }

  screenToDocumentY(screenValue: number): number {
    if (this.currentTemplate) {
      return screenValue * this.currentTemplate.scaleFactorY;
    } else {
      return 0;
    }
  }

  documentToScreenX(documentValue: number): number {
    return documentValue / this.currentTemplate.scaleFactorX;
  }

  documentToScreenY(documentValue: number): number {
    return documentValue / this.currentTemplate.scaleFactorY;
  }

  scaleFontSize(documentValue: any): number {
    const fontSize = parseFloat(documentValue) || 12;
    return fontSize / this.currentTemplate.scaleFactorX;
  }

  async addFieldToCanvas(fieldInfo: TemplateField) {
    let obj: fabric.Object;

    if (!fieldInfo) {
      return;
    }

    if (fieldInfo.fieldType === TemplateFieldType.DataEntity) {
      let isValid: boolean = fieldInfo.isValid;

      let de: DataEntity = await this.getDataEntityByCode(
        fieldInfo.name
      ).toPromise();

      if (!de) {
        de = DataEntityFactory.createDataEntity('system-data-entity', {
          templateCode: fieldInfo.name.toUpperCase(),
          isValid: isValid
        });
      } else {
        de.isValid = isValid; // this.setEntityValidation(de.templateCode);
      }

      if (de) {
        if (!fieldInfo.criteria) {
          fieldInfo.criteria = [
            new ConditionTarget({
              isOtherwise: true,
              value: `\$\{${fieldInfo.name.toUpperCase()}\}`
            })
          ];
        }

        obj = await de.formatTemplateItem(
          fieldInfo,
          this.buildHelperFunctions()
        );

        if (
          !de.isValid &&
          de.dataEntityTypeCode === WorkflowService.DATA_ENTITIES.MapSketch.code
        ) {
          this._toastr.info(
            ` ${de.activityLabel} orientation was updated; please confirm the placement. <br /> <u>click to close</u> `,
            'Orientation Changed',
            { disableTimeOut: true, enableHtml: true }
          );
        }

        if (obj) {
          obj.customData = {
            label: de.buildTemplateLabel(fieldInfo),
            id: fieldInfo.id,
            code: fieldInfo.name
          };
        }
      }
    } else {
      obj = new fabric.ResizableTextbox(fieldInfo.name, {
        width: this.documentToScreenX(fieldInfo.width),
        height: this.documentToScreenY(fieldInfo.height),
        left: this.documentToScreenX(fieldInfo.x),
        top: this.documentToScreenY(fieldInfo.y),
        fontSize: this.scaleFontSize(fieldInfo.fontSize),
        stroke: `rgb(${fieldInfo.fontColor || '0, 0, 0'})`,
        editable: false,
        backgroundColor: fieldInfo.isValid == false ? 'red' : 'rgba(255, 0, 0, .25)',
        customData: {
          label: fieldInfo.name,
          id: fieldInfo.id,
          code: fieldInfo.name
        }
      });
    }

    if (obj) {
      obj.hasRotatingPoint = false;
      this.canvas.add(obj);
    }
    this.canvas.renderAll();
  }
  buildHelperFunctions(): any {
    return {
      screenToDocumentX: this.screenToDocumentX.bind(this),
      screenToDocumentY: this.screenToDocumentY.bind(this),
      documentToScreenX: this.documentToScreenX.bind(this),
      documentToScreenY: this.documentToScreenY.bind(this),
      scaleFontSize: this.scaleFontSize.bind(this)
    };
  }

  async initTemplatePage() {
    if (!this.currentPage) {
      this.currentPage = new TemplatePage();
    }

    this.currentPage.index = this.pageIndex;
    this.currentPage.isLandscape = this.templatePages[
      this.pageIndex
    ].isLandscape;

    const fields: TemplateField[] = this.currentPage.fields || [];

    await Promise.all(
      fields.map(async field => {
        await this.addFieldToCanvas(field);
      })
    );

    this.canvas.renderAll();
  }

  clearTemplatePage() {
    if (this.canvas) {
      this.canvas.clear();
      this.canvas.renderAll();
    }
  }

  resetTemplatePage() {
    this.currentPage.fields = [];
    this.clearTemplatePage();
  }

  handleDragOver(e) {
    e.preventDefault();
    e.dataTransfer.dropEffect = 'move';
  }

  handleDragEnter(e) {
    // this / e.target is the current hover target.
    e.target.classList.add('over');
  }

  handleDragLeave(e) {
    e.target.classList.remove('over'); // this / e.target is previous target element.
  }

  handleDragStart(e) {
    if (e && e.target && e.target.getAttribute) {
      e.dataTransfer.setData('text/plain', e.target.getAttribute('data-value'));
      e.dataTransfer.dropEffect = 'copy';
    }
  }

  handleDrop(e) {
    // this / e.target is current target element.
    if (e.preventDefault) {
      e.preventDefault();
    }
    if (e.stopPropagation) {
      e.stopPropagation();
    }

    const data = e.dataTransfer.getData('text/plain');
    if (!data) {
      return;
    }

    let fieldInfo: any;
    try {
      fieldInfo = JSON.parse(data);
    } catch (err) {
      return;
    }

    // set fieldInfo x,y,width,height
    const offset = this.getOffset(this.canvasContainer);

    const fieldExtra = fieldInfo.extraData
      ? `${fieldInfo.extraData.value || fieldInfo.extraData.toString()}`
      : '';

    const fieldLabel = (
      fieldInfo.code + (fieldExtra ? '.' + fieldExtra : '')
    ).toUpperCase();

    const fieldObj = this.buildField({
      x: e.clientX - offset.left + this.canvasContainer.scrollLeft,
      y: e.clientY - offset.top + this.canvasContainer.scrollTop,
      label: fieldLabel,
      name: fieldInfo.code.toLowerCase(),
      extraData: fieldExtra
    });

    this.addFieldToPage(fieldObj);
    this.addFieldToCanvas(fieldObj);
    return false;
  }

  buildField(field: Partial<TemplateField>): TemplateField {
    if (field) {
      const fieldObj: TemplateField = {
        x: this.screenToDocumentX(field.x || 0),
        y: this.screenToDocumentY(field.y || 0),
        width: field.width,
        height: field.height,
        name: field.name.toLowerCase(),
        label: field.label,
        id: field.id || Utilities.generateId(),
        extraData: field.extraData,
        fontColor: field.fontColor,
        fontSize: field.fontSize,
        fontWeight: field.fontWeight,
        criteria: field.criteria,
        fieldType: field.fieldType || TemplateFieldType.DataEntity,
        outputFormat: field.outputFormat,
        orientation: field.orientation,
        verticalOrientation: field.verticalOrientation
      };

      return fieldObj;
    }
  }

  addFieldToPage(fieldInfo: TemplateField) {
    if (!this.currentPage.fields) {
      this.currentPage.fields = [];
    }

    this.currentPage.fields.push(fieldInfo);
  }

  handleDragEnd(e) {
    if (e.stopPropagation) {
      e.stopPropagation(); // stops the browser from redirecting.
    }
    // this/e.target is the source node.
    if (e && e.target) {
      e.target.classList.remove('dragging');
    }
  }

  private removeField(item) {
    const objectData = item.customData;

    // remove the field from the page.
    this.currentPage.fields = this.currentPage.fields.filter(
      f => f.id !== objectData.id
    );

    // remove the field from the canvas.
    this.canvas.remove(item);
    this.canvas.renderAll();
  }

  private moveObject(target, event?): void {
    this.dragMoveListener({
      scaleX: target.scaleX,
      scaleY: target.scaleY,
      target: target
    });
    this.canvas.renderAll();

    if (event) {
      event.preventDefault();
    }
  }

  private handleDelete(event) {
    if (event.keyCode === 46) {
      // delete
      const items = this.canvas.getActiveObjects();
      if (!items) {
        return;
      }

      for (const item of items) {
        this.removeField(item);
      }
    }

    return true;
  }

  private handleKeydown(event) {
    const target = this.canvas.getActiveObject();
    const template = document.getElementById('template');

    if (target && document.activeElement === template) {
      if (event.keyCode === 40) {
        // arrow down
        target.top += 1; // move target down 5 pixels
        this.moveObject(target, event);
      } else if (event.keyCode === 38) {
        // arrow up
        target.top -= 1; // move target up 5 pixels
        this.moveObject(target, event);
      } else if (event.keyCode === 37) {
        // arrow left
        target.left -= 1; // move target left 5 pixels
        this.moveObject(target, event);
      } else if (event.keyCode === 39) {
        // arrow right
        target.left += 1; // move target right 5 pixels
        this.moveObject(target, event);
      }
    }

    return true;
  }

  private dragMoveListener(options) {
    let targets: any[];
    let baseLeft: number;
    let baseTop: number;

    if (options.target.customData) {
      targets = [options.target];
      baseLeft = 0;
      baseTop = 0;
    } else {
      targets = options.target.getObjects();
      baseLeft = options.target.left;
      baseTop = options.target.top;
    }

    for (const target of targets) {
      // scale up the dimensions only then reset scale factor back to 1
      target.width = target.width * target.scaleX;
      target.height = target.height * target.scaleY;
      target.scaleY = 1;
      target.scaleX = 1;

      const x: number = target.group
        ? target.group.left + target.group.width / 2 + target.left // origin of group is set to bottom right https://stackoverflow.com/questions/29829475/how-to-get-the-canvas-relative-position-of-an-object-that-is-in-a-group
        : target.left;
      const y: number = target.group
        ? target.group.top + target.group.height / 2 + target.top
        : target.top;
      const height: number = target.height;
      const width: number = target.group
        ? target.width * target.group.scaleX
        : target.width;

      if (target.customData && target.customData.id) {
        const fieldObj = this.findField(target.customData.id);

        if (fieldObj) {
          fieldObj.x = this.screenToDocumentX(x);
          fieldObj.y = this.screenToDocumentY(y);
          fieldObj.width = this.screenToDocumentX(width);
          fieldObj.height = this.screenToDocumentY(height);
        }
      }
    }

    this.calculateActiveGroupCoords();
    this.canvas.renderAll();
  }

  detectChanges() {
    if (!this.isDestroyed) {
      this._ref.detectChanges();
    }
  }

  updateCriteria(criteria: ConditionTarget[]) {
    this.formatData.criteria.value = criteria;
    this.detectChanges();
    this.applyFormat();
  }

  // Use => in order to force `this` into being the FeeDataEntityEditorComponent
  getThenLabel = (clause: ConditionTarget): string => {
    const value = clause.value;

    return value.replace('${', '<span class="de">').replace('}', '</span>');
  };

  updateCustomNameCriteria(criteria: ConditionTarget[]) {
    this.activity.model.customNameCriteria = criteria;
    let hasValue = false;

    if (criteria && criteria.length > 0) {
      criteria.forEach((c, index) => {
        if ((c.value || '') !== '') {
          hasValue = true;
          return false;
        }
      });
    }
    this.form.controls['customNameFormula'].setValue(hasValue ? 'done' : '');
    this.detectChanges();
  }
}
