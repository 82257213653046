import { Component, OnInit } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { CustomList, CustomListItem } from 'src/app/models/custom-list';
import { SystemService, Utilities } from 'src/app/services';

@Component({
  selector: 'wm-global-custom-list-editor',
  templateUrl: './global-custom-list-editor.component.html',
  styleUrl: './global-custom-list-editor.component.css'
})
export class GlobalCustomListEditorComponent implements OnInit {
  customListId: string;
  customList: CustomList;
  form: UntypedFormGroup;

  constructor(
    private _systemSvc: SystemService,
    private _router: Router,
    private _toastr: ToastrService,
    private _route: ActivatedRoute
  ) {}

  ngOnInit() {
    this.form = new UntypedFormGroup({
      name: new UntypedFormControl(null, [Validators.required])
    });

    this._route.params.subscribe(params => {
      this.customListId = params['id'];

      if (this.customListId) {
        this._systemSvc.getGlobalCustomList(this.customListId).subscribe(list => {
          if (list) {
            this.customList = list;
          }
        });
      } else {
        this.customList = {
          id: Utilities.EMPTY_GUID,
          name: null,
          clientId: null,
          items: [],
          roleId: null
        };
      }
    });
  }

  remove() {
    this._systemSvc.deleteGlobalCustomList(this.customListId).subscribe(res => {
      this._toastr.success('Removed!');
      this.goBack();
    });
  }

  removeItem(listItem) {
    this._systemSvc
      .deleteGlobalCustomListItem(listItem.id)
      .subscribe(res => {
        this._toastr.success('Item removed');
      });
  }

  save() {
    for (const item of this.customList.items) {
      item.customListId = this.customList.id;
    }

    const list: CustomList = {
      id: this.customList.id,
      clientId: null,
      name: this.customList.name,
      items: this.customList.items.map(
        (item): CustomListItem => ({
          id: item.id,
          customListId: this.customList.id,
          position: item.position,
          text: item.text,
          value: item.value
        })
      ),
      roleId: null
    };

    this._systemSvc.saveGlobalCustomList(list).subscribe(res => {
      this._toastr.success('Saved!');
      this.goBack();
    });
  }

  goBack() {
    this._router.navigate([
      '/admin/global/custom-lists'
    ]);
  }

  canRemove() {
    return this.customList.id !== Utilities.EMPTY_GUID;
  }
}
