<div class="container">
  <div class="row justify-content-center align-items-center pt-5">
    <div class="col-6">
      <div class="card">
        <div class="card-body">
          <h6 class="card-title">
            Auto Reset Tester
          </h6>
          <div class="form-group">
            <label class="label">Jurisdiction</label>
            <select class="form-control" [ngModel]="selectedJurisdictionId" (ngModelChange)="jurisdictionChanged($event)">
              <option *ngFor="let j of jurisdictions" [ngValue]="j.id">{{j.name}}</option>
            </select>
          </div>
          <div class="form-group">
            <label class="label">Workflow</label>
            <div *ngIf="!workflows && loadingWorkflows">Loading Workflows...</div>
            <div *ngIf="!workflows && !selectedJurisdictionId">Select Jurisdiction</div>
            <select class="form-control" [ngModel]="selectedWorkflowId" (ngModelChange)="workflowChanged($event)" *ngIf="workflows">
              <option *ngFor="let w of workflows" [ngValue]="w.id">{{w.version.name}}</option>
            </select>
          </div>
          <div *ngIf="selectedWorkflowId && !selectedWorkflow">Loading Workflow Details...</div>
          <div class="form-group" *ngIf="selectedWorkflow">
            <div>Current Number: {{selectedWorkflow.clientApplicationNumber}}</div>
            <div>Month: {{selectedWorkflow.resetClientAppNumberMonth}}</div>
            <div>Day: {{selectedWorkflow.resetClientAppNumberDay}}</div>
            <div>Next Date: {{selectedWorkflow.nextClientAppNumberResetDateText}}</div>
          </div>
          <div class="form-group">
            <label class="label">As if today was Date/Time</label>
            <input
              type="datetime"
              [(ngModel)]="asOfDate"
              class="form-control"
            />
          </div>
          <button
            class="btn btn-raised btn-primary btn-justified"
            (click)="getNextNumber()"
            [disabled]="testRunning || !selectedJurisdictionId || !selectedWorkflowId || !asOfDate"
          >
            Test
          </button>
          <div *ngIf="testRunning">
            Calculating Number as of {{asOfDate}}
            <br />
            <app-loader></app-loader>
          </div>

          <div *ngIf="nextNumber && !testRunning">
            <div class="form-group">
              <div class="label">Next Number</div>
              <div>{{ nextNumber }}</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
