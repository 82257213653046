import { GenerateClientApplicationNumberDataEntity } from './../../models/data-entities/client-app-number-data-entity';
import { GenerateApplicationNumberActivity } from './../../models/activities/generate-application-number-activity';
import { Component, OnInit } from '@angular/core';
import { Client, Workflow } from 'src/app/models';
import { Activity, ActivityModel } from 'src/app/models/activities';
import { DataEntity } from 'src/app/models/data-entities';
import { DataService, WorkflowService } from 'src/app/services';

@Component({
  selector: 'wm-auto-reset-tester',
  templateUrl: './auto-reset-tester.component.html',
  styleUrl: './auto-reset-tester.component.css'
})
export class AutoResetTesterComponent implements OnInit {
  jurisdictions: Client[];
  workflows: Workflow[];

  selectedJurisdictionId: string;
  selectedWorkflowId: string;
  selectedWorkflow: Workflow;

  asOfDate: string;
  nextNumber: string;
  testRunning: boolean;
  loadingWorkflows: boolean;

  constructor(private _dataSvc: DataService) {}

  jurisdictionChanged(jurisdictionId: string) {
    this.selectedJurisdictionId = jurisdictionId;
    this.workflows = null;

    this.loadingWorkflows = true;
    this._dataSvc
      .getWorkflows(new Client({ id: this.selectedJurisdictionId }), false)
      .subscribe(workflows => {
        if (workflows) {
          this.workflows = workflows.filter(
            f => f.resetClientAppNumberMonth && f.resetClientAppNumberDay
          );
          this.loadingWorkflows = false;
        }
      });
  }

  workflowChanged(workflowId: string) {
    this.selectedWorkflowId = workflowId;
    this.selectedWorkflow = null;
    this._dataSvc.getWorkflow(workflowId).subscribe(workflow => {
      this.selectedWorkflow = workflow;
    });
  }

  getNextNumber(activityId?: string, templateCode?: string) {
    this.testRunning = true;
    this._dataSvc
      .testAutoReset({
        workflowId: this.selectedWorkflowId,
        asOfDate: this.asOfDate
      })
      .subscribe(result => {
        this.nextNumber = result.nextNumber;
        this.testRunning = false;
      });
  }

  ngOnInit(): void {
    this._dataSvc.getClients().subscribe(clients => {
      this.jurisdictions = clients;
    });
  }
}
