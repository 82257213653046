<span>
    Select previous contractor registration field(s)
    <wm-help-text
        placement="right"
        helpText="The previous contractor registration could have been created using the Contractor Register Workflow, or this Contractor Renewal Workflow.  Select a field from either or both of the workflows to carry over to this Data Entity in a renewal application."
    ></wm-help-text>
</span>

<br />

<div
    class="mt-4"
    *ngFor="let option of sourceConfig.options; let i = index"
>
    <ng-container *ngIf="option.contractorWorkflowType === WorkflowType.ContractorRegistration">
        <b>{{ option.contractorTypeName }} Register Workflow</b>
        <div class="mt-1">
            <wm-data-entity-autocomplete
                id="'registrationExternalDataSource-' + option.sourceWorkflowId"
                [showFormulaEditor]="false"
                [clearOnSelect]="true"
                [form]="form"
                [model]="registerWorkflowTemplateCode"
                [required]="false"
                [workflowId]="option.sourceWorkflowId"
                [workflowVersionId]="option.sourceWorkflowDraftVersionId"
                [types]=""
                [activityTypes]="contractorWorkflowForms"
                [excludedTemplateCodes]="defaultContractorFields"
                (valueUpdate)="changeRegisterWorkflowTemplateCode($event)"
            ></wm-data-entity-autocomplete>
        </div>
    </ng-container>

    <div [formGroup]="form" *ngIf="option.contractorWorkflowType === WorkflowType.ContractorRenewal">
        <span>
            <b>This workflow</b>&nbsp;
            <wm-help-text
                placement="right"
                helpText="Values from this Data Entity on this Contractor Renewal Workflow can be carried over to subsequent Contractor Renewal Workflow applications."
            ></wm-help-text>
        </span>

        <select
            class="form-control"
            formControlName="mapFromThisWorkflow"
            (change)="handleChanges()"
            [(ngModel)]="sourceConfig.selectedMappings.mapFromThisWorkflow"
        >
            <option [ngValue]="false">(Do not map to this workflow)</option>
            <option [ngValue]="true">{{ entity.templateCode }}</option>
        </select>
    </div>
</div>

<wm-control-message
    [control]="form.controls['contractorRegistrationExternalDataField']"
></wm-control-message>
