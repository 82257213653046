import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'wm-user-contractor-registration-details',
  templateUrl: './user-contractor-registration-details.component.html',
  styleUrls: ['./user-contractor-registration-details.component.css']
})
export class UserContractorRegistrationDetailsComponent implements OnInit {
  registrationId: string;
  topMargin: number;

  constructor(private _route: ActivatedRoute) {}

  ngOnInit() {
    this._route.params.subscribe(parms => {
      this.registrationId = parms.registrationId;
    });
  }

  setTopMarginForNavbar(pixelHeight: number) {
    this.topMargin = pixelHeight;
  }
}
