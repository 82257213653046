<form [formGroup]="form" *ngIf="entity">
  <br />
  <wm-de-label [entity]="entity" [for]="entity.templateCode"></wm-de-label>
  <div>
    <div class="row">
      <div class="col">
        <wm-upload
          [previouslyUploadedFiles]="previouslyExistingDocuments"
          [validDocumentTypes]="documentEntity.validDocumentTypes"
          [pathSegments]="[
            this.applicationId,
            this.parentEntityId,
            this.documentTemplateCode
          ]"
          (fileUploaded)="fileUploaded($event)"
          (fileDeleted)="documentDeleted($event)"
          [key]="entity.templateCode"
          [isPreview]="isPreview"
          category="Application"
          [showRemove]="showRemove"
          [deleteStoredDocument]="deleteStoredDocument"
          deleteDocumentMessage="The document will only be deleted once Save, Next or Update is clicked."
        ></wm-upload>
      </div>
    </div>
  </div>
</form>
