<wm-modal-confirm
  #BulkActions
  class="mr-2"
  commandText="Submit"
  buttonText=""
  [showButtonText]="true"
  title="Bulk Actions"
  btnColor="primary"
  btnIcon="content_copy"
  (opened)="onOpen()"
  (accepted)="onSave()"
  (canceled)="onCancel()"
  [showCancelButton]="false"
  [showCommandButton]="false"
  [form]="activityForm"
  [iconStyle]="{ fontSize: '19px', 'vertical-align': 'middle' }"
  [enabled]="!saving"
>
  <ng-container *ngIf="opened">
    <div [formGroup]="moveForm">
      <div class="form-group">
        <div
          class="custom-control custom-radio"
          *ngFor="let action of bulkActions"
        >
          <input
            formControlName="bulkAction"
            name="bulkAction"
            type="radio"
            [value]="action"
            [id]="action.id"
            class="custom-control-input"
          />
          <label
            class="custom-control-label"
            [for]="action.id"
            style="padding-top:4px;"
          >
            {{ action.name }}
          </label>
        </div>
      </div>
    </div>

    <div *ngFor="let de of dataEntities">
      <div class="form-group">
        <div class="row" *ngIf="de && de.entity && !de.entity.isSystemEntity">
          <div class="col">
            <div class="custom-control custom-checkbox">
              <input
                type="checkbox"
                [value]="de.entity.templateCode"
                [id]="'chkBulk_' + de.entity.templateCode"
                class="custom-control-input"
                (change)="toggleEntity($event, de)"
                [(ngModel)]="selectedDEs[de.entity.templateCode].selected"
              />
              <label
                class="custom-control-label"
                [for]="'chkBulk_' + de.entity.templateCode"
              >
                {{ de.entity.label }}</label
              >
              <br />
              <div class="mr-2" style="padding-left:24px;">
                {{ de.entity.dataEntityTypeName }}
              </div>
            </div>
          </div>
          <div class="col"></div>
        </div>
      </div>
    </div>
    <div [formGroup]="moveForm">
      <div class="form-group" *ngIf="isSysAdmin && isPerformingCopy">
        <label class="bmd-label-static" for="jurisdiction">
          Jurisdiction
        </label>
        <select
          class="form-control"
          name="jurisdiction"
          formControlName="jurisdiction"
          [(ngModel)]="selectedJurisdiction"
          [disabled]="!clients"
          (ngModelChange)="clientsIdx ? loadWorkflows(clientsIdx[$event]).subscribe() : null"
        >
          <option [ngValue]="null" disabled>Select a Jurisdiction</option>
          <ng-container *ngFor="let c of clients">
            <option *ngIf="c" [value]="c.id">
              {{ c.name }}
            </option>
          </ng-container>
        </select>
      </div>

      <div class="form-group" *ngIf="isPerformingCopy">
        <label class="bmd-label-static" for="workflow">
          Workflow
        </label>
        <select
          class="form-control"
          name="workflow"
          formControlName="workflow"
          [(ngModel)]="selectedWorkflow"
          [disabled]="!workflows"
          (ngModelChange)="loadWorkflowActivities($event)"
        >
          <option [ngValue]="null" disabled>Select a Workflow</option>
          <ng-container *ngFor="let w of workflows">
            <option *ngIf="w" [value]="w.version.id">
              {{ w.version.name }}
            </option>
          </ng-container>
        </select>
      </div>

      <div class="form-group">
        <select
          class="form-control"
          name="activityId"
          formControlName="activityId"
        >
          <option [ngValue]="null" disabled>Select a form activity</option>
          <ng-container *ngFor="let form of formActivities">
            <option *ngIf="form" [value]="form.id">
              {{ form.model.screenName }}
            </option>
          </ng-container>
        </select>
      </div>
    </div>

    <div class="modal-footer">
      <button
        type="button"
        class="btn btn-raised btn-secondary ml-2"
        (click)="dismiss()"
      >
        Cancel
      </button>
      <button
        type="button"
        class="btn btn-raised btn-primary ml-2"
        [disabled]="moveForm.invalid"
        (click)="doBulkAction()"
      >
        {{ moveLabel }}
      </button>
    </div>
  </ng-container>
</wm-modal-confirm>
