import { ParentChildWorkflowNamedResponse } from './../models/ParentChildWorkflowNamed';
import {
  CustomReport,
  CustomReportResult,
  DataSetField
} from './../models/custom-report';
import {
  SharedAccessDetails,
  SharedAcessActivity
} from './../models/shared-access-details';
import { MapIndexLayer } from './../models/map-layer';
import { WorkflowValidationItemComponent } from './../components/workflow/workflow-validation-item/workflow-validation-item.component';
import { DataEntityDetail } from './../models/data-entity-detail';
import { CustomList, CustomListItem } from './../models/custom-list';
import { CopyContractorTypeRequest } from './../models/copy-contractor-request';
import { WorkflowVersion } from './../models/workflow-version';
import { WorkflowApplicationSearchFilterBuilder } from './../models/workflow-application-search-filter';
import {
  PermitSummaryReportResponse,
  PermitSummaryReportRequest,
  PagedPermitSummaryReportRequest,
  PagedPermitSummaryReportResponse
} from './../models/permits-reports';
import {
  ContractorSummaryReportRequest,
  PagedContractorSummaryReportRequest,
  ContractorSummaryReportResponse,
  PagedContractorSummaryReportResponse,
  ContractorFeeSummaryReportResponse,
  PagedContractorFeeSummaryReportResponse,
  ContractorFeeSummaryReportRequest,
  PagedContractorFeeSummaryReportRequest
} from './../models/contractor-reports';
import {
  FeeRecipientReportRequest,
  PagedFeeRecipientReportRequest,
  FeeRecipientReportResponse,
  PagedFeeRecipientReportResponse,
  DailyFeeReportResponse,
  PagedDailyFeeReportResponse,
  DailyFeeReportRequest,
  PagedDailyFeeReportRequest,
  PagedPaymentsReportRequest,
  PagedPaymentReportResponse,
  PaymentsReportRequest,
  PaymentReportResponse
} from './../models/financial-reports';
import { Injectable, Inject, forwardRef, isDevMode } from '@angular/core';
import {
  Observable,
  Subscriber,
  Subscription,
  of,
  Subject,
  throwError,
  empty
} from 'rxjs';
import { map, catchError, tap } from 'rxjs/operators';
import * as _ from 'underscore';

import { Client } from '../models/client';
import * as Activities from '../models/activities';
import {
  RenewableParentsResponse,
  Workflow,
  WorkflowType
} from '../models/workflow';
import { ApplicationConfiguration } from '../models/application-configuration';
import { Contractor } from '../models/contractor';

import {
  WorkflowApplication,
  WorkflowApplicationVM,
  PagedWorkflowApplicationVM,
  ApplicationStatus
} from '../models/workflow-application';
import { User } from '../models/user';
import { Contact } from '../models/contact';
import { MapService } from '../models/map-service';
import { WorkflowContextService } from './workflow-context.service';
import { Role } from '../models/role';
import { CommunityWorkflow } from '../models/community-workflow';
import { Address } from '../models/address';
import { EmailAddress } from '../models/email-address';
import { PhoneNumber } from '../models/phone-number';
import { ContractorRequirementType } from '../models/contractor-requirement-type';
import {
  ContractorRegistration,
  ContractorRegistrationNote,
  PagedContractorRegistrationVM,
  UnpagedContractorRegistrationVM
} from '../models/contractor-registration';
import { Document } from '../models/document';
import { ClientSearchOptions } from '../models/client-search';
import {
  ContractorSearchOptions,
  ContractorTypeSearchOptions
} from '../models/contractor-search';
import { ContractorType } from '../models/contractor-type';
import {
  ExternalDataConnection,
  SearchParameter
} from '../models/external-data-connection';
import { Action } from '../models/actions';
import { Permission } from '../models/permission';
import { WorkflowCheckpoint } from '../models/workflow-checkpoint';
import {
  HttpClient,
  HttpHeaders,
  HttpParams,
  HttpEventType,
  HttpResponse,
  HttpRequest,
  HttpErrorResponse
} from '@angular/common/http';

import {
  MapServer,
  MapLayer,
  PaymentProcessor,
  PaymentAccount,
  UserSearchOptions,
  ParcelDataSource,
  PaymentProcessorType,
  Securable
} from '../models';
import { ActivityModel, Activity, PaymentActivity } from '../models/activities';
import { PagedInspectionsVM, InspectionVM } from '../models/inspections';
import {
  ActivityFactory,
  Utilities,
  WorkflowPaymentRequest,
  WorkflowPaymentRequestGet,
  WorkflowPaymentRequestPOST
} from '.';

import { StartApplicationResponse } from '../models/start-application-response';
import { SearchParcelDataCriteria } from '../models/search-parcel-data-criteria';
import { SearchParcelDataResult } from '../models/search-parcel-data-result';
import {
  DataEntity,
  FeeDataEntity,
  DateDataEntity
} from '../models/data-entities';
import { PaymentTransactionRequest } from '../models/payment-transaction-request';
import { PaymentTransactionItem } from '../models/payment-transaction-item';
import { ActivityNavigationInfo } from '../models/activity-navigation-Info';
import { WorkflowApplicationSearchFilter } from '../models/workflow-application-search-filter';
import { ContractorDocumentCategory } from '../models/contractor-document-category';
// import { BREAKPOINT } from '@angular/flex-layout';
import { JsonNetDecycle } from './utilities/json-net-decycle';
import { ActivityUtilities } from './utilities/activities';
import { ContractorDocument } from '../models/contractor-document';
import { ContractorProfileVM } from './contractor.service';
import { ContractorRegistrationDocument } from '../models/contractor-registration-document';
import { ValidationResponse } from 'src/app/models/validation';
import { MapExtent } from '../models/mapextent';
import {
  ItemSearchOptionFieldOption,
  ItemSearchOptionField,
  ExportEntityOption
} from '../components/filter-list/models/filterClasses';
import { InspectionDocument } from '../models/inspection-document';
import { VoidRequest, BulkVoidRequest } from '../models/voidRequest';

import { PaymentRequest } from '../models/activities/payment-activity';
import { Tag } from '../models/tag';
import { WorkflowTag } from '../models/workflow-tag';
import { ContractorTypeTag } from '../models/contractor-type-tag';
import { CopyWorkflowRequest } from '../models/copy-workflow-request';
import { ConditionTarget } from '../components/system/condition-builder/condition-builder.model';
import { DataEntityFactory } from './workflow.service';
import { WorkflowGraph } from '../models/workflow-graph';
import { TimeZoneInfo } from '../models/time-zone-info';
import { PaymentConfirmation } from '../models/payment-request';
import { SharedAccessRequest } from '../models/shared-access-details';
import {
  PagedRegistrationVM,
  Registration,
  RegistrationNote,
  RegistrationVM,
  UnpagedRegistrationVM
} from '../models/registration';
import { RegistrationDocument } from '../models/registration-document';
import {
  PreviousSystemDataDetailsRequest,
  PreviousSystemDataHeadersRequest,
  PreviousSystemDataHeadersResponse,
  PreviousSystemDataDetailsResponse,
  PreviousSystemPermitAttachment,
  PreviousSystemPermitAttachmentsResponse,
  PreviousSystemDataDetail,
  PreviousSystemDataHeader,
  SavePreviousSystemPermitResponse,
  PreviousSystemDataImportRequest,
  PreviousSystemDataImportResponse
} from '../models/previous-system-data';
import {
  Agenda,
  AgendaPagedRequest,
  AgendaPagedResponse,
  AgendaQueueItem,
  AgendaQueueItemsPagedRequest,
  AgendaQueueItemsPagedResponse,
  AgendaType
} from '../models/agenda';
import { PrintAgendaRequest } from '../models/print-agenda';
import { CustomReportRequest } from '../models/custom-report';
import { faGlasses } from '@fortawesome/free-solid-svg-icons';
import {
  ExportFormat,
  ScheduledExport,
  ScheduledExportsResponse,
  ScheduleFrequencyOptions
} from '../models/scheduled-export';
import {
  InviteUserRequest,
  InviteUserResponse,
  InvitedUser
} from '../models/invited-user';
import {
  DataSet,
  ReportDataSet,
  WorkflowApplicationReportDataSet
} from '../models/report-data-set';
import { WorkflowApplicationActivityDataAudit } from '../models/workflow-application-activity-data-audit';
import { WorkflowApplicationActivityAuditDetails } from '../models/workflow-application-activity-audit-details';
import { DataEntitiesExportParameters } from '../models/exportOption';
import { DuplicateActivityResponse } from '../models/duplicate-activity-response';
import { NavigateToActivityResponse } from '../models/navigate-to-activity-response';
import {
  CustomFieldMigrationQueueItem,
  CustomFieldMigrationQueueRequest
} from '../models/custom-field-data-operations';
import {
  QueryMapLayer,
  QueryMapLayerRequest,
  QueryMapLayerResult
} from '../models/query-map-layer';
import { CanDeleteRoleResponse } from '../models/can-delete-role-response';
import * as moment from 'moment';
import { SaveActivityResponse } from '../models/save-activity-response';
import { CustomReportRequestItem } from '../models/custom-report-request';
import {
  FeeRecipient,
  FeeRecipientGenerationImpactReport,
  FeeRecipientGenerationRequest,
  FeeRecipientGenerationResponse
} from '../models/fee-recipient';
import { ValidateDomainResponse } from '../models/validate-domain-response';
import { Announcement, AvailableGlobalAnnouncementAudiences, RefreshAnnouncementsResponse } from '../models/announcement';

export interface NoParamConstructor<T> {
  new (options?: any): T;
}

export class ApplicationDiagramInfo {
  completedActivities: ActivityNavigationInfo[];
  inProgressActivities: ActivityNavigationInfo[];
  canceledActivities: ActivityNavigationInfo[];
  iterations: number[];
  applicationNumber: string;
}

export class SaveEmailRequest {
  clientId: string;
  customFromDomain: string;
}
export class SaveEmailResponse {

}

@Injectable()
export class DataService {
  testAutoReset(request: { workflowId: string; asOfDate: string; }): Observable<{nextNumber: string}> {
    return Utilities.postData(
      this._context,
      this._http,
      null,
      '/api/workflow/testAutoReset',
      request);
  }

  copyEntityToOtherWorkflow(arg0: {
    targetClientId: string;
    sourceWorkflowVersionId: string;
    targetWorkflowVersionId: string;
    sourceActivityId: string;
    targetActivityId: string;
    templateCodes: string[];
  }): Observable<void> {
    return Utilities.postData(
      this._context,
      this._http,
      null,
      '/api/workflow/CopyDataEntities',
      arg0
    );
  }

  processScheduledEmails(): Observable<void> {
    return Utilities.getData(
      this._context,
      this._http,
      null,
      '/api/system/runrecurringjob',
      { jobType: 'send-scheduled-emails' }
    );
  }
  getPaymentButtonParameters(accountId: any, amount: string): Observable<any> {
    return Utilities.postData(
      this._context,
      this._http,
      null,
      '/api/workflow/GetPaymentButtonParameters',
      null,
      null,
      null,
      { accountId, amount }
    );
  }
  confirmForteCheckoutPayment(
    applicationId: string,
    activity: Activities.PaymentActivity,
    order_number: any,
    confirmationDataJSON: string
  ): Observable<{ nextActivity: Activity<ActivityModel> }> {
    return Utilities.postData(
      this._context,
      this._http,
      null,
      '/api/workflow/ConfirmForteCheckoutPayment',
      {
        WorkflowApplicationId: applicationId,
        paymentActivity: activity,
        confirmationDataJSON
      },
      null,
      null,
      {
        uniqueId: order_number
      }
    );
  }
  getCustomReportRequestDetails(requestIds: {
    [key: string]: string;
  }): Observable<CustomReportRequestItem[]> {
    const requestBody: { key: string; value: string }[] = [];

    Object.keys(requestIds).forEach(k => {
      requestBody.push({ key: k, value: requestIds[k] });
    });

    return Utilities.postDataList(
      this._context,
      this._http,
      CustomReportRequestItem,
      '/api/system/getCustomReportRequestDetails',
      requestBody
    );
  }

  getCustomReportRequests(
    customReportId: string,
    userId: string
  ): Observable<CustomReportRequestItem[]> {
    return Utilities.getDataList(
      this._context,
      this._http,
      CustomReportRequestItem,
      '/api/system/GetCustomReportRequests',
      {
        customReportId,
        userId
      }
    );
  }
  getDataSetForFilterInput(id: string): Observable<DataSet> {
    return Utilities.getData(
      this._context,
      this._http,
      null,
      '/api/report/GetDataSetForFilterInput',
      {
        dataSetId: id
      }
    );
  }
  getPreviousSystemDataFields(clientId: string): Observable<DataSetField[]> {
    return Utilities.getData(
      this._context,
      this._http,
      null,
      '/api/workflow/GetPreviousSystemFields',
      {
        clientId
      }
    );
  }

  isDomainTestValidationEnabled(id: string): Observable<boolean> {
    return Utilities.getData<boolean>(
      this._context,
      this._http,
      null,
      `/api/client/isDomainTestValidationEnabled`,
      {
        clientId: id
      }
    );
  }
  enableTestModeForCustomFromDomain(id: string): Observable<void> {
    return Utilities.postData<null>(
      this._context,
      this._http,
      null,
      '/api/client/EnableTestModeForCustomFromDomain',
      null,
      null,
      null,
      {
        clientId: id
      }
    )
  }
  disableTestModeForCustomFromDomain(id: string): Observable<void> {
    return Utilities.postData<null>(
      this._context,
      this._http,
      null,
      '/api/client/DisableTestModeForCustomFromDomain',
      null,
      null,
      null,
      {
        clientId: id
      }
    )
  }
  saveEmailSettings(emailSettings: SaveEmailRequest): Observable<SaveEmailResponse> {
    return Utilities.postData<SaveEmailResponse>(
      this._context,
      this._http,
      SaveEmailResponse,
      '/api/client/SaveEmailSettings/',
      emailSettings
    );
  }
  validateCustomFromDomain(clientId: string): Observable<ValidateDomainResponse> {
    return Utilities.postData(
      this._context,
      this._http,
      null,
      '/api/client/validateCustomFromDomain',
      null,
      null,
      null,
      {
        clientId
      }
    );
  }

  undiscardApplication(applicationId: string): Observable<boolean> {
    return Utilities.postData(
      this._context,
      this._http,
      null,
      '/api/workflow/UndiscardApplication',
      null,
      null,
      null,
      {
        applicationId
      }
    );
  }

  reopenApplication(applicationId: string): Observable<boolean> {
    return Utilities.postData(
      this._context,
      this._http,
      null,
      '/api/workflow/ReopenWorkflowApplication',
      null,
      null,
      null,
      { applicationId }
    );
  }

  calculateFormulas(
    activity: Activities.Activity<Activities.ActivityModel>,
    applicationId: string,
    templateCodes: string[]
  ): Observable<{ calculatedValues: { [key: string]: string } }> {
    return Utilities.postData<{ calculatedValues: { [key: string]: string } }>(
      this._context,
      this._http,
      null,
      '/api/Workflow/calculateFormulas/',
      {
        applicationId,
        activity,
        templateCodes
      }
    );
  }
  testExternalConnection(
    externalDataConnection: ExternalDataConnection
  ): Observable<boolean> {
    return Utilities.postData(
      this._context,
      this._http,
      null,
      '/api/system/TestExternalDataConnection',
      externalDataConnection
    );
  }
  runAutoVoidProcess(
    asOfDateTime: Date
  ): Observable<{ applicationsVoided: number; applicationsProcessed: number }> {
    const asOf = moment(asOfDateTime).format('MM/DD/YYYY hh:mm A');

    return Utilities.postData(
      this._context,
      this._http,
      null,
      '/api/workflow/AutoVoidApplication',
      null,
      null,
      null,
      {
        asOfDateTime: asOf
      }
    );
  }
  getInspectionActivity(
    applicationId: string,
    inspectionId: string,
    actionType: string
  ): Observable<Activities.Activity<Activities.ActivityModel>> {
    return Utilities.postData(
      this._context,
      this._http,
      null,
      '/api/workflow/GetInspectionActivity',
      null,
      null,
      null,
      {
        applicationId,
        inspectionId,
        actionType
      }
    );
  }
  disablePaymentAccount(paymentAccountId: string): Observable<void> {
    return Utilities.postData(
      this._context,
      this._http,
      null,
      '/api/system/DisablePaymentAccount',
      {
        paymentAccountId
      },
      null,
      null
    );
  }
  enablePaymentAccount(paymentAccountId: string): Observable<void> {
    return Utilities.postData(
      this._context,
      this._http,
      null,
      '/api/system/EnablePaymentAccount',
      {
        paymentAccountId
      },
      null,
      null
    );
  }
  recalculateApplicationDescription(applicationId: string): Observable<string> {
    return Utilities.postData(
      this._context,
      this._http,
      null,
      '/api/workflow/RecalculateApplicationDescription',
      null,
      null,
      null,
      {
        applicationId
      }
    );
  }

  getTagsAlphabetically(clientId: string): Observable<Tag[]> {
    return Utilities.getDataList(
      this._context,
      this._http,
      Tag,
      '/api/system/gettagsalphabetically',
      { clientId }
    );
  }
  calculateClientAppNumberResetDate(
    resetClientAppNumberMonth: number,
    resetClientAppNumberDay: number
  ): Observable<string> {
    return Utilities.getData<string>(
      this._context,
      this._http,
      null,
      `/api/workflow/CalculateClientAppNumberResetDate`,
      {
        monthOfYear: resetClientAppNumberMonth,
        dayOfMonth: resetClientAppNumberDay
      }
    );
  }

  getMapLayerDetails(mapLayerIndexItemId: string): Observable<MapLayer> {
    return Utilities.getData(
      this._context,
      this._http,
      MapLayer,
      '/api/Map/GetMapLayerDetails',
      {
        mapLayerIndexItemId
      }
    );
  }

  copyDataSet(request: {
    dataSetId: string;
    targetClientId: string;
    targetName: string;
  }) {
    return Utilities.postData(
      this._context,
      this._http,
      null,
      '/api/workflow/copyDataSet',
      request
    );
  }
  regeneratePrintTemplate(
    applicationId: string,
    activityId: string,
    applicationIteration: number
  ): Observable<boolean> {
    return Utilities.postData(
      this._context,
      this._http,
      null,
      '/api/workflow/RegeneratePrintTemplate',
      null,
      null,
      null,
      {
        applicationId,
        activityId,
        iteration: applicationIteration
      }
    );
  }
  inviteUser(request: InviteUserRequest): Observable<InviteUserResponse> {
    return Utilities.postData(
      this._context,
      this._http,
      InviteUserResponse,
      '/api/security/InviteUser',
      request
    );
  }
  getUserInvitation(invitationId: string): Observable<InvitedUser> {
    return Utilities.getData<InvitedUser>(
      this._context,
      this._http,
      InvitedUser,
      `/api/security/GetUserInvitation`,
      {
        invitationId
      }
    );
  }
  getUserParcels(userId: string): Observable<{ [key: string]: string }[]> {
    return Utilities.getDataList(
      this._context,
      this._http,
      null,
      `/api/workflow/getUserParcels?userId=${userId}`
    );
  }
  exportDataSet(options: {
    dataSetId: string;
    exportFormat: ExportFormat;
    columnsToInclude: string[];
    filterInput: {
      inputValues: ItemSearchOptionFieldOption[];
      maxRows?: number;
    };
  }): Observable<any> {
    return Utilities.postDataFile<any>(
      this._context,
      this._http,
      null,
      '/api/report/exportDataSet/',
      options,
      null,
      null,
      null,
      'arraybuffer'
    );
  }
  getPublishedDataSets(clientId: string): Observable<DataSet[]> {
    return Utilities.getDataList<DataSet>(
      this._context,
      this._http,
      null,
      `/api/Workflow/GetPublishedDataSets?clientId=${clientId}`
    );
  }
  publishDataSet(dataSetId: string): Observable<boolean> {
    return Utilities.postData<boolean>(
      this._context,
      this._http,
      null,
      `/api/workflow/publishDataSet?id=${dataSetId}`,
      null
    );
  }
  getDataSets(clientId: string): Observable<DataSet[]> {
    return Utilities.getDataList<DataSet>(
      this._context,
      this._http,
      null,
      `/api/Workflow/GetDataSets?clientId=${clientId}`
    );
  }
  getDataSet(
    dataSetId: string,
    buildFieldFilterItems: boolean = false
  ): Observable<DataSet> {
    return Utilities.getData<DataSet>(
      this._context,
      this._http,
      null,
      `/api/Workflow/GetDataSet`,
      {
        id: dataSetId,
        buildFieldFilterItems
      }
    );
  }
  saveDataSet(dataSet: DataSet): Observable<DataSet> {
    return Utilities.postData<DataSet>(
      this._context,
      this._http,
      null,
      `/api/workflow/saveDataSet`,
      dataSet
    );
  }
  deleteDataSet(dataSetId: string): Observable<boolean> {
    return Utilities.postData<boolean>(
      this._context,
      this._http,
      null,
      `/api/workflow/deleteDataSet?id=${dataSetId}`,
      null
    );
  }
  testDataSet(request: CustomReportRequest): Observable<any[]> {
    return Utilities.postData<any[]>(
      this._context,
      this._http,
      null,
      `/api/report/testDataSet`,
      request,
      null,
      null,
      null,
      60000
    );
  }
  getActivityDataAuditDetails(
    applicationId: string,
    auditId: string
  ): Observable<WorkflowApplicationActivityAuditDetails> {
    return Utilities.getData<WorkflowApplicationActivityAuditDetails>(
      this._context,
      this._http,
      WorkflowApplicationActivityAuditDetails,
      '/api/workflow/GetWorkflowApplicationActivityAuditDetails',
      {
        applicationId,
        activityDataAuditId: auditId
      }
    );
  }
  getActivityDataAudit(
    applicationId: string,
    activityDataId: string
  ): Observable<WorkflowApplicationActivityDataAudit[]> {
    return Utilities.getDataList<WorkflowApplicationActivityDataAudit>(
      this._context,
      this._http,
      WorkflowApplicationActivityDataAudit,
      '/api/workflow/GetActivityDataAudit',
      {
        applicationId,
        activityDataId
      }
    );
  }
  isFieldUniqueInLayer(
    mapLayerIndexItemId: string,
    fieldName: string
  ): Observable<boolean> {
    return Utilities.postData(
      this._context,
      this._http,
      null,
      '/api/Map/IsFieldUniqueInLayer',
      null,
      null,
      null,
      {
        mapLayerIndexItemId,
        fieldName
      }
    );
  }
  changeQueryMapLayerFeatureIdField(
    mapLayerIndexItemId: string,
    featureIdField: string
  ): Observable<void> {
    return Utilities.postData(
      this._context,
      this._http,
      null,
      '/api/Map/ChangeQueryMapLayerFeatureField',
      {
        mapLayerIndexItemId,
        featureIdField
      }
    );
  }
  getUserGuideLink() {
    return Utilities.getData(
      this._context,
      this._http,
      null,
      `/api/Document/GetUserGuideLink`
    );
  }
  executeCustomReport(request: CustomReportRequest): Observable<any> {
    return Utilities.postDataFile<string[]>(
      this._context,
      this._http,
      null,
      `/api/report/ExecuteCustomReport`,
      request,
      null,
      null,
      null,
      'arraybuffer'
    );
  }
  getCustomReportForInput(reportId: string): Observable<CustomReport> {
    return Utilities.getData<CustomReport>(
      this._context,
      this._http,
      CustomReport,
      `/api/report/GetCustomReportForInput?reportId=${reportId}`
    );
  }
  getCustomReports(clientId: string): Observable<CustomReport[]> {
    return Utilities.getDataList<CustomReport>(
      this._context,
      this._http,
      CustomReport,
      `/api/report/getcustomreports?clientId=${clientId}`
    );
  }
  confirmWorkflowPaymentRequest(
    uniqueId: string,
    data: any
  ): Observable<PaymentConfirmation> {
    const postData = Utilities.objectToPost(data);
    // const formData: FormData = new FormData();

    // console.log('data', data);
    // const keys = Object.keys(data);
    // console.log('keys', keys);
    // for (const k in keys) {
    //   console.log('add form data ' + keys[k], data[keys[k]]);
    //   formData.append(keys[k], data[keys[k]]);
    // }

    return Utilities.postData<PaymentConfirmation>(
      this._context,
      this._http,
      PaymentConfirmation,
      `/api/workflow/ConfirmWorkflowPaymentRequest?uniqueid=${uniqueId}`,
      data,
      'application/x-www-form-urlencoded'
    );
  }

  refundPaymentRequest(paymentRequestId: string): Observable<boolean> {
    return Utilities.postData<boolean>(
      this._context,
      this._http,
      null,
      '/api/workflow/RefundPaymentRequest',
      null,
      null,
      null,
      {
        paymentRequestId
      }
    );
  }
  getExportWorkflows(): Observable<Workflow[]> {
    return Utilities.getDataList(
      this._context,
      this._http,
      Workflow,
      '/api/workflow/GetExportWorkflows'
    );
  }
  searchWorkflow(
    workflowId: string,
    text: string
  ): Observable<
    {
      type: string;
      id: string;
      activityId: string;
      activityName: string;
      templateCode: string;
      dataEntityLabel: string;
    }[]
  > {
    return Utilities.postDataList<{
      type: string;
      id: string;
      activityId: string;
      activityName: string;
      templateCode: string;
      dataEntityLabel: string;
    }>(
      this._context,
      this._http,
      null,
      '/api/workflow/SearchWorkflowDefinition',
      null,
      {
        workflowId,
        searchText: text
      }
    );
  }

  getShareActivities(id: string): Observable<SharedAcessActivity[]> {
    return Utilities.postDataList<SharedAcessActivity>(
      this._context,
      this._http,
      SharedAcessActivity,
      '/api/workflow/getShareActivities',
      null,
      {
        shareDetailId: id
      }
    );
  }

  revokeSharedAccess(id: string): Observable<void> {
    return Utilities.postData<void>(
      this._context,
      this._http,
      null,
      '/api/workflow/revokeSharedAccess',
      null,
      null,
      null,
      {
        id
      }
    );
  }

  loadDestinationEditor(
    transportMethod: string
  ): Observable<Activities.DestinationConfiguration> {
    return Utilities.postData<Activities.DestinationConfiguration>(
      this._context,
      this._http,
      Activities.DestinationConfiguration,
      '/api/workflow/LoadDestinationEditor',
      {
        transportMethod
      },
      null,
      null,
      {
        transportMethod
      }
    );
  }
  createDataEntity(
    dataEntityType: string,
    activity: Activity<ActivityModel>
  ): Observable<DataEntity> {
    return Utilities.postData<DataEntity>(
      this._context,
      this._http,
      null,
      `/api/workflow/CreateDataEntity`,
      {
        dataEntityType: dataEntityType,
        activity: activity
      }
    );
  }

  getPaymentRequest(requestId: string): Observable<PaymentRequest> {
    return Utilities.postData<PaymentRequest>(
      this._context,
      this._http,
      PaymentRequest,
      `/api/system/GetPaymentRequest`,
      null,
      null,
      null,
      {
        paymentRequestId: requestId
      }
    );
  }

  getDataEntityDetailsBeforeMe(
    workflow: Workflow,
    activity: Activities.Activity<Activities.ActivityModel>,
    types: string[],
    designStatus: ApplicationStatus,
    excludedTypes?: string[]
  ): Observable<DataEntityDetail[]> {
    return Utilities.postDataList<DataEntityDetail>(
      this._context,
      this._http,
      null,
      '/api/workflow/GetDataEntityDetailsDefinedBeforeMe',
      {
        workflowId: workflow.id,
        activityId: activity.id,
        dataEntityTypes: types,
        designStatus,
        excludedDataEntityTypes: excludedTypes
      }
    );
  }
  getCompletdActivities(
    applicationId: string
  ): Observable<Activities.Activity<Activities.ActivityModel>[]> {
    return Utilities.postDataList(
      this._context,
      this._http,
      Activity,
      '/api/workflow/GetCompleteApplicationActivities',
      null,
      {
        applicationId
      }
    );
  }
  getContractorExportEntityOptions(): Observable<ExportEntityOption[]> {
    return Utilities.getData<ExportEntityOption[]>(
      this._context,
      this._http,
      null,
      '/api/contractor/GetContractorExportEntityOptions'
    );
  }
  getContractorExportEntities(): Observable<{ [key: string]: string }> {
    return Utilities.getData<{
      [key: string]: string;
    }>(
      this._context,
      this._http,
      null,
      '/api/contractor/GetContractorExportEntities'
    );
  }

  getDataEntityByCode(
    workflow: Workflow,
    templateCode: string
  ): Observable<DataEntity> {
    return Utilities.postData<DataEntity>(
      this._context,
      this._http,
      DataEntity,
      '/api/workflow/GetDataEntityByCode',
      null,
      null,
      null,
      {
        workflowId: workflow.id,
        templateCode
      }
    );
  }
  getDataEntitiesBeforeMe(
    workflow: Workflow,
    activity: Activities.Activity<Activities.ActivityModel>,
    types: string[],
    excludedTypes?: string[],
    filterUnprefixed = false
  ): Observable<DataEntity[]> {
    return Utilities.postDataList<DataEntity>(
      this._context,
      this._http,
      DataEntity,
      '/api/workflow/getDataEntitiesDefinedBeforeMe',
      {
        workflowId: workflow.id,
        activityId: activity ? activity.id : null,
        dataEntityTypes: types,
        designStatus: workflow.designStatus,
        workflowVersionId: !workflow.version.isDraft
          ? workflow.version.id
          : null,
        excludedDataEntityTypes: excludedTypes,
        filterUnprefixed: filterUnprefixed
      }
    ).pipe(
      map(entities => {
        if (entities) {
          return entities.map(e =>
            DataEntityFactory.createDataEntity(e.dataEntityTypeCode, e)
          );
        }
        return null;
      })
    );
  }
  copyWorkflow(request: CopyWorkflowRequest): Observable<Workflow> {
    return Utilities.postData<Workflow>(
      this._context,
      this._http,
      Workflow,
      '/api/workflow/copyworkflow',
      request
    );
  }
  copyContractorType(
    request: CopyContractorTypeRequest
  ): Observable<ContractorType> {
    return Utilities.postData<ContractorType>(
      this._context,
      this._http,
      ContractorType,
      '/api/contractor/CopyContractorType',
      request
    );
  }
  deleteDocumentByPath(path: string): Observable<boolean> {
    return Utilities.postData<boolean>(
      this._context,
      this._http,
      null,
      '/api/document/DeleteDocumentByAbsolutePath',
      null,
      null,
      null,
      {
        path: path
      }
    );
  }
  getActivityEditor(
    workflowId: string,
    activityId: string,
    versionId?: string
  ): Observable<Activity<ActivityModel>> {
    return Utilities.postData<Activity<ActivityModel>>(
      this._context,
      this._http,
      Activity,
      '/api/workflow/LoadActivityEditor',
      null,
      null,
      null,
      {
        workflowId,
        activityId,
        versionId
      }
    );
  }
  getAvailableContractorTypeActions(
    securable: Securable
  ): Observable<string[]> {
    return Utilities.getDataList<string>(
      this._context,
      this._http,
      null,
      '/api/security/GetAvailableContractorTypeActions',
      {
        contractorTypeId: securable.id
      }
    );
  }
  getAvailableWorkflowActions(securable: Securable): Observable<string[]> {
    return Utilities.getDataList<string>(
      this._context,
      this._http,
      null,
      '/api/security/GetAvailableWorkflowActions',
      {
        workflowId: securable.id
      }
    );
  }
  getPermissionsForWorkflow(
    securable: Securable
  ): Observable<{ [key: string]: { [key: string]: Partial<Permission> } }> {
    return Utilities.getData<{
      [key: string]: { [key: string]: Partial<Permission> };
    }>(
      this._context,
      this._http,
      null,
      '/api/security/GetPermissionsForWorkflow',
      {
        workflowId: securable.id
      }
    );
  }

  getPermissionsForContractorType(
    securable: Securable
  ): Observable<{ [key: string]: { [key: string]: Partial<Permission> } }> {
    return Utilities.getData<{
      [key: string]: { [key: string]: Partial<Permission> };
    }>(
      this._context,
      this._http,
      null,
      '/api/security/GetPermissionsForContractorType',
      {
        contractorTypeId: securable.id
      }
    );
  }

  deleteInspectionDocument(documentId: string): Observable<void> {
    return Utilities.postData<void>(
      this._context,
      this._http,
      null,
      '/api/workflow/DeleteInspectionDocument',
      null,
      null,
      null,
      { documentId: documentId }
    );
  }
  getParentAccounts(
    processorType: PaymentProcessorType,
    clientId: string,
    currentAccountId: string
  ): Observable<PaymentAccount[]> {
    return Utilities.getDataList<PaymentAccount>(
      this._context,
      this._http,
      PaymentAccount,
      '/api/system/GetParentAccounts',
      {
        processorType: processorType,
        clientId: clientId,
        currentAccountId: currentAccountId
      }
    );
  }
  deleteContractorRegistrationDocument(documentId: string): Observable<void> {
    return Utilities.postData<void>(
      this._context,
      this._http,
      null,
      '/api/contractor/DeleteContractorRegistrationDocument',
      null,
      null,
      null,
      { documentId: documentId }
    );
  }

  saveInspectionDocument(
    document: InspectionDocument
  ): Observable<InspectionDocument> {
    const cycDoc: InspectionDocument = JsonNetDecycle.decycle(document);
    return Utilities.postData(
      this._context,
      this._http,
      InspectionDocument,
      '/api/workflow/saveInspectionDocument/',
      cycDoc
    );
  }

  saveContractorRegistrationDocument(
    document: ContractorRegistrationDocument
  ): Observable<ContractorRegistrationDocument> {
    const cycDoc: ContractorRegistrationDocument = JsonNetDecycle.decycle(
      document
    );
    return Utilities.postData(
      this._context,
      this._http,
      ContractorRegistrationDocument,
      '/api/contractor/saveContractorRegistrationDocument/',
      cycDoc
    );
  }
  getInspectionDocuments(
    registrationId: string
  ): Observable<InspectionDocument[]> {
    return Utilities.getDataList<InspectionDocument>(
      this._context,
      this._http,
      InspectionDocument,
      '/api/workflow/getInspectionDocuments',
      { inspectionId: registrationId }
    );
  }
  getContractorRegistrationDocuments(
    registrationId: string
  ): Observable<ContractorRegistrationDocument[]> {
    return Utilities.getDataList<ContractorRegistrationDocument>(
      this._context,
      this._http,
      ContractorRegistrationDocument,
      '/api/contractor/getContractorRegistrationDocuments',
      { registrationId: registrationId }
    );
  }

  saveInspectionDocuments(
    inspectionId: string,
    documents: InspectionDocument[]
  ): Observable<InspectionDocument[]> {
    return Utilities.postDataList<InspectionDocument>(
      this._context,
      this._http,
      InspectionDocument,
      '/api/workflow/saveInspectionDocuments',
      { inspectionId: inspectionId, documents: documents }
    );
  }
  saveContractorRegistrationDocuments(
    registrationId: string,
    documents: ContractorRegistrationDocument[]
  ): Observable<ContractorRegistrationDocument[]> {
    return Utilities.postDataList<ContractorRegistrationDocument>(
      this._context,
      this._http,
      ContractorRegistrationDocument,
      '/api/contractor/saveContractorRegistrationDocuments',
      { registrationId: registrationId, documents: documents }
    );
  }
  saveContractorDocument(
    item: ContractorDocument
  ): Observable<ContractorDocument> {
    const cycDoc: ContractorDocument = JsonNetDecycle.decycle(item);
    return Utilities.postData(
      this._context,
      this._http,
      ContractorDocument,
      '/api/contractor/saveContractorDocument/',
      cycDoc
    );
  }
  getContractorProfile(contractorId: string): Observable<ContractorProfileVM> {
    return Utilities.getData<ContractorProfileVM>(
      this._context,
      this._http,
      ContractorProfileVM,
      '/api/contractor/getContractorProfile/',
      { contractorId: contractorId }
    );
  }
  // addContractorDocuments(
  //   contractorId: string,
  //   documents: Document[]
  // ): Observable<ContractorDocument[]> {
  //   return Utilities.postDataList<ContractorDocument>(
  //     this._context,
  //     this._http,
  //     ContractorDocument,
  //     "/api/contractor/addContractorDocuments",
  //     { contractorId: contractorId, documents: documents }
  //   );
  // }
  saveContractorDocuments(
    contractorId: string,
    documents: ContractorDocument[]
  ): Observable<ContractorDocument[]> {
    return Utilities.postDataList<ContractorDocument>(
      this._context,
      this._http,
      ContractorDocument,
      '/api/contractor/saveContractorDocuments',
      { contractorId: contractorId, documents: documents }
    );
  }

  getContractorDocuments(
    contractorId: string
  ): Observable<ContractorDocument[]> {
    return Utilities.getDataList<ContractorDocument>(
      this._context,
      this._http,
      ContractorDocument,
      '/api/contractor/getContractorDocuments',
      { contractorId: contractorId }
    );
  }
  deleteContractorDocument(documentId: string): Observable<void> {
    return Utilities.postData<void>(
      this._context,
      this._http,
      null,
      '/api/contractor/DeleteContractorDocument',
      null,
      null,
      null,
      { documentId: documentId }
    );
  }
  deleteContractorRegistration(
    deleteRegistrationId: string
  ): Observable<boolean> {
    return Utilities.postData<boolean>(
      this._context,
      this._http,
      null,
      '/api/contractor/DeleteContractorRegistration',
      null,
      null,
      null,
      { contractorRegistrationId: deleteRegistrationId }
    );
  }
  initApplication(): Observable<{
    systemActivities: Activity<ActivityModel>[];
    appConfiguration: ApplicationConfiguration;
    userActions: { [key: string]: string[] };
    user: User;
    actions: { [key: string]: Action };
  }> {
    return Utilities.getData<{
      systemActivities: Activity<ActivityModel>[];
      appConfiguration: ApplicationConfiguration;
      userActions: { [key: string]: string[] };
      user: User;
      actions: { [key: string]: Action };
    }>(this._context, this._http, null, '/api/system/initApplication');
  }
  startContractorRegistrationRenewal(
    registrationId: any,
    contractorTypeId: string,
    isTestApplication: boolean
  ): Observable<{ applicationId: string }> {
    return Utilities.postData<{ applicationId: string }>(
      this._context,
      this._http,
      null,
      '/api/contractor/StartRegistrationRenewal',
      null,
      null,
      null,
      {
        contractorRegistrationId: registrationId,
        contractorTypeId: contractorTypeId,
        isTestApplication: isTestApplication
      }
    );
  }
  initActivities(): Observable<Activity<ActivityModel>[]> {
    return Utilities.getDataList<Activity<ActivityModel>>(
      this._context,
      this._http,
      Activity,
      '/api/workflow/getSystemActivities'
    );
  }

  calculateFormula(
    formulaCriteria: ConditionTarget[],
    activity: Activity<ActivityModel>,
    applicationId: string,
    templateCode: string
  ): any {
    return Utilities.postData<string>(
      this._context,
      this._http,
      null,
      '/api/Workflow/calculateFormula/',
      {
        formulaCriteria,
        applicationId,
        activity,
        templateCode
      }
    );
  }
  doesApplicationHaveFees(applicationId: string): any {
    return Utilities.getData<boolean>(
      this._context,
      this._http,
      null,
      '/api/workflow/doesApplicationHaveFees',
      {
        applicationId: applicationId
      }
    );
  }
  constructor(
    @Inject(forwardRef(() => WorkflowContextService))
    private _context: WorkflowContextService,
    @Inject(forwardRef(() => HttpClient)) private _http: HttpClient
  ) {}

  getPaymentTransactions(request: PaymentTransactionRequest): any {
    return Utilities.postDataList<PaymentTransactionItem>(
      this._context,
      this._http,
      PaymentTransactionItem,
      '/api/system/getPaymentTransactions',
      request
    );
  }
  getParcelDataSourceResults(
    searchCriteria: SearchParcelDataCriteria
  ): Observable<{ [key: string]: string }[]> {
    return Utilities.postDataList<{ [key: string]: string }>(
      this._context,
      this._http,
      null,
      '/api/system/getParcelDataSourceResults/',
      searchCriteria
    );
  }
  exportEntities(
    workflowId: string,
    options: DataEntitiesExportParameters
  ): any {
    const t = Utilities.postDataFile<string[]>(
      this._context,
      this._http,
      null,
      '/api/workflow/exportEntities/',
      options,
      null,
      null,
      { exportId: workflowId },
      'arraybuffer'
    );

    return t;
  }
  getWorkflowApplicationEntity(
    applicationId: string,
    templateCode: string,
    activityDataId?: string
  ): any {
    if (applicationId && applicationId !== Utilities.EMPTY_GUID) {
      const request = Utilities.postDataList<DataEntity>(
        this._context,
        this._http,
        null,
        '/api/workflow/getWorkflowApplicationEntity/' +
          applicationId +
          '/' +
          templateCode +
          '/' +
          (activityDataId || ''),
        null,
        null
      );
      return request;
    } else {
      return empty();
    }
  }

  getExportEntityOptions(workflowId: string): Observable<ExportEntityOption[]> {
    return Utilities.getData<ExportEntityOption[]>(
      this._context,
      this._http,
      null,
      '/api/workflow/GetExportEntityOptions/' + workflowId,
      null
    );
  }

  getExportEntities(workflowId: string): Observable<{ [key: string]: string }> {
    return Utilities.getData<{ [key: string]: string }>(
      this._context,
      this._http,
      null,
      '/api/workflow/GetExportEntityOptions/' + workflowId,
      null
    );
  }

  getWorkflowEntities(workflowId: string): Observable<any[]> {
    const request = Utilities.postDataList<string>(
      this._context,
      this._http,
      null,
      '/api/workflow/getWorkflowEntities/' + workflowId,
      null,
      null
    );
    return request;
  }
  createWorkflowPaymentRequest(
    applicationId: string,
    activity: PaymentActivity,
    paymentRequestId?: string
  ): Observable<WorkflowPaymentRequest> {
    return Utilities.postData<WorkflowPaymentRequest>(
      this._context,
      this._http,
      WorkflowPaymentRequest,
      '/api/workflow/createWorkflowPaymentRequest/' + applicationId,
      activity,
      null,
      null,
      { paymentRequestId: paymentRequestId || '' }
    ).pipe(
      map(result => {
        if (result) {
          if (result.method === 'GET') {
            return new WorkflowPaymentRequestGet(
              result.url,
              result.data,
              result.result,
              result.errorMessage,
              result.reloadActivity
            );
          } else {
            return new WorkflowPaymentRequestPOST(
              result.url,
              result.data,
              result.result,
              result.errorMessage,
              result.reloadActivity
            );
          }
        }
      })
    );
  }
  bulkVoidApplications(voidRequest: BulkVoidRequest) {
    return Utilities.postData<string>(
      this._context,
      this._http,
      null,
      '/api/Workflow/bulkVoidWorkflowApplications/',
      voidRequest
    );
  }
  voidApplications(applicationId: string, voidRequest: VoidRequest) {
    return Utilities.postData<string>(
      this._context,
      this._http,
      null,
      '/api/Workflow/voidWorkflowApplication/' + applicationId,
      voidRequest
    );
  }
  unVoidApplication(applicationId: string): Observable<boolean> {
    return Utilities.postData<boolean>(
      this._context,
      this._http,
      null,
      '/api/Workflow/unVoidWorkflowApplication',
      null,
      null,
      null,
      { workflowApplicationId: applicationId }
    );
  }
  cancelApplications(applicationId: string) {
    return Utilities.postData<string>(
      this._context,
      this._http,
      null,
      '/api/Workflow/cancelWorkflowApplication/' + applicationId,
      applicationId
    );
  }

  cleanupPDFImages(container: string, doc: Document): any {
    return Utilities.postDataList<string>(
      this._context,
      this._http,
      null,
      '/api/Document/cleanupPDFImages',
      doc,
      {
        container
      }
    );
  }
  getServerTime() {
    return Utilities.getData<Date>(
      this._context,
      this._http,
      Date,
      '/api/System/getTime'
    );
  }

  convertPDFToPageImages(file: Document): any {
    return Utilities.postDataList<string>(
      this._context,
      this._http,
      null,
      '/api/Document/convertPDFToPageImages',
      file
    );
  }

  getWorkflowActivityForPreview(
    workflowId: string,
    activityId: string
  ): Observable<Activities.Activity<ActivityModel>> {
    return Utilities.postData<Activities.Activity<ActivityModel>>(
      this._context,
      this._http,
      Activities.Activity,
      '/api/Workflow/GetWorkflowActivityForPreview/' +
        workflowId +
        '/' +
        activityId,
      null
    );
  }

  getDocuments(pathSegments: string[]): Observable<Document[]> {
    const url = '/api/Document/getDocuments/';
    return Utilities.postDataList<Document>(
      this._context,
      this._http,
      Document,
      url,
      pathSegments
    );
  }
  removeDocument(containerPath: string, document: Document): Observable<boolean> {
    return Utilities.postData<boolean>(
      this._context,
      this._http,
      null,
      '/api/Document/DeleteDocument/' + containerPath + '/',
      document
    );
  }

  getAdminClients(includeRoles?: boolean): Observable<Client[]> {
    return Utilities.getDataList<Client>(
      this._context,
      this._http,
      Client,
      '/api/client/getAdminclients/',
      {
        includeRoles
      }
    ).pipe(
      map(c => {
        if (c) {
          c.sort((a, b) => (a.name > b.name ? -1 : a.name === b.name ? 0 : 1));
        }
        return c;
      })
    );
  }
  getClients(): Observable<Client[]> {
    return Utilities.getDataList<Client>(
      this._context,
      this._http,
      Client,
      '/api/client/getclients/'
    );
  }
  getClient(id: string): Observable<Client> {
    return Utilities.getData<Client>(
      this._context,
      this._http,
      Client,
      '/api/client/getClient/' + id
    ).pipe(
      tap(c => {
        if (c && this._context) {
          this._context.hasPreviousSystemData = c.hasPreviousSystemData;
        }
      })
    );
  }
  getPublicClient(id: string): Observable<Client> {
    return Utilities.getData<Client>(
      this._context,
      this._http,
      Client,
      '/api/client/getPublicClient/' + id
    ).pipe(
      tap(c => {
        if (this._context && c) {
          this._context.hasPreviousSystemData = c.hasPreviousSystemData;
        }
      })
    );
  }
  searchClients(options: ClientSearchOptions): Observable<Client[]> {
    return Utilities.postDataList<Client>(
      this._context,
      this._http,
      Client,
      '/api/client/searchClients/',
      options
    );
  }
  saveClient(client: Client): Observable<Client> {
    client.mapServices2 = client.mapServices;
    return Utilities.postData<Client>(
      this._context,
      this._http,
      Client,
      '/api/client/SaveClient/',
      client
    );
  }
  saveContact(contact: Contact): Observable<Contact> {
    return Utilities.postData<Contact>(
      this._context,
      this._http,
      Contact,
      '/api/client/SaveContact/',
      contact
    );
  }
  removeContact(contact: Contact): Observable<Contact> {
    return Utilities.postData<Contact>(
      this._context,
      this._http,
      Contact,
      '/api/client/RemoveContact/',
      contact
    );
  }
  disableClient(client: Client): Observable<Client> {
    return Utilities.postData<Client>(
      this._context,
      this._http,
      Client,
      '/api/client/disableclient/',
      null,
      null,
      null,
      {
        clientId: client.id
      }
    );
  }
  enableClient(client: Client): Observable<Client> {
    return Utilities.postData<Client>(
      this._context,
      this._http,
      Client,
      '/api/client/enableclient/',
      null,
      null,
      null,
      {
        clientId: client.id
      }
    );
  }
  deleteClient(client: Client): Observable<Client> {
    return Utilities.postData<Client>(
      this._context,
      this._http,
      Client,
      '/api/client/deleteClient/',
      client
    );
  }

  deleteTestWorkflowApplication(applicationId: string): Observable<boolean> {
    return Utilities.postData<boolean>(
      this._context,
      this._http,
      null,
      '/api/workflow/DeleteTestWorkflowApplication',
      null,
      null,
      null,
      { applicationId: applicationId }
    );
  }
  getFullApplication(applicationId: string): Observable<WorkflowApplication> {
    return Utilities.getData<WorkflowApplication>(
      this._context,
      this._http,
      WorkflowApplication,
      '/api/workflow/getFullWorkflowApplication/',
      { applicationId: applicationId }
    );
  }
  getApplication(applicationId: string): Observable<WorkflowApplication> {
    return Utilities.getData<WorkflowApplication>(
      this._context,
      this._http,
      WorkflowApplication,
      '/api/workflow/getWorkflowApplication/',
      { applicationId: applicationId }
    );
  }
  startApplication(
    workflowId: string,
    isTestApplication: boolean
  ): Observable<StartApplicationResponse> {
    return Utilities.getData<StartApplicationResponse>(
      this._context,
      this._http,
      StartApplicationResponse,
      '/api/workflow/startWorkflowApplication/',
      { workflowId: workflowId, isTestApplication: isTestApplication }
    );
  }
  getCurrentApplicationActivity(
    applicationId: string,
    expectedActivity?: string
  ): Observable<{
    activity: Activities.Activity<ActivityModel>;
    workflowId: string;
    workflowVersionId: string;
    applicationNumber: string;
    clientApplicationNumber: string;
    client: Client;
    isTestApplication: boolean;
    navigationActivities: ActivityNavigationInfo[];
    fees: FeeDataEntity[];
    hasFees?: boolean;
    submitterId: string;
    workflowName: string;
    canUserVoidApplication: boolean;
    isProcessing: boolean;
  }> {
    return Utilities.getData<{
      activity: Activities.Activity<ActivityModel>;
      workflowId: string;
      workflowVersionId: string;
      applicationNumber: string;
      clientApplicationNumber: string;
      client: Client;
      isTestApplication: boolean;
      navigationActivities: ActivityNavigationInfo[];
      fees: FeeDataEntity[];
      hasFees: boolean;
      submitterId: string;
      workflowName: string;
      canUserVoidApplication: boolean;
      isProcessing: boolean;
    }>(
      this._context,
      this._http,
      null,
      '/api/workflow/GetCurrentApplicationActivity/',
      { applicationId: applicationId, activityType: expectedActivity }
    );
  }
  // getApplications(
  //   workflowId: string,
  //   sortField: string,
  //   sortDescending: boolean,
  //   filterOptions?: any
  // ): Observable<PagedWorkflowApplicationVM> {
  //   return this.getUserApplications(
  //     null,
  //     workflowId,
  //     sortField,
  //     sortDescending,
  //     filterOptions
  //   );
  // }
  getUserApplications(
    user: User,
    workflowId: string,
    sortField: string,
    sortDescending: boolean,
    filterOptions?: any
  ): Observable<PagedWorkflowApplicationVM> {
    // build the userid filter
    let userFilter: ItemSearchOptionField;

    const searchOptionList: ItemSearchOptionFieldOption[] = [];

    const userIdOption = new ItemSearchOptionFieldOption();
    userIdOption.searchText = user.id;
    userIdOption.filterText = '';
    searchOptionList.push(userIdOption);
    sortDescending = sortDescending ? sortDescending : true;
    userFilter = { title: 'submitterId', options: searchOptionList };
    filterOptions = filterOptions ? filterOptions : [];
    filterOptions.push(userFilter);
    const searchWorkflowApplicationOptions = {
      workflow: null,
      additionalFilters: filterOptions,
      sortField: sortField,
      sortDescending: sortDescending,
      pageNumber: 1,
      pageSize: 10
    };

    return Utilities.postData<PagedWorkflowApplicationVM>(
      this._context,
      this._http,
      PagedWorkflowApplicationVM,
      '/api/workflow/GetUserWorkflowApplications/',
      searchWorkflowApplicationOptions,
      null,
      null
    );
  }
  // I just figured out that these aren't supposed to be restricted on WorkflowId... shesh
  getAllWorkflowApplications(
    workflowId: string,
    sortField: string,
    sortDescending: boolean,
    filterOptions: ItemSearchOptionField[],
    pageNumber: number,
    pageSize: number,
    extent: MapExtent,
    showAll?: boolean
  ): Observable<PagedWorkflowApplicationVM> {
    pageNumber = pageNumber ? pageNumber : 1;
    pageSize = pageSize ? pageSize : 10;
    const additionalFilters = filterOptions ? filterOptions : null;
    // add the client id to the filter if we are in the context of a client
    const clientFilter = additionalFilters.find(f => f.title === 'clientId');

    if (!clientFilter && this._context.client) {
      additionalFilters.push(
        new ItemSearchOptionField({
          title: 'clientId',
          options: [
            new ItemSearchOptionFieldOption({
              searchText: this._context.client.id
            })
          ]
        })
      );
    }
    const searchWorkflowApplicationOptions = {
      workflow: workflowId,
      additionalFilters: additionalFilters,
      sortField: sortField,
      sortDescending: sortDescending,
      pageNumber: pageNumber,
      pageSize: pageSize,
      extent: extent,
      showAll
    };
    return Utilities.postData<PagedWorkflowApplicationVM>(
      this._context,
      this._http,
      PagedWorkflowApplicationVM,
      '/api/workflow/GetAllWorkflowApplications/',
      searchWorkflowApplicationOptions,
      null,
      null
    );
  }
  filterWorkflowApplications(filterOptions: WorkflowApplicationSearchFilter) {
    // convert this concrete object into a dictionary of dictionaries that looks like this:  {field.title:{field.option.title:field.option.filterText}}
    // Then on the API I just need a Dictionary<string,<dictionary<string,string>>?????  I think
    const fieldDict = {};
    filterOptions.options.forEach(option => {
      const fieldKey = option.field.title;
      const fieldOptionDictionary = [];
      option.field.options.forEach(fieldOption => {
        if (fieldOption.selected === true) {
          fieldOptionDictionary.push(fieldOption);
        }
      });
      fieldDict[option.field.title] = fieldOptionDictionary;
    });
    const stringDict = JSON.stringify(fieldDict);

    const request = Utilities.postDataList<string[]>(
      this._context,
      this._http,
      null,
      '/api/workflow/filterWorkflowApplications/',
      fieldDict,
      null
    );
    return request;
  }

  getContractors(client?: Client): Observable<Contractor[]> {
    return Utilities.getDataList<Contractor>(
      this._context,
      this._http,
      Contractor,
      '/api/contractor/getContractors/',
      { clientId: client.id }
    );
  }
  getInspection(inspectionId: string): Observable<InspectionVM> {
    return Utilities.getData<InspectionVM>(
      this._context,
      this._http,
      InspectionVM,
      '/api/workflow/GetInspection/',
      { inspectionId: inspectionId }
    );
  }
  searchInspections(
    clientId: string,
    sortField: string,
    sortDescending: boolean,
    filterObjects: any,
    pageNumber: number,
    pageSize: number,
    ext: MapExtent
  ): Observable<PagedInspectionsVM> {
    pageNumber = pageNumber >= 0 ? pageNumber : 0;
    pageSize = pageSize ? pageSize : 10;
    const additionalFilters = filterObjects ? filterObjects : null;
    const searchOptions = {
      clientId: clientId,
      additionalFilters: additionalFilters,
      sortField: sortField,
      sortDescending: sortDescending,
      pageNumber: pageNumber,
      pageSize: pageSize,
      Extent: ext
    };
    return Utilities.postData<PagedInspectionsVM>(
      this._context,
      this._http,
      PagedInspectionsVM,
      '/api/workflow/SearchInspections/',
      searchOptions,
      null,
      null
    );
  }
  searchExpiredContractorRegistrations(
    clientId: string,
    sortField: string,
    sortDescending: boolean,
    filterObjects: any,
    pageNumber: number,
    pageSize: number
  ): Observable<PagedContractorRegistrationVM> {
    pageNumber = pageNumber ? pageNumber : 1;
    pageSize = pageSize ? pageSize : 10;
    const additionalFilters = filterObjects ? filterObjects : null;
    const searchContractorRegistrationOptions = {
      clientId: clientId,
      additionalFilters: additionalFilters,
      sortField: sortField,
      sortDescending: sortDescending,
      pageNumber: pageNumber,
      pageSize: pageSize
    };
    return Utilities.postData<PagedContractorRegistrationVM>(
      this._context,
      this._http,
      PagedContractorRegistrationVM,
      '/api/contractor/SearchExpiredContractorRegistrations/',
      searchContractorRegistrationOptions,
      null,
      null
    );
  }

  searchContractorRegistrationsUnpaged(
    clientId: string,
    sortField?: string,
    sortDescending?: boolean,
    filterObjects?: ItemSearchOptionField[],
    publicData: boolean = false
  ): Observable<UnpagedContractorRegistrationVM> {
    const searchContractorRegistrationOptions = {
      clientId,
      additionalFilters: filterObjects,
      sortField,
      sortDescending,
      publicData
    };
    return Utilities.postData<UnpagedContractorRegistrationVM>(
      this._context,
      this._http,
      UnpagedContractorRegistrationVM,
      '/api/contractor/SearchContractorRegistrationsUnpaged/',
      searchContractorRegistrationOptions,
      null,
      null
    );
  }

  searchContractorRegistrations(
    clientId: string,
    sortField?: string,
    sortDescending?: boolean,
    filterObjects?: ItemSearchOptionField[],
    pageNumber: number = 1,
    pageSize: number = 10,
    publicData: boolean = false
  ): Observable<PagedContractorRegistrationVM> {
    const searchContractorRegistrationOptions = {
      clientId,
      additionalFilters: filterObjects,
      sortField,
      sortDescending,
      pageNumber,
      pageSize,
      publicData
    };
    return Utilities.postData<PagedContractorRegistrationVM>(
      this._context,
      this._http,
      PagedContractorRegistrationVM,
      '/api/contractor/SearchContractorRegistrations/',
      searchContractorRegistrationOptions,
      null,
      null
    );
  }
  searchContractorRegistrations2(
    options: ContractorSearchOptions
  ): Observable<ContractorRegistration[]> {
    return Utilities.postDataList<ContractorRegistration>(
      this._context,
      this._http,
      ContractorRegistration,
      '/api/contractor/searchContractorRegistrations/',
      { options: options },
      { clientId: options.clientId }
    );
  }
  exportContractorRegistrations(
    clientId: string,
    useExcelFormat: boolean,
    exportColumns: string[],
    sortField?: string,
    sortDescending?: boolean,
    filterObjects?: ItemSearchOptionField[],
    pageNumber: number = 1,
    pageSize: number = 10,
    publicData: boolean = false
  ): Observable<any> {
    const searchContractorRegistrationOptions = {
      clientId,
      useExcelFormat,
      exportColumns,
      additionalFilters: filterObjects,
      sortField,
      sortDescending,
      pageNumber,
      pageSize,
      publicData
    };
    return Utilities.postDataFile<any>(
      this._context,
      this._http,
      null,
      '/api/contractor/ExportContractorRegistrations/',
      searchContractorRegistrationOptions,
      null,
      null,
      null,
      'arraybuffer'
    );
  }
  searchContractors(
    options: ContractorSearchOptions
  ): Observable<Contractor[]> {
    return Utilities.postDataList<Contractor>(
      this._context,
      this._http,
      Contractor,
      '/api/contractor/searchContractors/',
      { options: options },
      { clientId: options.clientId }
    );
  }
  getPaymentProcessors(): Observable<PaymentProcessor[]> {
    return Utilities.getDataList<PaymentProcessor>(
      this._context,
      this._http,
      PaymentProcessor,
      '/api/system/getPaymentProcessors/'
    );
  }
  getPaymentProcessor(
    paymentProcessorId: string
  ): Observable<PaymentProcessor> {
    return Utilities.getData<PaymentProcessor>(
      this._context,
      this._http,
      PaymentProcessor,
      '/api/system/getPaymentProcessor/' + paymentProcessorId
    );
  }
  savePaymentProcessor(processor: PaymentProcessor): Observable<boolean> {
    return Utilities.postData<boolean>(
      this._context,
      this._http,
      null,
      '/api/system/savePaymentProcessor',
      processor
    );
  }
  getClientPaymentAccounts(clientId: string): Observable<PaymentAccount[]> {
    return Utilities.getDataList<PaymentAccount>(
      this._context,
      this._http,
      PaymentAccount,
      '/api/system/getPaymentAccounts/' + clientId
    );
  }
  getPaymentAccount(paymentAccountId: string): Observable<PaymentAccount> {
    return Utilities.getData<PaymentAccount>(
      this._context,
      this._http,
      PaymentAccount,
      '/api/system/getPaymentAccount/' + paymentAccountId
    );
  }
  savePaymentAccount(paymentAccount: PaymentAccount): Observable<boolean> {
    return Utilities.postData<boolean>(
      this._context,
      this._http,
      null,
      '/api/system/savePaymentAccount/',
      paymentAccount
    );
  }
  deletePaymentAccount(paymentAccountId: string): Observable<boolean> {
    return Utilities.postData<boolean>(
      this._context,
      this._http,
      null,
      '/api/system/deletePaymentAccount/',
      null,
      null,
      null,
      { paymentAccountId: paymentAccountId }
    );
  }
  searchContractorTypes(
    client: Client,
    options: ContractorTypeSearchOptions
  ): Observable<ContractorType[]> {
    return Utilities.postDataList<ContractorType>(
      this._context,
      this._http,
      ContractorType,
      '/api/contractor/searchContractorTypes/',
      { options: options },
      { clientId: client.id }
    );
  }
  saveContractorType(contractorType: ContractorType) {
    const decType = contractorType;

    // clean up the contractor reference on any tags
    if ((decType.contractorTypeTags || []).length > 0) {
      decType.contractorTypeTags.forEach((tag, idx) => {
        tag.contractorType = null;
      });
    }

    // clear out references so we don't have serialization issues
    decType.registerActivities = {};
    decType.registerEmailActivities = {};
    decType.registerFeeActivities = {};
    decType.registerFormActivities = {};
    decType.registerPrintTemplateActivities = {};

    decType.renewActivities = {};
    decType.renewEmailActivities = {};
    decType.renewFeeActivities = {};
    decType.renewFormActivities = {};
    decType.renewPrintTemplateActivities = {};

    return Utilities.postData<ContractorType>(
      this._context,
      this._http,
      ContractorType,
      '/api/contractor/saveContractorType/',
      decType
    );
  }

  // deleteContractorRegistrationDocument(documentId: string): Observable<void> {
  //   return Utilities.postData<void>(
  //     this._context,
  //     this._http,
  //     null,
  //     '/api/contractor/DeleteContractorRegistrationDocument',
  //     null,
  //     null,
  //     null,
  //     { documentId: documentId }
  //   );
  // }
  // deleteMapService(mapService: MapService): Observable<boolean> {
  //   return Utilities.postData<boolean>(
  //     this._context,
  //     this._http,
  //     null,
  //     '/api/map/deleteMapService/' + mapService.id,
  //     null
  //   );
  // }
  deleteContractorType(id: string): Observable<boolean> {
    return Utilities.postData<boolean>(
      this._context,
      this._http,
      null,
      '/api/contractor/DeleteContractorType/' + id,
      null
    );
  }
  saveContractorDetails(contractor: Contractor): Observable<Contractor> {
    const decycled = JsonNetDecycle.decycle(contractor);
    return Utilities.postData<Contractor>(
      this._context,
      this._http,
      Contractor,
      '/api/contractor/saveContractorDetails/',
      decycled
    );
  }

  saveContractorRegistrationCustomFields(
    registrationCustomFields: any[],
    contractorId: string
  ): Observable<any[]> {
    const request = {
      customfields: registrationCustomFields,
      contractorId: contractorId
    };
    return Utilities.postData<any>(
      this._context,
      this._http,
      null,
      '/api/contractor/SaveContractorRegistrationCustomFields',
      request,
      null,
      null
    );
  }

  getParcelNote(
    id: string
  ): Observable<{ internalNote: string; publicNote: string }> {
    return Utilities.getData<{ internalNote: string; publicNote: string }>(
      this._context,
      this._http,
      null,
      '/api/System/GetParcelNote/',
      { parcelId: id }
    );
  }

  saveParcelNote(
    publicNote: string,
    internalNote: string,
    parcelId: string
  ): Observable<boolean> {
    return Utilities.postData<boolean>(
      this._context,
      this._http,
      null,
      '/api/System/SaveParcelNote',
      {
        publicNote: publicNote,
        internalNote: internalNote,
        parcelId: parcelId
      },
      null,
      null
    );
  }
  saveContractorRegistrationNote(
    note: ContractorRegistrationNote,
    contractorID: string,
    contractorUserID: string
  ): Observable<Contractor> {
    const decycled = JsonNetDecycle.decycle(note);
    return Utilities.postData<Contractor>(
      this._context,
      this._http,
      Contractor,
      '/api/contractor/saveRegistrationNote',
      {
        note: decycled,
        registrationID: decycled.registrationID,
        contractorID: contractorID,
        contractorUserID: contractorUserID
      }
    );
  }
  saveContractor(contractor: Contractor): Observable<Contractor> {
    const decycled = JsonNetDecycle.decycle(contractor);
    return Utilities.postData<Contractor>(
      this._context,
      this._http,
      Contractor,
      '/api/contractor/saveContractor/',
      decycled
    );
  }
  getContractor(id: string): Observable<Contractor> {
    return Utilities.getData<Contractor>(
      this._context,
      this._http,
      Contractor,
      '/api/contractor/getContractor/',
      { contractorId: id }
    );
  }
  getContractorByRegistrationId(id: string): Observable<Contractor> {
    return Utilities.getData<Contractor>(
      this._context,
      this._http,
      Contractor,
      '/api/contractor/getContractorByRegistrationId/',
      { contractorRegistrationId: id }
    );
  }
  getContractorRequirementTypes(
    clientId: string
  ): Observable<ContractorRequirementType[]> {
    return Utilities.getDataList<ContractorRequirementType>(
      this._context,
      this._http,
      ContractorRequirementType,
      '/api/contractor/getContractorRequirementTypes/',
      { clientId: clientId }
    );
  }
  getContractorRequirementType(
    requirementTypeId: string
  ): Observable<ContractorRequirementType> {
    return Utilities.getData<ContractorRequirementType>(
      this._context,
      this._http,
      ContractorRequirementType,
      '/api/contractor/getContractorRequirementType/',
      { contractorRequirementTypeId: requirementTypeId }
    );
  }
  getContractorTypes(clientId: string): Observable<ContractorType[]> {
    return Utilities.getDataList<ContractorType>(
      this._context,
      this._http,
      ContractorType,
      '/api/contractor/GetContractorRegistrationTypes/' + clientId
    );
  }
  getContractorType(
    id: string,
    loadDraft?: boolean
  ): Observable<ContractorType> {
    return Utilities.getData<ContractorType>(
      this._context,
      this._http,
      ContractorType,
      '/api/contractor/getContractorType/',
      {
        contractorTypeId: id,
        loadDraftWorkflow: loadDraft || false
      }
    );
  }
  getContractorRegistration(
    registrationId: string
  ): Observable<ContractorRegistration> {
    return Utilities.getData<ContractorRegistration>(
      this._context,
      this._http,
      ContractorRegistration,
      '/api/contractor/getContractorRegistration/',
      { contractorRegistrationId: registrationId }
    );
  }
  saveContractorRegistration(
    registration: ContractorRegistration
  ): Observable<ContractorRegistration> {
    return Utilities.postData<ContractorRegistration>(
      this._context,
      this._http,
      ContractorRegistration,
      '/api/contractor/saveContractorRegistration/',
      registration
    );
  }
  getContractorRegistrationHistory(
    registrationId: string
  ): Observable<ContractorRegistration[]> {
    return Utilities.getDataList<ContractorRegistration>(
      this._context,
      this._http,
      ContractorRegistration,
      '/api/contractor/getContractorRegistrationHistory/',
      { registrationId: registrationId }
    );
  }
  getContractorRegistrationWorkflows(
    clientId: string,
    userId: string
  ): Observable<Workflow[]> {
    return Utilities.getDataList<Workflow>(
      this._context,
      this._http,
      Workflow,
      '/api/contractor/GetContractorRegistrationWorkflows/',
      { clientId: clientId, userId: userId }
    );
  }
  getWorkflowVersion(workflowVersionId: string, includeGraph?: boolean) {
    return Utilities.getData(
      this._context,
      this._http,
      Workflow,
      `/api/workflow/getSpecificWorkflowVersion/${workflowVersionId}`,
      {
        includeGraph
      }
    );
  }
  getPublishedWorkflows(
    client: Client,
    types: WorkflowType[] = null,
    includeChildRenewals: boolean = false
  ): Observable<Workflow[]> {
    let reqObj = null;

    if (types) {
      reqObj = {
        clientId: client.id,
        types,
        includeChildRenewals
      };
    } else {
      reqObj = {
        clientId: client.id
      };
    }

    return Utilities.getDataList<Workflow>(
      this._context,
      this._http,
      Workflow,
      '/api/workflow/getPublishedWorkflows/',
      reqObj
    );
  }
  getUniqueInspectorIds(
    clientId?: string
  ): Observable<{ [key: string]: string }> {
    return Utilities.postData<{ [key: string]: string }>(
      this._context,
      this._http,
      null,
      '/api/workflow/getUniqueInspectorIds/',
      null,
      null,
      null,
      { clientId }
    );
  }
  getPublicWorkflows(
    client: Client,
    showArchived?: boolean,
    workflowTypes?: number[]
  ): Observable<Workflow[]> {
    return Utilities.postDataList<Workflow>(
      this._context,
      this._http,
      Workflow,
      '/api/workflow/getPublicWorkflows/',
      workflowTypes,
      {
        clientId: client ? client.id : null,
        showArchived: showArchived
      }
    );
  }

  getWorkflows(
    client: Client,
    showArchived?: boolean,
    workflowTypes?: number[],
    loadGlobal?: boolean
  ): Observable<Workflow[]> {
    if (client || !loadGlobal) {
      return Utilities.postDataList<Workflow>(
        this._context,
        this._http,
        Workflow,
        '/api/workflow/getWorkflows/',
        workflowTypes,
        {
          clientId: client ? client.id : null,
          showArchived: showArchived
        }
      );
    } else {
      return Utilities.postDataList<Workflow>(
        this._context,
        this._http,
        Workflow,
        '/api/workflow/getGlobalWorkflows/',
        [0, 1, 2, 3],
        {
          showArchived: showArchived
        }
      );
    }
  }

  getGlobalContractorWorkflows() {
    return Utilities.getDataList<Workflow>(
      this._context,
      this._http,
      Workflow,
      '/api/contractor/getGlobalContractorWorkflows/'
    );
  }
  getCurrentWorkflows(client: Client): Observable<Workflow[]> {
    return Utilities.postDataList<Workflow>(
      this._context,
      this._http,
      Workflow,
      '/api/workflow/getCurrentWorkflows/',
      {
        clientId: client.id
      },
      null
    );
  }
  getDraftVersionEntities(workflowId: string): Observable<DataEntity[]> {
    return Utilities.postDataList<DataEntity>(
      this._context,
      this._http,
      DataEntity,
      `/api/workflow/GetDraftWorkflowDataEntities/?workflowId=${workflowId}`,
      null
    ).pipe(
      map(entities => {
        return entities.map(e =>
          DataEntityFactory.createDataEntity(e.dataEntityTypeCode, e)
        );
      })
    );
  }
  getParentChildWorkflows(
    workflowId: string
  ): Observable<ParentChildWorkflowNamedResponse> {
    return Utilities.postData<ParentChildWorkflowNamedResponse>(
      this._context,
      this._http,
      ParentChildWorkflowNamedResponse,
      '/api/workflow/GetParentChildWorkflows/',
      { workflowId }
    );
  }
  getWorkflowVersionDataEntities(
    workflowVersionId: string,
    types?: string[],
    templateCodes?: string[],
    onlyGlobalDataEntities?: boolean,
    filterUnprefixed = false,
    activityTypes?: string[]
  ): Observable<DataEntity[]> {
    return Utilities.postDataList<DataEntity>(
      this._context,
      this._http,
      DataEntity,
      '/api/workflow/GetWorkflowVersionDataEntities/',
      {
        workflowVersionId,
        dataEntityTypes: types,
        templateCodes,
        onlyGlobalDataEntities,
        filterUnprefixed: filterUnprefixed,
        activityTypes: activityTypes
      }
    );
  }
  getPaymentRequests(applicationId: string): Observable<PaymentRequest[]> {
    return Utilities.getDataList<PaymentRequest>(
      this._context,
      this._http,
      PaymentRequest,
      `/api/workflow/GetPaymentRequests/${applicationId}`
    );
  }
  getWorkflowActivities(
    workflowId: string
  ): Observable<Activities.Activity<ActivityModel>[]> {
    return Utilities.getDataList<Activities.Activity<ActivityModel>>(
      this._context,
      this._http,
      Activities.Activity,
      '/api/workflow/getWorkflowActivities/' + workflowId
    );
  }

  hideWorkflowFromPublic(workflowId: string): Observable<boolean> {
    return Utilities.postData<boolean>(
      this._context,
      this._http,
      null,
      '/api/workflow/hideWorkflowFromPublic/',
      null,
      null,
      null,
      {
        workflowId
      }
    );
  }
  makeWorkflowVisibleToPublic(workflowId: string): Observable<boolean> {
    return Utilities.postData<boolean>(
      this._context,
      this._http,
      null,
      '/api/workflow/makeWorkflowVisibleToPublic/',
      null,
      null,
      null,
      {
        workflowId
      }
    );
  }
  getWorkflow(
    workflowId: string,
    loadGraph = true,
    loadFullGraph = true
  ): Observable<Workflow> {
    return Utilities.getData<Workflow>(
      this._context,
      this._http,
      Workflow,
      '/api/workflow/getDraftWorkflow/' + workflowId,
      {
        loadGraph,
        loadFullGraph
      }
    );
  }
  getPublishedWorkflow(workflowId: string): Observable<Workflow> {
    return Utilities.getData<Workflow>(
      this._context,
      this._http,
      Workflow,
      '/api/workflow/getPublishedWorkflow/' + workflowId
    );
  }

  getFeeRecipients(clientId: string): Observable<FeeRecipient[]> {
    return Utilities.getDataList<FeeRecipient>(
      this._context,
      this._http,
      FeeRecipient,
      '/api/client/GetFeeRecipients',
      { clientId }
    );
  }

  saveFeeRecipient(feeRecipient: FeeRecipient): Observable<FeeRecipient> {
    return Utilities.postData<FeeRecipient>(
      this._context,
      this._http,
      FeeRecipient,
      '/api/client/SaveFeeRecipient',
      feeRecipient
    );
  }

  deleteFeeRecipient(feeRecipientId: string): Observable<boolean> {
    return Utilities.postData<boolean>(
      this._context,
      this._http,
      null,
      '/api/client/DeleteFeeRecipient',
      null,
      null,
      null,
      { feeRecipientId }
    );
  }

  getFeeRecipientGenerationImpactReport(
    clientId: string
  ): Observable<FeeRecipientGenerationImpactReport> {
    return Utilities.getData<FeeRecipientGenerationImpactReport>(
      this._context,
      this._http,
      FeeRecipientGenerationImpactReport,
      '/api/client/GetFeeRecipientGenerationImpactReport/',
      { clientId: clientId }
    );
  }

  generateFeeRecipients(
    request: FeeRecipientGenerationRequest
  ): Observable<FeeRecipientGenerationResponse> {
    return Utilities.postData<FeeRecipientGenerationResponse>(
      this._context,
      this._http,
      null,
      '/api/client/GenerateFeeRecipients',
      request,
      null,
      null
    );
  }

  getGlobalCustomLists(): Observable<CustomList[]> {
    return Utilities.getDataList<CustomList>(
      this._context,
      this._http,
      CustomList,
      '/api/system/GetGlobalCustomLists',
      null
    );
  }

  getGlobalCustomList(customListId: string): Observable<CustomList> {
    return Utilities.getData<CustomList>(
      this._context,
      this._http,
      CustomList,
      '/api/system/GetGlobalCustomList/',
      { customListId: customListId }
    );
  }

  saveGlobalCustomList(list: CustomList): Observable<CustomList> {
    return Utilities.postData<CustomList>(
      this._context,
      this._http,
      CustomList,
      '/api/system/SaveGlobalCustomList',
      list
    );
  }

  deleteGlobalCustomList(customListId: string): Observable<void> {
    return Utilities.postData<void>(
      this._context,
      this._http,
      null,
      '/api/system/DeleteGlobalCustomList/',
      null,
      null,
      null,
      { customListId }
    );
  }

  deleteGlobalCustomListItem(listItemId: string): Observable<void> {
    return Utilities.postData<void>(
      this._context,
      this._http,
      null,
      '/api/system/DeleteGlobalCustomListItem/',
      null,
      null,
      null,
      { listItemId: listItemId }
    );
  }

  getCustomLists(clientId: string): Observable<CustomList[]> {
    return Utilities.getDataList<CustomList>(
      this._context,
      this._http,
      CustomList,
      '/api/client/lists',
      {
        clientId
      }
    );
  }
  getCustomList(customListId: string): Observable<CustomList> {
    return Utilities.getData<CustomList>(
      this._context,
      this._http,
      CustomList,
      `/api/client/lists/${customListId}`
    );
  }
  saveCustomList(list: CustomList): Observable<CustomList> {
    return Utilities.postData<CustomList>(
      this._context,
      this._http,
      CustomList,
      '/api/client/lists',
      list
    );
  }
  deleteCustomList(customListId: string): Observable<void> {
    return Utilities.postData<void>(
      this._context,
      this._http,
      null,
      `/api/client/lists/${customListId}/delete`,
      null
    );
  }

  deleteCustomListItem(customListId: string, itemId: string): Observable<void> {
    return Utilities.postData<void>(
      this._context,
      this._http,
      null,
      `/api/client/lists/${customListId}/items/${itemId}/delete`,
      null
    );
  }
  getContacts(id: string): Observable<Contact[]> {
    return Utilities.getDataList<Contact>(
      this._context,
      this._http,
      Contact,
      '/api/client/getcontacts/' + id
    );
  }
  getContact(contactId: string): Observable<Contact> {
    return Utilities.getData<Contact>(
      this._context,
      this._http,
      Contact,
      '/api/client/getContact/' + contactId
    );
  }
  getMapLayers(endpointUrl: string): Observable<MapLayer[]> {
    return Utilities.getDataList<MapLayer>(
      this._context,
      this._http,
      MapLayer,
      '/api/map/getMapLayers/',
      { endpointUrl: escape(endpointUrl) }
    );
  }
  getIndexedMapLayers(mapServiceId: string): Observable<MapLayer[]> {
    return Utilities.getDataList<MapLayer>(
      this._context,
      this._http,
      MapLayer,
      '/api/map/GetIndexedMapLayers/',
      { mapServiceId }
    );
  }

  getMapServers(): Observable<MapServer[]> {
    return Utilities.getDataList<MapServer>(
      this._context,
      this._http,
      MapServer,
      '/api/map/getMapServers/'
    );
  }
  getMapServices(clientId: string): Observable<MapService[]> {
    return Utilities.getDataList<MapService>(
      this._context,
      this._http,
      MapService,
      '/api/map/getMapServices/',
      { clientId }
    );
  }
  getMapService(id: string): Observable<MapService> {
    return Utilities.getData<MapService>(
      this._context,
      this._http,
      MapService,
      '/api/map/getMapService/' + id
    );
  }
  deleteMapService(mapService: MapService): Observable<boolean> {
    return Utilities.postData<boolean>(
      this._context,
      this._http,
      null,
      '/api/map/deleteMapService/' + mapService.id,
      null
    );
  }
  getMapLayerIndex(mapServiceId: string): Observable<MapIndexLayer[]> {
    return Utilities.getDataList<MapIndexLayer>(
      this._context,
      this._http,
      MapIndexLayer,
      '/api/map/getMapLayerIndex/',
      { mapServiceId: mapServiceId }
    );
  }
  indexMapServiceLayers(mapServiceId: string): Observable<boolean> {
    return Utilities.postData(
      this._context,
      this._http,
      null,
      '/api/system/indexMapService/',
      {
        id: mapServiceId
      }
    );
  }
  saveMapLayerIndexItem(mapLayer: MapIndexLayer): Observable<MapIndexLayer> {
    return Utilities.postData(
      this._context,
      this._http,
      MapIndexLayer,
      '/api/system/saveMapLayerIndexItem/',
      mapLayer
    );
  }
  getWorkflowCheckpoints(id: string): Observable<WorkflowCheckpoint[]> {
    return Utilities.getDataList<WorkflowCheckpoint>(
      this._context,
      this._http,
      WorkflowCheckpoint,
      '/api/workflow/getWorkflowCheckpoints/' + id
    );
  }
  saveMapService(mapService: MapService): Observable<MapService> {
    return Utilities.postData<MapService>(
      this._context,
      this._http,
      MapService,
      '/api/system/saveMapService/',
      mapService
    );
  }
  getClientMapServices(client?: Client): Observable<MapService[]> {
    return Utilities.getDataList<MapService>(
      this._context,
      this._http,
      MapService,
      '/api/system/getClientMapServices/' + (client ? client.id : '')
    );
  }
  getApplicationConfiguration(): Observable<ApplicationConfiguration> {
    return Utilities.getData<ApplicationConfiguration>(
      this._context,
      this._http,
      ApplicationConfiguration,
      '/api/system/getApplicationConfiguration/'
    );
  }
  addPermission(
    roleId: string,
    actionId: string,
    securableId?: string
  ): Observable<Permission> {
    return Utilities.postData<Permission>(
      this._context,
      this._http,
      Permission,
      '/api/security/addPermission/',
      null,
      null,
      null,
      { roleId: roleId, actionId: actionId, securableId: securableId }
    );
  }
  removePermission(
    roleId: string,
    actionId: string,
    securableId?: string
  ): Observable<boolean> {
    return Utilities.postData<boolean>(
      this._context,
      this._http,
      null,
      '/api/security/removePermission/',
      null,
      null,
      null,
      {
        roleId: roleId,
        actionId: actionId || '',
        securableId: securableId || ''
      }
    );
  }
  getAllActions(clientId?: string): Observable<Action[]> {
    return Utilities.getDataList<Action>(
      this._context,
      this._http,
      Action,
      '/api/security/getAllActions/',
      {
        clientId: clientId
      }
    );
  }
  getActionsForRole(roleId: string): Observable<{ [key: string]: string[] }> {
    return Utilities.postData<{ [key: string]: string[] }>(
      this._context,
      this._http,
      null,
      '/api/security/getActionsForRole/',
      null,
      null,
      null,
      {
        roleId: roleId
      }
    );
  }
  getActionsForUser(
    user: Partial<User>
  ): Observable<{ [key: string]: string[] }> {
    if (user) {
      return Utilities.postData<{ [key: string]: string[] }>(
        this._context,
        this._http,
        null,
        '/api/security/getActionsForUser/',
        null,
        null,
        null,
        {
          userId: user.id
        }
      );
    } else {
      return of(null);
    }
  }
  getRolesWithAnyActions(
    clientId: string,
    actions: string[]
  ): Observable<Role[]> {
    return Utilities.postDataList<Role>(
      this._context,
      this._http,
      Role,
      '/api/security/getRolesWithAnyActions/',
      actions,
      { clientId }
    );
  }
  getRolesWithAllActions(
    clientId: string,
    actions: string[]
  ): Observable<Role[]> {
    return Utilities.postDataList<Role>(
      this._context,
      this._http,
      Role,
      '/api/security/getRolesWithAllActions/',
      actions,
      { clientId }
    );
  }
  getRoles(client: Client): Observable<Role[]> {
    return Utilities.getDataList<Role>(
      this._context,
      this._http,
      Role,
      '/api/security/getRoles/',
      { clientId: client ? client.id : null }
    );
  }
  getAllRoles(): Observable<Role[]> {
    return Utilities.getDataList<Role>(
      this._context,
      this._http,
      Role,
      '/api/security/getAllRoles/'
    );
  }
  isWorkflowApplicationInProgress(applicationId: string): Observable<boolean> {
    return Utilities.getData<boolean>(
      this._context,
      this._http,
      null,
      '/api/workflow/IsWorkflowApplicationInProgress',
      { applicationId: applicationId }
    );
  }
  getWorkflowRoles(workflowId: string): Observable<Role[]> {
    return Utilities.getDataList<Role>(
      this._context,
      this._http,
      Role,
      '/api/workflow/GetWorkflowRoles',
      { workflowId: workflowId }
    );
  }
  searchRoles(client: Client, searchText: string): Observable<Role[]> {
    return Utilities.postDataList<Role>(
      this._context,
      this._http,
      Role,
      '/api/security/searchRoles/',
      null,
      { clientId: client ? client.id : null, searchText: searchText }
    );
  }
  searchUsers(client: Client, options: UserSearchOptions): Observable<User[]> {
    return Utilities.postDataList<User>(
      this._context,
      this._http,
      User,
      '/api/security/searchUsers/',
      options,
      { clientId: client ? client.id : null }
    );
  }
  associateRole(user: User, role: Partial<Role>): Observable<Role> {
    return Utilities.postData<Role>(
      this._context,
      this._http,
      Role,
      '/api/security/associateRole/',
      { userId: user.id, roleId: role.id }
    );
  }
  removeUserRole(userId: string, roleId: string): Observable<boolean> {
    return Utilities.postData<boolean>(
      this._context,
      this._http,
      null,
      '/api/security/removeUserRole/',
      null,
      null,
      null,
      { userId: userId, roleId: roleId }
    );
  }
  getRole(id: string): Observable<Role> {
    return Utilities.getData<Role>(
      this._context,
      this._http,
      Role,
      '/api/security/getrole/' + id
    );
  }
  saveRole(role: Partial<Role>): Observable<Role> {
    const r = JsonNetDecycle.decycle(role);
    return Utilities.postData<Role>(
      this._context,
      this._http,
      Role,
      '/api/security/saverole/',
      r
    );
  }
  canDeleteRole(role: Partial<Role>): Observable<CanDeleteRoleResponse> {
    return Utilities.postData<CanDeleteRoleResponse>(
      this._context,
      this._http,
      null,
      `/api/security/candeleterole/${role.id}`,
      null
    );
  }
  deleteRole(role: Partial<Role>): Observable<Role> {
    const r = JsonNetDecycle.decycle(role);
    return Utilities.postData<Role>(
      this._context,
      this._http,
      Role,
      '/api/security/deleterole/',
      r
    );
  }
  getUserRoles(userId: string): Observable<Role[]> {
    return Utilities.getDataList<Role>(
      this._context,
      this._http,
      Role,
      '/api/security/getuserroles/' + userId
    );
  }
  getStates(): Observable<string[]> {
    return Utilities.getDataList<string>(
      this._context,
      this._http,
      null,
      '/api/system/getstates/'
    );
  }

  getParcelDataSources(
    clientId?: string
  ): Observable<ExternalDataConnection[]> {
    return Utilities.getDataList<ExternalDataConnection>(
      this._context,
      this._http,
      ExternalDataConnection,
      '/api/system/getparceldatasources/' + clientId
    );
  }
  getParcelDataSourceFields(parcelDataSourceId: string): Observable<string[]> {
    return Utilities.getDataList<string>(
      this._context,
      this._http,
      null,
      '/api/system/getparceldatasourcefields/',
      { parcelDataSourceId: parcelDataSourceId }
    );
  }
  getCommunityWorkflows(): Observable<CommunityWorkflow[]> {
    return Utilities.getDataList<CommunityWorkflow>(
      this._context,
      this._http,
      CommunityWorkflow,
      '/api/workflow/getcommunityworkflows/'
    );
  }
  getExternalDataConnection(id: string): Observable<ExternalDataConnection> {
    return Utilities.getData<ExternalDataConnection>(
      this._context,
      this._http,
      ExternalDataConnection,
      '/api/system/getexternaldataconnection/' + id
    );
  }
  searchExternalDataConnections(options): Observable<ExternalDataConnection[]> {
    return Utilities.postDataList<ExternalDataConnection>(
      this._context,
      this._http,
      ExternalDataConnection,
      '/api/system/searchexternaldataconnections/',
      options
    );
  }
  deleteExecternalDataConnection(
    edc: ExternalDataConnection
  ): Observable<boolean> {
    return Utilities.postData<boolean>(
      this._context,
      this._http,
      null,
      '/api/system/deleteExternalDataConnection/',
      edc
    );
  }
  getCommunityWorkflow(id: string): Observable<CommunityWorkflow> {
    return Utilities.getData<CommunityWorkflow>(
      this._context,
      this._http,
      CommunityWorkflow,
      '/api/workflow/getcommunityworkflow/' + id
    );
  }
  searchCommunityWorkflows(
    searchText: string
  ): Observable<CommunityWorkflow[]> {
    return Utilities.postDataList<CommunityWorkflow>(
      this._context,
      this._http,
      CommunityWorkflow,
      'api/workflow/searchCommunityWorkflows/',
      searchText
    );
  }
  saveAddress(address: Address): Observable<Address> {
    return Utilities.postData<Address>(
      this._context,
      this._http,
      Address,
      '/api/system/saveAddress/',
      address
    );
  }
  saveEmailAddress(emailAddress: EmailAddress): Observable<EmailAddress> {
    return Utilities.postData<EmailAddress>(
      this._context,
      this._http,
      EmailAddress,
      '/api/system/saveEmailAddress/',
      emailAddress
    );
  }
  savePhoneNumber(phoneNumber: PhoneNumber): Observable<PhoneNumber> {
    return Utilities.postData<PhoneNumber>(
      this._context,
      this._http,
      PhoneNumber,
      '/api/system/savePhoneNumber/',
      phoneNumber
    );
  }
  // getContractorDocuments(
  //   parentId: string,
  //   parentType: string
  // ): Observable<Document[]> {
  //   return Utilities.getDataList<Document>(
  //     this._context,
  //     this._http,
  //     Document,
  //     "/api/document/getParentDocuments/",
  //     { parentId: parentId, parentType: parentType }
  //   );
  // }
  getDocumentInfo(documentId: string): Observable<Document> {
    return Utilities.getData<Document>(
      this._context,
      this._http,
      Document,
      '/api/document/getDocumentInfo/',
      { documentId: documentId }
    );
  }
  getDocumentBytes(documentId: string): Observable<any> {
    return Utilities.getData<number[]>(
      this._context,
      this._http,
      null,
      '/api/document/getDocumentBytes/',
      { documentId: documentId }
    );
  }
  saveDocument(document: Document): Observable<Document> {
    return Utilities.postData<Document>(
      this._context,
      this._http,
      Document,
      '/api/document/saveDocumentInfo/',
      document
    );
  }
  saveExternalDataConnection(
    edc: ExternalDataConnection
  ): Observable<ExternalDataConnection> {
    return Utilities.postData<ExternalDataConnection>(
      this._context,
      this._http,
      ExternalDataConnection,
      '/api/system/saveExternalDataConnection/',
      edc
    );
  }
  saveContractorRequirementType(
    requirementType: ContractorRequirementType
  ): Observable<ContractorRequirementType> {
    return Utilities.postData<ContractorRequirementType>(
      this._context,
      this._http,
      ContractorRequirementType,
      '/api/system/savecontractorrequirementtype/',
      requirementType
    );
  }
  saveWorkflowChanges(
    workflowId: string,
    changes: any[],
    designStatus: ApplicationStatus
  ): Observable<boolean> {
    return Utilities.postData<boolean>(
      this._context,
      this._http,
      null,
      '/api/workflow/saveWorkflowChanges/',
      changes,
      null,
      null,
      { workflowId, designerStatus: designStatus }
    );
  }

  saveActivityDefinition(
    workflowId: string,
    activity: Activities.Activity<ActivityModel>
  ): Observable<void> {
    return Utilities.postData<void>(
      this._context,
      this._http,
      null,
      '/api/workflow/saveActivityDefinition/',
      activity,
      null,
      null,
      { workflowId }
    );
  }

  preSerializeStep(activity: Activity<ActivityModel>) {
    if (activity && activity instanceof Activities.FormActivity) {
      const entities = (<Activities.FormActivity>activity).model.getEntities();

      entities.forEach(e => {
        e.preStringify();
      });
    }
  }

  saveActivity(
    applicationId: string,
    activity: Activities.Activity<ActivityModel>,
    editingCompletedApplication = false
  ): Observable<SaveActivityResponse> {
    this.preSerializeStep(activity);
    return Utilities.postData<SaveActivityResponse>(
      this._context,
      this._http,
      null,
      '/api/workflow/saveActivity/',
      activity,
      null,
      null,
      {
        applicationId: applicationId,
        editingCompletedApplication: editingCompletedApplication
      }
    );
  }
  navigateToActivity(
    applicationId: string,
    activityDataId: string,
    isSummaryView: boolean = false
  ): Observable<NavigateToActivityResponse> {
    return Utilities.getData<NavigateToActivityResponse>(
      this._context,
      this._http,
      NavigateToActivityResponse,
      '/api/workflow/navigateToActivity/' + applicationId + '/' + activityDataId + '/' + isSummaryView
    );
  }
  navigatePrevious(
    applicationId: string,
    activity: Activity<ActivityModel>
  ): Observable<NavigateToActivityResponse> {
    this.preSerializeStep(activity);
    return Utilities.postData<NavigateToActivityResponse>(
      this._context,
      this._http,
      NavigateToActivityResponse,
      '/api/workflow/navigatePrevious/',
      activity,
      null,
      null,
      { applicationId: applicationId }
    );
  }
  processActivity(
    applicationId: string,
    activity: Activities.Activity<ActivityModel>,
    isNavigating: boolean
  ): Observable<{
    applicationId: string;
    applicationNumber: string;
    clientApplicationNumber: string;
    nextActivity: Activity<ActivityModel>;
    navigationActivities: ActivityNavigationInfo[];
    fees: FeeDataEntity[];
    hasFees: boolean;
    refreshUser: boolean;
  }> {
    this.preSerializeStep(activity);
    return Utilities.postData<{
      applicationId: string;
      applicationNumber: string;
      clientApplicationNumber: string;
      nextActivity: Activity<ActivityModel>;
      navigationActivities: ActivityNavigationInfo[];
      fees: FeeDataEntity[];
      hasFees: boolean;
      refreshUser: boolean;
    }>(
      this._context,
      this._http,
      null,
      '/api/workflow/processActivity/',
      activity,
      null,
      null,
      { applicationId, isNavigating }
    );
  }

  importPreviousSystemData(
    request: PreviousSystemDataImportRequest
  ): Observable<PreviousSystemDataImportResponse> {
    return Utilities.postData<PreviousSystemDataImportResponse>(
      this._context,
      this._http,
      null,
      '/api/Workflow/ImportPreviousSystemData/',
      request
    );
  }

  saveWorkflowApplicationNotes(
    applicationId: string,
    internalNotes: string,
    externalNotes: string
  ): Observable<boolean> {
    return Utilities.postData<boolean>(
      this._context,
      this._http,
      null,
      '/api/Workflow/SaveWorkflowApplicationNotes/',
      {
        applicationId,
        internalNotes,
        externalNotes
      }
    );
  }
  saveWorkflowApplication(application: Workflow): Observable<Workflow> {
    return Utilities.postData<Workflow>(
      this._context,
      this._http,
      Workflow,
      '/api/Workflow/SaveWorkflowApplication/',
      application
    );
  }
  saveWorkflow(workflow: Workflow): Observable<Workflow> {
    const wk = workflow;

    wk.workflowTags = null; // TODO: Remove once we can figure out how to get around $ref serialization issue
    if (wk.client) {
      wk.clientID = wk.client.id;
      wk.client = null;
    }
    wk.version.workflow = null;

    return Utilities.postData<Workflow>(
      this._context,
      this._http,
      Workflow,
      '/api/Workflow/SaveWorkflow/',
      wk
    );
  }
  archiveWorkflow(
    workflowId: string,
    archiveNotes: string
  ): Observable<boolean> {
    return Utilities.postData<boolean>(
      this._context,
      this._http,
      null,
      '/api/Workflow/ArchiveWorkflow/',
      { workflowId: workflowId, archiveNotes: archiveNotes }
    );
  }
  restoreWorkflow(workflowId: string): Observable<boolean> {
    return Utilities.postData<boolean>(
      this._context,
      this._http,
      null,
      '/api/Workflow/RestoreWorkflow/',
      null,
      null,
      null,
      { workflowId }
    );
  }
  restoreWorkflowCheckpoint(checkpointId: string): Observable<boolean> {
    return Utilities.postData<boolean>(
      this._context,
      this._http,
      null,
      '/api/Workflow/RestoreCheckpoint',
      null,
      null,
      null,
      { checkpointId: checkpointId }
    );
  }
  cleanupWorkflowHistory(workflowId: string): Observable<boolean> {
    return Utilities.postData<boolean>(
      this._context,
      this._http,
      null,
      '/api/Workflow/CleanupWorkflowHistory/' + workflowId,
      null
    );
  }
  disableWorkflow(workflowId: string): Observable<boolean> {
    return Utilities.postData<boolean>(
      this._context,
      this._http,
      null,
      '/api/Workflow/DisableWorkflow/',
      null,
      null,
      null,
      { workflowId: workflowId }
    );
  }
  enableWorkflow(workflowId: string): Observable<boolean> {
    return Utilities.postData<boolean>(
      this._context,
      this._http,
      null,
      '/api/Workflow/EnableWorkflow/',
      null,
      null,
      null,
      { workflowId: workflowId }
    );
  }
  publishWorkflow(workflowId: string): Observable<string> {
    return Utilities.postData<string>(
      this._context,
      this._http,
      null,
      '/api/Workflow/PublishWorkflow/' + workflowId,
      null,
      null,
      null
    );
  }
  hasOnlinePaymentIntegration(client: Client): Observable<boolean> {
    return Utilities.postData<boolean>(
      this._context,
      this._http,
      null,
      '/api/system/hasOnlinePaymentIntegration/' + client.id,
      null
    );
  }
  isRoleNameAvailable(clientId: string, roleName: string, roleId: string): any {
    return Utilities.getData<any>(
      this._context,
      this._http,
      null,
      '/api/security/IsRoleNameAvailable/' + clientId + '/' + roleId,
      {
        roleName: escape(roleName)
      }
    );
  }
  isTemplateCodeAvailable(
    workflowId: string,
    templateCode: string,
    label: string
  ): any {
    return Utilities.postData<any>(
      this._context,
      this._http,
      null,
      '/api/workflow/IsTemplateCodeAvailable',
      {
        workflowId,
        templateCode,
        label
      }
    );
  }
  getWorkflowFees(
    workflowId: string,
    paymentActivityId: string
  ): Observable<FeeDataEntity[]> {
    return Utilities.postDataList<FeeDataEntity>(
      this._context,
      this._http,
      FeeDataEntity,
      '/api/workflow/GetWorkflowFees/' + workflowId + '/' + paymentActivityId,
      null
    );
  }

  getWorkflowApplicationFees(
    applicationId: string,
    paymentActivityDataId: string,
    ignoreStatus: boolean = false
  ): Observable<FeeDataEntity[]> {
    return Utilities.postDataList<FeeDataEntity>(
      this._context,
      this._http,
      FeeDataEntity,
      '/api/workflow/GetWorkflowApplicationFees/' +
        applicationId +
        '/' +
        paymentActivityDataId +
        '/' +
        ignoreStatus,
      null
    );
  }
  getNavigationPanelItems(
    applicationId: string,
    currentActivityId: string
  ): Observable<ActivityNavigationInfo[]> {
    return Utilities.getDataList<ActivityNavigationInfo>(
      this._context,
      this._http,
      ActivityNavigationInfo,
      '/api/workflow/GetNavigationPanelItems/' +
        applicationId +
        '/' +
        currentActivityId
    );
  }
  getGuidepostSources(): Observable<ParcelDataSource[]> {
    return Utilities.getDataList<ParcelDataSource>(
      this._context,
      this._http,
      null,
      '/api/system/getGuidepostSources/'
    );
  }
  hasParcelDataSource(clientId: string) {
    return Utilities.getData<boolean>(
      this._context,
      this._http,
      null,
      '/api/system/HasParcelDataSource/' + clientId
    );
  }
  getFeeRecipientReport(
    params: FeeRecipientReportRequest
  ): Observable<FeeRecipientReportResponse> {
    return Utilities.postData<FeeRecipientReportResponse>(
      this._context,
      this._http,
      null,
      '/api/system/GetFeeRecipientReport',
      params,
      null,
      null
    );
  }
  getPagedFeeRecipientReport(
    params: PagedFeeRecipientReportRequest
  ): Observable<PagedFeeRecipientReportResponse> {
    return Utilities.postData<PagedFeeRecipientReportResponse>(
      this._context,
      this._http,
      null,
      '/api/system/GetPagedFeeRecipientReport',
      params,
      null,
      null
    );
  }
  exportFeeRecipientReport(params: FeeRecipientReportRequest): Observable<any> {
    return Utilities.postDataFile<any>(
      this._context,
      this._http,
      null,
      '/api/system/ExportFeeRecipientReport',
      params,
      null,
      null,
      null,
      'arraybuffer'
    );
  }
  getDailyFeeReport(
    params: DailyFeeReportRequest
  ): Observable<DailyFeeReportResponse> {
    return Utilities.postData<DailyFeeReportResponse>(
      this._context,
      this._http,
      null,
      '/api/system/GetDailyFeeReport',
      params,
      null,
      null
    );
  }
  getPagedDailyFeeReport(
    params: PagedDailyFeeReportRequest
  ): Observable<PagedDailyFeeReportResponse> {
    return Utilities.postData<PagedDailyFeeReportResponse>(
      this._context,
      this._http,
      null,
      '/api/system/GetPagedDailyFeeReport',
      params,
      null,
      null
    );
  }
  getPaymentsReport(
    params: PaymentsReportRequest
  ): Observable<PaymentReportResponse> {
    return Utilities.postData<PaymentReportResponse>(
      this._context,
      this._http,
      null,
      '/api/system/GetPaymentsReport',
      params,
      null,
      null
    );
  }
  getPagedPaymentsReport(
    params: PagedPaymentsReportRequest
  ): Observable<PagedPaymentReportResponse> {
    return Utilities.postData<PagedPaymentReportResponse>(
      this._context,
      this._http,
      null,
      '/api/system/GetPagedPaymentsReport',
      params,
      null,
      null
    );
  }
  exportPaymentsReport(params: PaymentsReportRequest): Observable<any> {
    return Utilities.postDataFile<any>(
      this._context,
      this._http,
      null,
      '/api/system/ExportPaymentsReport',
      params,
      null,
      null,
      null,
      'arraybuffer'
    );
  }
  exportDailyFeeReport(params: DailyFeeReportRequest): Observable<any> {
    return Utilities.postDataFile<any>(
      this._context,
      this._http,
      null,
      '/api/system/ExportDailyFeeReport',
      params,
      null,
      null,
      null,
      'arraybuffer'
    );
  }
  exportInspectionQueue(
    clientId: string,
    sortField: string,
    sortDescending: boolean,
    filterObjects: any,
    pageNumber: number,
    pageSize: number,
    ext: MapExtent,
    useExcelFormat: boolean,
    exportColumns: string[]
  ): Observable<any> {
    pageNumber = pageNumber >= 0 ? pageNumber : 0;
    pageSize = pageSize ? pageSize : 10;
    const additionalFilters = filterObjects ? filterObjects : null;
    const searchOptions = {
      clientId: clientId,
      additionalFilters: additionalFilters,
      sortField: sortField,
      sortDescending: sortDescending,
      pageNumber: pageNumber,
      pageSize: pageSize,
      Extent: ext,
      useExcelFormat: useExcelFormat,
      exportColumns: exportColumns
    };
    return Utilities.postDataFile<any>(
      this._context,
      this._http,
      null,
      '/api/workflow/ExportInspections/',
      searchOptions,
      null,
      null,
      null,
      'arraybuffer'
    );
  }
  getContractorSummaryReport(
    params: ContractorSummaryReportRequest
  ): Observable<ContractorSummaryReportResponse> {
    return Utilities.postData<ContractorSummaryReportResponse>(
      this._context,
      this._http,
      null,
      '/api/contractor/GetContractorSummaryReport',
      params,
      null,
      null
    );
  }
  getPagedContractorSummaryReport(
    params: PagedContractorSummaryReportRequest
  ): Observable<PagedContractorSummaryReportResponse> {
    return Utilities.postData<PagedContractorSummaryReportResponse>(
      this._context,
      this._http,
      null,
      '/api/contractor/GetPagedContractorSummaryReport',
      params,
      null,
      null
    );
  }

  exportContractorSummaryReport(
    params: ContractorSummaryReportRequest
  ): Observable<any> {
    return Utilities.postDataFile<any>(
      this._context,
      this._http,
      null,
      '/api/contractor/ExportContractorSummaryReport',
      params,
      null,
      null,
      null,
      'arraybuffer'
    );
  }
  getContractorFeeSummaryReport(
    params: ContractorFeeSummaryReportRequest
  ): Observable<ContractorFeeSummaryReportResponse> {
    return Utilities.postData<ContractorFeeSummaryReportResponse>(
      this._context,
      this._http,
      null,
      '/api/contractor/GetContractorFeeSummaryReport',
      params,
      null,
      null
    );
  }
  getPagedContractorFeeSummaryReport(
    params: PagedContractorFeeSummaryReportRequest
  ): Observable<PagedContractorFeeSummaryReportResponse> {
    return Utilities.postData<PagedContractorFeeSummaryReportResponse>(
      this._context,
      this._http,
      null,
      '/api/contractor/GetPagedContractorFeeSummaryReport',
      params,
      null,
      null
    );
  }
  exportContractorFeeSummaryReport(
    params: ContractorFeeSummaryReportRequest
  ): Observable<any> {
    return Utilities.postDataFile<any>(
      this._context,
      this._http,
      null,
      '/api/contractor/ExportContractorFeeSummaryReport',
      params,
      null,
      null,
      null,
      'arraybuffer'
    );
  }
  getPermitSummaryReport(
    params: PermitSummaryReportRequest
  ): Observable<PermitSummaryReportResponse> {
    return Utilities.postData<PermitSummaryReportResponse>(
      this._context,
      this._http,
      null,
      '/api/workflow/GetPermitSummaryReport',
      params,
      null,
      null
    );
  }
  getPagedPermitSummaryReport(
    params: PagedPermitSummaryReportRequest
  ): Observable<PagedPermitSummaryReportResponse> {
    return Utilities.postData<PagedPermitSummaryReportResponse>(
      this._context,
      this._http,
      null,
      '/api/workflow/GetPagedPermitSummaryReport',
      params,
      null,
      null
    );
  }
  exportPermitSummaryReport(
    params: PermitSummaryReportRequest
  ): Observable<any> {
    return Utilities.postDataFile<any>(
      this._context,
      this._http,
      null,
      '/api/workflow/ExportPermitSummaryReport',
      params,
      null,
      null,
      null,
      'arraybuffer'
    );
  }
  initNewContractorType(clientId: string): Observable<ContractorType> {
    return Utilities.getData<ContractorType>(
      this._context,
      this._http,
      ContractorType,
      '/api/contractor/InitNewContractorType/' + clientId
    );
  }
  deleteDataEntity(
    workflowId: string,
    templateCode: string
  ): Observable<boolean> {
    return Utilities.postData<boolean>(
      this._context,
      this._http,
      null,
      '/api/workflow/deleteDataEntity/' +
        workflowId +
        '/' +
        escape(templateCode),
      null
    );
  }
  getContractorDocumentCategories(): Observable<ContractorDocumentCategory[]> {
    return Utilities.getDataList<ContractorDocumentCategory>(
      this._context,
      this._http,
      ContractorDocumentCategory,
      '/api/contractor/getContractorDocumentCategories'
    );
  }
  saveContractorDocumentCategory(
    category: ContractorDocumentCategory
  ): Observable<boolean> {
    return Utilities.postData<boolean>(
      this._context,
      this._http,
      null,
      '/api/contractor/saveContractorDocumentCategory',
      category
    );
  }
  deleteContractorDocumentCategory(categoryId: string): Observable<boolean> {
    return Utilities.postData<boolean>(
      this._context,
      this._http,
      null,
      '/api/contractor/deleteContractorDocumentCategory',
      null,
      null,
      null,
      { contractorDocumentCategoryId: categoryId }
    );
  }
  publishContractorType(contractorTypeId: string) {
    return Utilities.postData<boolean>(
      this._context,
      this._http,
      null,
      '/api/contractor/publishContractorType',
      null,
      null,
      null,
      { contractorTypeId: contractorTypeId }
    );
  }

  getApplicationSummary(
    applicationId: string
  ): Observable<{
    application: WorkflowApplication;
    fees: FeeDataEntity[];
    totalPaid: number;
    submitter: User;
    completedActivities: Activity<ActivityModel>[];
    registrationId: string;
  }> {
    return Utilities.getData<{
      application: WorkflowApplication;
      fees: FeeDataEntity[];
      totalPaid: number;
      submitter: User;
      completedActivities: Activity<ActivityModel>[];
      registrationId: string;
    }>(
      this._context,
      this._http,
      null,
      '/api/workflow/GetWorkflowApplicationSummary/',
      { applicationId: applicationId }
    ).pipe(
      tap(appSummary => {
        if (appSummary) {
          this._context.client$.emit(
            appSummary.application.workflowVersion.workflow.client
          );
        }
      })
    );
  }

  addActivityToWorkflow(
    workflowId: string,
    previousActivityId: string,
    activity: Activity<ActivityModel>
  ): Observable<WorkflowGraph> {
    return Utilities.postData(
      this._context,
      this._http,
      WorkflowGraph,
      '/api/workflow/AddActivityToWorkflow',
      activity,
      null,
      null,
      {
        workflowId,
        previousActivityId
      }
    );
  }

  removeActivityFromWorkflow(
    workflowId: string,
    activityId: string
  ): Observable<WorkflowGraph> {
    return Utilities.postData(
      this._context,
      this._http,
      WorkflowGraph,
      '/api/workflow/RemoveActivityFromWorkflow',
      null,
      null,
      null,
      {
        workflowId,
        activityId
      }
    );
  }

  getAvailableContractorTypes(
    clientId: string,
    contractorId: string
  ): Observable<ContractorType[]> {
    return Utilities.getDataList<ContractorType>(
      this._context,
      this._http,
      ContractorType,
      '/api/contractor/GetAvailableContractorRegistrationTypes',
      { clientId: clientId, contractorId: contractorId }
    );
  }

  getClientsWithContractors(): Observable<Client[]> {
    return Utilities.getDataList<Client>(
      this._context,
      this._http,
      Client,
      '/api/client/GetClientsWithContractors'
    );
  }

  validateWorkflow(
    workflowId: string,
    runExtendedValidation: boolean,
    versionId?: string
  ) {
    return Utilities.postDataList<ValidationResponse>(
      this._context,
      this._http,
      null,
      `/api/workflow/ValidateWorkflowDesign/`,
      null,
      {
        workflowId,
        runExtendedValidation,
        workflowVersionId: versionId
      }
    );
  }

  validateWorkflowActivity(
    workflowId: string,
    activityId: string,
    fullTree?: boolean
  ): Observable<ValidationResponse[]> {
    return Utilities.postDataList<ValidationResponse>(
      this._context,
      this._http,
      null,
      `/api/workflow/ValidateWorkflowActivityDesign/`,
      { workflowId, activityIds: [activityId], validateFullTree: fullTree }
    );
  }

  validateWorkflowDataEntity(
    workflowId: string,
    activityId: string,
    templateCode: string
  ): Observable<ValidationResponse[]> {
    return Utilities.postDataList<ValidationResponse>(
      this._context,
      this._http,
      null,
      `/api/workflow/ValidateWorkflowDataEntity/`,
      { workflowId, activityId, templateCode }
    );
  }

  saveTag(tag: Tag): Observable<Tag> {
    return Utilities.postData(
      this._context,
      this._http,
      Tag,
      '/api/system/SaveTag',
      tag
    );
  }

  deleteTag(tag: Tag): Observable<void> {
    return Utilities.postData(
      this._context,
      this._http,
      null,
      '/api/system/deletetag',
      null,
      null,
      null,
      { tagId: tag.id }
    );
  }

  addWorkflowTag(workflowTag: WorkflowTag): Observable<void> {
    return Utilities.postData(
      this._context,
      this._http,
      null,
      '/api/system/addworkflowtag',
      workflowTag
    );
  }

  addContractorTypeTag(contractorTypeTag: ContractorTypeTag): Observable<void> {
    return Utilities.postData(
      this._context,
      this._http,
      null,
      '/api/system/addcontractorTypeTag',
      contractorTypeTag
    );
  }

  removeWorkflowTag(workflowTag: WorkflowTag): Observable<void> {
    return Utilities.postData(
      this._context,
      this._http,
      null,
      '/api/system/removeworkflowtag',
      workflowTag
    );
  }
  removeContractorTypeTag(
    contractorTypeTag: ContractorTypeTag
  ): Observable<void> {
    return Utilities.postData(
      this._context,
      this._http,
      null,
      '/api/system/removeContractorTypeTag',
      contractorTypeTag
    );
  }
  getTags(clientId: string): Observable<Tag[]> {
    return Utilities.getDataList(
      this._context,
      this._http,
      Tag,
      '/api/system/gettags',
      { clientId }
    );
  }

  getTagsForWorkflows(clientId: string): Observable<Tag[]> {
    return Utilities.getDataList(
      this._context,
      this._http,
      Tag,
      '/api/system/GetTagsForWorkflows',
      { clientId }
    );
  }
  getTagsForContractorTypes(clientId: string): Observable<Tag[]> {
    return Utilities.getDataList(
      this._context,
      this._http,
      Tag,
      '/api/system/GetTagsForContractorTypes',
      { clientId }
    );
  }
  getTimeZones(): Observable<TimeZoneInfo[]> {
    return Utilities.getDataList(
      this._context,
      this._http,
      TimeZoneInfo,
      '/api/system/GetTimezones'
    );
  }
  getActivitiesDefinedBeforeMe(
    workflowVersionId: string,
    activityId: string,
    excludeTypes?: string[],
    includeTypes?: string[]
  ): Observable<Activity<ActivityModel>[]> {
    return Utilities.postDataList(
      this._context,
      this._http,
      Activity,
      '/api/workflow/GetActivitiesDefinedBeforeMe',
      {
        workflowVersionId,
        activityId,
        filterTypes: includeTypes,
        excludeTypes
      }
    );
  }

  getWorkflowApplicationShareDetails(
    applicationId: string,
    activity: Activity<ActivityModel>
  ): Observable<SharedAccessDetails> {
    const request = new SharedAccessRequest();

    request.applicationId = applicationId;
    request.currentActivity = activity;
    request.currentActivityDataId = activity.activityDataId;

    return Utilities.postData(
      this._context,
      this._http,
      SharedAccessDetails,
      '/api/workflow/GetWorkflowApplicationShareDetails',
      request
    );
  }

  shareWorkflowApplication(details: SharedAccessDetails): Observable<void> {
    return Utilities.postData(
      this._context,
      this._http,
      null,
      '/api/workflow/ShareWorkflowApplication',
      details
    );
  }

  getPreviousSystemDataHeaders(
    params: PreviousSystemDataHeadersRequest
  ): Observable<PreviousSystemDataHeadersResponse> {
    return Utilities.postData<PreviousSystemDataHeadersResponse>(
      this._context,
      this._http,
      null,
      '/api/workflow/GetPreviousSystemPermitHeaders',
      params,
      null,
      null
    );
  }
  savePreviousSystemAttachment(
    params: PreviousSystemPermitAttachment
  ): Observable<PreviousSystemPermitAttachment> {
    return Utilities.postData<PreviousSystemPermitAttachment>(
      this._context,
      this._http,
      null,
      '/api/workflow/SavePreviousSystemPermitAttachment',
      params,
      null,
      null
    );
  }

  getPreviousSystemPermitAttachments(params: {
    headerId: number;
  }): Observable<PreviousSystemPermitAttachmentsResponse> {
    return Utilities.postData<PreviousSystemPermitAttachmentsResponse>(
      this._context,
      this._http,
      null,
      '/api/workflow/GetPreviousSystemPermitAttachments',
      params,
      null,
      null
    );
  }

  updatePreviousSystemPermitAttachmentPath(
    params: PreviousSystemPermitAttachment
  ): Observable<PreviousSystemPermitAttachment> {
    return Utilities.postData<PreviousSystemPermitAttachment>(
      this._context,
      this._http,
      null,
      '/api/workflow/UpdatePreviousSystemPermitAttachmentPath',
      params,
      null,
      null
    );
  }

  getPreviousSystemDataDetails(
    params: PreviousSystemDataDetailsRequest
  ): Observable<PreviousSystemDataDetailsResponse> {
    return Utilities.postData<PreviousSystemDataDetailsResponse>(
      this._context,
      this._http,
      null,
      '/api/workflow/GetPreviousSystemPermitDetails',
      params,
      null,
      null
    );
  }

  getRegistration(registrationId: string): Observable<Registration> {
    return Utilities.getData<Registration>(
      this._context,
      this._http,
      Registration,
      '/api/workflow/GetRegistration/',
      { registrationId: registrationId }
    );
  }

  searchRegistrationsUnpaged(
    clientId: string,
    sortField?: string,
    sortDescending?: boolean,
    filterObjects?: ItemSearchOptionField[],
    publicData: boolean = false
  ): Observable<UnpagedRegistrationVM> {
    const searchRegistrationOptions = {
      clientId,
      additionalFilters: filterObjects,
      sortField,
      sortDescending,
      publicData
    };
    return Utilities.postData<UnpagedRegistrationVM>(
      this._context,
      this._http,
      UnpagedRegistrationVM,
      '/api/workflow/SearchRegistrationsUnpaged/',
      searchRegistrationOptions,
      null,
      null
    );
  }

  searchRegistrationsPaged(
    clientId: string,
    sortField?: string,
    sortDescending?: boolean,
    filterObjects?: ItemSearchOptionField[],
    pageNumber: number = 1,
    pageSize: number = 10,
    publicData: boolean = false
  ): Observable<PagedRegistrationVM> {
    const searchRegistrationOptions = {
      clientId,
      additionalFilters: filterObjects,
      sortField,
      sortDescending,
      pageNumber,
      pageSize,
      publicData
    };
    return Utilities.postData<PagedRegistrationVM>(
      this._context,
      this._http,
      PagedRegistrationVM,
      '/api/workflow/SearchRegistrationsPaged/',
      searchRegistrationOptions,
      null,
      null
    );
  }

  exportRegistrations(
    clientId: string,
    useExcelFormat: boolean,
    exportColumns: string[],
    sortField?: string,
    sortDescending?: boolean,
    filterObjects?: ItemSearchOptionField[],
    pageNumber: number = 1,
    pageSize: number = 10,
    publicData: boolean = false
  ): Observable<any> {
    const searchRegistrationOptions = {
      clientId,
      useExcelFormat,
      exportColumns,
      additionalFilters: filterObjects,
      sortField,
      sortDescending,
      pageNumber,
      pageSize,
      publicData
    };
    return Utilities.postDataFile<any>(
      this._context,
      this._http,
      null,
      '/api/workflow/ExportRegistrations/',
      searchRegistrationOptions,
      null,
      null,
      null,
      'arraybuffer'
    );
  }

  saveRegistration(registration: Registration): Observable<Registration> {
    const cycReg: Registration = JsonNetDecycle.decycle(registration);
    return Utilities.postData(
      this._context,
      this._http,
      Registration,
      '/api/workflow/SaveRegistration',
      cycReg
    );
  }

  deleteRegistration(deleteRegistrationId: string): Observable<boolean> {
    return Utilities.postData<boolean>(
      this._context,
      this._http,
      null,
      '/api/workflow/DeleteRegistration',
      null,
      null,
      null,
      { registrationId: deleteRegistrationId }
    );
  }

  getRegistrationDocuments(
    registrationId: string
  ): Observable<RegistrationDocument[]> {
    return Utilities.getDataList<RegistrationDocument>(
      this._context,
      this._http,
      RegistrationDocument,
      '/api/workflow/GetRegistrationDocuments',
      { registrationId: registrationId }
    );
  }

  saveRegistrationDocuments(
    registrationId: string,
    documents: RegistrationDocument[]
  ): Observable<RegistrationDocument[]> {
    return Utilities.postDataList<RegistrationDocument>(
      this._context,
      this._http,
      RegistrationDocument,
      '/api/workflow/SaveRegistrationDocuments',
      { registrationId: registrationId, documents: documents }
    );
  }

  saveRegistrationDocument(
    document: RegistrationDocument
  ): Observable<RegistrationDocument> {
    const cycDoc: RegistrationDocument = JsonNetDecycle.decycle(document);
    return Utilities.postData(
      this._context,
      this._http,
      RegistrationDocument,
      '/api/workflow/SaveRegistrationDocument/',
      cycDoc
    );
  }

  saveRegistrationCustomFields(
    registration: Registration
  ): Observable<Registration> {
    const decycled = JsonNetDecycle.decycle(registration);
    return Utilities.postData<Registration>(
      this._context,
      this._http,
      Registration,
      '/api/workflow/SaveRegistrationCustomFields/',
      decycled
    );
  }

  deleteRegistrationDocument(documentId: string): Observable<void> {
    return Utilities.postData<void>(
      this._context,
      this._http,
      null,
      '/api/workflow/DeleteRegistrationDocument',
      null,
      null,
      null,
      { documentId: documentId }
    );
  }

  saveRegistrationNote(note: RegistrationNote): Observable<RegistrationNote> {
    const decycled = JsonNetDecycle.decycle(note);
    return Utilities.postData<RegistrationNote>(
      this._context,
      this._http,
      RegistrationNote,
      '/api/workflow/SaveRegistrationNote',
      decycled
    );
  }

  startRegistrationRenewal(
    registrationId: any,
    testRenewalWorkflowId: string,
    isTestApplication: boolean
  ): Observable<{ applicationId: string }> {
    return Utilities.postData<{ applicationId: string }>(
      this._context,
      this._http,
      null,
      '/api/workflow/StartRegistrationRenewal',
      null,
      null,
      null,
      {
        registrationId: registrationId,
        isTestApplication: isTestApplication,
        testRenewalWorkflowId: testRenewalWorkflowId
      }
    );
  }

  getRenewableParentIds(
    workflowId: string
  ): Observable<RenewableParentsResponse> {
    return Utilities.getData<RenewableParentsResponse>(
      this._context,
      this._http,
      RenewableParentsResponse,
      '/api/workflow/GetRenewableParentIds',
      { workflowId: workflowId }
    );
  }

  disableRegistration(registrationId: string): Observable<boolean> {
    return Utilities.postData<boolean>(
      this._context,
      this._http,
      null,
      '/api/workflow/DisableRenewableRegistration',
      null,
      null,
      null,
      { registrationId: registrationId }
    );
  }

  reEnableRegistration(registrationId: string): Observable<boolean> {
    return Utilities.postData<boolean>(
      this._context,
      this._http,
      null,
      '/api/workflow/ReEnableRenewableRegistration',
      null,
      null,
      null,
      { registrationId: registrationId }
    );
  }

  getAgendaTypes(clientId: string): Observable<AgendaType[]> {
    return Utilities.getDataList<AgendaType>(
      this._context,
      this._http,
      AgendaType,
      '/api/agenda/GetAgendaTypes/' + clientId
    );
  }

  saveDetailRows(
    detailRows: PreviousSystemDataDetail[],
    previousSystemDetailsRequest: PreviousSystemDataDetailsRequest,
    previousSystemDataHeader: PreviousSystemDataHeader
  ): Observable<SavePreviousSystemPermitResponse> {
    return Utilities.postData<SavePreviousSystemPermitResponse>(
      this._context,
      this._http,
      null,
      '/api/workflow/SaveDetailRows',
      {
        previousSystemDetailsResponse: detailRows,
        detailsRequest: previousSystemDetailsRequest,
        previousSystemDataHeader: previousSystemDataHeader
      }
    );
  }

  saveAgendaType(agendaType: AgendaType): Observable<AgendaType> {
    return Utilities.postData(
      this._context,
      this._http,
      AgendaType,
      '/api/agenda/SaveAgendaType',
      agendaType
    );
  }

  getAgendaType(agendaTypeId: string): Observable<AgendaType> {
    return Utilities.getData<AgendaType>(
      this._context,
      this._http,
      AgendaType,
      '/api/agenda/GetAgendaType/' + agendaTypeId
    );
  }

  getFullAgendaType(
    params: AgendaQueueItemsPagedRequest
  ): Observable<AgendaQueueItemsPagedResponse> {
    return Utilities.postData<AgendaQueueItemsPagedResponse>(
      this._context,
      this._http,
      null,
      '/api/agenda/GetAgendaQueueItemsForAgendaType',
      params,
      null,
      null
    );
  }

  saveAgenda(agenda: Agenda, updateAgendaItems = false): Observable<Agenda> {
    // solves circular ref
    agenda.agendaType = null;

    const params = {
      agenda: agenda,
      updateAgendaItems: updateAgendaItems
    };

    return Utilities.postData(
      this._context,
      this._http,
      Agenda,
      '/api/agenda/SaveAgenda',
      params,
      null,
      null
    );
  }

  saveAgendaQueueItem(
    agendaQueueItem: AgendaQueueItem
  ): Observable<AgendaQueueItem> {
    return Utilities.postData(
      this._context,
      this._http,
      AgendaQueueItem,
      '/api/agenda/SaveAgendaQueueItemAndAdvanceWorkflow',
      agendaQueueItem
    );
  }

  getAgenda(agendaId: string): Observable<Agenda> {
    return Utilities.getData<Agenda>(
      this._context,
      this._http,
      Agenda,
      '/api/agenda/GetAgenda/' + agendaId
    );
  }

  getAgendaWithPagedQueue(
    params: AgendaPagedRequest
  ): Observable<AgendaPagedResponse> {
    return Utilities.postData<AgendaPagedResponse>(
      this._context,
      this._http,
      null,
      '/api/agenda/GetAgendaWithPagedQueue',
      params,
      null,
      null
    );
  }

  printAgenda(params: PrintAgendaRequest): Observable<any> {
    return Utilities.postDataFile<any>(
      this._context,
      this._http,
      null,
      '/api/agenda/PrintAgenda',
      params,
      null,
      null,
      null,
      'arraybuffer'
    );
  }

  getActivity(
    applicationId: string,
    activityDataId: string
  ): Observable<Activity<ActivityModel>> {
    return Utilities.getData<Activity<ActivityModel>>(
      this._context,
      this._http,
      Activities.Activity,
      '/api/workflow/GetActivity/' + applicationId + '/' + activityDataId
    );
  }

  duplicateActivities(
    activities: Activity<ActivityModel>[]
  ): Observable<DuplicateActivityResponse> {
    const request = { activities: activities };

    return Utilities.postData<any>(
      this._context,
      this._http,
      null,
      '/api/workflow/DuplicateActivities',
      request,
      null,
      null,
      null
    );
  }

  getApplicationDiagramInfo(
    applicationId: string,
    iteration?: number
  ): Observable<ApplicationDiagramInfo> {
    return Utilities.postData<ApplicationDiagramInfo>(
      this._context,
      this._http,
      ApplicationDiagramInfo,
      '/api/workflow/GetApplicationDiagramInfo',
      null,
      null,
      null,
      { applicationId, iteration }
    );
  }

  getApplicationActivityInfo(
    appId: string,
    activityId: string
  ): Observable<Activity<ActivityModel>[]> {
    return Utilities.postDataList<Activity<ActivityModel>>(
      this._context,
      this._http,
      Activity,
      '/api/workflow/GetApplicationActivityInfo',
      null,
      {
        applicationId: appId,
        activityId: activityId
      }
    );
  }

  disableContractorRegistration(registrationId: string): Observable<boolean> {
    return Utilities.postData<boolean>(
      this._context,
      this._http,
      null,
      '/api/contractor/DisableContractorRegistration',
      null,
      null,
      null,
      { registrationId: registrationId }
    );
  }

  reEnableContractorRegistration(registrationId: string): Observable<boolean> {
    return Utilities.postData<boolean>(
      this._context,
      this._http,
      null,
      '/api/contractor/ReEnableContractorRegistration',
      null,
      null,
      null,
      { registrationId: registrationId }
    );
  }

  isProcessing(applicationId: string): Observable<boolean> {
    return Utilities.getData<boolean>(
      this._context,
      this._http,
      null,
      '/api/workflow/IsProcessing',
      {
        workflowApplicationId: applicationId
      }
    );
  }

  getScheduledExports(
    clientId: string,
    componentId: string
  ): Observable<ScheduledExportsResponse> {
    return Utilities.getData<ScheduledExportsResponse>(
      this._context,
      this._http,
      ScheduledExportsResponse,
      '/api/system/GetScheduledExports',
      { clientId: clientId, componentId: componentId }
    );
  }

  saveScheduledExport(
    scheduledExport: ScheduledExport
  ): Observable<ScheduledExport> {
    return Utilities.postData(
      this._context,
      this._http,
      ScheduledExport,
      '/api/system/SaveScheduledExport',
      scheduledExport
    );
  }

  calculateNextExportOn(options: ScheduleFrequencyOptions): Observable<string> {
    return Utilities.postData(
      this._context,
      this._http,
      null,
      '/api/system/CalculateNextExportOn',
      options
    );
  }

  runSchedulingEngine(durationMinutes: number): Observable<boolean> {
    return Utilities.getData<boolean>(
      this._context,
      this._http,
      null,
      '/api/system/RunSchedulingEngine',
      { durationMinutes: durationMinutes }
    );
  }

  reSendEmail(
    applicationId: string,
    activityDataId: string
  ): Observable<boolean> {
    return Utilities.postData<boolean>(
      this._context,
      this._http,
      null,
      '/api/workflow/ResendEmailActivity',
      null,
      null,
      null,
      {
        applicationId: applicationId,
        activityDataId: activityDataId
      }
    );
  }

  getCombinedQueryMapLayer(
    mapLayerIndexItemId: string
  ): Observable<QueryMapLayer> {
    return Utilities.getData<QueryMapLayer>(
      this._context,
      this._http,
      null,
      '/api/map/getCombinedQueryMapLayer',
      { mapLayerIndexItemId }
    );
  }

  getQueryMapLayers(clientId: string): Observable<QueryMapLayer[]> {
    return Utilities.getDataList<QueryMapLayer>(
      this._context,
      this._http,
      null,
      '/api/map/getQueryMapLayersForClient',
      { clientId }
    );
  }

  getQueryMapLayersForLayer(
    mapLayerIndexItemId: string
  ): Observable<QueryMapLayer[]> {
    return Utilities.getDataList<QueryMapLayer>(
      this._context,
      this._http,
      null,
      '/api/map/getQueryMapLayersForLayer',
      { mapLayerIndexItemId }
    );
  }

  queryMapLayer(
    request: QueryMapLayerRequest
  ): Observable<QueryMapLayerResult> {
    return Utilities.postData<QueryMapLayerResult>(
      this._context,
      this._http,
      null,
      '/api/map/QueryMapLayer',
      request
    );
  }

  updateClientApplicationNumber(
    applicationId: string,
    newApplicationNumber: string,
    ignoreWarning: boolean
  ): Observable<{ applicationNumberExist: boolean }> {
    return Utilities.postData<{ applicationNumberExist: boolean }>(
      this._context,
      this._http,
      null,
      '/api/workflow/updateClientApplicationNumber',
      null,
      null,
      null,
      {
        applicationId,
        newApplicationNumber,
        ignoreWarning
      }
    );
  }

  queueCustomFieldMigration(
    request: CustomFieldMigrationQueueRequest
  ): Observable<boolean> {
    return Utilities.postData(
      this._context,
      this._http,
      null,
      '/api/contractor/QueueCustomFieldMigration',
      request
    );
  }

  getPendingCustomFieldMigrations(
    contractorTypeId: string
  ): Observable<CustomFieldMigrationQueueItem[]> {
    return Utilities.getData<CustomFieldMigrationQueueItem[]>(
      this._context,
      this._http,
      null,
      '/api/contractor/GetPendingCustomFieldMigrations',
      {
        contractorTypeId: contractorTypeId
      }
    );
  }

  queueCustomFieldTemplateCodeRename(
    draftTemplateCode: string,
    templateCode: string,
    workflowId: string
  ): Observable<boolean> {
    return Utilities.postData(
      this._context,
      this._http,
      null,
      '/api/workflow/QueueCustomFieldTemplateCodeRename',
      {
        draftTemplateCode: draftTemplateCode,
        templateCode: templateCode,
        workflowId: workflowId
      }
    );
  }

  getAnnouncementsToManage(
    getGlobalList: boolean,
    clientId?: string
  ): Observable<Announcement[]> {
    return Utilities.getData<Announcement[]>(
      this._context,
      this._http,
      null,
      '/api/system/GetAnnouncementsToManage',
      {
        getGlobalList: getGlobalList,
        clientId: clientId
      }
    );
  }

  getGlobalAnnouncementAudiences(): Observable<AvailableGlobalAnnouncementAudiences> {
    return Utilities.getData<AvailableGlobalAnnouncementAudiences>(
      this._context,
      this._http,
      null,
      '/api/system/GetGlobalAnnouncementAudiences',
      null
    );
  }

  saveAnnouncement(announcement: Announcement): Observable<Announcement> {
    return Utilities.postData(
      this._context,
      this._http,
      Announcement,
      '/api/system/SaveAnnouncement',
      announcement
    );
  }

  deleteAnnouncement(announcementId: string): Observable<boolean> {
    return Utilities.postData<boolean>(
      this._context,
      this._http,
      null,
      '/api/system/DeleteAnnouncement',
      null,
      null,
      null,
      { announcementId: announcementId }
    );
  }

  getGlobalAnnouncements(): Observable<Announcement[]> {
    return Utilities.getDataList<Announcement>(
      this._context,
      this._http,
      Announcement,
      '/api/system/GetGlobalAnnouncements'
    );
  }

  getAnnouncementsForClient(clientId: string): Observable<Announcement[]> {
    return Utilities.getData<Announcement[]>(
      this._context,
      this._http,
      null,
      '/api/system/GetAnnouncementsForClient',
      { clientId: clientId }
    );
  }

  checkForNewAnnouncements(mostRecentAnnouncementIds: string[], clientId: string = null): Observable<RefreshAnnouncementsResponse> {
    const request = {
      mostRecentAnnouncementIds: mostRecentAnnouncementIds,
      clientId: clientId
    }

    return Utilities.postData<RefreshAnnouncementsResponse>(
      this._context,
      this._http,
      RefreshAnnouncementsResponse,
      '/api/system/GetNewAnnouncements',
      request
    );
  }

  getCompletedApplicationActivityForEditing(
    workflowApplicationId: string, 
    workflowApplicationActivityDataId: string
  ): Observable<Activity<ActivityModel>> {
    return Utilities.getData<Activity<ActivityModel>>(
      this._context,
      this._http,
      Activity<ActivityModel>,
      '/api/workflow/GetCompletedApplicationActivityForEditing',
      { 
        workflowApplicationId: workflowApplicationId, 
        workflowApplicationActivityDataId: workflowApplicationActivityDataId 
      }
    );
  }
}
