<form [formGroup]="form">
  <div class="row">
    <div class="col">
      <wm-modal-confirm
        #newDocument
        commandText="Finish"
        buttonText="Upload Document"
        [showButtonText]="true"
        title="New Document"
        class="float-right"
        btnColor="success"
        (opened)="newDocStarted()"
        (accepted)="addDocuments()"
        (canceled)="newDoc = false"
        [form]="newDocForm"
      >
        <wm-upload
          *ngIf="newDoc"
          validDocumentTypes="pdf"
          [allowMultipleFiles]="true"
          [pathSegments]="[this.parentId]"
          category="Contractor"
          (uploadComplete)="newDocumentsUploaded($event)"
          [showUploadedFilesList]="false"
        >
        </wm-upload>
      </wm-modal-confirm>
    </div>
  </div>
  <table class="table">
    <tr>
      <th>Category</th>
      <th>Name</th>
      <th>Created By</th>
      <th>Uploaded On</th>
      <th></th>
    </tr>
    <ng-container *ngFor="let doc of documents; let i = index">
      <tr [formGroupName]="doc.id">
        <td>
          <div *ngIf="doc.editing">
            <select
              class="form-control"
              [(ngModel)]="doc.categoryId"
              formControlName="docCategory"
            >
              <option value="">Select Item...</option>
              <option *ngFor="let cat of documentCategories" [value]="cat.id">{{
                cat.name
              }}</option>
            </select>
          </div>
          <div *ngIf="!doc.editing">
            {{ doc.category?.name }}
          </div>
        </td>
        <td>
          <div *ngIf="doc.editing">
            <input
              type="text"
              class="form-control"
              [(ngModel)]="doc.documentName"
              name="documentName"
              formControlName="docName"
            />
            <wm-control-message
              [control]="form.controls['docName-' + i]"
            ></wm-control-message>
          </div>
          <div *ngIf="!doc.editing">
            <a [href]="getDocPath(doc)" target="_blank">{{
              doc.documentName
            }}</a>
          </div>
        </td>
        <td>{{ doc.createdBy }}</td>
        <td>{{ doc.modifiedOn | date: 'M/d/yyyy h:mm a' }}</td>
        <td>
          <div class="dropdown">
            <button
              class="btn btn-secondary dropdown-toggle"
              type="button"
              id="dropdownMenuButton"
              data-toggle="dropdown"
              aria-haspopup="true"
              aria-expanded="false"
              style="padding-top:0px;"
            >
              Action
            </button>
            <div class="dropdown-menu" aria-labelledby="dropdownMenuButton">
              <a
                class="dropdown-item"
                *ngFor="let command of commands"
                [class.disabled]="command.canPerform(doc) ? null : true"
                (click)="command.handler(doc[command.idField])"
                >{{ command.title }}</a
              >
            </div>
          </div>
        </td>
      </tr>
      <tr *ngIf="doc.editing">
        <td colspan="5">
          <button
            class="btn btn-success"
            [disabled]="form && form.controls[doc.id].invalid"
            (click)="saveDocument(doc)"
          >
            Save
          </button>
          <button class="btn btn-success" (click)="cancelEdit(doc)">
            Cancel
          </button>
        </td>
      </tr>
    </ng-container>
  </table>
</form>

<wm-modal-confirm
  title="Delete Contractor Document"
  [showButton]="false"
  commandText="Delete"
  (accepted)="deleteDocument()"
  #deleteDocModal
>
  <div>
    Are you sure you want to delete this document?
  </div>
</wm-modal-confirm>
