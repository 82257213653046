import { map, catchError } from 'rxjs/operators';
import {
  HttpEventType,
  HttpErrorResponse,
  HttpResponse
} from '@angular/common/http';
import { ToastrService } from 'ngx-toastr';
import {
  Component,
  ViewChild,
  ElementRef,
  OnInit,
  Input,
  Output,
  EventEmitter
} from '@angular/core';
import { DocumentService } from '../../services';
import * as numeral from 'numeral';
import { Document } from 'src/app/models';

export interface UploadResponse {
  id: string;
  name: string;
  parentId: string;
  parentPath: string;
  parentType: string;
  path: string;
  pathName: string;
}

interface UploadFile {
  data: File;
  inProgress: boolean;
  progress: number;
  size: any;
}

@Component({
  selector: 'wm-upload',
  templateUrl: './upload.component.html',
  styleUrls: ['./upload.component.css']
})
export class UploadComponent implements OnInit {
  @ViewChild('uploadButton', { static: true }) uploadButton: ElementRef;
  @ViewChild('fileUpload', { static: true }) fileUpload: ElementRef;

  @Input() validDocumentTypes: string;
  @Input() allowMultipleFiles = true;
  @Input() pathSegments: string[];
  @Input() key: string;
  @Input() isPreview = false;
  @Input() buttonText: string;
  @Input() category: string;
  @Input() deleteStoredDocument = false;
  @Input() deleteDocumentMessage: string;
  @Input() showRemove = true;
  @Input() previouslyUploadedFiles: Document[] = [];
  @Input() showUploadedFilesList = true;

  @Output() uploadComplete: EventEmitter<Document[]> = new EventEmitter<Document[]>();
  @Output() fileUploaded: EventEmitter<UploadResponse> = new EventEmitter<UploadResponse>();
  @Output() fileDeleted: EventEmitter<Document> = new EventEmitter<Document>();


  // Files being uploaded in the current batch
  uploadedFilesBatch: any[] = [];
  files: UploadFile[] = [];
  uploadedFiles: Document[] = [];

  constructor(
    private _documentSvc: DocumentService,
    private _toastrSvc: ToastrService
  ) {}

  ngOnInit() {
    if (this.previouslyUploadedFiles && this.previouslyUploadedFiles.length > 0) {
      this.uploadedFiles = this.previouslyUploadedFiles;
    }

    this.validDocumentTypes = this.stripFileTypes(this.validDocumentTypes);

    if (!this.buttonText) {
      this.buttonText = 'Select file';

      if (this.allowMultipleFiles) {
        this.buttonText += 's';
      }
    }
  }

  stripFileTypes(fileTypes: string) {
    if (!fileTypes) {
      return '';
    }

    return fileTypes
      .split(',') // split it into an array of each type
      .map(type => `${type.trim()}`) // then map it to an array of trimmed file types
      .join(','); // then join it back into a comma separated list
  }

  public async uploadFile(
    file: UploadFile
  ): Promise<HttpResponse<UploadResponse>> {
    const extraData = {
      name: file.data.name,
      parentPath: this.pathSegments,
      filename: file.data.name,
      origName: file.data.name,
      category: this.category
    };

    file.inProgress = true;

    const res = await this._documentSvc
      .upload(file.data, extraData)
      .pipe(
        map(event => {
          switch (event.type) {
            case HttpEventType.UploadProgress:
              file.progress = Math.round((event.loaded / event.total) * 100);
              break;
            case HttpEventType.Response:
              return event;
          }
        }),
        catchError((error: HttpErrorResponse) => {
          file.inProgress = false;
          throw new Error(
            `Upload failed: ${file.data.name}, (${error.message})`
          );
        })
      )
      .toPromise();

    return res;
  }

  onClick() {
    const fileUpload = this.fileUpload.nativeElement;
    fileUpload.onchange = () => {
      for (let index = 0; index < fileUpload.files.length; index++) {
        const file = fileUpload.files[index];
        this.files.push({
          data: file,
          inProgress: false,
          progress: 0,
          size: numeral(file.size).format('0.0 b')
        });
      }

      if (this.files.length > 0) {
        this._documentSvc.filesAdded.emit({
          files: this.files,
          key: this.key
        });

        this.uploadFiles();
      }
    };
    fileUpload.click();
  }

  async uploadFiles() {
    for (const file of this.files) {
      try {
        const result = await this.uploadFile(file);

        if (result.status === 200 && result.body != null) {
          this.uploadedFilesBatch.push(result.body);

          this.fileUploaded.emit(result.body);

          this.uploadedFiles.push(result.body as any)
        }
      } catch (err) {
        this._toastrSvc.error(err.message, 'Upload failed!');
      }
    }

    this.uploadComplete.emit(this.uploadedFilesBatch);

    this._documentSvc.fileUploadComplete.emit({
      files: this.uploadedFilesBatch,
      key: this.key
    });

    this.reset();
  }

  reset() {
    this.uploadedFilesBatch = [];
    this.files = [];
    this.fileUpload.nativeElement.value = null;
  }

  clearUploaded() {
    this.uploadedFiles = [];
  }

  deleteFile(file: Document) {
    const updateFiles = () => {
      const files = this.uploadedFiles.filter(f => f !== file);
        
      this.uploadedFiles = files;

      this.fileDeleted.emit(file);
    };

    // internal and external notes use this component. Clicking delete should delete their docs
    // from storage.  Application documents don't need to be deleted from storage, their value
    // just needs updated so the application EntityData will be updated on clicking Next/Update
    // deleteStoredDocument is a flag only internal and external notes set to true
    if (this.deleteStoredDocument) {
      this._documentSvc
        .removeDocument(file.path, file) //I guess pass file.path here???
        .subscribe(result => {
          if (result) {
            updateFiles();
          } else {
            this._toastrSvc.error('Document failed to delete.');
          }
        });
    } else {
      updateFiles();
    }
  }
}
