import { Filter } from './filter-builder';
import { Document } from 'src/app/models';

export class PreviousSystemDataHeadersRequest {
  clientId: string;
  isPagedRequest: boolean;
  pageSize: number;
  pageNumber: number;
  filters?: Filter[];
  sortField?: string;
  sortDescending?: boolean;
}

export class PreviousSystemDataHeadersResponse {
  recordCount: number;
  pageCount: number;
  items: PreviousSystemDataHeader[];
}

export class PreviousSystemDataHeader {
  id: number;
  type: string;
  number: string;
  name: string;
  address: string;
  dateTimeString: string;
  parcelNumber: string;
}
export class SavePreviousSystemPermitResponse {
  success: boolean;
  messages: { [key: string]: string };
  savedHeaderRecord: PreviousSystemDataHeader;
}

export class PreviousSystemDataDetailsRequest {
  clientId: string;
  headerId: number;
}

export class PreviousSystemDataDetailsResponse {
  items: PreviousSystemDataDetail[];
}

export class PreviousSystemDataDetail {
  detailKey: string;
  detailValue: string;
}

export class PreviousSystemPermitAttachment {
  id: number;
  previousSystemPermitHeaderId: number;
  name: string;
  pathName: string;
  path: string;
  attachmentPath: string;
  deletedOn: Date;
  modifiedOn: Date;
  modifiedBy: string;
}

export class PreviousSystemPermitAttachmentsResponse {
  items: PreviousSystemPermitAttachment[];
}

export class PreviousSystemDataImportRequest {
  tabularData: Document[];
  attachmentIndexFile: Document;
  clearExisting: boolean;
  clientId: string;
  requestedByUserId: string;
  uploadKey: string;

  constructor(options?: Partial<PreviousSystemDataImportRequest>) {
    if (options) {
      Object.assign(this, options);
    }
  }
}

export class PreviousSystemDataImportResponse {
  success: boolean;
  message: string;
}

