import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Component, OnInit, EventEmitter, Output, Input } from '@angular/core';
import { ListItemEditorComponent } from './../../workflow/data-entities/list-data-entity/list-data-entity-editor/list-item-editor/list-item-editor.component';
import { ListOption } from '../../../models/listOption';
@Component({
  selector: 'wm-list-editor',
  templateUrl: './list-editor.component.html',
  styleUrls: ['./list-editor.component.css']
})
export class ListEditorComponent implements OnInit {
  @Output() public listChange: EventEmitter<ListOption[]> = new EventEmitter<
    ListOption[]
  >();
  @Output() public delete: EventEmitter<ListOption> = new EventEmitter<
    ListOption
  >();
  @Output() public add: EventEmitter<ListOption> = new EventEmitter<
    ListOption
  >();

  @Input() public listItems: ListOption[] = [];
  @Input() public canEdit = false;
  @Input() isRoleMembersList = false;

  public currentItem: string = null;

  public showNew = false;
  public newItem: ListOption = new ListOption({ text: '', value: '' });
  public bulkAddText = '';

  constructor(private modalService: NgbModal) {}

  ngOnInit() {}

  public emitChange() {
    for (let index = 0; index < this.listItems.length; index++) {
      const element = this.listItems[index];
      element.position = index;
    }

    this.listChange.emit(this.listItems);
  }

  public removeItem(t) {
    const i = this.listItems.indexOf(t);
    this.listItems.splice(i, 1);
    this.emitChange();
    this.delete.emit(t);
  }

  public addItem(item: ListOption, editor: ListItemEditorComponent) {
    this.listItems.push(item);
    this.showNew = false;
    this.newItem = new ListOption({ text: '', value: '' });
    this.emitChange();
    editor.reset();
    this.add.emit(this.newItem);
  }

  public saveItem() {
    this.currentItem = null;
    this.emitChange();
  }

  public open(content) {
    this.modalService
      .open(content, { ariaLabelledBy: 'modal-bulk-title' })
      .result.then(
        () => {
          if (this.bulkAddText) {
            const list = this.bulkAddText.split(',');
            const filteredList = list.filter(item => !!item.trim());
            const listOptions = filteredList.map(item => {
              const text = item.trim();
              if (!text) {
                return;
              }
              const opt = new ListOption({ text, value: text });

              return opt;
            });

            this.listItems.push(...listOptions);
          }

          this.bulkAddText = '';
          this.emitChange();
        },
        () => {}
      );
  }
}
