import { Component, OnInit, OnDestroy } from '@angular/core';

import { ApplicationConfiguration } from '../../../models/application-configuration';
import { WorkflowContextService } from '../../../services/workflow-context.service';
import { DataService } from '../../../services/data.service';
import { Client } from '../../../models/client';
import { LoginOrientation } from '../../../components/system/login/login.component';
import { Workflow } from '../../../models/workflow';
import { Subscription } from 'rxjs';

@Component({
  selector: 'wm-landing.view',
  templateUrl: './landing.view.component.html',
  styleUrls: ['./landing.view.component.css']
})
export class LandingViewComponent implements OnInit, OnDestroy {
  topMargin: number;

  constructor(
    private _service: DataService,
    public context: WorkflowContextService
  ) {}
  client: Client;

  ngOnDestroy() {
    if (this.clientSubscription) {
      this.clientSubscription.unsubscribe();
    }

    if (this.configSubscription) {
      this.configSubscription.unsubscribe();
    }
  }

  clientSubscription: Subscription;
  configSubscription: Subscription;

  appConfiguration: ApplicationConfiguration;
  loginOrientation = LoginOrientation;
  currentHero: string;
  workflows: Workflow[];
  clients: Client[];

  ngOnInit() {
    this.context.viewAsRole$.emit(null);
    this._service.getClients().subscribe(clients => {
      this.clients = clients;
    });

    this.appConfiguration = this.context.appConfiguration;
    this.client = this.context.client;

    this.configSubscription = this.context.appConfiguration$.subscribe(
      item => (this.appConfiguration = item)
    );

    this.currentHero = './assets/images/heros/c11.jpg';
  }

  setTopMarginForNavbar(pixelHeight: number) {
    this.topMargin = pixelHeight;
  }
}
