import {
  Component,
  OnInit,
  ViewChild,
  ElementRef,
  Output,
  EventEmitter,
  Input,
  AfterViewInit
} from '@angular/core';

// Esri bits:
// the esri-loader npm module defines the default api version, but you have to also include that version in the css ref in the .css file.
import esri = __esri;
import { MapExtent } from 'src/app/models/mapextent';
import { EsriLoaderService } from 'src/app/components/arcgis/esri-loader.service';
import { Extent } from 'esri/geometry';
import { Observable, Subject, fromEvent, of, combineLatest } from 'rxjs';
import { ItemSearchOptionField } from '../../filter-list/models/filterClasses';
import { distinctUntilChanged, debounceTime, startWith } from 'rxjs/operators';
import { GeomUtils } from '../geom-utils';
import * as moment from 'moment';
import { forEach } from 'lodash';
import { GridSettings } from 'src/app/models/grid-settings';

/**
 * This type represents the request for data that is needed by the search query.
 *
 * @type MapDataRequest
 */
export class MapDataRequest {
  query: ItemSearchOptionField[];
  ext: MapExtent;
  gimmeTheData$: EventEmitter<IBasicMapData[]>;
}

/**
 * Map data objects to be displayed must have the required geometry field.  This geometry is always a point and in WGS84.
 * It is expected that there are other attributes as well, which the popup template will consume.
 *
 * @interface IBasicMapData
 */
export interface IBasicMapData {
  geometryWkts: string[];
  data: any;
}

@Component({
  selector: 'app-basic-esri-map',
  templateUrl: './basic-esri-map.component.html',
  styleUrls: ['./basic-esri-map.component.css']
})
export class BasicEsriMapComponent implements OnInit, AfterViewInit {
  @Output()
  mapLoaded = new EventEmitter();

  @Output()
  mapExtentChanged = new EventEmitter<MapExtent>();

  @Output()
  mapDataRequested = new EventEmitter<MapDataRequest>();

  @Input()
  inputFilters: Observable<ItemSearchOptionField[]>;

  @ViewChild('mapViewNode', { static: true })
  private mapViewEl: ElementRef;

  @Input()
  popupTemplateTitle = 'Application ID: {applicationNumber}';

  @Input()
  popupTemplateContent = ``;

  viewApplicationAction = {
    // This text is displayed as a tooltip
    title: 'View Application',
    // The ID by which to reference the action in the event handler
    id: 'view-application',
    // Sets the icon font used to style the action button
    className: 'esri-icon-zoom-out-magnifying-glass'
  };

  @Input()
  popupActions = [];

  @Input()
  startingExtent: {
    xmax: number;
    ymax: number;
    xmin: number;
    ymin: number;
  } = null;

  @Input() initialFilters: ItemSearchOptionField[];

  defaultSymbol = {
    type: 'simple-marker',
    color: 'blue',
    size: 8,
    outline: {
      width: 0.5,
      color: 'darkblue'
    }
  };

  private map: esri.Map;
  private mapView: esri.MapView;

  loading = true; // binds to the 'loading' indicator

  private pointLayer: esri.GraphicsLayer;
  private firstDataFetch = true;

  constructor(private esriLoaderService: EsriLoaderService) {}

  async ngOnInit() {
    await this.initializeMap();

    if (!this.popupTemplateContent) {
      this.popupTemplateContent = `Type: {permitType}<br>
      Description: {description}<br>
      Applicant: {applicantsName}<br>
      Created: {dateCreated}<br>
      Status: {status}<br>`;
    }

    if ((this.popupActions || []).length === 0) {
      this.popupActions = [this.viewApplicationAction];
    }

    if (!this.popupTemplateTitle) {
      this.popupTemplateTitle = 'Application ID: {applicationNumber}';
    }

    // wire up the callback observable - this gets data emitted from the parent control
    const mapData$ = new EventEmitter<IBasicMapData[]>();
    mapData$.subscribe({
      next: async (d: IBasicMapData[]) => {
        await this.putDataOnTheMap(d);
        this.loading = false;
      }
    });

    // initialize the Observable pipeline:
    combineLatest(
      this.inputFilters.pipe(startWith(<ItemSearchOptionField[]>null)), // start with a null filter
      this.mapExtentChanged
    )
      .pipe(
        distinctUntilChanged(),
        debounceTime(200)
      )
      .subscribe({
        next: ([qry, ext]) => {
          this.loading = true;
          let legacyFilters = null;
          if (qry instanceof GridSettings && qry.legacyFilters) {
            legacyFilters = qry.legacyFilters;
          }
          this.mapDataRequested.emit(<MapDataRequest>{
            query: legacyFilters || this.initialFilters,
            ext: ext,
            gimmeTheData$: mapData$
          });
        },
        error: e => {
          this.loading = false;
          console.error(e);
          return null;
        }
      });
  }

  ngAfterViewInit(): void {}

  async putDataOnTheMap(data: IBasicMapData[]) {
    const [
      Graphic,
      SpatialReference,
      webMercatorUtils,
      Extent
    ] = await this.esriLoaderService.LoadModules([
      'esri/Graphic',
      'esri/geometry/SpatialReference',
      'esri/geometry/support/webMercatorUtils',
      'esri/geometry/Extent'
    ]);

    if ((this.popupActions || []).length > 0) {
      this.mapView.popup.on('trigger-action', event => {
        // Execute the measureThis() function if the measure-this action is clicked
        const action = this.popupActions.find(a => a.id === event.action.id);

        if (action && action.handler) {
          action.handler(this.mapView.popup.selectedFeature.attributes);
        }
      });
    }
    const aGraphics: esri.Graphic[][] = data
      .filter(o => !!o.geometryWkts) // remove items w/o geometry
      .map(o => {
        const applicationGraphics: esri.Graphic[] = [];
        o.geometryWkts.forEach(geometryWKT => {
          const geom1 = GeomUtils.WktToArcGisGeom(geometryWKT);
          const geom3 = webMercatorUtils.geographicToWebMercator(geom1);
          applicationGraphics.push(
            new Graphic({
              geometry: geom3,
              symbol: this.defaultSymbol,
              attributes: o.data, // changed this to be a specific model because dojo was throwing circular reference issues
              popupTemplate: {
                title: this.popupTemplateTitle,
                content: this.popupTemplateContent,
                actions: this.popupActions
              }
            })
          );
        });
        return applicationGraphics;
      });
    // flatten it out.
    const newGraphics = [].concat(...aGraphics);
    this.pointLayer.graphics.removeAll(); // do this again, as multiple async requests may arrive, we only want the last batch of features
    this.pointLayer.graphics.addMany(newGraphics);

    if (this.firstDataFetch && this.startingExtent === null) {
      this.firstDataFetch = false;
      await this.zoomToSelection();
    }
  }

  async zoomToSelection(): Promise<any> {
    if (this.pointLayer.graphics.length === 0) {
      return;
    }

    let ext1 = await this.getExtentFromAnyGeom(
      this.pointLayer.graphics.getItemAt(0).geometry
    );

    if (this.pointLayer.graphics.length === 1) {
      ext1.xmax = ext1.xmax + 10;
      ext1.ymax = ext1.ymax + 10;
      ext1.xmin = ext1.xmin - 10;
      ext1.ymin = ext1.ymin - 10;
    }

    for (let i = 1; i < this.pointLayer.graphics.length; i++) {
      ext1 = ext1.union(
        await this.getExtentFromAnyGeom(
          this.pointLayer.graphics.getItemAt(i).geometry
        )
      );
    }

    ext1.expand(2);
    return this.mapView.goTo(ext1);
  }

  async getExtentFromAnyGeom(g: esri.Geometry): Promise<esri.Extent> {
    const [Extent] = await this.esriLoaderService.LoadModules([
      'esri/geometry/Extent'
    ]);
    if (g.type === 'point') {
      const pt = g as esri.Point;
      return new Extent({
        xmin: pt.x,
        xmax: pt.x,
        ymin: pt.y,
        ymax: pt.y,
        spatialReference: pt.spatialReference
      });
    } else {
      return g.extent.clone();
    }
  }

  async initializeMap() {
    const [
      EsriMap,
      EsriMapView,
      SpatialReference,
      GraphicsLayer,
      watchUtils,
      Locate
    ] = await this.esriLoaderService.LoadModules([
      'esri/Map',
      'esri/views/MapView',
      'esri/geometry/SpatialReference',
      'esri/layers/GraphicsLayer',
      'esri/core/watchUtils',
      'esri/widgets/Locate'
    ]);

    // the layer for visualizing the points to be displayed
    this.pointLayer = new GraphicsLayer({});
    // originally we were looking for point cluster, which doesn't seem to be in the 4.11 ArcGIS JS api.
    //   but there is an alternate project at: https://github.com/nickcam/FlareClusterLayer

    // Set type of map
    const mapProperties: esri.MapProperties = {
      basemap: 'topo',
      layers: [this.pointLayer]
    };
    this.map = new EsriMap(mapProperties);
    // Set type of map view
      const mapViewProperties: esri.MapViewProperties = {
      container: this.mapViewEl.nativeElement,
      map: this.map,
      constraints: {
        snapToZoom: false
      },
      extent: this.startingExtent || {
        // Continental USA - helps with initial query.  When the map zooms out too far, it starts producing extents that don't make sense.
        xmax: -66.15485030280396,
        xmin: -126.56035948902678,
        ymax: 52.276217308571994,
        ymin: 20.439053181092586,
        spatialReference: SpatialReference.WGS84
      },
      spatialReference: SpatialReference.WebMercator // Required for any of the esri basemaps to work.
    };

    this.mapView = new EsriMapView(mapViewProperties);

    const locate = new Locate({
      view: this.mapView,
      useHeadingEnabled: false,
      goToOverride: function(view, options) {
        options.target.scale = 1500;
        return view.goTo(options.target);
      }
    });

    this.mapView.ui.add(locate, "top-left");

    await this.mapView.when();

    // wire up extent tracking
    watchUtils.whenTrue(this.mapView, 'stationary', async () => {
      if (this.mapView.extent) {
        const ext = await this.getCurrentMapExtentWGS84();
        // clip the horizontal extent to the Continental USA
        if (ext.xmax > -66.15485030280396) {
          ext.xmax = -66.15485030280396;
        }
        if (ext.xmin < -126.56035948902678) {
          ext.xmin = -126.56035948902678;
        }
        if (ext.ymax > 52.276217308571994) {
          ext.ymax = 52.276217308571994;
        }
        if (ext.ymin < 20.439053181092586) {
          ext.ymin = 20.439053181092586;
        }
        this.mapExtentChanged.emit(ext);
      }
    });

    this.mapLoaded.emit();
  }

  async getCurrentMapExtentWGS84() {
    const [webMercatorUtils] = await this.esriLoaderService.LoadModules([
      'esri/geometry/support/webMercatorUtils'
    ]);

    if (!this.mapView.extent) {
      throw new Error('No map extent has been defined');
    }

    const wgs84Ext = webMercatorUtils.webMercatorToGeographic(
      this.mapView.extent
    );
    return new MapExtent(
      wgs84Ext.xmin,
      wgs84Ext.ymin,
      wgs84Ext.xmax,
      wgs84Ext.ymax
    );
  }

  zoom(ext: Extent) {
    this.mapView.goTo(ext);
  }
}
