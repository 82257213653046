import {
  FieldOrientation,
  VerticalOrientation
} from './../activities/print-template-activity';
import { ConditionValue } from './../../components/system/condition-builder/condition-builder.model';
import { Validators } from '@angular/forms';
import { fabric } from 'fabric';
import { DataEntityListItem } from '../../components/workflow/activities/print-template-activity/print-template-activity-editor/print-template-activity-editor.component';
import {
  TemplateField,
  TemplateFieldType,
  ActivityModel,
  Activity
} from '../activities';
import {
  ConditionOperator,
  defaultOperators
} from 'src/app/components/system/condition-builder/condition-builder.model';
import { Observable, of } from 'rxjs';
import { ActivityNavigationInfo } from '../activity-navigation-Info';
import { ExternalDataSource } from '../external-data-source';

export class DataEntity {
  formattedValue: string;
  dataEntityTypeCode: string;
  dataEntityTypeDescription: string;
  dataEntityTypeName: string;
  label?: string;
  origLabel?: string;
  instructions?: string;
  templateCode?: string;
  templateValue?: string;
  isRequired?: boolean;
  isRequirement?: boolean;
  isSearchable?: boolean;
  isSensitiveData?: boolean;
  isHelpText: boolean;
  value?: any;
  editorComponentPath?: string;
  editorComponentName?: string;
  editComponentPath?: string;
  editComponentName?: string;
  viewComponentPath?: string;
  viewComponentName?: string;
  parent?: ParentReference;
  parentActivity?: Activity<ActivityModel>;
  isSystemEntity?: boolean;
  allowMove?: boolean;
  allowCopy?: boolean;
  allowTemplateCodeEdit?: boolean;
  availablePrintFormatOptions: string[];
  availableConditionalOperators: string[] = [];
  availableFormulaOperators: string[] = [];
  hasCompletedDependencies: boolean;
  isValid = true;
  isLocked: boolean;
  externalDataField: string;
  externalDataSourceId: string;
  availableExternalDataSources?: ExternalDataSource[];
  externalDataSource?: ExternalDataSource;
  canEditExternalValue?: boolean;
  activityId: string;
  activityLabel: string;
  conditionDetails: { activityId: string; activityName: string }[];
  completedDependentActivityIds: string[];
  completedDependentActivtyInfo: ActivityNavigationInfo[];
  canUnlock: boolean;
  isCalculating: boolean;
  isRegistrationCustomField: boolean;

  hasPreview = true;
  hasEditor = true;

  constructor(options?: Partial<DataEntity>) {
    if (options) {
      Object.assign(this, options);
    }
  }

  get entityLabel(): string {
    const label = this.label.replace(/<[^>]+>/g, '');

    if (!this.isRequired) {
      return label;
    } else {
      return `${label} <span class="text-red">*<span>`;
    }
  }

  getRestrictionMask(): string {
    return '';
  }

  getValidators(): any[] {
    const validators: any[] = [];

    if (this.isRequired) {
      validators.push(Validators.required);
    }

    return validators;
  }

  getAvailableOperators(): ConditionOperator[] {
    return this.availableConditionalOperators.map(o =>
      defaultOperators.find(op => op.operator === o)
    );
  }

  getPossibleValues(): ConditionValue[] {
    return null;
  }

  getPrintTemplateItems(): DataEntityListItem[] {
    return [
      new DataEntityListItem({
        code: this.templateCode,
        text: this.templateCode
      })
    ];
  }

  translateDETextToFormula(text: string): string {
    const regTemplateParser = new RegExp(
      /\<de (?:entity-)?type="(.*)" code="(.*)"\>.*\<\/de\>/,
      'g'
    );

    let match;
    let formattedModel = text;
    while ((match = regTemplateParser.exec(text)) !== null) {
      formattedModel = formattedModel.replace(match[0], `\$\{${match[2]}\}`);
    }
    return formattedModel;
  }

  buildTemplateLabel(fieldInfo: TemplateField) {
    const conditions = fieldInfo.criteria || [];

    if (fieldInfo.fieldType === TemplateFieldType.Image) {
      return conditions.length > 1 ? 'Conditional' : fieldInfo.label;
    }

    const conditionValue =
      conditions.length === 1
        ? this.translateDETextToFormula(conditions[0].value)
        : fieldInfo.label;

    const strippedConditionValue = conditionValue
      .replace('${', '')
      .replace('}', '')
      .toLowerCase();
    const isValueDefault =
      strippedConditionValue === fieldInfo.name.toLowerCase();

    return conditions.length > 1
      ? 'Conditional'
      : isValueDefault
      ? this.translateDETextToFormula(fieldInfo.label)
      : conditionValue;
  }

  updateTemplateItem(fieldInfo: TemplateField, obj: fabric.Object) {
    obj.customData.label = this.buildTemplateLabel(fieldInfo);
  }

  /*
    This returns document dimensions, not screen dimensions
  */
  getTemplateDimensions(
    fieldInfo: TemplateField,
    helperFunctions: any
  ): { width: number; height: number } {
    let width: number = (fieldInfo.width || 0) === 0 ? 50 : fieldInfo.width;
    let height: number = (fieldInfo.height || 0) === 0 ? 20 : fieldInfo.height;

    if (width < 10) {
      width = 10;
    }

    if (height < 10) {
      height = 10;
    }

    return {
      width,
      height
    };
  }

  getFormattedValue(): any {
    return this.value;
  }

  /*
    this is here to add a hook for those entities that need to clear stuff before things are serialized.
  */
  preStringify() {}

  resizeTemplateItem(
    fieldInfo: TemplateField,
    helperFunctions,
    obj: fabric.Object
  ): void {
    if (fieldInfo) {
      obj.left = helperFunctions.documentToScreenX(fieldInfo.x);
      obj.top = helperFunctions.documentToScreenY(fieldInfo.y);
      obj.width = helperFunctions.documentToScreenX(fieldInfo.width);
      obj.height = helperFunctions.documentToScreenY(fieldInfo.height);
      obj.fontSize = helperFunctions.scaleFontSize(fieldInfo.fontSize);

      if (fieldInfo.orientation === FieldOrientation.Vertical) {
        if (fieldInfo.verticalOrientation === VerticalOrientation.Left) {
          obj.angle = 90;
        } else {
          obj.angle = 270;
          obj.left = obj.left - obj.height;
          obj.top = obj.top + obj.width;
        }
      } else {
        obj.angle = 0;
      }

      if (obj.group) {
        // when in a group, coordinates are based on the bottom right of that group, not based on the canvas........
        obj.left = obj.left - obj.group.left - obj.group.width / 2;
        obj.top = obj.top - obj.group.top - obj.group.height / 2;
        // calculate the percentage of the obj relative to the group and size it based on that percentage
        const groupDocWidth = helperFunctions.screenToDocumentX(
          obj.group.width * obj.group.scaleX
        );
        const objWidthPctOfGroup = fieldInfo.width / groupDocWidth;
        obj.width = obj.group.width * objWidthPctOfGroup;
      }

      const smallestDimension = Math.min(obj.height, obj.width);

      // if the font size is bigger than the box, shrink it down to fit
      if (obj.fontSize > smallestDimension) {
        obj.fontSize = smallestDimension;
      }

      obj.setCoords();
    }
  }

  async formatTemplateItem(
    fieldInfo: TemplateField,
    helperFunctions
  ): Promise<fabric.Object> {
    const label = this.buildTemplateLabel(fieldInfo);

    // get document dimensions with minimums applied
    const dimensions = this.getTemplateDimensions(fieldInfo, helperFunctions);

    // document coordinates
    fieldInfo.width = dimensions.width;
    fieldInfo.height = dimensions.height;

    return of(
      new fabric.ResizableTextbox('X', {
        width: helperFunctions.documentToScreenX(fieldInfo.width),
        height: helperFunctions.documentToScreenY(fieldInfo.height),
        left: helperFunctions.documentToScreenX(fieldInfo.x),
        top: helperFunctions.documentToScreenY(fieldInfo.y),
        fontSize: helperFunctions.scaleFontSize(fieldInfo.fontSize),
        stroke: `rgb(${fieldInfo.fontColor || '0, 0, 0'})`,
        editable: false,
        lockScalingY: false,
        lockSkewingY: true,
        lockScalingX: false,
        lockSkewingX: true,
        backgroundColor: this.isValid == false ? 'red' : 'rgba(255, 0, 0, .25)',
        customData: {
          label,
          id: fieldInfo.id,
          code: fieldInfo.name
        }
      })
    ).toPromise();
  }

  getSpecialAvailablePrintFormatOptions(): { [key: string]: string[] } {
    // hook for any data entiy that has specific available print format options
    return null;
  }
}

export class ParentReference {
  id: string;
  name: string;
  type: string;
  activityType?: string;

  constructor(options?: Partial<ParentReference>) {
    if (options) {
      Object.assign(this, options);
    }
  }
}
