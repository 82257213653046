<form>
  <div *ngIf="isFormSet">
    <div [formGroup]="form" class="form-group">
      <ng-container
        *ngIf="
          (!availableExternalDataSources ||
            availableExternalDataSources.length === 0) &&
          !entity.externalDataSource
        "
      >
        <i>
          There are no external data sources available for this Data Entity
        </i>
      </ng-container>
      <ng-container
        *ngIf="
          (availableExternalDataSources &&
            availableExternalDataSources.length > 0) ||
          entity.externalDataSource
        "
      >
        <label class="bmd-label-floating" for="externalDataSource">
          Get value from an external data source?
        </label>
        <select
          class="form-control"
          formControlName="externalDataSource"
          name="externalDataSource"
          (change)="changeExternalSource()"
          [(ngModel)]="externalDataSource"
        >
          <option [ngValue]="null">None</option>
          <option
            *ngFor="let eds of availableExternalDataSources"
            [ngValue]="eds"
          >
            {{ eds.label }}
          </option>
        </select>
      </ng-container>
    </div>

    <div class="form-group card p-2" *ngIf="externalDataSource">
      <!-- Search parcel source options -->
      <wm-parcel-search-external-source-config
        *ngIf="externalDataSource.type === externalDataSourceTypeCodes.parcelSearchEDStypeCode"
        [form]="form"
        [externalDataSource]="externalDataSource"
        [entity]="entity"
        (externalSourceUpdated)="handleChanges()"
      ></wm-parcel-search-external-source-config>

      <!-- Previous Registration Data source options -->
      <wm-registration-external-source-config
        *ngIf="externalDataSource.type === externalDataSourceTypeCodes.registrationEDStypeCode"
        [form]="form"
        [externalDataSource]="externalDataSource"
        [entity]="entity"
        (externalSourceUpdated)="handleChanges()"
      ></wm-registration-external-source-config>

      <!-- Previous Contractor Registration Data source options -->
      <wm-contractor-registration-external-source-config
        *ngIf="externalDataSource.type === externalDataSourceTypeCodes.contractorRegistrationEDStypeCode"
        [form]="form"
        [externalDataSource]="externalDataSource"
        [entity]="entity"
        (externalSourceUpdated)="handleChanges()"
      ></wm-contractor-registration-external-source-config>

      <!-- Can Edit Mapped Value toggle -->
      <div [formGroup]="form" class="switch mt-5">
        <label>
          <input
            type="checkbox"
            formControlName="canEditExternalValue"
            [(ngModel)]="entity.canEditExternalValue"
          />
          Can edit mapped value
        </label>
      </div>
    </div>
  </div>
</form>
