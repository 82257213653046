import { Utilities } from './../../../../services/utilities/index';
import { ToastrService } from 'ngx-toastr';
import { ClientService } from './../../../../services/client.service';
import { CustomList, CustomListItem } from './../../../../models/custom-list';
import { ActivatedRoute, Router } from '@angular/router';
import { UntypedFormGroup, UntypedFormControl, Validators } from '@angular/forms';
import { WorkflowContextService } from './../../../../services/workflow-context.service';
import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { Role } from 'src/app/models';
import { SecurityService, SystemService } from 'src/app/services';
import { Subscription } from 'rxjs';
import { ModalConfirmComponent } from 'src/app/components/system/modal-confirm/modal-confirm.component';
import { indexOf } from 'lodash';

@Component({
  selector: 'app-custom-list-detail-view',
  templateUrl: './custom-list-detail-view.component.html',
  styleUrls: ['./custom-list-detail-view.component.css']
})
export class CustomListDetailViewComponent implements OnInit, OnDestroy {
  @ViewChild('customListTemplates', { static: false }) customListTemplatesModal: ModalConfirmComponent;
  @ViewChild('changeCustomListType') changeCustomListTypeModal: ModalConfirmComponent;

  customListId: string;
  customList: CustomList;
  form: UntypedFormGroup;
  selectedTemplateForm: UntypedFormGroup;
  isRoleMembersList: boolean = false;
  availableRoles: Role[] = [];
  private clientSubscription: Subscription;
  globalCustomLists: CustomList[];
  selectedTemplate: CustomList;

  constructor(
    public _context: WorkflowContextService,
    private _clientSvc: ClientService,
    private _securitySvc: SecurityService,
    private _systemSvc: SystemService,
    private _router: Router,
    private _toastr: ToastrService,
    private route: ActivatedRoute
  ) {}

  ngOnInit() {
    if (this._context.client) {
      this.init();
    } else {
      this.clientSubscription = this._context.client$.subscribe(() => {
        this.init();
      });
    }
  }

  ngOnDestroy() {
    if (this.clientSubscription) {
      this.clientSubscription.unsubscribe();
      this.clientSubscription = null;
    }
  }

  init() {
    this.form = new UntypedFormGroup({
      name: new UntypedFormControl(null, [Validators.required]),
      isRoleMemembersList: new UntypedFormControl(null, [Validators.nullValidator]),
      roleSelected: new UntypedFormControl(null, (this.isRoleMembersList ? [Validators.required] : [Validators.nullValidator]))
    });

    this.selectedTemplateForm = new UntypedFormGroup({
      selectedTemplate: new UntypedFormControl(null, [Validators.required])
    });

    this.route.params.subscribe(params => {
      this.customListId = params['id'];

      this._securitySvc.getRoles(this._context.client).subscribe(roles => {
        this.availableRoles = roles;

        if (this.customListId) {
          this._clientSvc.getCustomList(this.customListId).subscribe(list => {
            if (list) {
              this.customList = list;
    
              if (list.roleId && list.roleId !== '') {
                this.isRoleMembersList = true;
              }
            }
          });
        } else {
          this.customList = {
            id: Utilities.EMPTY_GUID,
            name: null,
            clientId: this._context.client.id,
            items: [],
            roleId: null
          };
        }
      })
    });
  }

  public remove() {
    this._clientSvc.deleteCustomList(this.customListId).subscribe(res => {
      this._toastr.success('Removed!');
      this.goBack();
    });
  }

  public removeItem(item) {
    this._clientSvc
      .deleteCustomListItem(this.customList.id, item.id)
      .subscribe(res => {
        this._toastr.success('Item removed');
      });
  }

  public save() {
    if (!this.isRoleMembersList) {
      this.customList.roleId = null;
    }

    if (this.customList.items.length > 0) {
      for (const item of this.customList.items) {
        item.customListId = this.customList.id;
      }
    }

    const list: CustomList = {
      id: this.customList.id,
      clientId: this.customList.clientId,
      name: this.customList.name,
      items: this.customList.items.length > 0 
        ? this.customList.items.map(
          (item): CustomListItem => ({
            id: item.id,
            customListId: this.customList.id,
            position: item.position,
            text: item.text,
            value: item.value,
            imagePath: item.imagePath,
            roleMemberUserId: item.roleMemberUserId
          })
        )
        : [],
      roleId: this.customList.roleId
    };

    this._clientSvc.saveCustomList(list).subscribe(res => {
      this._toastr.success('Saved!');
      this.goBack();
    });
  }

  public goBack() {
    this._router.navigate([
      'admin',
      'jurisdiction',
      this._context.client.id,
      'settings',
      'lists'
    ]);
  }

  public canRemove() {
    return this.customList.id !== Utilities.EMPTY_GUID;
  }

  tryToggleCustomListType() {
    if (this.customList.items && this.customList.items.length > 0) {
      this.changeCustomListTypeModal.open();
    } else {
      this.reset();
    }
  }

  cancelChangeCustomListType() {
    this.isRoleMembersList = !this.isRoleMembersList;
  }
  
  toggleCustomListType() {
    this.reset();
  }

  reset() {
    this.customList.items = [];
    
    if (!this.isRoleMembersList) {
      this.customList.roleId = null;
    }
  }

  openCustomListTemplates() {
    this._systemSvc.getGlobalCustomLists().subscribe(templates => {
      this.globalCustomLists = templates;

      this.selectedTemplate = null;

      this.customListTemplatesModal.open();
    });
  }

  loadSelectedTemplate() {
    if (this.selectedTemplate) {
      const originalLength = this.customList.items.length;

      for (let templateItem of this.selectedTemplate.items) {
        if (!this.customList.items.some(cli => cli.value.toLowerCase() === templateItem.value.toLowerCase())) {
          const itemToAdd = new CustomListItem({
            id: Utilities.generateId(),
            position: originalLength + templateItem.position,
            customListId: this.customList.id,
            text: templateItem.text,
            value: templateItem.value,
            imagePath: templateItem.imagePath
          });
      
          this.customList.items.push(itemToAdd);
        }
      }

      this._toastr.success('Template items loaded');
    }
  }

  loadRoleMembers() {
    if (this.customList.roleId && this.customList.roleId !== '') {
      this._securitySvc.getRole(this.customList.roleId).subscribe(r => {
          if (r && r.members && r.members.length > 0) {
            let roleMemberListItems = r.members.map(m => 
              new CustomListItem({
                id: Utilities.generateId(),
                position: indexOf(r.members, m),
                customListId: this.customList.id,
                text: m.user.name,
                value: m.user.emailAddress,
                roleMemberUserId: m.user.id
              })
            );

            roleMemberListItems.sort((a, b) => (a.text > b.text ? 1 : -1))

            this.customList.items = roleMemberListItems;
          }
        });
    } else {
      this.customList.items = [];
    }
  }
}
