export class Announcement {
    id: string;
    clientId?: string;
    title: string;
    htmlContent: string;
    startDateString: string;
    endDateString: string;
    isEnabled: boolean;
    audienceIsGlobal: boolean;
    modifiedOnString: string;

    announcementClients: AnnouncementClient[];
    announcementStates: AnnouncementState[];

    isLive: boolean;

    constructor(options?: Partial<Announcement>) {
        if (options) {
            Object.assign(this, options);
        }
    }
}

export class AnnouncementClient {
    id: string;
    announcementId: string;
    clientId: string;
    clientName: string;

    constructor(options?: Partial<AnnouncementClient>) {
        if (options) {
            Object.assign(this, options);
        }
    }
}

export class AnnouncementState {
    id: string;
    announcementId: string;
    stateName: string;

    constructor(options?: Partial<AnnouncementState>) {
        if (options) {
            Object.assign(this, options);
        }
    }
}

export enum AnnouncementAudienceType {
    AllClients = 1,
    Jurisdictions = 2,
    States = 3
}

export class AvailableAnnouncementClient {
    clientId: string;
    clientName: string;
    stateCode: string;
    selected: boolean;
}

export class AvailableAnnouncementState {
    stateName: string;
    selected: boolean;
}

export class AvailableGlobalAnnouncementAudiences {
    availableJurisdictions: AvailableAnnouncementClient[];
    availableStates: AvailableAnnouncementState[];
}

export class RefreshAnnouncementsResponse {
    newAnnouncements: Announcement[];
}