import {
  Component,
  OnInit,
  Inject,
  forwardRef,
  OnDestroy,
  Input,
  ViewChild
} from '@angular/core';
import { Subscription } from 'rxjs';
import { Document } from '../../../models/document';
import {
  UntypedFormBuilder,
  UntypedFormGroup,
  ValidationErrors
} from '@angular/forms';
import { DocumentService } from '../../../services/document.service';
import { ToastrService } from 'ngx-toastr';
import {
  Utilities,
  WorkflowContextService,
  WorkflowService
} from 'src/app/services';
import { ModalConfirmComponent } from '../../system/modal-confirm/modal-confirm.component';
import { UploadComponent } from '../../upload/upload.component';
import { PreviousSystemDataImportRequest } from 'src/app/models/previous-system-data';

@Component({
  selector: 'wm-workflow-previous-sytem-import',
  templateUrl: './workflow-previous-sytem-import.component.html',
  styleUrls: ['./workflow-previous-sytem-import.component.css']
})
export class WorkFlowPreviousSytemImportComponent implements OnInit, OnDestroy {
  @Input() isPreview: boolean;
  @ViewChild('clearModal', { static: true }) clearModal: ModalConfirmComponent;
  @ViewChild('uploadComponent', { static: true }) uploadComponent: UploadComponent;
  @ViewChild('uploadAttachmentIndexComponent', { static: true }) uploadAttachmentIndexComponent: UploadComponent;

  form: UntypedFormGroup;
  document: Document;
  documentTemplateCode: string;
  fileCompleteSub: Subscription;
  fileAddedSub: Subscription;
  clientId = this._context.client.id;
  newUploadKey: string; // upload key for when the file dialog gets opened
  activeUploadKey: string = null; // sets the activeUploadKey one the user selects the files to upload and loads the newUploadKey for when you select upload the next time
  uploadData = false;
  uploadDataAttachments = false;
  uploadRequest: PreviousSystemDataImportRequest;
  
  constructor(
    @Inject(forwardRef(() => DocumentService))
    private _documentSvc: DocumentService,
    private _fb: UntypedFormBuilder,
    private _context: WorkflowContextService,
    private _toastrSvc: ToastrService,
    private _workflowSvc: WorkflowService
  ) {}

  ngOnInit() {
    this.newUploadKey = Utilities.generateId();

    this.form = this._fb.group({
      uploadData: [''],
      uploadDataAttachments: [''],
      clearExisting: [''],
      type: this._fb.group({
        documents: this._fb.array([])
      })
    });

    this.form.addControl(
      'hasRelevantSettingsSpecified',
      this._fb.control('', this.hasRelevantSettingsSpecified.bind(this))
    );

    if (this.fileAddedSub) {
      this.fileAddedSub.unsubscribe();
    }

    this.fileAddedSub = this._documentSvc.filesAdded.subscribe(
      (files: { files: any; key: string }) => {
        this.activeUploadKey = files.key;
        this.newUploadKey = Utilities.generateId();
      }
    );

    if (this.fileCompleteSub) {
      this.fileCompleteSub.unsubscribe();
    }

    this.fileCompleteSub = this._documentSvc.fileUploadComplete.subscribe(
      (files: { files: any; key: string }) => {}
    );

    this.initializeRequest();
  }

  ngOnDestroy(): void {
    if (this.fileCompleteSub) {
      this.fileCompleteSub.unsubscribe();
    }

    if (this.fileAddedSub) {
      this.fileAddedSub.unsubscribe();
    }

    this.form.removeControl('hasRelevantSettingsSpecified');
  }
  
  fileUploaded(file: Document) {
    const existingFile = this.uploadRequest.tabularData.find(td => td.name === file.name);

    if (existingFile) {
      const fileIdx = this.uploadRequest.tabularData.indexOf(existingFile);
      
      this.uploadRequest.tabularData[fileIdx] = file;
    } else {
      this.uploadRequest.tabularData.push(file);
    }

    this.updateHasRelevantSettingsSpecified();
  }
  
  removeFile(file: Document) {
    const filteredFiles = this.uploadRequest.tabularData.filter(td => td.name !== file.name);

    this.uploadRequest.tabularData = filteredFiles;

    this.updateHasRelevantSettingsSpecified();
  }
  
  indexFileUploaded(file: Document) {
    this.uploadRequest.attachmentIndexFile = file;

    this.updateHasRelevantSettingsSpecified();
  }
  
  removeIndexFile(indexFile: Document) {
    this.uploadRequest.attachmentIndexFile = null;

    this.updateHasRelevantSettingsSpecified();
  }

  checkUpload() {
    if (this.uploadRequest.clearExisting) {
      this.clearModal.open();
    } else {
      this.importData();
    }
  }

  importData() {
    this.uploadRequest.uploadKey = this.activeUploadKey

    // clear out values if they were populated but then they 
    // changed their minds and didn't want to include that category
    if (!this.uploadData) {
      this.uploadRequest.tabularData = [];
    }

    if (!this.uploadDataAttachments) {
      this.uploadRequest.attachmentIndexFile = null;
    }

    this._workflowSvc
      .importPreviousSystemData(this.uploadRequest)
      .subscribe(response => {
        if (response.success) {
          this.uploadComponent.clearUploaded();
          this.uploadAttachmentIndexComponent.clearUploaded();

          this._toastrSvc.success(response.message);

          this.initializeRequest();
        } else {
          this._toastrSvc.error(response.message);
        }
      });
  }

  initializeRequest() {
    this.uploadData = false;
    this.uploadDataAttachments = false;
    this.uploadRequest = new PreviousSystemDataImportRequest({
      clientId: this.clientId || this._context.client.id,
      requestedByUserId: this._context.user.id,
      tabularData: []
    });
  }

  hasRelevantSettingsSpecified(): ValidationErrors | null {
    if (this.uploadData) {
      if (!this.uploadRequest.tabularData || this.uploadRequest.tabularData.length === 0) {
        return { tabularDataFileSelected: true };
      } 
    }

    if (this.uploadDataAttachments) {
      if (!this.uploadRequest.attachmentIndexFile) {
        return { attachmentIndexFileSelected: true };
      } 
    }

    if (this.uploadRequest) {
      if (!this.uploadRequest.tabularData || this.uploadRequest.tabularData.length === 0) {
        if (this.uploadRequest.clearExisting) {
          return { cannotReplaceData: true };
        }
      }
    }

    return null;
  }

  updateHasRelevantSettingsSpecified() {
    this.form
      .get('hasRelevantSettingsSpecified')
      .setValidators(this.hasRelevantSettingsSpecified.bind(this));
    this.form.get('hasRelevantSettingsSpecified').updateValueAndValidity();
    this.form.get('hasRelevantSettingsSpecified').markAsTouched();
  }
}
