<app-navbar 
  isAdminArea="true"
  (pixelHeightChanged)="setTopMarginForNavbar($event)"
></app-navbar>

<div class="container mt-4" [style]="'padding-top: ' + topMargin + 'px;'">
  <div class="row">
    <div class="col">
      <router-outlet></router-outlet>
    </div>
  </div>
</div>
