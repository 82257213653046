<div [style]="'display:' + displayStyle">

    <ng-container *ngIf="userAnnouncements && userAnnouncements.length > 0">

        <div class="announcement-banner row">

            <div class="col-1"></div>

            <div class="col-6 align-self-center text-center">
                <a  
                    (click)="openAnnouncement()"
                    ngbTooltip="Learn more"
                    style="cursor: pointer; text-transform: unset"
                    class="btn btn-primary btn-sm mt-1"
                    type="button"
                >
                    View
                </a>
                &nbsp;
                <a  
                    (click)="openAnnouncement()"
                    ngbTooltip="Learn more"
                    style="cursor: pointer"
                >
                    <strong>
                        {{ visibleAnnouncement.title }}
                    </strong>
                </a>
            </div>

            <div class="col-2 align-self-center text-center">
                <button
                    class="btn btn-primary btn-sm mt-1"
                    style="text-transform: unset"
                    type="button"
                    (click)="dismissAnnouncement()"
                >
                    Don't show again
                </button>
            </div>

            <div class="col-1 align-self-center">
                <!-- previous announcement -->
                <i 
                    class="material-icons mt-1"
                    style="cursor: pointer;"
                    ngbTooltip="Previous Announcement"
                    [hidden]="visibleAnnouncementIndex === 0"
                    (click)="previousAnnouncement()"
                >
                    arrow_back
                </i>
            </div>

            <div class="col-1 align-self-center">
                <!-- next announcement -->
                <i 
                    class="material-icons mt-1"
                    style="cursor: pointer;"
                    ngbTooltip="Next Announcement"
                    [hidden]="(visibleAnnouncementIndex + 1) >= userAnnouncements.length"
                    (click)="nextAnnouncemet()"
                >
                    arrow_forward
                </i>
            </div>

            <div class="col-1">
                <button
                    type="button"
                    class="close mt-1"
                    aria-label="Close"
                    ngbTooltip="Close"
                    (click)="closeBanner()"
                >
                    <span aria-hidden="true">&times;</span>
                </button>

            </div>

        </div>

        <wm-modal-confirm
            #fullAnnouncement
            [showButton]="false"
            commandText="Don't show me again"
            cancelText="Close"
            [title]="visibleAnnouncement.title"
            (accepted)="dismissAnnouncement()"
            btnColor="success"
            class="mx-2"
            [closeOnAccept]="true"
        >
            <div class="row">
                <div class="col">
                    <div [innerHtml]="visibleAnnouncement.htmlContent"></div>
                </div>
            </div>
        </wm-modal-confirm>

    </ng-container> 


</div>