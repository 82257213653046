import { DataService } from './../../../../services/data.service';
import { Component, OnInit, HostListener } from '@angular/core';
import { LoginOrientation } from '../../../../components/system/login/login.component';
import {
  WorkflowContextService,
  SecurityService,
  Utilities
} from '../../../../services';
import { Router, ActivatedRoute } from '@angular/router';
import { Actions } from '../../../../models';
import { Observable, combineLatest, of } from 'rxjs';
import { map, filter } from 'rxjs/operators';

@Component({
  selector: 'app-client-admin',
  templateUrl: './client-admin.component.html',
  styleUrls: ['./client-admin.component.css']
})
export class ClientAdminComponent implements OnInit {
  clientId: string;
  loginOrientation = LoginOrientation;
  canEditMapServices = false;
  canEditClients = false;
  canEditPaymentProcessorAccounts = false;
  canManageFeeRecipients = false;
  canEditExternalConnections = false;
  canEditContacts = false;
  canEditUsers = false;
  canEditRoles = false;
  canInviteUsers = false;
  canViewPaymentTransactions = false;
  canEditWorkflows = false;
  canViewDataExport = false;
  canViewCustomReports = false;
  canEditContractors = false;
  canViewInspections = false;
  canViewContractorSummary = false;
  canViewContractorFeeSummary = false;
  canViewPermitSummary = false;
  canViewDailyFees = false;
  canViewFeeRecipients = false;
  canViewPSD = false;
  canViewUserGuide = false;
  canViewRenewals = false;
  canViewMeetings = false;
  canDoAnything = false;
  canManageDataSets = false;

  public innerWidth: any;

  menuItems = null;
  helpDocumentLink: any;
  userGuideSub: any;

  topMargin: number;

  constructor(
    public context: WorkflowContextService,
    public router: Router,
    private route: ActivatedRoute,
    private _securitySvc: SecurityService,
    private _service: DataService
  ) {
    // TODO: Remove this when not needed. It is here ONLY to test loading pages without any context yet.
    // this.context.client$.emit();

    this.clientId = this.route.snapshot.paramMap.get('clientId');

    // TODO: This doesn't ALWAYS complete before child components need it to
    if (this.clientId) {
      this._service.getPublicClient(this.clientId).subscribe(client => {
        this.context.client$.emit(client);
      });
    }
  }

  activeRoute(url, exact = false) {
    return this.router.isActive(this.createUrl(url), exact);
  }

  canViewWorkflows(): boolean {
    return this.canEditWorkflows;
  }
  canViewContractors(): boolean {
    return this.canEditContractors;
  }
  canEditSecurity(): boolean {
    return this.canEditRoles || this.canEditUsers;
  }

  canEditSettings(): boolean {
    return (
      this.canEditExternalConnections ||
      this.canEditMapServices ||
      this.canEditPaymentProcessorAccounts ||
      this.canManageFeeRecipients ||
      this.canEditClients
    );
  }

  canViewFinancialReports(): boolean {
    return (
      this.canViewDailyFees ||
      this.canViewFeeRecipients ||
      this.canViewPaymentTransactions
    );
  }

  canViewContractorReports(): boolean {
    return this.canViewContractorSummary || this.canViewContractorFeeSummary;
  }

  canViewPermitReports(): boolean {
    return this.canViewPermitSummary;
  }

  canViewReports() {
    return (
      this.canViewDataExport ||
      this.canViewPermitReports() ||
      this.canViewFinancialReports() ||
      this.canViewContractorReports() ||
      this.canViewCustomReports
    );
  }

  canViewEmailSettings() {
    return this.canDoAnything;
  }

  canAccessMeetings() {
    return this.canViewMeetings;
  }

  canViewHelpMenu() {
    return this.canViewUserGuide;
  }

  showCommunityWorkflow() {
    return this.context.client; // this.context.client && this.location.path().indexOf('/admin/workflow') > -1;
  }

  initMenuPermissions(): Observable<any> {
    const result$ = combineLatest(
      this._securitySvc.isLoginEntitled(Actions.MAP_SERVICE_MANAGE).pipe(
        map(r => {
          this.canEditMapServices = r;
          return r;
        })
      ),
      this._securitySvc.isLoginEntitled(Actions.CLIENTS_MANAGE_SETTINGS).pipe(
        map(r => {
          this.canEditClients = r;
          return r;
        })
      ),
      this._securitySvc
        .isLoginEntitled(Actions.PAYMENT_PROCESSOR_ACCOUNT_MANAGE)
        .pipe(
          map(r => {
            this.canEditPaymentProcessorAccounts = r;
            return r;
          })
        ),
      this._securitySvc.isLoginEntitled(Actions.MANAGE_FEE_RECIPIENTS).pipe(
        map(r => {
          this.canManageFeeRecipients = r;
          return r;
        })
      ),
      this._securitySvc
        .isLoginEntitled(Actions.EXTERNAL_CONNECTION_MANAGE)
        .pipe(
          map(r => {
            this.canEditExternalConnections = r;
            return r;
          })
        ),
      this._securitySvc.isLoginEntitled(Actions.USER_MANAGE).pipe(
        map(r => {
          this.canEditUsers = r;
          return r;
        })
      ),
      this._securitySvc.isLoginEntitled(Actions.ROLE_MANAGE).pipe(
        map(r => {
          this.canEditRoles = r;
          return r;
        })
      ),
      this._securitySvc.isLoginEntitled(Actions.REPORT_DATA_EXPORT_VIEW).pipe(
        map(r => {
          this.canViewDataExport = r;
          return r;
        })
      ),
      this._securitySvc.isLoginEntitled(Actions.VIEW_CUSTOM_REPORTS).pipe(
        map(r => {
          this.canViewCustomReports = r;
          return r;
        })
      ),
      this._securitySvc
        .isLoginEntitled(Actions.REPORT_PAYMENT_TRANSACTION_VIEW)
        .pipe(
          map(r => {
            this.canViewPaymentTransactions = r;
            return r;
          })
        ),
      this._securitySvc
        .isLoginEntitled(null, null, Actions.WORKFLOW_ACTIONS)
        .pipe(
          map(r => {
            this.canEditWorkflows = r;
            return r;
          })
        ),
      this._securitySvc
        .isLoginEntitled(null, null, Actions.CONTRACTOR_ACTIONS)
        .pipe(
          map(r => {
            this.canEditContractors = r;
            return r;
          })
        ),
      this._securitySvc.isLoginEntitled(Actions.VIEW_INSPECTIONS).pipe(
        map(r => {
          this.canViewInspections = r;
          return r;
        })
      ),
      this._securitySvc.isLoginEntitled(Actions.VIEW_CONTRACTOR_SUMMARY).pipe(
        map(r => {
          this.canViewContractorSummary = r;
          return r;
        })
      ),
      this._securitySvc
        .isLoginEntitled(Actions.VIEW_CONTRACTOR_FEE_SUMMARY)
        .pipe(
          map(r => {
            this.canViewContractorFeeSummary = r;
            return r;
          })
        ),
      this._securitySvc.isLoginEntitled(Actions.VIEW_PERMIT_SUMMARY).pipe(
        map(r => {
          this.canViewPermitSummary = r;
          return r;
        })
      ),
      this._securitySvc.isLoginEntitled(Actions.VIEW_PREVIOUS_SYSTEM_DATA).pipe(
        map(r => {
          this.canViewPSD = r;
          return r;
        })
      ),
      this._securitySvc.isLoginEntitled(Actions.RENEWALS_VIEW).pipe(
        map(r => {
          this.canViewRenewals = r;
          return r;
        })
      ),
      this._securitySvc.isLoginEntitled(Actions.VIEW_USER_GUIDE).pipe(
        map(r => {
          this.canViewUserGuide = r;
          return r;
        })
      ),
      this._securitySvc
        .isLoginEntitled(null, null, Actions.AGENDA_ACTIONS)
        .pipe(
          map(r => {
            this.canViewMeetings = r;
            return r;
          })
        ),
      this._securitySvc.isLoginEntitled(Actions.INVITE_USER).pipe(
        map(r => {
          this.canInviteUsers = r;
          return r;
        })
      ),
      this._securitySvc.isLoginEntitled(Actions.DO_ANYTHING).pipe(
        map(r => {
          this.canDoAnything = r;
          return r;
        })
      ),
      this._securitySvc.isLoginEntitled(Actions.MANAGE_DATASETS).pipe(
        map(r => {
          this.canManageDataSets = r;
          return r;
        })
      )
    );

    return result$;
  }

  getDefaultLink(menuItem): string {
    if (menuItem.children) {
      const viewableChildren = menuItem.children.filter(
        c => c.canView === true
      );

      if (viewableChildren && viewableChildren.length > 0) {
        return this.createUrl(viewableChildren[0].url);
      }
    } else {
      return this.createUrl(menuItem.url);
    }
    return null;
  }

  @HostListener('window:resize', ['$event'])
  onResize(event) {
    this.innerWidth = window.innerWidth;
  }

  get sidebarClass() {
    return this.innerWidth < 768 ? 'bmd-drawer-overlay' : '';
  }

  createUrl(url: string) {
    const hasValidUrlProtocol = (urltest = '') =>
      ['http://', 'https://', 'ftp://'].some(protocol => {
        return urltest.startsWith(protocol);
      });
    if (hasValidUrlProtocol(url)) {
      return url;
    } else {
      return `/admin/jurisdiction/${this.clientId}${url}`;
    }
  }

  ngOnInit() {
    this.innerWidth = window.innerWidth;
    this.helpDocumentLink = '';

    this.userGuideSub = this._service.getUserGuideLink().subscribe(results => {
      this.helpDocumentLink = results;
    });

    this.route.paramMap.subscribe(params => {
      this.clientId = params.get('clientId');
      this.initMenuPermissions().subscribe(a => {
        this.menuItems = [
          {
            name: 'Workflows',
            url: `/workflows`,
            canView: this.canViewWorkflows(),
            children: [
              {
                name: 'List',
                url: `/workflows/list`,
                canView: this.canEditWorkflows
              }
              // {
              //   name: 'Archive',
              //   url: `/workflows/archive`
              // }
            ]
          },
          {
            name: 'Applications',
            url: `/workflow-applications`,
            canView: true,
            children: [
              {
                name: 'Search by Parcel',
                url: `/workflow-applications/search`,
                canView: true
              },
              {
                name: 'Search by Feature',
                url: `/workflow-applications/search-feature`,
                canView: true
              },
              {
                name: 'List',
                url: `/workflow-applications/list`,
                canView: true
              },
              {
                name: 'Previous System List',
                url: `/workflow-applications/previous-system-list`,
                canView: this.canViewPSD
              }
            ]
          },
          {
            name: 'Renewals',
            url: `/renewals`,
            canView: this.canViewRenewals,
            children: [
              {
                name: 'List',
                url: `/renewals/list`,
                canView: this.canViewRenewals
              }
            ]
          },
          {
            name: 'Contractors',
            url: `/contractors`,
            canView: this.canViewContractors(),
            children: [
              {
                name: 'Contractor Types',
                url: `/contractors/types`,
                canView: this.canEditContractors
              },
              {
                name: 'Search',
                url: `/contractors/search`,
                canView: this.canEditContractors
              }
            ]
          },
          {
            name: 'Contacts',
            url: `/contacts`,
            canView: this.canEditContacts,
            children: [
              {
                name: 'List',
                url: `/contacts/list`,
                canView: this.canEditContacts
              }
            ]
          },
          {
            name: 'Inspections',
            url: `/inspections`,
            canView: this.canViewInspections,
            children: [
              {
                name: 'Queue',
                url: `/inspections`,
                canView: true
              },
              {
                name: 'Assigned Inspections',
                url: `/inspections/assignedInspections`,
                canView: true
              }
            ]
          },
          {
            name: 'Reports',
            url: `/reports`,
            canView: this.canViewReports(),
            children: [
              {
                name: 'Financial',
                url: `/reports/financial`,
                canView: this.canViewFinancialReports()
              },
              {
                name: 'Contractors',
                url: `/reports/contractors`,
                canView: this.canViewContractorReports()
              },
              {
                name: 'Permits',
                url: `/reports/permits`,
                canView: this.canViewPermitReports()
              },
              {
                name: 'Data Export',
                url: `/reports/export`,
                canView: this.canViewDataExport
              },
              {
                name: 'Custom',
                url: `/reports/custom-reports`,
                canView: this.canViewCustomReports
              },
              {
                name: 'Data Sets',
                url: '/reports/data-sets',
                canView: this.canManageDataSets
              }
            ]
          },
          {
            name: 'Meetings',
            url: `/meetings`,
            canView: this.canAccessMeetings(),
            children: [
              {
                name: 'Agendas',
                url: `/meetings/agenda-types`,
                canView: true
              }
            ]
          },
          {
            name: 'Users',
            url: `/users`,
            canView: this.canEditSecurity(),
            children: [
              {
                name: 'List',
                url: `/users/list`,
                canView: this.canEditUsers
              },
              {
                name: 'Roles',
                url: `/users/roles`,
                canView: this.canEditRoles
              },
              {
                name: 'Invite User',
                url: `/users/invitations/add`,
                canView: this.canInviteUsers
              }
            ]
          },
          {
            name: 'Settings',
            url: `/settings`,
            canView: this.canEditSettings(),
            children: [
              {
                name: 'General',
                url: `/settings/general`,
                canView: this.canEditClients
              },
              {
                name: 'Previous System',
                url: `/settings/import-previous-system`,
                canView: this.canDoAnything
              },
              {
                name: 'Map Services',
                url: `/settings/maps`,
                canView: this.canEditMapServices
              },
              {
                name: 'Payments',
                url: `/settings/payment-settings`,
                canView:
                  this.canEditPaymentProcessorAccounts ||
                  this.canManageFeeRecipients
              },
              {
                name: 'Data Connections',
                url: `/settings/data`,
                canView: this.canEditExternalConnections
              },
              {
                name: 'Custom Lists',
                url: '/settings/lists',
                canView: this.canEditClients
              },
              {
                name: 'Contacts',
                url: '/settings/contacts',
                canView: this.canEditClients
              },
              {
                name: 'Tags',
                url: '/settings/tags',
                canView: this.canEditClients
              },
              {
                name: 'Email Settings',
                url: '/settings/email-settings',
                canView: this.canViewEmailSettings()
              }
            ]
          },
          {
            name: 'Help',
            url: `/help`,
            canView: this.canViewHelpMenu(),
            materialIconCode: 'help'
          }
        ];
      });
    });
  }

  setTopMarginForNavbar(pixelHeight: number) {
    this.topMargin = pixelHeight;
  }
}
