<form [formGroup]="form">

  <div class="row">
    <!-- title -->
    <div class="col-6">
      <div class="form-group">
        <label class="bmd-label-floating" for="title">Title</label>
        <input
          type="text"
          class="form-control"
          [(ngModel)]="announcement.title"
          name="title"
          formControlName="title"
        />
        <wm-control-message
          [control]="form.controls['title']"
        ></wm-control-message>
      </div>
    </div>

    <div class="col-2"></div>

    <!-- enabled slider -->
    <div class="col-2 text-center">
      <div class="row">
        <div class="col">
          Enabled
        </div>
      </div>
      <div class="row">
        <div class="col">
          <div class="switch">
            <label>
              <input
                id="isEnabled"
                type="checkbox"
                [(ngModel)]="announcement.isEnabled"
                formControlName="enabled"
              />
            </label>
          </div>
        </div>
      </div>
    </div>

    <div class="col-2"></div>
  </div>

  <!-- start date/time -->
  <div class="row mt-3">
    <div class="col-4 align-self-center">
      Start Date:
      <input
        ngbDatepicker
        #dStart="ngbDatepicker"
        class="form-control"
        placeholder="mm/dd/yyyy"
        name="startDate-name"
        id="startDate-name"
        (focus)="onFocusStartDate()"
        [restoreFocus]="false"
        [autoClose]="true"
        (change)="setStartDate()"
        (dateSelect)="setStartDate()"
        formControlName="startDate"
        firstDayOfWeek="7"
      />
      <wm-control-message
        [control]="form.controls['startDate']"
      ></wm-control-message>
    </div>

    <div class="col-3 align-items-center">
      <ngb-timepicker
        class="p-0 m-0"
        id="startTime-id"
        name="startTime-name"
        [(ngModel)]="startTime"
        (ngModelChange)="setStartTime()"
        [meridian]="true"
        formControlName="startTime"
      ></ngb-timepicker>
      <wm-control-message
        [control]="form.controls['startTime']"
      ></wm-control-message>
    </div>

    <div class="col-1 p-0 align-self-center text-left">
      {{  globalManagement ? userMachineTimeZoneCode : clientTimeZoneCode }}
    </div>
  </div>

  <!-- toggle End Date editability -->
  <div class="switch">
    <label>
      Specify an End Date?
      <input
        type="checkbox"
        formControlName="haveEndDate"
        name="haveEndDate"
        [(ngModel)]="haveEndDate"
      />
    </label>
  </div>


  <!-- end date/time -->
  <div class="row mt-3" [hidden]="!haveEndDate">
    <div class="col-4 align-self-center">
      End Date (optional):
      <input
        ngbDatepicker
        #dEnd="ngbDatepicker"
        class="form-control"
        placeholder="mm/dd/yyyy"
        name="endDate-name"
        id="endDate-name"
        [restoreFocus]="false"
        [autoClose]="true"
        (dateSelect)="setEndDate()"
        (change)="setEndDate()"
        (focus)="onFocusEndDate()"
        formControlName="endDate"
        firstDayOfWeek="7"
      />
      <wm-control-message
        [control]="form.controls['endDate']"
      ></wm-control-message>
    </div>

    <div class="col-3 align-items-center">
      <ngb-timepicker
        class="p-0 m-0"
        id="endTime-id"
        name="endTime-name"
        [(ngModel)]="endTime"
        (ngModelChange)="setEndTime()"
        [meridian]="true"
        formControlName="endTime"
      ></ngb-timepicker>
      <wm-control-message
        [control]="form.controls['endTime']"
      ></wm-control-message>
    </div>
    <div class="col-1 p-0 align-self-center text-left">
      {{ globalManagement ? userMachineTimeZoneCode : clientTimeZoneCode }}
    </div>
  </div>

  <!-- audience -->
  <div class="row mt-5">
    <div class="col">
      Audience
    </div>
  </div>

  <div class="row mt-2">
    <div class="col-sm-3 text-left float-left">
      <div class="radio">
        <label>
          <input
            type="radio"
            name="audienceType"
            [value]="1"
            [(ngModel)]="audienceType"
            [ngModelOptions]="{ standalone: true }"
            [checked]="audienceType === AnnouncementAudienceType.AllClients"
            (change)="handleAudienceTypeChange()"
          />
          All Jurisdictions
        </label>
      </div>
    </div>

    <div class="col-sm-3 text-left float-left">
      <div class="radio">
        <label>
          <input
            type="radio"
            name="audienceType"
            [value]="2"
            [(ngModel)]="audienceType"
            [ngModelOptions]="{ standalone: true }"
            [checked]="audienceType === AnnouncementAudienceType.Jurisdictions"
            (change)="handleAudienceTypeChange()"
          />
          Jurisdiction(s)
        </label>
      </div>
    </div>

    <div class="col-sm-3 text-left float-left">
      <div class="radio">
        <label>
          <input
            type="radio"
            name="audienceType"
            [value]="3"
            [(ngModel)]="audienceType"
            [ngModelOptions]="{ standalone: true }"
            [checked]="audienceType === AnnouncementAudienceType.States"
            (change)="handleAudienceTypeChange()"
          />
          State(s)
        </label>
      </div>
    </div>
    <div class="col-sm-3"></div>
  </div>


  <div class="row mt-2">
    <!-- jurisdiction(s) selector-->
    <ng-container *ngIf="audienceType == 2">
      <div class="col-6" style="display:block;height:300px;overflow-y:scroll;">
        <div class="row" *ngFor="let aj of availableJurisdictions">
          <div class="col-sm-1">
            <input
              type="checkbox"
              [id]="aj.clientId"
              [(ngModel)]="aj.selected"
              [ngModelOptions]="{standalone: true}"
              (change)="addRemoveJurisdiction(aj)"
            />
          </div>
          <div class="col-sm-11">
            {{ aj.clientName }}
          </div>
        </div>
      </div>

      <div class="col-6" style="display:block;height:300px;">
        <div class="card">
          <div class="card-header">Selected Jurisdictions</div>
          <div class="card-body overflow-auto" style="height:240px;overflow-y:scroll;">
            <ng-container *ngIf="announcement.announcementClients && announcement.announcementClients.length > 0">
              <ng-container *ngFor="let ac of announcement.announcementClients">
                <div class="row">
                  <div class="col">
                    <div
                      class="chip"
                      [autoClose]="'outside'"
                      triggers="manual"
                      #p="ngbPopover"
                      (click)="removeJurisdictionFromSelected(ac.clientId)"
                      #popovers="ngbPopover"
                      [ngbPopover]="popContent"
                      container="body"
                      placement="bottom"
                      [id]="'filter-' + ac.id"
                      style="margin-bottom:0.5rem; height:auto;"
                    >
                      <fa-icon
                        ngbTooltip="Remove this Jurisdiction"
                        placement="left"
                        class="close"
                        [icon]="faTimes"
                        (click)="removeJurisdictionFromSelected(ac.clientId)"
                      ></fa-icon>
                      <span class="font-weight-bold">{{ ac.clientName }}</span>
                    </div>
                  </div>
                </div>
              </ng-container>
            </ng-container>
          </div>
        </div>
      </div>
    </ng-container>

    <!-- state(s) selector-->
    <ng-container *ngIf="audienceType == 3">
      <div class="col-6" style="display:block;height:300px;overflow-y:scroll;">
        <div class="row" *ngFor="let ast of availableStates">
          <div class="col-sm-1">
            <input
              type="checkbox"
              [id]="ast.stateName"
              [value]="ast.selected"
              [(ngModel)]="ast.selected"
              [ngModelOptions]="{standalone: true}"
              (change)="addRemoveState(ast)"
            />
          </div>
          <div class="col-sm-11">
            {{ ast.stateName }}
          </div>
        </div>
      </div>

      <div class="col-6" style="display:block;height:300px;">
        <div class="card">
          <div class="card-header">Selected States</div>
          <div class="card-body overflow-auto" style="height:240px;overflow-y:scroll;">
            <ng-container *ngIf="announcement.announcementStates && announcement.announcementStates.length > 0">
              <ng-container *ngFor="let ast of announcement.announcementStates">
                <div class="row">
                  <div class="col">
                    <div
                      class="chip"
                      [autoClose]="'outside'"
                      triggers="manual"
                      #p="ngbPopover"
                      (click)="removeStateFromSelected(ast.stateName)"
                      #popovers="ngbPopover"
                      [ngbPopover]="popContent"
                      container="body"
                      placement="bottom"
                      [id]="'filter-' + ast.id"
                      style="margin-bottom:0.5rem; height:auto;"
                    >
                      <fa-icon
                        ngbTooltip="Remove this State"
                        placement="left"
                        class="close"
                        [icon]="faTimes"
                        (click)="removeStateFromSelected(ast.stateName)"
                      ></fa-icon>
                      <span class="font-weight-bold">{{ ast.stateName }}</span>
                    </div>
                  </div>
                </div>
              </ng-container>
            </ng-container>
          </div>
        </div>
      </div>
    </ng-container>

  </div>

  <!-- full announcement -->
  <div class="row mt-5">
    <div class="col">
      <label class="bmd-label-floating p-2" for="htmlContent">Announcement Body</label>
      <ckeditor
        #htmlContentEditor
        [editor]="Editor"
        [config]="editorConfig"
        formControlName="htmlContent"
        [(ngModel)]="announcement.htmlContent"
        (ready)="editorReady($event)"
      ></ckeditor>
      <wm-control-message
        [control]="form.controls['htmlContent']"
      ></wm-control-message>
    </div>
  </div>
    
</form>