<h3>Import Previous System Data</h3>

<div [formGroup]="form">
  
  <!-- UPLOAD DATA switch -->
  <div class="row mt-4">
    <div class="col">
      <div class="switch">
        <label>
          <input 
            type="checkbox" 
            formControlName="uploadData"
            [(ngModel)]="uploadData" 
            (ngModelChange)="updateHasRelevantSettingsSpecified()"
          />
          Upload Data
        </label>
      </div>
    </div>
  </div>
  
  <!-- UPLOAD DATA options -->
  <div class="row mt-2 ml-2" [hidden]="!uploadData">
    <div class="col">

      <div class="row mt-1">
        <div class="col-auto">
          <wm-upload
            #uploadComponent
            buttonText="Select File"
            validDocumentTypes="csv"
            [pathSegments]="[
              this.clientId,
              'dataImport',
              'previousSystem',
              this.newUploadKey
            ]"
            (fileUploaded)="fileUploaded($event)"
            (fileDeleted)="removeFile($event)"
            [key]="newUploadKey"
            [isPreview]="isPreview"
            category="Application"
            [deleteStoredDocument]="false"
          ></wm-upload>
        </div>
        <div class="col-auto">
          <wm-help-text
            placement="right"
            helpText="This CSV file must contain six columns (the searchable fields): permit_number, parcel_number, applicant, address, issue_date, type.  All other columns not given those names will be included as expandable details for that record."
          ></wm-help-text>
        </div>
      </div>

    </div>
  </div>

  <!-- UPLOAD DATA ATTACHMENTS switch -->
  <div class="row mt-4">
    <div class="col">
      <div class="switch">
        <label>
          <input 
            type="checkbox" 
            formControlName="uploadDataAttachments"
            [(ngModel)]="uploadDataAttachments" 
            (ngModelChange)="updateHasRelevantSettingsSpecified()"
          />
          Upload Data Attachments
        </label>
      </div>
    </div>
  </div>
  
  <!-- UPLOAD DATA ATTACHMENTS options -->
  <div class="row mt-2 ml-2" [hidden]="!uploadDataAttachments">
    <div class="col">
      <div class="text-danger">Attachments must be located in a dedicated folder on \\tsc.schneidercorp.com\gisdata\GeoPermits\previous-system-imports. Create a CSV index file that contains two columns: permit_number and path.  The permit number must match a Previous System Data record exactly and the path should specify the location of the attachment file, starting with the dedicated folder that was added to the file server.</div>
      <div class="row mt-3">
        <div class="col">
          <wm-upload
            #uploadAttachmentIndexComponent
            buttonText="Select Index File"
            validDocumentTypes="csv"
            [pathSegments]="[
              this.clientId,
              'dataImport',
              'previousSystem',
              this.newUploadKey
            ]"
            (fileUploaded)="indexFileUploaded($event)"
            (fileDeleted)="removeIndexFile($event)"
            [key]="newUploadKey"
            [isPreview]="isPreview"
            category="Application"
            [deleteStoredDocument]="false"
          ></wm-upload>
        </div>
      </div>
    </div>
  </div>
  
  <div class="row mt-5">
    <div class="col">
      <input
        type="checkbox"
        formControlName="clearExisting"
        [(ngModel)]="uploadRequest.clearExisting"
        (ngModelChange)="updateHasRelevantSettingsSpecified()"
      />
      Replace any existing data
      <span *ngIf="uploadRequest.clearExisting" class="text-danger"> &nbsp; WARNING: any Previous System Data previously imported for this jurisdiction will be permanently deleted, including all attachments.</span>
    </div>
  </div>

  <!-- START UPLOAD button -->
  <div class="row mt-2">
    <div class="col">
      <button
        type="button"
        class="btn btn-raised btn-primary mr-1"
        (click)="checkUpload()"
        [disabled]="form.invalid || (!uploadData && !uploadDataAttachments)"
      >
        Start Upload
      </button>
    </div>
  </div>
    
</div>

<!-- Replace existing data confirmation modal -->
<wm-modal-confirm
  #clearModal
  title="Clear Previously Imported Records"
  [showButton]="false"
  commandText="Yes"
  moduleId="clear"
  (accepted)="importData()"
  ><div>
    Are you sure you want to delete any previously imported data and attachments and
    replace with new data?
  </div>
</wm-modal-confirm>
