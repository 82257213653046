<app-navbar (pixelHeightChanged)="setTopMarginForNavbar($event)"></app-navbar>

<div class="container" [style]="'margin-top: ' + topMargin + 'px;'">
  <div class="row mt-5 pt-5">
    <div class="col-7"></div>
    <div class="col-5 pr-0 mr-0 d-flex justify-content-end">
      <button
        type="button"
        class="btn btn-secondary d-flex justify-content-center align-content-between mr-1"
        (click)="panelConfigModal.open()"
      >
        <span>My Dashboard Settings </span
        ><i class="material-icons ml-1">settings</i>
      </button>
      <wm-modal-confirm
        title="My Dashboard Appearance"
        [showButtonText]="false"
        [showButton]="false"
        commandText="Save"
        (opened)="onModalOpen()"
        (canceled)="onModalCancel()"
        (accepted)="onModalSave()"
        #panelConfigModal
      >
        <div>
          <div class="row mt-1 text-center">
            <div class="col-sm-2">Order</div>
            <div class="col-sm-6"></div>
            <div class="col-sm-2">Show</div>
            <div class="col-sm-2">Collapse</div>
          </div>

          <div dragula="panelDrags" [(dragulaModel)]="trialPanelConfigs">
            <div
              class="row mt-2 text-center border shadow-sm"
              *ngFor="let panel of trialPanelConfigs"
            >
              <div class="col-sm-2">
                <i class="material-icons">drag_handle</i>
              </div>
              <div class="col-sm-6 text-left">{{ panel.name }}</div>
              <div class="col-sm-2">
                <div class="radio">
                  <label class="pb-1">
                    <input
                      type="radio"
                      [name]="panel"
                      [value]="1"
                      [(ngModel)]="panel.visibility"
                      [checked]="panel.visibility == '1'"
                    />
                  </label>
                </div>
              </div>
              <div class="col-sm-2">
                <div class="radio">
                  <label class="pb-1">
                    <input
                      type="radio"
                      [name]="panel"
                      [value]="2"
                      [(ngModel)]="panel.visibility"
                      [checked]="panel.visibility == '2'"
                    />
                  </label>
                </div>
              </div>
            </div>
          </div>
        </div>
      </wm-modal-confirm>
    </div>
  </div>
</div>

<div class="container">
  <div class="row">
    <div class="col mt-1">
      <div>
        <app-loader *ngIf="isAllLoaded().length > 0"></app-loader>
      </div>
      <div class="row mt-1">
        <div class="col">
          <div
            ngbAccordion
            #acc="ngbAccordion"
            [destroyOnHide]="false"
            *ngIf="panelConfigs"
            class="card"
          >
            <div
              *ngFor="let panel of panelConfigs"
              ngbAccordionItem="{{ panel.panelId }}"
              [id]="panel.id"
            >
              <div
                ngbAccordionHeader
                class="d-flex align-items-center justify-content-between card-header"
              >
                <h5 class="m-0">{{ panel.name }}:</h5>
                <div>
                  <div
                    class="chip m-0"
                    *ngIf="
                      !acc.isExpanded(panel.panelId) &&
                      isAllLoaded().length == 0
                    "
                  >
                    Items: {{ this.recordCounts[panel.panelId] }}
                  </div>
                  <button ngbAccordionToggle class="btn btn-link p-0 ml-2">
                    {{ acc.isExpanded(panel.panelId) ? 'collapse' : 'expand' }}
                  </button>
                </div>
              </div>
              <div ngbAccordionCollapse class="card-body">
                <div ngbAccordionBody>
                  <ng-template
                    [ngTemplateOutlet]="this[panel.templateId]"
                  ></ng-template>
                </div>
              </div>
            </div>
          </div>

          <ng-template #myWorkflowApplications>
            <wm-workflow-application-list
              id="workflow-application-list-pane"
              (recordCountSet)="
                setRecordCounts($event, 'wm-workflow-application-list-pane')
              "
              [workflowId]="null"
              [workflows]="workflows"
              [staticFilters]="myWorkflowsFilter"
              (dataLoaded)="incrementLoaded($event)"
              (filtersChanged)="
                filtersChange('wm-workflow-application-list-pane')
              "
              *ngIf="workflows"
            ></wm-workflow-application-list>
          </ng-template>

          <ng-template #applicationsSharedByMe>
            <wm-workflow-application-list
              id="workflow-applications-shared-by-me"
              (recordCountSet)="
                setRecordCounts($event, 'wm-workflow-applications-shared-by-me')
              "
              [workflowId]="null"
              [workflows]="workflows"
              [staticFilters]="sharedByMeWorkflowsFilter"
              (dataLoaded)="incrementLoaded($event)"
              (filtersChanged)="
                filtersChange('wm-workflow-applications-shared-by-me')
              "
              *ngIf="workflows"
            ></wm-workflow-application-list>
          </ng-template>

          <ng-template #applicationsSharedWithMe>
            <wm-workflow-application-list
              id="workflow-applications-shared-with-me"
              (recordCountSet)="
                setRecordCounts(
                  $event,
                  'wm-workflow-applications-shared-with-me'
                )
              "
              [workflowId]="null"
              [workflows]="workflows"
              [staticFilters]="sharedWithMeWorkflowsFilter"
              (dataLoaded)="incrementLoaded($event)"
              (filtersChanged)="
                filtersChange('wm-workflow-applications-shared-with-me')
              "
              *ngIf="workflows"
            ></wm-workflow-application-list>
          </ng-template>

          <ng-template #applicationsNeedingMyAttention>
            <wm-workflow-applications-needing-my-attention
              (recordCountSet)="
                setRecordCounts(
                  $event,
                  'wm-workflow-applications-needing-my-attention'
                )
              "
              [workflows]="workflows"
              (updateStatus)="updateStatusEvent($event)"
              (dataLoaded)="incrementLoaded($event)"
              (filtersChanged)="
                filtersChange('wm-workflow-applications-needing-my-attention')
              "
              *ngIf="workflows"
            >
            </wm-workflow-applications-needing-my-attention>
          </ng-template>

          <ng-template #applicationsPendingPayment>
            <wm-workflow-applications-pending-payments
              (recordCountSet)="
                setRecordCounts(
                  $event,
                  'wm-workflow-applications-pending-payments'
                )
              "
              [workflows]="workflows"
              (updateStatus)="updateStatusEvent($event)"
              (dataLoaded)="incrementLoaded($event)"
              (filtersChanged)="
                filtersChange('wm-workflow-applications-pending-payments')
              "
              *ngIf="workflows"
            >
            </wm-workflow-applications-pending-payments>
          </ng-template>

          <ng-template #inspectionsToSchedule>
            <app-my-inspection-queue-schedule
              (recordCountSet)="
                setRecordCounts($event, 'app-my-inspection-queue-schedule-pane')
              "
              [client]="context.client"
              (updateStatus)="updateStatusEvent($event)"
              (dataLoaded)="incrementLoaded($event)"
              (filtersChanged)="
                filtersChange('app-my-inspection-queue-schedule-pane')
              "
            ></app-my-inspection-queue-schedule>
          </ng-template>

          <ng-template #inspectionsToComplete>
            <app-my-inspection-queue-complete
              #myInspectionQueueComplete
              (recordCountSet)="
                setRecordCounts($event, 'app-my-inspection-queue-complete-pane')
              "
              [client]="context.client"
              (updateStatus)="updateStatusEvent($event)"
              (dataLoaded)="incrementLoaded($event)"
              (filtersChanged)="
                filtersChange('app-my-inspection-queue-complete-pane')
              "
            >
            </app-my-inspection-queue-complete>
          </ng-template>

          <ng-template #upcomingInspections>
            <app-upcoming-inspection-to-complete
              #upcomingInspectionToCompleteComponent
              (recordCountSet)="
                setRecordCounts(
                  $event,
                  'app-upcoming-inspection-to-complete-pane'
                )
              "
              [sortField]="upcomingInspectionsSortField"
              [sortDescending]="upcomingInspectionsSortDescending"
              [client]="context.client"
              (updateStatus)="updateStatusEvent($event)"
              (dataLoaded)="incrementLoaded($event)"
              (filtersChanged)="
                filtersChange('app-upcoming-inspection-to-complete-pane')
              "
            >
            </app-upcoming-inspection-to-complete>
          </ng-template>

          <ng-template #requestedInspections>
            <app-inspection-requested-queue
              #inspectionRequestedQueueComponent
              (recordCountSet)="
                setRecordCounts($event, 'app-inspection-requested-queue-pane')
              "
              [client]="context.client"
              (updateStatus)="updateStatusEvent($event)"
              (dataLoaded)="incrementLoaded($event)"
              (filtersChanged)="
                filtersChange('app-inspection-requested-queue-pane')
              "
            >
            </app-inspection-requested-queue>
          </ng-template>

          <ng-template #expiringRegistrations>
            <app-contractor-expire-registrations
              (recordCountSet)="
                setRecordCounts(
                  $event,
                  'app-contractor-expire-registrations-pane'
                )
              "
              (filtersChanged)="
                filtersChange('app-contractor-expire-registrations-pane')
              "
            >
            </app-contractor-expire-registrations>
          </ng-template>

          <ng-template #expiringRenewals>
            <wm-expiring-renewals
              (recordCountSet)="
                setRecordCounts($event, 'wm-expiring-renewals-pane')
              "
              (filtersChanged)="filtersChange('wm-expiring-renewals-pane')"
            >
            </wm-expiring-renewals>
          </ng-template>
        </div>
      </div>
    </div>
  </div>
</div>
