<li
  class="list-group-item d-flex justify-content-between align-items-start"
  *ngIf="de && de.entity"
>
  <div class="align-self-center">
    <h6>
      {{ de.entity.label }}
      <span *ngIf="hasErrors" class="text-warning">(validation problems)</span>
      <span *ngIf="de.entity.isRequired" class="text-danger">*</span>
      &nbsp;
      <span
        *ngIf="de.entity.isRequirement"
        class="chip m-0 bg-primary"
        style="color:white; pointer-events:none;"
        >Requirement</span
      >
    </h6>
    <span>{{ de.entity.dataEntityTypeName }}</span>
  </div>

  <div class="align-self-center d-flex align-items-center">
    <wm-activity-details-modal
      [title]="modalLabel"
      [btnShow]="false"
      [moduleId]="'modal-' + de.entity.templateCode"
      [id]="de.entity.templateCode"
      [form]="form"
      #detailModal
      (opened)="loadEditor()"
      (saved)="onSave()"
      (canceled)="onCancel()"
      [disabled]="_workflowSvc.designerVersionMode || saving"
    >
      <wm-data-entity-editor
        *ngIf="deId"
        [workflow]="workflow"
        [id]="'modal-editor' + de.entity.templateCode"
        #deEditor
        [form]="form"
        [activity]="activity"
        [dataEntityTemplateCode]="deId"
        [modalRef]="detailModal"
        (updated)="updateEntity($event)"
      ></wm-data-entity-editor>
    </wm-activity-details-modal>

    <wm-workflow-validation-item
      [showView]="false"
      [workflowId]="workflow.id"
      [itemId]="de.entity.templateCode"
      [activityId]="de.entity.parent.id"
      (valid)="validationError = $event"
    ></wm-workflow-validation-item>

    <button
      type="button"
      class="btn btn-primary bmd-btn-icon"
      (click)="showConditions(conditionContent)"
      ngbTooltip="Dependencies"
      *ngIf="
        _workflowSvc.designerVersionMode &&
        de.entity.conditionDetails &&
        de.entity.conditionDetails.length > 0
      "
    >
      <i class="material-icons">
        list
      </i>
    </button>
    <button
      type="button"
      class="btn btn-primary bmd-btn-icon"
      (click)="moveCopyModal.open()"
      ngbTooltip="Copy / Move"
      [disabled]="_workflowSvc.designerVersionMode || saving"
      *ngIf="de.entity.allowMove || de.entity.allowCopy"
    >
      <i class="material-icons">
        file_copy
      </i>
    </button>
    <button
      type="button"
      class="btn btn-primary bmd-btn-icon"
      (click)="detailModal.open()"
      [disabled]="saving"
      ngbTooltip="Edit"
    >
      <i class="material-icons">
        edit
      </i>
    </button>
    <wm-modal-confirm
      *ngIf="!de.entity.isSystemEntity || !context.client"
      title="Delete"
      ngbTooltip="Delete"
      commandText="Delete"
      btnIcon="delete"
      buttonClass="btn btn-primary bmd-btn-icon"
      [showButtonText]="true"
      [buttonStyle]="ButtonStyle.Button"
      (accepted)="deleteEntity.emit(de)"
      [enabled]="!_workflowSvc.designerVersionMode && !de.entity.isRequirement && !saving"
      #deleteDEModal
    >
      <div>
        Are you sure you want to delete this data entity?
      </div>
    </wm-modal-confirm>
  </div>
</li>

<!-- Condition Modal -->
<ng-template #conditionContent let-modal>
  <div class="modal-header">
    <h4 class="modal-title" id="modal-move-title">
      Activities Dependent On this Entity
    </h4>
    <button
      type="button"
      class="close"
      aria-label="Close"
      (click)="modal.dismiss('Cross click')"
    >
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <div *ngFor="let ca of de.entity.conditionDetails">
      <a
        style="cursor: pointer;color:link;text-decoration: underline;"
        (click)="highlightActivity(ca.activityId); modal.dismiss('Cross click')"
        >{{ ca.activityName }}</a
      >
    </div>
  </div>
</ng-template>

<!-- Copy DE modal -->
<wm-modal-confirm
  #moveCopyModal
  title="Move Data Entity"
  ngbTooltip="Move Data Entity"
  [form]="moveForm"
  [commandText]="moveLabel"
  [showButton]="false"
  buttonClass="btn btn-primary bmd-btn-icon"
  [showButtonText]="true"
  [buttonStyle]="ButtonStyle.Button"
  [closeOnAccept]="false"
  (canceled)="onMoveCopyCanceled()"
  (accepted)="onMoveCopyAccepted()"
  (opened)="loadCopyToWorkflowDetails()"
>
  <form [formGroup]="moveForm">
    <div class="form-group">
      <div class="custom-control custom-radio" *ngIf="de.entity.allowMove">
        <input
          type="radio"
          formControlName="type"
          name="type"
          id="type-move"
          value="move"
          class="custom-control-input"
        />
        <label class="custom-control-label" for="type-move">
          Move
        </label>
      </div>

      <div
        class="custom-control custom-radio"
        *ngIf="!de.entity.isRequirement && de.entity.allowMove"
      >
        <input
          type="radio"
          formControlName="type"
          name="type"
          id="type-copy"
          value="copy"
          class="custom-control-input"
        />
        <label class="custom-control-label" for="type-copy">
          Copy
        </label>
      </div>
      <div
        class="custom-control custom-radio"
        *ngIf="de.entity.allowCopy"
      >
        <input
          type="radio"
          formControlName="type"
          name="type"
          id="type-copy-to-workflow"
          value="copy-to-workflow"
          class="custom-control-input"
        />
        <label class="custom-control-label" for="type-copy-to-workflow">
          Copy to other Workflow
        </label>
      </div>
    </div>

    <div class="form-group" *ngIf="isSysAdmin && isPerformingCopy">
      <label class="bmd-label-static" for="jurisdiction">
        Jurisdiction
      </label>
      <select
        class="form-control"
        name="jurisdiction"
        formControlName="jurisdiction"
        [(ngModel)]="selectedJurisdiction"
        [disabled]="!clients"
        (ngModelChange)="clientsIdx ? changeJurisdiction(clientsIdx[$event]) : null"
      >
        <option [ngValue]="null" disabled>Select a Jurisdiction</option>
        <ng-container *ngFor="let c of clients">
          <option *ngIf="c" [value]="c.id">
            {{ c.name }}
          </option>
        </ng-container>
      </select>
    </div>

    <div class="form-group" *ngIf="isPerformingCopy">
      <label class="bmd-label-static" for="workflow">
        Workflow
      </label>
      <select
        class="form-control"
        name="workflow"
        formControlName="workflow"
        [(ngModel)]="selectedWorkflow"
        [disabled]="!workflows"
        (ngModelChange)="loadWorkflowActivities($event)"
      >
        <option [ngValue]="null" disabled>Select a Workflow</option>
        <ng-container *ngFor="let w of workflows">
          <option *ngIf="w" [value]="w.version.id">
            {{ w.version.name }}
          </option>
        </ng-container>
      </select>
    </div>

    <div class="form-group">
      <label class="bmd-label-static" for="activityId">
        To
      </label>
      <select
        class="form-control"
        name="activityId"
        formControlName="activityId"
        [(ngModel)]="selectedActivityId"
      >
        <option [ngValue]="null" disabled>Select a form activity</option>
        <ng-container *ngFor="let form of formActivities">
          <option *ngIf="form" [value]="form.id">
            {{ form.model.screenName }}
          </option>
        </ng-container>
      </select>
    </div>
    <!-- <div *ngIf="migratingCustomFields">
      <p>
        Updating Custom Field data...
      </p>
      <app-loader></app-loader>
    </div> -->
  </form>
</wm-modal-confirm>

<!-- Confirm move and migrate custom fields modal-->
<wm-modal-confirm
  #confirmMoveModal
  title="Move Data Entity"
  ngbTooltip="Move Data Entity"
  [commandText]="moveLabel"
  [showButton]="false"
  buttonClass="btn btn-primary bmd-btn-icon"
  [showButtonText]="true"
  [buttonStyle]="ButtonStyle.Button"
  (canceled)="onAbortMove()"
  (accepted)="move(moveForm.value)"
>
  <wm-move-custom-field-info
    [hasContractorToRegistrationMove]="
      customFieldDataOperationType ===
      CustomFieldDataOperationType.MoveEntityFromContractorToRegistrationForm
    "
    [hasRegistrationToContractorMove]="
      customFieldDataOperationType ===
      CustomFieldDataOperationType.MoveEntityFromRegistrationToContractorForm
    "
  ></wm-move-custom-field-info>

  <p>
    Are you sure you want to move this Data Entity to
    {{ selectedActivityName }}?
  </p>
</wm-modal-confirm>
