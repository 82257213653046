import {
  Component,
  OnInit,
  Input,
  ChangeDetectorRef,
  Output,
  EventEmitter,
  AfterViewChecked
} from '@angular/core';
import { DataEntity } from 'src/app/models/data-entities';
import {
  Validators,
  UntypedFormGroup,
  UntypedFormBuilder
} from '@angular/forms';
import {
  ExternalDataSource,
  ExternalDataSourceTypeCodes
} from 'src/app/models/external-data-source';

@Component({
  selector: 'wm-external-data-source-config',
  templateUrl: './external-data-source-config.component.html',
  styleUrls: ['./external-data-source-config.component.css']
})
export class ExternalDataSourceConfigComponent implements OnInit, AfterViewChecked {
  @Input() entity: DataEntity;
  @Input() form: UntypedFormGroup;

  @Output() externalSourceUpdated: EventEmitter<ExternalDataSource> = new EventEmitter<ExternalDataSource>();

  availableExternalDataSources: ExternalDataSource[];
  externalDataSource: ExternalDataSource;
  externalDataSourceTypeCodes = new ExternalDataSourceTypeCodes();

  isFormSet = false;

  constructor(
    private _ref: ChangeDetectorRef, 
    private _fb: UntypedFormBuilder
  ) {}

  ngOnInit() {
    if (
      this.entity.availableExternalDataSources &&
      this.entity.availableExternalDataSources.length > 0
    ) {
      this.availableExternalDataSources = this.entity.availableExternalDataSources;

      if (this.entity.externalDataSource) {
        // do this so the object bound to the dropdown is the same object that is being iterated over for the dropdown
        this.externalDataSource = this.availableExternalDataSources.find(
          aeds => aeds.key === this.entity.externalDataSource.key
        );
      }
    }

    this.form.addControl(
      'externalDataSource',
      this._fb.control('', Validators.nullValidator)
    );

    this.form.addControl(
      'canEditExternalValue',
      this._fb.control('', Validators.nullValidator)
    );

    this.isFormSet = true;
  }

  ngAfterViewChecked() {
    this._ref.detectChanges();
  }

  handleChanges() {
    // emit a cloned object so destroying this component won't remove the EDS object from
    // the data entity before its changes are persisted
    this.externalSourceUpdated.emit(
      JSON.parse(JSON.stringify(this.externalDataSource))
    );
  }

  changeExternalSource() {
    if (this.externalDataSource) {
      // set default value of CanEditExternalValue
      this.entity.canEditExternalValue = this.externalDataSource.canEditExternalValueDefault;
    } else {
      // null out the depricated properties' values, since we are trying to clear the external data source configuration
      this.entity.externalDataField = null;
      this.entity.externalDataSourceId = null;
    }

    this.handleChanges();

    this._ref.detectChanges();
  }
}
