import { AfterViewChecked, ChangeDetectorRef, Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, ValidationErrors, Validators } from '@angular/forms';
import { WorkflowType } from 'src/app/models';
import { DataEntity } from 'src/app/models/data-entities';
import { ContractorRegistrationExternalSourceConfig, ContractorRegistrationExternalSourceSelectedMappings, ExternalDataSource } from 'src/app/models/external-data-source';
import { WorkflowService } from 'src/app/services';

@Component({
  selector: 'wm-contractor-registration-external-source-config',
  templateUrl: './contractor-registration-external-source-config.component.html',
  styleUrl: './contractor-registration-external-source-config.component.css'
})
export class ContractorRegistrationExternalSourceConfigComponent implements OnInit, OnDestroy, AfterViewChecked {
  @Input() form: UntypedFormGroup;
  @Input() externalDataSource: ExternalDataSource;
  @Input() entity: DataEntity;
  
  @Output() externalSourceUpdated: EventEmitter<any> = new EventEmitter<any>(); 

  sourceConfig: ContractorRegistrationExternalSourceConfig;
  contractorWorkflowForms = [ WorkflowService.ACTIVITIES.ContractorInfo, WorkflowService.ACTIVITIES.ContractorRegistrationInfo ];
  defaultContractorFields = [
    'BUSINESS_NAME', 
    'COMPANY_WEB_ADDRESS', 
    'CONTACT_STREET_ADDRESS_CITY', 
    'CONTACT_STREET_ADDRESS_STATE', 
    'CONTACT_ADDRESS_STREET_1', 
    'CONTACT_ADDRESS_STREET_2', 
    'CONTACT_STREET_ADDRESS_ZIP', 
    'CONTACT_BUSINESS_CELL', 
    'CONTACT_BUSINESS_FAX', 
    'CONTACT_BUSINESS_PHONE',
    'CONTACT_EMAIL', 
    'CONTACT_FIRST_NAME', 
    'CONTACT_LAST_NAME', 
    'CONTACT_TITLE'
  ];
  registerWorkflowTemplateCode: string;
  WorkflowType = WorkflowType;

  constructor(
    private _ref: ChangeDetectorRef,
    private _fb: UntypedFormBuilder
  ) {}

  ngOnInit() {
    if (this.entity.externalDataSource) {
      // it's possible the saved ExternalDataSource is no longer a valid option in the design.
      // Workflow validation will catch that, just keep it from erroring here.
      if (this.externalDataSource) {
        this.sourceConfig = this.externalDataSource.sourceConfig as ContractorRegistrationExternalSourceConfig;

        // apply any existing selections from the entity.externalDataSource
        (this.externalDataSource.sourceConfig as ContractorRegistrationExternalSourceConfig).selectedMappings =
          (this.entity.externalDataSource.sourceConfig as ContractorRegistrationExternalSourceConfig).selectedMappings 
          || new ContractorRegistrationExternalSourceSelectedMappings({ mapFromThisWorkflow: false });
      }
    }

    this.setregisterWorkflowTemplateCode();

    this.form.addControl(
      'contractorRegistrationExternalDataField',
      this._fb.control('', Validators.nullValidator)
    );

    this.form.addControl(
      'mapFromThisWorkflow',
      this._fb.control('', Validators.nullValidator)
    );
  }

  ngAfterViewChecked() {
    this._ref.detectChanges();
  }
  
  ngOnDestroy() {
    // this seems to be necessary for controls with custom validator functions
    this.form.removeControl('contractorRegistrationExternalDataField');
  }

  handleChanges() {
    this.externalSourceUpdated.emit();

    this.setValidators();
  }

  setregisterWorkflowTemplateCode() {
    const selectedMappings = (this.externalDataSource.sourceConfig as ContractorRegistrationExternalSourceConfig).selectedMappings;

    this.registerWorkflowTemplateCode = selectedMappings
      ? selectedMappings.registerWorkflowFieldTemplateCode
      : null;
  }

  changeRegisterWorkflowTemplateCode(templateCode: string) {
    (this.externalDataSource.sourceConfig as ContractorRegistrationExternalSourceConfig)
        .selectedMappings
        .registerWorkflowFieldTemplateCode = templateCode;

    this.setregisterWorkflowTemplateCode();

    this.handleChanges();
  }

  setValidators() {
    this.form.get('contractorRegistrationExternalDataField').clearValidators();

    this.form.get('contractorRegistrationExternalDataField').updateValueAndValidity();

    this.form.get('contractorRegistrationExternalDataField').setValidators(this.contractorFieldMapped.bind(this));
    
    this.form.get('contractorRegistrationExternalDataField').updateValueAndValidity();
  }

  contractorFieldMapped(): ValidationErrors | null {
    const selectedMappings = (this.externalDataSource.sourceConfig as ContractorRegistrationExternalSourceConfig)
      .selectedMappings;

    if (selectedMappings.mapFromThisWorkflow 
      || ( selectedMappings.registerWorkflowFieldTemplateCode
        && selectedMappings.registerWorkflowFieldTemplateCode !== ''
      )
    ) {
      return null;
    }
    
    return { noContractorWorkflowFieldMapped: true };
  }
}
