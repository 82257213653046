import { AfterViewChecked, ChangeDetectorRef, Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, ValidationErrors, Validators } from '@angular/forms';
import { DataEntity } from 'src/app/models/data-entities';
import { ExternalDataSource, RegistrationExternalSourceConfig, RegistrationExternalSourceMapping, RegistrationExternalSourceOption, RegistrationExternalSourceSelectedMappings } from 'src/app/models/external-data-source';
import { WorkflowService } from 'src/app/services';

@Component({
  selector: 'wm-registration-external-source-config',
  templateUrl: './registration-external-source-config.component.html',
  styleUrl: './registration-external-source-config.component.css'
})
export class RegistrationExternalSourceConfigComponent implements OnInit, OnDestroy, AfterViewChecked {
  @Input() form: UntypedFormGroup;
  @Input() externalDataSource: ExternalDataSource;
  @Input() entity: DataEntity;
  
  @Output() externalSourceUpdated: EventEmitter<any> = new EventEmitter<any>(); 

  sourceConfig: RegistrationExternalSourceConfig;
  renewableWorkflowForms = [WorkflowService.ACTIVITIES.Form];
  mappedValues: string[] = [];

  constructor(
    private _ref: ChangeDetectorRef,
    private _fb: UntypedFormBuilder
  ) {}

  ngOnInit() {
    if (this.entity.externalDataSource) {
      // it's possible the saved ExternalDataSource is no longer a valid option in the design.
      // Workflow validation will catch that, just keep it from erroring here.
      if (this.externalDataSource) {
        this.sourceConfig = this.externalDataSource.sourceConfig as RegistrationExternalSourceConfig;

        // apply any existing selections from the entity.externalDataSource
        (this.externalDataSource.sourceConfig as RegistrationExternalSourceConfig).selectedMappings =
          (this.entity.externalDataSource.sourceConfig as RegistrationExternalSourceConfig).selectedMappings 
          || new RegistrationExternalSourceSelectedMappings({ mapFromThisWorkflow: false });
      }
    }

    this.buildRegistrationMappedValuesIndex();

    this.form.addControl(
      'registrationExternalDataField',
      this._fb.control('', Validators.nullValidator)
    );

    this.form.addControl(
      'mapFromThisWorkflow',
      this._fb.control('', Validators.nullValidator)
    );
  }

  ngAfterViewChecked() {
    this._ref.detectChanges();
  }
  
  ngOnDestroy() {
    // this seems to be necessary for controls with custom validator functions
    this.form.removeControl('registrationExternalDataField');
  }

  handleChanges() {
    this.externalSourceUpdated.emit();

    this.setValidators();
  }

  // index selected renewable workflow templateCodes (or lack thereof) in the same order as the workflow options.
  // this makes those values dynamically accesible to be bound models for their matching data-entity-autocomplete instance
  buildRegistrationMappedValuesIndex() {
    this.mappedValues = [];

    const selectedMappings = (this.externalDataSource
      .sourceConfig as RegistrationExternalSourceConfig).selectedMappings;

    const otherWorkflowMappings = selectedMappings
      ? selectedMappings.otherWorkflowMappings
      : null;

    for (const o of (this.externalDataSource
      .sourceConfig as RegistrationExternalSourceConfig).options) {
      if (otherWorkflowMappings) {
        const mappedValue = otherWorkflowMappings.find(
          owm => owm.sourceWorkflowId === o.sourceWorkflowId
        );

        this.mappedValues.push(
          mappedValue ? mappedValue.fieldTemplateCode : ''
        );
      } else {
        this.mappedValues.push('');
      }
    }
  }

  changeParentRenewableMapping(
    option: RegistrationExternalSourceOption,
    templateCode: string
  ) {
    const otherWorkflowMappings = (this.externalDataSource
      .sourceConfig as RegistrationExternalSourceConfig).selectedMappings
      .otherWorkflowMappings;

    const existingMapping = otherWorkflowMappings
      ? otherWorkflowMappings.find(
          owm => owm.sourceWorkflowId === option.sourceWorkflowId
        )
      : null;

    if (templateCode && templateCode !== '') {
      if (existingMapping) {
        // update the mapping
        existingMapping.fieldTemplateCode = templateCode;
      } else {
        // create a new otherWorkflowMappings array if it doesn't exist yet
        if (
          !(this.externalDataSource
            .sourceConfig as RegistrationExternalSourceConfig).selectedMappings
            .otherWorkflowMappings
        ) {
          (this.externalDataSource
            .sourceConfig as RegistrationExternalSourceConfig).selectedMappings.otherWorkflowMappings = [];
        }

        // add the mapping
        (this.externalDataSource
          .sourceConfig as RegistrationExternalSourceConfig).selectedMappings.otherWorkflowMappings.push(
          new RegistrationExternalSourceMapping({
            sourceWorkflowId: option.sourceWorkflowId,
            fieldTemplateCode: templateCode
          })
        );
      }
    } else {
      // remove the existing mapping because the change was to not map anything from this renewable parent
      (this.externalDataSource
        .sourceConfig as RegistrationExternalSourceConfig).selectedMappings.otherWorkflowMappings = otherWorkflowMappings
        ? otherWorkflowMappings.filter(
            m => m.sourceWorkflowId !== option.sourceWorkflowId
          )
        : null;
    }

    this.buildRegistrationMappedValuesIndex();

    this.handleChanges();
  }

  setValidators() {
    this.form.get('registrationExternalDataField').clearValidators();

    this.form.get('registrationExternalDataField').updateValueAndValidity();

    this.form.get('registrationExternalDataField').setValidators(this.renwableFieldMapped.bind(this));
    
    this.form.get('registrationExternalDataField').updateValueAndValidity();
  }

  renwableFieldMapped(): ValidationErrors | null {
    if (this.externalDataSource) {
      if (
        (this.externalDataSource
          .sourceConfig as RegistrationExternalSourceConfig).selectedMappings
      ) {
        if (
          (this.externalDataSource
            .sourceConfig as RegistrationExternalSourceConfig).selectedMappings
            .mapFromThisWorkflow ||
          ((this.externalDataSource
            .sourceConfig as RegistrationExternalSourceConfig).selectedMappings
            .otherWorkflowMappings &&
            (this.externalDataSource
              .sourceConfig as RegistrationExternalSourceConfig)
              .selectedMappings.otherWorkflowMappings.length > 0)
        ) {
          return null;
        }
      }
    }

    return { noRenewableFieldMapped: true };
  }
}
